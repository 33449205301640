import React, { useEffect, useRef, useCallback, useState } from "react";
import {Helmet} from "react-helmet-async";
import "./style.css";
const Banner1 = React.lazy(() => import("../../components/Banner1"));
const BlocoDepoimentos = React.lazy(
  () => import("../../components/BlocoDepoimentos")
);
const BlocoParceiros = React.lazy(
  () => import("../../components/BlocoParceiros")
);
const BlocoSteps = React.lazy(() => import("../../components/BlocoSteps"));
const Footer = React.lazy(() => import("../../components/shared/Footer"));
const Header = React.lazy(() => import("../../components/shared/Header"));
const Cookies = React.lazy(() => import("../../components/Cookies"));
const BlocoLinkProposta = React.lazy(
  () => import("../../components/BlocoLinkProposta")
);

const Home: React.FC = ({ match }: any) => {
  const faqRef = useRef<HTMLDivElement>(null);
  const [parameters, setParameters] = useState<any>({});
  const [isBanrisul, setBanrisul] = useState(false);

  const [qName, setQName] = useState("");
  const [qDoc, setQDoc] = useState("");
  const [qPhone, setQPhone] = useState("");
  const [qEmail, setQEmail] = useState("");

  const [isMobile, setIsMobile] = useState(false);
  const [cookies, setCookies] = useState(
    !!localStorage.getItem("@cookies-concash")
  );
  function ajustarTela() {
    const tamanho = window.innerWidth;
    if (tamanho <= 500) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }
  const screenWidth = window.screen.width;
  window.addEventListener("resize", ajustarTela);
  useEffect(() => {
    ajustarTela();
    setIsMobile(window.innerWidth <= 500);
  }, []);

  const navegar = useCallback(() => {
    if (window.location.search === "?redirect=santander")
      window.location.href = window.location.origin + "/santander";

    if (window.location.search === "?redirect=chevrolet")
      window.location.href = window.location.origin + "/chevrolet";

    if (window.location.search === "?redirect=chevrolet")
      window.location.href = window.location.origin + "/chevrolet";

    setTimeout(() => {
      if (window.location.hash === "#faq")
        window.location.href = `${window.location.href}`;

      if (window.location.hash === "#parceiros")
        window.location.href = `${window.location.href}`;
      if (window.location.hash.includes("/venderconsorcio")) {
        window.location.href = `${window.location.href}`;
      }
      if (window.location.hash.includes("#banrisul")) {
        window.location.href = `/#venderconsorcio`;
        setBanrisul(true);
      }
    }, 500);
  }, []);

  useEffect(() => {
    var hash = window.location.search;
    if (hash.includes("nomeRazao")) {
      var result = hash.split("&").map(function (res: any, item) {
        var parts = res.split("=");
        const chave = parts[0].replace("?", "");

        const valor = parts[1].replaceAll("%20", " ");

        return { chave: chave, valor };
      }, {});
      result.map((el: any) => {
        if (el.chave === "email") setQEmail(el.valor);
        if (el.chave === "telefone") setQPhone(el.valor);
        if (el.chave === "nomeRazao") setQName(el.valor);
        if (el.chave === "cpfCnpj") setQDoc(el.valor);
      });
      setParameters(result);
    }

    if (window.location.href.includes("banrisul")) {
      localStorage.setItem("administradoraO", "114");
      window.location.href = "/venderconsorcio";
    }
    navegar();
  }, []);

  return (
    <>
      {!cookies && <Cookies setCookies={setCookies} />}
      <Helmet prioritizeSeoTags>
        <title>Concash - Vender Consórcio</title>
        <link rel="canonical" href="https://concash.com.br/" />

        <meta
          name="description"
          content="Venda seu consórcio na Concash com a melhor negociação e receba seu dinheiro à vista, no prazo mais rápido do mercado. Solicite sua proposta."
        />
          <meta property="og:title" content="Concash - Vender Consórcio"/>

      </Helmet>
      <div style={{ backgroundColor: "#fff", overflow: "hidden" }}>
        <Header mobile={isMobile} />
        <Banner1 mobile={isMobile} />
        <BlocoParceiros mobile={isMobile} />
        <BlocoSteps />
        <BlocoLinkProposta />
        <BlocoDepoimentos />
        <Footer />
      </div>
    </>
  );
};

export default Home;
