import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { colors } from "../../styles/colors";
import "./style.css";
import { V3TextCustom } from "../../styles/themes/components/input";

interface SSearcheableProps {
  options: any;
  action?: any;
  id: string;
  name: string;
  label: string;
  initial?: any;
  size?: string;
  height?: any;
}

export const SSearcheable: React.FC<SSearcheableProps> = ({
  options,
  id,
  name,
  action,
  label,
}) => {
  const [value, setValue] = React.useState<string | null>(options[0]);
  const [text, setText] = useState(null);

  return (
    <>
      <Autocomplete
        id={id}
        sx={{
          bgcolor: colors.lightGray,
          borders: 0,
          display: "flex",
          borderColor: "transparent !important",
          flex: 1,
        }}
        options={options}
        onChange={(event: any, newValue: any) => {
          if (newValue) {
            setValue(newValue);
            setText(newValue.label);
            action(newValue);
          } else {
            setValue("");
            setText(null);
            action("");
          }
        }}
        value={value}
        renderInput={(params) => (
          <TextField
            label={label}
            {...params}
            sx={{
              bgcolor: colors.lightGray,
              border: 0,
              display: "flex",
              flex: 1,
            }}
          />
        )}
      />
    </>
  );
};

export const V3Searcheable: React.FC<SSearcheableProps> = ({
  options,
  id,
  name,
  action,
  label,
  initial,
  size,
  height,
}) => {
  const [value, setValue] = React.useState<any>(options[0]);

  const [etiqueta, setEtiqueta] = useState(initial ? initial.label : label);
  useEffect(() => {
    setValue(initial);
  }, []);

  useEffect(() => {}, [value]);

  return (
    <>
      <Autocomplete
        id={id}
        sx={{
          borders: 0,
          display: "flex",
          borderColor: "transparent !important",
          flex: 1,
          height: height,
        }}
        options={options}
        defaultValue={initial}
        defaultChecked
        onChange={(event: any, newValue: any) => {
          if (!newValue) {
            setValue(null);
            action(null);
          } else {
            setValue(newValue);
            action(newValue);
          }
        }}
        value={value}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <V3TextCustom
            label={initial ? initial.label : label}
            {...params}
            sx={{
              border: 0,
              display: "flex",
              flex: 1,
            }}
          />
        )}
      />
    </>
  );
};
