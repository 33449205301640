export const administradorasList = [
  {
      "id": 93,
      "label": "ADEMICON ADMINISTRADORA DE CONSORCIOS S.A."
  },
  {
      "id": 94,
      "label": "ADMINISTRADORA DE CONSORCIO NACIONAL GAZIN LTDA"
  },
  {
      "id": 95,
      "label": "ADMINISTRADORA DE CONSORCIO NACIONAL HONDA LTDA"
  },
  {
      "id": 97,
      "label": "ADMINISTRADORA DE CONSORCIO NACIONAL LIDER LTDA."
  },
  {
      "id": 98,
      "label": "ADMINISTRADORA DE CONSORCIO NACIONAL VALOR S.A."
  },
  {
      "id": 99,
      "label": "ADMINISTRADORA DE CONSORCIO NACIONAL VIME LTDA."
  },
  {
      "id": 100,
      "label": "ADMINISTRADORA DE CONSORCIO RCI BRASIL LTDA"
  },
  {
      "id": 101,
      "label": "ADMINISTRADORA DE CONSORCIO SPENGLER LTDA."
  },
  {
      "id": 102,
      "label": "ADMINISTRADORA DE CONSORCIO UNICOOB LTDA"
  },
  {
      "id": 103,
      "label": "ADMINISTRADORA DE CONSORCIOS BECKER LTDA."
  },
  {
      "id": 96,
      "label": "ADMINISTRADORA DE CONSORCIOS SICREDI LTDA"
  },
  {
      "id": 104,
      "label": "AGIBANK ADMINISTRADORA DE CONSORCIOS LTDA"
  },
  {
      "id": 105,
      "label": "ALPHA ADMINISTRADORA DE CONSORCIO LTDA."
  },
  {
      "id": 106,
      "label": "ANCORA ADMINISTRADORA DE CONSORCIOS S.A."
  },
  {
      "id": 107,
      "label": "APEC - ADMINISTRADORA DE CONSORCIOS S/A"
  },
  {
      "id": 111,
      "label": "APICE ADMINISTRADORA DE CONSORCIO LTDA"
  },
  {
      "id": 108,
      "label": "ASSOCIACAO BAHIANA DE MEDICINA ABM"
  },
  {
      "id": 109,
      "label": "ASSOCIACAO DOS JUIZES DO RIO GRANDE DO SUL"
  },
  {
      "id": 110,
      "label": "ATUAL ADMINISTRADORA DE CONSORCIO LTDA."
  },
  {
      "id": 112,
      "label": "BAMAQ ADMINISTRADORA DE CONSORCIOS LTDA"
  },
  {
      "id": 113,
      "label": "BANCORBRAS ADMINISTRADORA DE CONSORCIOS S.A."
  },
  {
      "id": 114,
      "label": "BANRISUL S.A. - ADMINISTRADORA DE CONSORCIOS"
  },
  {
      "id": 115,
      "label": "BB ADMINISTRADORA DE CONSORCIOS S.A."
  },
  {
      "id": 116,
      "label": "BBC ADMINISTRADORA DE CONSORCIOS LTDA"
  },
  {
      "id": 117,
      "label": "BR CONSORCIOS ADMINISTRADORA DE CONSORCIOS LTDA"
  },
  {
      "id": 118,
      "label": "BRADESCO ADMINISTRADORA DE CONSORCIOS LTDA."
  },
  {
      "id": 119,
      "label": "BREITKOPF ADMINISTRADORA DE CONSORCIO LTDA."
  },
  {
      "id": 120,
      "label": "BRENNER ADMINISTRADORA DE CONSORCIO LTDA"
  },
  {
      "id": 121,
      "label": "BRISA ADMINISTRADORA DE CONSORCIOS LTDA"
  },
  {
      "id": 122,
      "label": "BRQUALY ADMINISTRADORA DE CONSORCIOS LTDA."
  },
  {
      "id": 124,
      "label": "CAMVEL ADMINISTRADORA DE CONSORCIOS LTDA."
  },
  {
      "id": 125,
      "label": "CANOPUS ADMINISTRADORA DE CONSORCIOS S/A"
  },
  {
      "id": 126,
      "label": "CARLESSI ADMINISTRADORA DE CONSORCIOS LTDA"
  },
  {
      "id": 127,
      "label": "CICAL ADMINISTRADORA DE CONSORCIOS LTDA"
  },
  {
      "id": 128,
      "label": "CIMAVEL ADMINISTRADORA DE CONSORCIO LTDA."
  },
  {
      "id": 129,
      "label": "CLUBE NAVAL - CAIXA BENEFICIENTE"
  },
  {
      "id": 130,
      "label": "CNF - ADMINISTRADORA DE CONSORCIOS NACIONAL LTDA."
  },
  {
      "id": 131,
      "label": "CNK ADMINISTRADORA DE CONSORCIO LTDA."
  },
  {
      "id": 123,
      "label": "CNP CONSORCIO S.A. ADMINISTRADORA DE CONSORCIOS"
  },
  {
      "id": 132,
      "label": "COIMEX ADMINISTRADORA DE CONSORCIOS S.A."
  },
  {
      "id": 133,
      "label": "COMAUTO ADMINISTRADORA DE CONSORCIO LTDA"
  },
  {
      "id": 134,
      "label": "CONBR ADMINISTRADORA DE CONSORCIOS LTDA."
  },
  {
      "id": 135,
      "label": "CONBRAV - ADMINISTRADORA DE CONSORCIOS LTDA."
  },
  {
      "id": 136,
      "label": "CONESUL ADMINISTRADORA DE CONSORCIOS LTDA."
  },
  {
      "id": 137,
      "label": "CONSHOP ADMINISTRADORA DE CONSORCIOS LTDA."
  },
  {
      "id": 138,
      "label": "CONSORCIO NACIONAL VOLKSWAGEN - ADMINISTRADORA DE CONSORCIO LTDA."
  },
  {
      "id": 139,
      "label": "CONVEF ADMINISTRADORA DE CONSORCIOS LTDA."
  },
  {
      "id": 140,
      "label": "COOPERATIVA MISTA JOCKEY - PROIBIDA DE COMERCIALIZAR COTAS DE CONSORCIO POR SI OU POR REPRESENTANTES PESSOAS FISICAS OU JURIDICAS, POR 5 ANOS, NA SUBSECAO JUDICIARIA DE MARILIA/SP"
  },
  {
      "id": 141,
      "label": "DESTAQUE ADMINISTRADORA DE CONSORCIOS LTDA"
  },
  {
      "id": 142,
      "label": "DISAL ADMINISTRADORA DE CONSORCIOS LTDA"
  },
  {
      "id": 2,
      "label": "DISBRAVE ADMINISTRADORA DE CONSORCIOS LTDA."
  },
  {
      "id": 3,
      "label": "ELDORADO ADMINISTRADORA DE CONSORCIO LTDA"
  },
  {
      "id": 4,
      "label": "EMBRACON ADMINISTRADORA DE CONSORCIO LTDA"
  },
  {
      "id": 5,
      "label": "EUTBEM ADMINISTRADORA DE CONSORCIO S.A."
  },
  {
      "id": 6,
      "label": "EVOY ADMINISTRADORA DE CONSORCIO LTDA."
  },
  {
      "id": 7,
      "label": "FANCAR ADMINISTRADORA DE CONSORCIO LTDA."
  },
  {
      "id": 8,
      "label": "FARROUPILHA ADMINISTRADORA DE CONSORCIOS LTDA."
  },
  {
      "id": 9,
      "label": "FEDERACAO NAC. DE ASSOCIACOES DOS SERVIDORES DO BCO CENTRAL"
  },
  {
      "id": 10,
      "label": "FERRAZ ADMINISTRACAO E CONSORCIOS LTDA"
  },
  {
      "id": 11,
      "label": "FIEL PADUA ADMINISTRADORA DE CONSORCIOS LTDA"
  },
  {
      "id": 12,
      "label": "FINAMA ADMINISTRADORA DE CONSORCIO LTDA"
  },
  {
      "id": 13,
      "label": "FIPAL ADMINISTRADORA DE CONSORCIOS LTDA"
  },
  {
      "id": 14,
      "label": "FRA-FREIRE ADMINISTRADORA DE CONSORCIOS LTDA."
  },
  {
      "id": 15,
      "label": "FRANCAUTO ADMINISTRADORA DE CONSORCIO LTDA."
  },
  {
      "id": 16,
      "label": "FUGANTI ADMINISTRADORA DE CONSORCIOS LTDA."
  },
  {
      "id": 17,
      "label": "FUNDACAO HABITACIONAL DO EXERCITO - FHE"
  },
  {
      "id": 18,
      "label": "GAMBATTO ADMINISTRADORA DE CONSORCIOS LTDA."
  },
  {
      "id": 19,
      "label": "GAPLAN ADMINISTRADORA DE CONSORCIO LTDA."
  },
  {
      "id": 20,
      "label": "GLOBEX ADMINISTRADORA DE CONSORCIOS LTDA."
  },
  {
      "id": 21,
      "label": "GLOBO ADMINISTRADORA DE CONSORCIOS LTDA."
  },
  {
      "id": 22,
      "label": "GMAC ADMINISTRADORA DE CONSORCIOS LTDA (CHEVROLET)"
  },
  {
      "id": 23,
      "label": "GRAVEL ADMINISTRADORA DE CONSORCIOS LTDA."
  },
  {
      "id": 24,
      "label": "GROSCON ADMINISTRADORA DE CONSORCIOS LTDA."
  },
  {
      "id": 25,
      "label": "GUARAPIRANGA VEICULOS ADMINISTRADORA DE CONSORCIOS LTDA."
  },
  {
      "id": 26,
      "label": "H. ADMINISTRADORA DE CONSORCIOS LTDA"
  },
  {
      "id": 27,
      "label": "HS ADMINISTRADORA DE CONSORCIOS LTDA"
  },
  {
      "id": 28,
      "label": "IRMAOS DE MARCO ADMINISTRADORA DE CONSORCIOS LTDA."
  },
  {
      "id": 29,
      "label": "ITAU ADMINISTRADORA DE CONSORCIOS LTDA."
  },
  {
      "id": 30,
      "label": "ITAU UNIBANCO VEICULOS ADMINISTRADORA DE CONSORCIOS LTDA."
  },
  {
      "id": 31,
      "label": "JORLAN ADMINISTRADORA DE CONSORCIO LTDA."
  },
  {
      "id": 32,
      "label": "JUGASA ADMINISTRADORA DE CONSORCIO S/A"
  },
  {
      "id": 33,
      "label": "KLUBI ADMINISTRADORA DE CONSORCIOS LTDA."
  },
  {
      "id": 34,
      "label": "LUIZA ADMINISTRADORA DE CONSORCIOS LTDA (MAGALU)"
  },
  {
      "id": 35,
      "label": "MAGGI ADMINISTRADORA DE CONSORCIOS LTDA"
  },
  {
      "id": 36,
      "label": "MASSEY FERGUSON ADMINISTRADORA DE CONSORCIOS LTDA"
  },
  {
      "id": 37,
      "label": "MENEGALLI ADMINISTRADORA DE CONSORCIOS LTDA"
  },
  {
      "id": 38,
      "label": "MERCABENCO ADMINISTRADORA DE CONSORCIO LTDA"
  },
  {
      "id": 39,
      "label": "MINAUTO ADMINISTRADORA DE CONSORCIO LTDA."
  },
  {
      "id": 40,
      "label": "MOTOASA ADMINISTRADORA DE CONSORCIOS LTDA."
  },
  {
      "id": 41,
      "label": "MULTIMARCAS ADMINISTRADORA DE CONSORCIOS LTDA."
  },
  {
      "id": 42,
      "label": "NANUQUE ADMINISTRADORA DE CONSORCIOS LTDA."
  },
  {
      "id": 43,
      "label": "NOVOTEMPO ADMINISTRADORA DE CONSORCIOS LTDA."
  },
  {
      "id": 44,
      "label": "OPCAO ADMINISTRADORA DE CONSORCIOS LTDA"
  },
  {
      "id": 45,
      "label": "PAN ADMINISTRADORA DE CONSORCIO LTDA"
  },
  {
      "id": 46,
      "label": "PORTO SEGURO ADMINISTRADORA DE CONSORCIOS LTDA"
  },
  {
      "id": 47,
      "label": "PORTOBENS ADMINISTRADORA DE CONSORCIOS LTDA"
  },
  {
      "id": 48,
      "label": "PRIMO ROSSI ADMINISTRADORA DE CONSORCIO LTDA."
  },
  {
      "id": 49,
      "label": "PROMOVE ADMINISTRADORA DE CONSORCIO LTDA."
  },
  {
      "id": 50,
      "label": "QUALINVEST ADMINISTRADORA DE CONSORCIOS LTDA."
  },
  {
      "id": 51,
      "label": "RANDON ADMINISTRADORA DE CONSORCIOS LTDA."
  },
  {
      "id": 52,
      "label": "RCN ADMINISTRADORA DE CONSORCIO NACIONAL LTDA"
  },
  {
      "id": 53,
      "label": "RECON ADMINISTRADORA DE CONSORCIOS LTDA"
  },
  {
      "id": 54,
      "label": "REDE OESTE ADMINISTRADORA DE CONSORCIOS LTDA."
  },
  {
      "id": 55,
      "label": "REDENCAO ADMINISTRADORA DE CONSORCIO LTDA."
  },
  {
      "id": 56,
      "label": "REMAZA ADMINISTRADORA DE CONSORCIO LTDA."
  },
  {
      "id": 57,
      "label": "RESERVA ADMINISTRADORA DE CONSORCIO LTDA"
  },
  {
      "id": 58,
      "label": "RIVEL ADMINISTRADORA DE CONSORCIOS LTDA."
  },
  {
      "id": 59,
      "label": "RODOBENS ADMINISTRADORA DE CONSORCIOS LTDA."
  },
  {
      "id": 60,
      "label": "SANMELL - ADMINISTRADORA DE CONSORCIOS LTDA"
  },
  {
      "id": 61,
      "label": "SANTA FE ADMINISTRADORA DE CONSORCIOS LTDA."
  },
  {
      "id": 62,
      "label": "SANTANDER BRASIL ADMINISTRADORA DE CONSORCIO LTDA."
  },
  {
      "id": 63,
      "label": "SCANIA ADMINISTRADORA DE CONSORCIOS LTDA."
  },
  {
      "id": 64,
      "label": "SERRANA ADMINISTRADORA DE CONSORCIOS LTDA"
  },
  {
      "id": 65,
      "label": "SERVOPA ADMINISTRADORA DE CONSORCIOS LTDA"
  },
  {
      "id": 66,
      "label": "SICOOB ADMINISTRADORA DE CONSORCIOS LTDA."
  },
  {
      "id": 67,
      "label": "SIMAO ADMINISTRADORA DE CONSORCIOS LTDA"
  },
  {
      "id": 68,
      "label": "SIMPALA LANCADORA E ADMINISTRADORA DE CONSORCIOS LTDA."
  },
  {
      "id": 69,
      "label": "SINOSSERRA ADMINISTRADORA DE CONSORCIOS LTDA"
  },
  {
      "id": 70,
      "label": "SOCIEDADE ADMINISTRADORA DE CONSORCIOS LTDA."
  },
  {
      "id": 71,
      "label": "SOLUCAO ADMINISTRADORA DE CONSORCIOS LTDA."
  },
  {
      "id": 72,
      "label": "SPERTA ADMINISTRADORA DE CONSORCIO NACIONAL LTDA"
  },
  {
      "id": 73,
      "label": "SPONCHIADO ADMINISTRADORA DE CONSORCIOS LTDA."
  },
  {
      "id": 74,
      "label": "SUZUKI MOTOS ADMINISTRADORA DE CONSORCIO LTDA."
  },
  {
      "id": 76,
      "label": "TAGIDE ADMINISTRADORA DE CONSORCIOS LTDA."
  },
  {
      "id": 75,
      "label": "TARRAF ADMINISTRADORA DE CONSORCIOS LTDA."
  },
  {
      "id": 77,
      "label": "TOYOTA ADMINISTRADORA DE CONSORCIOS DO BRASIL LTDA."
  },
  {
      "id": 78,
      "label": "TRADICAO ADMINISTRADORA DE CONSORCIO LTDA."
  },
  {
      "id": 79,
      "label": "TRIANGULO ADMINISTRADORA DE CONSORCIOS LTDA"
  },
  {
      "id": 80,
      "label": "UNAFISCO - ASSOCIACAO NACIONAL DOS AUDITORES FISCAIS DA RECEITA FEDERAL DO BRASIL"
  },
  {
      "id": 81,
      "label": "UNECONSORCIO ADMINISTRADORA DE CONSORCIOS LTDA"
  },
  {
      "id": 82,
      "label": "UNIAO CATARINENSE ADMINISTRADORA DE CONSORCIOS LTDA"
  },
  {
      "id": 83,
      "label": "UNIFISA ADMINISTRADORA NACIONAL DE CONSORCIOS LTDA"
  },
  {
      "id": 84,
      "label": "VALTRA ADMINISTRADORA DE CONSORCIOS LTDA"
  },
  {
      "id": 85,
      "label": "VEMAR ADMINISTRADORA DE CONSORCIO LTDA."
  },
  {
      "id": 86,
      "label": "VINAC ADMINISTRADORA DE CONSORCIOS LTDA."
  },
  {
      "id": 87,
      "label": "VIVIANI ADMINISTRADORA DE CONSORCIO NACIONAL LTDA."
  },
  {
      "id": 88,
      "label": "VKN ADMINISTRADORA DE CONSORCIO LTDA."
  },
  {
      "id": 89,
      "label": "VOLVO ADMINISTRADORA DE CONSORCIO LTDA."
  },
  {
      "id": 90,
      "label": "XS5 ADMINISTRADORA DE CONSORCIOS S.A."
  },
  {
      "id": 91,
      "label": "YAMAHA ADMINISTRADORA DE CONSORCIO LTDA."
  },
  {
      "id": 92,
      "label": "ZEMA ADMINISTRADORA DE CONSORCIO LTDA"
  }
];