import styled from "styled-components";

export const BoxStepDesktop = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 650px;
  min-height: 550px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 15px 40px #0000001a;
  border-radius: 20px;
  opacity: 1;
  margin-bottom: 50px;
  padding: 20px;
`;

export const BoxStepMobile = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100vw;
  height: fit-content;
`;

export const CenteredBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const CenteredBoxMobile = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  align-content: center;
  margin-left: 15px;
  margin-right: 15px;
`;

export const BoxPinStep = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 37px;
  margin-bottom: 30px;
`;
