import { useState, useEffect } from "react";
import {
  BoxPinStep,
  BoxStep,
  BoxStepDesktop,
  BoxStepMobile,
  CenteredBox,
  CenteredBoxMobile,
} from "../../../styles/themes/components/box";
import {
  V3Button,
  V3RadioButton,
} from "../../../styles/themes/components/button";
import { PinStepActive } from "../../../styles/themes/components/pin";
import {
  V3PropostaText,
  V3Text5,
  V3TextFooter,
  V3TextH1,
  V3TextInputInfo,
  V3Text5Black,
} from "../../../styles/themes/components/texts";
import { v3Colors } from "../../../styles/themes/v3/colors";
import FloatButton from "../../../components/FloatButton";

import { ImInfo } from "react-icons/im";
import { V3Modal } from "../../../styles/themes/components/modal";
import { formataCPF } from "../../../utils/formaters";
import { v3ValidateEmail } from "../../../styles/themes/utils/validate";
import { Checkbox, FormControlLabel } from "@mui/material";
import {
  ContainerDesktopOPT,
  ContainerMobileOPT,
} from "../../../styles/themes/components/shared/styles";
import ConcashLogo from "../../../assets/images/logo-negative.png";
import { BsWhatsapp } from "react-icons/bs";
import { RiPhoneLine } from "react-icons/ri";
import moment from "moment";
export const SucessoOferta = (props) => {
  const { navigtion } = props;
  const mobile = window.matchMedia("(max-width:768px)").matches;
  var whatsapp = localStorage.getItem("whatsapp");

  useEffect(() => {
        const vc = localStorage.getItem("concluido");
        if (vc !== "true") {
          window.location.href = "/";
        }
    whatsapp =
      "https://api.whatsapp.com/send/?phone=551140405686&text=Ol%C3%A1%2C+quero+vender+minha+cota+de+consorcio+para+a+Concash";
    localStorage.clear();
  }, []);

  return (
    <>
      {mobile && (
        <>
          <ContainerMobileOPT>
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: 40,
              }}
            >
              <img
                loading="lazy"
                onClick={() => (window.location.href = "/")}
                src={ConcashLogo}
                style={{ marginBottom: 10 }}
                width={110}
                height={30}
                alt="concash"
              />
              <V3Text5
                style={{
                  alignSelf: "center",
                  textAlign: "center",
                  marginBottom: 10,
                  padding: "10px 30px ",
                  fontWeight: "400",
                  lineHeight: 1.3,
                  fontSize: 15,
                }}
              >
                Venda seu consórcio online com segurança, rapidez e
                transparência. <br />
                Caso queira saber mais sobre a Concash{" "}
                <a href="/" style={{ color: v3Colors.grayHard }}>
                  clique aqui
                </a>
                .
              </V3Text5>
              <BoxStepMobile style={{ minHeight: 450 }}>
                <BoxPinStep>
                  <PinStepActive num={1} type="checked" />
                  <PinStepActive num={2} type="checked" />
                  <PinStepActive num={3} type="checked" />
                  <PinStepActive num={4} type="checked" />
                </BoxPinStep>
                <CenteredBoxMobile>
                  <V3TextH1 style={{ textAlign: "center" }}>
                    Obrigado por escolher a Concash!
                  </V3TextH1>
                </CenteredBoxMobile>
                <CenteredBoxMobile style={{ marginTop: "15px" }}>
                  <V3Text5Black style={{ textAlign: "center" }}>
                    Pode ficar tranquilo que um de nossos consultores irá entrar
                    em contato com você por ligação ou WhatsApp através do
                    número (11) 4040-5686 e te apresentar a melhor oferta.
                  </V3Text5Black>
                </CenteredBoxMobile>
                <CenteredBoxMobile
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: 15,
                    gap: 10,
                    padding: "0px 10px",
                  }}
                >
                  <V3Text5Black style={{ textAlign: "center" }}>
                    Logo mais o nosso novo Portal do Cliente estará pronto e
                    você poderá acompanhar toda a venda da sua conta de forma
                    100% online.
                    <br />
                    Caso tenha alguma dúvida, pode chamar a Concash através de
                    um dos nossos canais clicando nos botões abaixo:
                  </V3Text5Black>
                </CenteredBoxMobile>

                <CenteredBoxMobile style={{ gap: 20, marginTop: 30 }}>
                  <div
                    onClick={() => (window.location.href = "tel:+551140405686")}
                    style={{
                      display: "flex",
                      width: 250,
                      height: 50,
                      borderRadius: 8,
                      gap: 15,
                      backgroundColor: v3Colors.primary,
                      color: v3Colors.white,
                      alignItems: "center",
                      justifyContent: "center",
                      fontFamily: "Roboto, sans-serif",
                      fontWeight: "500",
                    }}
                  >
                    <RiPhoneLine size={24} />
                    <span href="tel:+551140405686">(11) 4040-5686</span>
                  </div>
                </CenteredBoxMobile>
                <CenteredBoxMobile style={{ gap: 20, marginTop: 30 }}>
                  <div
                    onClick={() => window.open(whatsapp, "_blank")}
                    style={{
                      display: "flex",
                      width: 250,
                      height: 50,
                      borderRadius: 8,
                      gap: 15,
                      backgroundColor: v3Colors.primary,
                      color: v3Colors.white,
                      alignItems: "center",
                      justifyContent: "center",
                      fontFamily: "Roboto",
                      fontWeight: "500",
                    }}
                  >
                    <BsWhatsapp size={24} />
                    <span>(11) 4040-5686</span>
                  </div>
                </CenteredBoxMobile>
              </BoxStepMobile>
            </div>
            <div>
              <V3TextFooter>
                © Copyright {moment(new Date()).format("YYYY")} Concash. Todos
                os direitos reservados{" "}
              </V3TextFooter>
            </div>

          </ContainerMobileOPT>
        </>
      )}
      {!mobile && (
        <ContainerDesktopOPT>
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: 40,
            }}
          >
            <img
              loading="lazy"
              onClick={() => (window.location.href = "/")}
              src={ConcashLogo}
              style={{ marginBottom: 10 }}
              width={110}
              height={30}
              alt="concash"
            />
            <V3Text5
              style={{
                alignSelf: "center",
                textAlign: "center",
                marginBottom: 10,
                padding: "10px 30px ",
                fontWeight: "400",
                lineHeight: 1.3,
                fontSize: 15,
              }}
            >
              Venda seu consórcio online com segurança, rapidez e transparência.
              <br /> Caso queira saber mais sobre a Concash{" "}
              <a href="/" style={{ color: v3Colors.grayHard }}>
                clique aqui
              </a>
              .
            </V3Text5>
            <BoxStepDesktop style={{ minHeight: 450 }}>
              <BoxPinStep>
                <PinStepActive num={1} type="checked" />
                <PinStepActive num={2} type="checked" />
                <PinStepActive num={3} type="checked" />
                <PinStepActive num={4} type="checked" />
              </BoxPinStep>
              <CenteredBoxMobile>
                <V3TextH1 style={{ textAlign: "center" }}>
                  Obrigado por escolher a Concash!
                </V3TextH1>
              </CenteredBoxMobile>
              <CenteredBoxMobile style={{ marginTop: "15px" }}>
                <V3Text5Black style={{ textAlign: "center" }}>
                  <a
                    href="https://cliente.hml.concash.com.br"
                    style={{ color: v3Colors.black }}
                    target="_blank" rel="noreferrer"
                  >
                    Clique aqui
                  </a>{" "}
                  e acesse o portal do cliente para acompanhar as etapas de
                  venda da sua cota e enviar a documentação necessária para
                  gerarmos o seu termo e o seu contrato.
                </V3Text5Black>
              </CenteredBoxMobile>
              <CenteredBox
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 15,
                  gap: 10,
                  padding: "0px 100px",
                }}
              >
                <V3Text5Black style={{ textAlign: "center" }}>
                  Caso tenha alguma dúvida, pode chamar um de nossos
                  especialistas em um dos canais abaixo.
                </V3Text5Black>
              </CenteredBox>

              <CenteredBox
                style={{
                  marginTop: 15,
                  padding: "0px 100px",
                }}
              ></CenteredBox>

              <CenteredBox style={{ gap: 20, marginTop: 30 }}>
                <div
                  onClick={() => (window.location.href = "tel:+551140405686")}
                  style={{
                    display: "flex",
                    width: 250,
                    height: 50,
                    borderRadius: 8,
                    gap: 15,
                    backgroundColor: v3Colors.primary,
                    color: v3Colors.white,
                    alignItems: "center",
                    justifyContent: "center",
                    fontFamily: "Roboto",
                    fontWeight: "500",
                  }}
                >
                  <RiPhoneLine size={24} />
                  <span href="tel:+551140405686">(11) 4040-5686</span>
                </div>
              </CenteredBox>
              <CenteredBox style={{ gap: 20, marginTop: 30 }}>
                <div
                  onClick={() => window.open(whatsapp, "_blank")}
                  style={{
                    display: "flex",
                    width: 250,
                    height: 50,
                    borderRadius: 8,
                    gap: 15,
                    backgroundColor: v3Colors.primary,
                    color: v3Colors.white,
                    alignItems: "center",
                    justifyContent: "center",
                    fontFamily: "Roboto",
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                >
                  <BsWhatsapp size={24} />
                  <span>(11) 4040-5686</span>
                </div>
              </CenteredBox>
            </BoxStepDesktop>
          </div>

          <div style={{ position: "relative", bottom: -15 }}>
            <V3TextFooter style={{ textAlign: "center" }}>
              © Copyright {moment(new Date()).format("YYYY")} Concash.
              <br />
              Todos os direitos reservados{" "}
            </V3TextFooter>

          </div>
        </ContainerDesktopOPT>
      )}
    </>
  );
};
