import axios from "axios";
import { v1 as uuidv1 } from "uuid";
const nodeEnv = process.env.REACT_APP_API_WEBSITES_URL;

export const ambiente: any = nodeEnv?.includes("hml") ? "hml" : "prod";

const api = axios.create({
  baseURL: nodeEnv,
});

api.interceptors.request.use(async (config: any) => {
  const RequestId = uuidv1();
  if (RequestId) {
    config.headers["RequestId"] = RequestId;
  }
  return config;
});

axios.interceptors.response.use(
  (response: any) => response,
  (error: any) => {
    console.log("error");
    console.log(error);
  }
);

export default api;
