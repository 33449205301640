import styled from "styled-components";
import { colors } from "../../styles/colors";
import { ScreenDisplay } from "../../styles/display";

export const ContactContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 150px 83px 210px 83px;

  .title-box {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0 200px;
    gap: 15px;
    margin-bottom: 50px;
  }

  .title {
    text-align: center;
    font: normal normal bold 50px/50px Roboto;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }

  .subtitle {
    text-align: center;
    font: normal normal normal 15px/23px Roboto;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }

  .box-main {
    display: flex;
    flex: 1;
  }

  .box-contact {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 0 83px;
    max-width: 650px;
    max-height: 682px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 15px 40px #0000001a;
    border-radius: 20px;
    opacity: 1;
    padding: 67px 60px;
    gap: 15px;
  }

  .box-infos {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .button-box {
    display: flex;
    flex: 1;
    align-items: flex-start;
    justify-content: space-between;
  }

  .text-obr {
    text-align: left;
    font: italic normal normal 15px/30px Roboto;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    text-style: italic;
  }

  .col-footer2 {
    display: flex;
    flex-direction: column;
    gap: 13px;
  }

  .contact-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .text-contact {
    text-align: left;
    font: normal normal normal 15px/27px Roboto;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    line-height: 1;
  }

  .subtext {
    font: normal normal normal 12px Roboto;
    letter-spacing: 0px;
    color: #898989;
    opacity: 1;
    line-height: 1;
  }
`;

export const ContactContainerMobile = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 50px 30px;

  .title-box {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 50px;
  }

  .title {
    text-align: center;
    font: normal normal bold 30px/30px Roboto;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }

  .subtitle {
    text-align: center;
    font: normal normal normal 15px/20px Roboto;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }

  .box-main {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .box-contact {
    display: flex;
    flex: 1;
    flex-direction: column;

    gap: 15px;
  }

  .box-infos {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .button-box {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .text-obr {
    text-align: left;
    font: italic normal normal 15px/30px Roboto;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    text-style: italic;
  }

  .col-footer2 {
    display: flex;
    flex-direction: column;
    gap: 13px;
  }

  .contact-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .text-contact {
    text-align: left;
    font: normal normal normal 15px/27px Roboto;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    line-height: 1;
  }

  .subtext {
    font: normal normal normal 12px Roboto;
    letter-spacing: 0px;
    color: #898989;
    opacity: 1;
    line-height: 1;
  }
`;
