import styled from "styled-components";
import { ScreenDisplay } from "../../styles/display";

import BGFAQ from "../../assets/images/faq_image.webp";
import { colors } from "../../styles/colors";

export const ContainerDesktop = styled.div`
  display: flex;
  flex: 1;
  margin: 83px 83px 210px 83px;

  section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .box-inf {
    display: flex;
  }

  .cell-contact {
    display: flex;
    flex: 2;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  .contact-title {
    display: flex;
    margin-top: 30px;
    align-items: center;
    justify-content: center;
    font: normal normal bold 25px/23px Roboto;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }
`;

export const ContainerMobile = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 30px;

  section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .box-inf {
    display: flex;
  }

  .cell-contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .contact-title {
    display: flex;
    text-align: center;
    margin-top: 30px;
    align-items: center;
    justify-content: center;
    font: normal normal bold 25px/23px Roboto;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }
`;

export const TitleDesktop = styled.span`
  font: normal normal bold 50px/50px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-top: 40px;
`;
export const TitleMobile = styled.span`
  font: normal normal bold 30px/30px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-top: 40px;
`;

export const SubtitleDesktop = styled.span`
  text-align: center;
  font: normal normal normal 15px/23px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  line-height: 1;
  margin: 10px 313px;
`;
export const SubtitleMobile = styled.span`
  text-align: center;
  font: normal normal normal 15px/23px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  line-height: 1;
  margin: 10px 30px;
`;
