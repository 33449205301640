import styled from "styled-components";
import { colors } from "../../../styles/colors";
import { ScreenDisplay } from "../../../styles/display";

export const H1 = styled.h1`
  font-family: "Poppins", sans-serif;
  font-display: swap;

  @media (${ScreenDisplay.mobile}) {
    font-size: 2rem;
    line-height: initial;
  }
  @media (${ScreenDisplay.desktop}) {
    font-size: 2.56rem;
    line-height: initial;
  }

  font-weight: 700;
  color: #14133b;
`;
export const H2 = styled.h2`
  font-family: "Poppins", sans-serif;
  font-display: swap;
  line-height: initial;
  @media (${ScreenDisplay.mobile}) {
    font-size: 1rem;
  }
  @media (${ScreenDisplay.desktop}) {
    font-size: 2.1rem;
  }

  font-weight: 700;
  color: #14133b;
`;
export const H3 = styled.h3`
  font-family: "Poppins", sans-serif;
  font-display: swap;
  @media (${ScreenDisplay.mobile}) {
    font-size: 1rem;
  }
  @media (${ScreenDisplay.desktop}) {
    font-size: 1.5rem;
  }

  font-weight: 700;
  color: #14133b;
`;
export const H4 = styled.h4`
  font-family: "Poppins", sans-serif;
  font-display: swap;
  line-height: normal;
  @media (${ScreenDisplay.mobile}) {
    font-size: 0.875rem;
  }
  @media (${ScreenDisplay.desktop}) {
    font-size: 1rem;
  }

  font-weight: 700;
  color: #14133b;
`;
export const H5 = styled.h5`
  font-family: "Poppins", sans-serif;
  font-display: swap;
  line-height: normal;
  @media (${ScreenDisplay.mobile}) {
    font-size: 0.625rem;
  }
  @media (${ScreenDisplay.desktop}) {
    font-size: 0.875rem;
  }

  font-weight: 600;
  color: #14133b;
`;

export const TextMediumGray = styled.span`
  font-family: "Poppins", sans-serif;
  font-display: swap;
  color: ${colors.gray};
  font-weight: 500;

  @media (${ScreenDisplay.desktop}) {
    font-size: 1.375rem;
  }
  @media (${ScreenDisplay.mobile}) {
    font-size: 1rem;
  }
`;

export const TextRegularGreen = styled.span`
  font-family: "Poppins", sans-serif;
  font-display: swap;
  font-size: 1rem;
  color: ${colors.softDarkGreen};
`;
export const TextRegularGray = styled.span`
  font-family: "Poppins", sans-serif;
  font-display: swap;
  font-size: 1rem;
  color: ${colors.darkGray};
  line-height: 1.5;
`;

export const TextBigGray = styled.span`
  font-family: "Poppins", sans-serif;
  font-display: swap;
  font-size: 1.5rem;
  color: ${colors.gray};
  line-height: 1.5;
`;

export const AccordionTitle = styled.span`
  font-family: "Poppins", sans-serif;
  font-display: swap;
  font-size: 1rem;
`;

export const LinkWhite = styled.a`
  font-family: "Poppins", sans-serif;
  font-display: swap;
  text-decoration: none;
  font-size: 1rem;
  color: #fff;
`;

export const LinkGray = styled.a`
  font-family: "Poppins", sans-serif;
  font-display: swap;
  text-decoration: none;
  font-size: 1rem;
  color: ${colors.gray};
`;

export const TextMobileWhite = styled.span`
  font-family: "Poppins", sans-serif;
  font-display: swap;
  text-decoration: none;
  font-size: 1rem;
  color: #fff;
`;

export const TextSmall = styled.span`
  font-family: "Poppins", sans-serif;
  font-display: swap;
  text-decoration: none;
  font-size: 0.625rem;
  color: #fff;
`;
