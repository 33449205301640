import React from 'react';


const SobreAConCash: React.FC = ({ match }: any) => {

  return (
    <img  style={{ width:'100%', height:'auto' }} loading='lazy' src='https://concash-prod-files-pub.s3.sa-east-1.amazonaws.com/Sobre_Concash.png'></img>
  );
};

export default SobreAConCash;
