import { useState, useEffect } from "react";
import {
  BoxPinStep,
  BoxStep,
  BoxStepDesktop,
  BoxStepMobile,
  CenteredBox,
  CenteredBoxMobile,
} from "../../../styles/themes/components/box";
import {
  V3Button,
  V3RadioButton,
} from "../../../styles/themes/components/button";
import { PinStepActive } from "../../../styles/themes/components/pin";
import {
  V3Text5,
  V3TextH1,
  V3TextInputInfo,
} from "../../../styles/themes/components/texts";
import { v3Colors } from "../../../styles/themes/v3/colors";
import {
  V3CustomTextField,
  V3FileInput,
  V3FileInputMobile,
  V3Select,
  V3StyledText,
  V3StyledTextCustom,
  V3StyledTextCustomized,
  V3TextCustom,
  V3TextInput,
} from "../../../styles/themes/components/input";
import { ImInfo } from "react-icons/im";
import { V3Modal } from "../../../styles/themes/components/modal";
import { formataCPF } from "../../../utils/formaters";
import { v4 as uuidv4 } from 'uuid';
import {
  v3CellphoneFormater,
  v3FormataCNPJ,
  v3MaskCPF,
  v3MaskPhone,
} from "../../../styles/themes/utils/format";
import {
  v3ValidaCpfCnpj,
  v3ValidateEmail,
  v3ValidatePhone,
} from "../../../styles/themes/utils/validate";
import { Backdrop, Checkbox, FormControlLabel, Radio } from "@mui/material";
import { validateEmail } from "../../../utils/validate";
import FloatButton from "../../../components/FloatButton";
import { BsWhatsapp } from "react-icons/bs";
import api from "../../../services/api";
import ContratoSantander from "../../../assets/images/contrato-santander.png";
import { V3Searcheable } from "../../../components/Selects";
import moment from "moment";
import axios from "axios";
import checkDevice from "../../../utils/isMobile";
import Lottie from "lottie-react";
import LoadingInd from "../../../assets/lottie/loading.json";
import { verifyDate } from "..";
import TagManager from 'react-gtm-module'
import { getURLParameter } from "../../../utils/sanitize";
export const Step3Desktop = (props) => {
  const { setCurrentStep } = props;
  const [loading, setLoading] = useState(false);


  const [administradora, setAdministradora] = useState(
    localStorage.getItem("administradoraO")
  );
  const [vc, setVc] = useState("");
  const [possui, setPossui] = useState(null);
  const [idEtapa, setIdEtapa] = useState("");
  const [hasInfo, setHasInfo] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [hasErrorWithoutClear, setHasErrorWithoutClear] = useState(false);
  const [hasSuccess, setHasSuccess] = useState(false);
  const [atendidas, setAtendidas] = useState([]);
  const [errorText, setErrorText] = useState("");
  const [successText, setSuccessText] = useState("");
  const [aceito, setAceito] = useState(false);
  const [hrAceite, setHrAceite] = useState("");

  const [grupo, setGrupo] = useState("");
  const [cota, setCota] = useState("");
  const [versao, setVersao] = useState("");
  const [contrato, setContrato] = useState("");

  const [cotaErr, setCotaErr] = useState(false);
  const [grupoErr, setGrupoErr] = useState(false);
  const [versaoErr, setVersaoErr] = useState(false);

  const [santander, setSantander] = useState(false);
  const [banrisul, setBanrisul] = useState(false);
  const [canopus, setCanopus] = useState(false);
  const [porto, setPorto] = useState(false);
  const [vw, setVw] = useState(false);

  const [chooseBanrisul, setChooseBanrisul] = useState(false);
  const [chooseSantander, setChooseSantander] = useState(false);

  const [gruposCanopus, setGruposCanopus] = useState([]);
  const [fileBase, setFileBase] = useState("");

  
  const [browserUser, setBrowserUser] = useState("");
  const [isMobileUser, setIsMobileUser] = useState(false);
  const [ipUser, setIpUser] = useState("");

  const isCanopus = localStorage.getItem("administradoraO") === "125";
  const canopusAceite = localStorage.getItem("canopusAceite");
  const startForm = localStorage.getItem("startForm");
  const horaAcesso = localStorage.getItem("horaAcesso");
  const timeZone = localStorage.getItem("timeZone");
  const [os, setOS] = useState("");

  const [origem, setOrigem] = useState("");
  const [midia, setMidia] = useState("");
  const [campanha, setCampanha] = useState("");
  const [segmento, setSegmento] = useState("");
  const [anuncio, setAnuncio] = useState("");

  async function getNavigationData() {
    if (window.navigator.appVersion.indexOf("Win") !== -1) {
      setOS("Windows");
    }
    if (window.navigator.appVersion.indexOf("Mac") !== -1) {
      setOS("MacOS");
    }
    if (window.navigator.appVersion.indexOf("X11") !== -1) {
      setOS("UnixOS");
    }
    if (window.navigator.appVersion.indexOf("Linux") !== -1) {
      setOS("Linux");
    }

    if (window.navigator.userAgent.indexOf("Chrome") !== -1) {
      setBrowserUser("Google Chrome");
    } else if (window.navigator.userAgent.indexOf("Firefox") !== -1) {
      setBrowserUser("Mozilla Firefox");
    } else if (window.navigator.userAgent.indexOf("MSIE") !== -1) {
      setBrowserUser("Internet Explorer");
    } else if (window.navigator.userAgent.indexOf("Edge") !== -1) {
      setBrowserUser("Edge");
    } else if (window.navigator.userAgent.indexOf("Safari") !== -1) {
      setBrowserUser("Safari");
    } else if (window.navigator.userAgent.indexOf("Opera") !== -1) {
      setBrowserUser("Opera");
    }

    const isMobileDevice = checkDevice();
    setIsMobileUser(isMobileDevice);
  }

  async function handleGetIP() {
    const dataip = await axios
      .get("https://ipv4.icanhazip.com")
      .then(({ data }) => {
        setIpUser(data.replace("\n", ""));
      })
      .catch((error) => console.log(error));
  }

  const plataforma = `{
      "acessado_em": "${horaAcesso}",
      "mobile": "${isMobileUser ? "SIM" : "NÃO"}",
      "navegador": "${browserUser}",
      "os": "${os}",
      "ip": "${ipUser}",
      "aceite_politica_em": "${hrAceite}",
      "inicio_form":" ${startForm}",
      "timezone": "${timeZone}",
      "canopus": "${isCanopus ? "SIM" : "NÃO"}",
      "hr_canopus_aceite": "${canopusAceite ? canopusAceite : "INDIFERENTE"}"
  }`;

  function handleResetOnboarding(state) {
    setHasError(state);
    localStorage.clear();
    window.location.reload();
  }

  async function getBase64(file) {
    setLoading(true)
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          resolve(reader.result);
        }
        reader.onerror = reject
      })
 }

  async function handleNext() {
    setAceito(true);
    setHrAceite(moment([], true).format("DD/MM/YYYY  HH:mm:ss"));

    const etp = localStorage.getItem("idEtapaAtual");
    const etapa = idEtapa ? idEtapa : etp;

    if (administradora === "125") {
      if (!grupo || !cota  || Number(cota) === 0) {
        setHasErrorWithoutClear(true);
        return setErrorText(
          "Preencha o número do grupo e cota do seu consórcio Canopus."
        );
      }
    } 
    const currentAdm = localStorage.getItem("administradoraO");
    if (atendidas.includes(currentAdm)) {
      if (!contrato) {
      
        if (administradora === "114" || administradora == "62") {
          if (administradora === "62" && !contrato && !fileBase) {
            setContrato("0");
          }

          if (administradora === "114" && !fileBase && !contrato) {
            setHasErrorWithoutClear(true);
            setGrupoErr(true);
            return setErrorText(
              "Preencha o número do contrato ou anexe o extrato."
            );
          }
        } else {
          if (!contrato && !grupo && !cota) {
            setHasErrorWithoutClear(true);

            return setErrorText(
              "Preencha grupo, cota e versão ou número do contrato."
            );
          }
          if (!cota || cota === null) {
            setHasErrorWithoutClear(true);
            setCotaErr(true);
            return setErrorText("Preencha o número de sua cota de consórcio.");
          }
          if (administradora !== "125" && administradora !== "46" && administradora !== "123") {
            if (!versao || versao === null) {
              setHasErrorWithoutClear(true);
              setVersaoErr(true);
              return setErrorText(
                "Preencha o número da versão da sua cota de consórcio."
              );
            }
          }
        }
      }
    }

    setLoading(true);
    let obj = [];

    if(grupo) {
      obj.push({
        nome: "grupo",
        valor: `${grupo}`,
      });
    }

    if(cota) {
      obj.push({
        nome: "cota",
        valor: `${cota}`,
      });
    }

    if(versao) {
      obj.push({
        nome: "versao",
        valor: `${versao}`,
      });
    }

    if(contrato) {
      obj.push({
        nome: "contrato",
        valor: contrato,
      });
    }

    const etapas = JSON.parse(localStorage.getItem("etapasO"));
    const pass = uuidv4();

    if(administradora === "62" && !contrato && fileBase) {
    const res = await getBase64(fileBase);

        const arquivo = res.replace("data:application/pdf;base64,", "");
         await api.patch(
          `/api/concash/1.0/cliente-cadastro/onboarding/avancar/${vc}/${etapa}`,
          [...obj,
          {
            nome: "extrato",
            valor: await arquivo
          }]
        )
          while (arquivo) {
            await api
            .patch(
              `/api/concash/1.0/cliente-cadastro/onboarding/avancar/${vc}/${etapas[4].id}`,
              [
                {
                  nome: "senhaEscolhida",
                  valor: pass,
                },
                {
                  nome: "senhaConfirmacao",
                  valor: pass,
                },
                {
                  nome: "aceitaTermosPolitica",
                  valor: "SIM",
                },
                {
                  nome: "plataforma",
                  valor: plataforma,
                },
                {
                  nome: "utmSource",
                  valor: origem ? origem : "",
                },
                {
                  nome: "utmMedium",
                  valor: midia ? midia : "",
                },
                {
                  nome: "utmCampaign",
                  valor: campanha ? campanha : "",
                },
                {
                  nome: "utmTerm",
                  valor: segmento ? segmento : "",
                },
                {
                  nome: "utmContent",
                  valor: anuncio ? anuncio : "",
                },
              ]
            )
            setLoading(false);
            localStorage.setItem("concluido", "true");
            window.location.href = 'vc-sucesso'
            break;
          }
          const tagManagerArgs = {
            gtmId: 'GTM-WB757J7'
          }
          var idTipoConsorcio=localStorage.getItem("tipoConsorcioO")
          var tipoConsorcio = ''
          if (idTipoConsorcio === "1") {
            tipoConsorcio = 'Imóvel'
          } else if (idTipoConsorcio === "2") {
            tipoConsorcio = 'Serviço'
          } else if (idTipoConsorcio === "3") {
            tipoConsorcio = 'Automóvel'
          } else {
            tipoConsorcio = 'Motocicleta'
          }
          var  idStatusCota =  localStorage.getItem("statusCotaO");
          var statusCota = ''
          if (idStatusCota === "1") {
            statusCota = 'ATIVA'
          } else {
            statusCota = 'CANCELADA'
          }
          TagManager.initialize(tagManagerArgs);
          window.dataLayer.push({
            event: "solicitacao-proposta-site-4",
            administradora: localStorage.getItem("administradoraODesc"),
            tipo: tipoConsorcio,
            status: statusCota,
            email: localStorage.getItem("emailO"),
            phone: localStorage.getItem("telO")
          });
          const currentAdm = localStorage.getItem("administradoraO");
          if (!atendidas.includes(currentAdm)) {
            return (window.location.href = "/nao-atendida");
          } else {
  
            localStorage.setItem("concluido", "true");
            window.location.href = 'vc-sucesso'
          }

    } else {
      await api
      .patch(
        `/api/concash/1.0/cliente-cadastro/onboarding/avancar/${vc}/${etapa}`,
        [...obj]
      )
      .then(async ({ data }) => {
        await api
        .patch(
          `/api/concash/1.0/cliente-cadastro/onboarding/avancar/${vc}/${etapas[4].id}`,
          [
            {
              nome: "senhaEscolhida",
              valor: pass,
            },
            {
              nome: "senhaConfirmacao",
              valor: pass,
            },
            {
              nome: "aceitaTermosPolitica",
              valor: "SIM",
            },
            {
              nome: "plataforma",
              valor: plataforma,
            },
            {
              nome: "utmSource",
              valor: origem ? origem : "",
            },
            {
              nome: "utmMedium",
              valor: midia ? midia : "",
            },
            {
              nome: "utmCampaign",
              valor: campanha ? campanha : "",
            },
            {
              nome: "utmTerm",
              valor: segmento ? segmento : "",
            },
            {
              nome: "utmContent",
              valor: anuncio ? anuncio : "",
            },
          ]
        )
        const tagManagerArgs = {
          gtmId: 'GTM-WB757J7'
        }
        var idTipoConsorcio=localStorage.getItem("tipoConsorcioO")
        var tipoConsorcio = ''
        if (idTipoConsorcio === "1") {
          tipoConsorcio = 'Imóvel'
        } else if (idTipoConsorcio === "2") {
          tipoConsorcio = 'Serviço'
        } else if (idTipoConsorcio === "3") {
          tipoConsorcio = 'Automóvel'
        } else {
          tipoConsorcio = 'Motocicleta'
        }
        var  idStatusCota =  localStorage.getItem("statusCotaO");
        var statusCota = ''
        if (idStatusCota === "1") {
          statusCota = 'ATIVA'
        } else {
          statusCota = 'CANCELADA'
        }
        TagManager.initialize(tagManagerArgs);
        window.dataLayer.push({
          event: "solicitacao-proposta-site-4",
          administradora: localStorage.getItem("administradoraODesc"),
          tipo: tipoConsorcio,
          status: statusCota,
          email: localStorage.getItem("emailO"),
          phone: localStorage.getItem("telO")
        });
        const currentAdm = localStorage.getItem("administradoraO");
        if (!atendidas.includes(currentAdm)) {
          return (window.location.href = "/nao-atendida");
        } else {
          localStorage.setItem("concluido", "true");
          window.location.href = 'vc-sucesso'
        }
      })
      .catch((data) => {
        setHasError(true);
        setErrorText(
          "Tivemos um problema ao processar seus dados, por favor, preencha os dados novamente."
        );
      });
    }
  }

  function handlePrev() {
    setCurrentStep(2);
  }

  async function recuperarEtapas() {
    const vcObj = JSON.parse(localStorage.getItem("vc"));

    if (!vcObj) {
      const on = localStorage.getItem("vc");
      const etp = await api.get(
        `/api/concash/1.0/cliente-cadastro/onboarding/recuperar/${on}`
      );
      localStorage.setItem("etapaAtualO", `3`);
      localStorage.setItem("vcObj", `${JSON.stringify(etp.data)}`);
      localStorage.setItem("etapasO", `${JSON.stringify(etp.data.etapas)}`);

      const cotaR = await etp.data.etapas[2].campos.find(
        (i) => i.nome === "cota"
      ).valor;
      const grupoR = await etp.data.etapas[2].campos.find(
        (i) => i.nome === "grupo"
      ).valor;
      const versaoR = await etp.data.etapas[2].campos.find(
        (i) => i.nome === "versao"
      ).valor;
      const contratoR = await etp.data.etapas[2].campos.find(
        (i) => i.nome === "contrato"
      ).valor;

      setCota(cotaR?.replace(/[^0-9]/g, ""));
      setGrupo(grupoR?.replace(/[^0-9]/g, ""));
      setVersao(versaoR?.replace(/[^0-9]/g, ""));
      setContrato(contratoR?.replace(/[^0-9]/g, ""));
    }

    const cotaR = await vcObj.etapas[2].campos.find((i) => i.nome === "cota")
      .valor;
    const grupoR = await vcObj.etapas[2].campos.find((i) => i.nome === "grupo")
      .valor;
    const versaoR = await vcObj.etapas[2].campos.find(
      (i) => i.nome === "versao"
    ).valor;
    const contratoR = await vcObj.etapas[2].campos.find(
      (i) => i.nome === "contrato"
    ).valor;

    setCota(cotaR?.replace(/[^0-9]/g, ""));
    setGrupo(grupoR?.replace(/[^0-9]/g, ""));
    setVersao(versaoR?.replace(/[^0-9]/g, ""));
    setContrato(contratoR?.replace(/[^0-9]/g, ""));
  }

  async function persistData() {
    const on = localStorage.getItem("vc");
    const etp = await api.get(
      `/api/concash/1.0/cliente-cadastro/onboarding/recuperar/${on}`
    );

    const cotaR = await etp.data.etapas[2].campos.find((i) => i.nome === "cota")
      .valor;
    const grupoR = await etp.data.etapas[2].campos.find(
      (i) => i.nome === "grupo"
    ).valor;
    const versaoR = await etp.data.etapas[2].campos.find(
      (i) => i.nome === "versao"
    ).valor;
    const contratoR = await etp.data.etapas[2].campos.find(
      (i) => i.nome === "contrato"
    ).valor;
    const possuiR = await etp.data.etapas[2].campos.find(
      (i) => i.nome === "possuiMaisDeUmaCota"
    ).valor;

    setCota(cotaR?.replace(/[^0-9]/g, ""));
    setGrupo(grupoR?.replace(/[^0-9]/g, ""));
    setVersao(versaoR?.replace(/[^0-9]/g, ""));
    setContrato(contratoR?.replace(/[^0-9]/g, ""));
    setPossui(possuiR);
  }

  useEffect(() => {
    persistData();
  }, [setCurrentStep]);

  async function handleGetAtendidas() {
    const { data } = await api
      .get("/api/administradoras/1.0/listar?parceiros=sim")
      .catch(() => {
        setHasError(true);
        setErrorText(
          "Houve um erro ao carregar a lista de parceiros atendidos."
        );
      });
    const ids = data.map((d) => {
      return `${d.id}`;
    });

    setAtendidas(ids);
  }

  async function handleGetGrupos(g) {
    const { data } = await api.get(`/api/grupo/1.0/listar?idAdministradora=125&idTipoConsorcio=${g}`)
      .catch(() => {
        setHasError(true);
        return setErrorText("Houve um erro ao buscar grupos Canopus.");
      });

    let unique = new Set();
    let arrGrupo = [{ label: "Selecione Grupo", id: 0 }];

    data.map((dt) => {
      arrGrupo.push({ id: dt.codigoGrupo, label: dt.codigoGrupo });
    });

    const filtered = arrGrupo.filter(function (el) {
      const duplicated = unique.has(el.id);
      unique.add(el.id);
      return !duplicated;
    });
    setGruposCanopus(filtered);
  }

  function handleGrupoCanopus(dt) {
    if (!dt) {
      setGrupo(null);
      setGrupoErr(true);
    } else {
      setGrupo(dt?.id);
      setGrupoErr(false);
    }
  }

  useEffect(() => {
    
    const origemUTM = getURLParameter('utm_source');
    const midiaUTM = getURLParameter('utm_medium');
    const campanhaUTM = getURLParameter('utm_campaign');
    const segmentoUTM = getURLParameter('utm_term');
    const anuncioUTM = getURLParameter('utm_content');
    if(!origem) {setOrigem(origemUTM)};
    if(!midia) {setMidia(midiaUTM)};
    if(!campanha) {setCampanha(campanhaUTM)};
    if(!segmento) {setSegmento(segmentoUTM)};
    if(!anuncio) {setAnuncio(anuncioUTM)};

    verifyDate();
    handleGetAtendidas();
    handleGetIP();
    getNavigationData();
    localStorage.setItem("etapaAtualO", 2);
    setAdministradora(localStorage.getItem("administradoraO"));
    const vcObj = JSON.parse(localStorage.getItem("vcObj"));
    setIdEtapa(vcObj.etapas[2].id);
    setVc(vcObj.id);

    if (!vcObj) {
      recuperarEtapas();
    }
    const tipoC = localStorage.getItem("tipoConsorcioO");
    tipoC && handleGetGrupos(tipoC);
  }, []);

  return (
    <>
          <Backdrop open={loading} style={{ zIndex: 999 }}>
        <div>
          <Lottie animationData={LoadingInd} />
        </div>
        </Backdrop>
      <BoxStepDesktop style={{ minHeight: 450 }}>
        <BoxPinStep>
          <PinStepActive num={1} type="checked" />
          <PinStepActive num={2} type="checked" />
          <PinStepActive num={3} type="active" />
          <PinStepActive num={4} type="deactive" />
        </BoxPinStep>
        <CenteredBox>
          <V3TextH1>Informações da sua cota</V3TextH1>
        </CenteredBox>

        {atendidas.includes(administradora) &&
          administradora !== "62" &&
          administradora !== "46" &&
          administradora !== "123" &&
          administradora !== "114" &&
          administradora !== "125" && (
            <>
              <CenteredBox style={{ marginTop: 15, padding: "0px 100px" }}>
                <V3TextH1 style={{ textAlign: "center" }}>
                  Informe os dados da cota que deseja vender
                </V3TextH1>
              </CenteredBox>
              <CenteredBox
                style={{
                  margin: "10px 75px 10px 100px",
                  gap: 10,
                }}
              >
                <V3TextCustom
                  label="Grupo"
                  onChange={(e) => {
                    const text = e.target.value.replace(/[^\d]/g, "");
                    if (text.length <= 5) {
                      setGrupo(text);
                      setGrupoErr(false);
                    }
                  }}
                  size="small"
                  value={grupo}
                  error={grupoErr}
                  required
                />
                <V3TextCustom
                  label="Cota"
                  onChange={(e) => {
                    const text = e.target.value.replace(/[^\d]/g, "");
                    if (text.length <= 4) {
                      setCota(text);
                      setCotaErr(false);
                    }
                  }}
                  size="small"
                  value={cota}
                  error={cotaErr}
                  required
                />
                <V3TextCustom
                  label="Versão"
                  onChange={(e) => {
                    const text = e.target.value.replace(/[^\d]/g, "");
                    if (text.length <= 2) {
                      setVersao(text);
                      setVersaoErr(false);
                    }
                  }}
                  value={versao}
                  size="small"
                  error={versaoErr}
                  required
                />
                <div style={{ position: "relative" }}>
                  <ImInfo
                    onClick={() => setHasInfo(true)}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </CenteredBox>
              <CenteredBox
                style={{ justifyContent: "center", margin: "10px 100px" }}
              >
                <V3Text5>OU</V3Text5>
              </CenteredBox>
              <CenteredBox
                style={{ justifyContent: "flex-start", margin: "0 100px" }}
              >
                <V3TextCustom
                  label="Informe o seu Contrato"
                  sx={{ width: 447 }}
                  onChange={(e) => {
                    const text = e.target.value.replace(/[^\d]/g, "");
                    if (text.length <= 20) {
                      setContrato(text);
                    }
                  }}
                  value={contrato}
                  size="small"
                />
              </CenteredBox>
            </>
          )}
        {administradora === "125" && (
          <>
            <CenteredBox style={{ marginTop: 15, padding: "0px 100px" }}>
              <V3TextH1 style={{ textAlign: "center" }}>
                Informe os dados da cota que deseja vender
              </V3TextH1>
            </CenteredBox>
            <CenteredBox
              style={{
                margin: "10px 75px 10px 100px",
                gap: 10,
              }}
            >
              <div style={{ display: "flex", flex: 1, height: 65 }}>
                <V3Select
                  id="grupo-canopus"
                  sx={{
                    display: "flex",
                    flex: 1,
                    borderRadius: 15,
                    height: 75,
                  }}
                  height={75}
                  error={grupoErr}
                  name="Grupo"
                  action={handleGrupoCanopus}
                  options={gruposCanopus}
                  value={grupo}
                  width={"100%"}
                  label="Grupo"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  maxHeight: 40,
                  alignItems: "flex-start",
                }}
              >
                <V3StyledTextCustomized
                  label="Cota"
                  onChange={(e) => {
                    const text = e.target.value.replace(/[^\d]/g, "");
                    if (text.length <= 4) {
                      setCota(text);
                      setCotaErr(false);
                    }
                  }}
                  sx={{ maxHeight: 45 }}
                  value={cota}
                  error={cotaErr}
                  required
                />
              </div>

              <div style={{ position: "relative" }}>
                <ImInfo
                  onClick={() => setCanopus(true)}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </CenteredBox>
          </>
        )}
        {administradora === "46" && (
          <>
            <CenteredBox style={{ marginTop: 15, padding: "0px 100px" }}>
              <V3TextH1 style={{ textAlign: "center" }}>
                Informe os dados da cota que deseja vender
              </V3TextH1>
            </CenteredBox>
            <CenteredBox
              style={{
                margin: "10px 75px 10px 100px",
                gap: 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  maxHeight: 40,
                  alignItems: "flex-start",
                }}
              >
                <V3StyledTextCustomized
                  label="Grupo"
                  onChange={(e) => {
                    const text = e.target.value;
                    if (text.length <= 8) {
                      setGrupo(text);
                      setGrupoErr(false);
                    }
                  }}
                  sx={{ maxHeight: 45 }}
                  value={grupo}
                  error={grupoErr}
                  required
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  maxHeight: 40,
                  alignItems: "flex-start",
                }}
              >
                <V3StyledTextCustomized
                  label="Cota"
                  onChange={(e) => {
                    const text = e.target.value;
                    if (text.length <= 6) {
                      setCota(text);
                      setCotaErr(false);
                    }
                  }}
                  sx={{ maxHeight: 45 }}
                  value={cota}
                  error={cotaErr}
                  required
                />
              </div>

              <div style={{ position: "relative" }}>
                <ImInfo
                  onClick={() => setPorto(true)}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </CenteredBox>
          </>
        )}
        {administradora === "123" && (
          <>
            <CenteredBox style={{ marginTop: 15, padding: "0px 100px" }}>
              <V3TextH1 style={{ textAlign: "center" }}>
                Informe os dados da cota que deseja vender
              </V3TextH1>
            </CenteredBox>
            <CenteredBox
              style={{
                margin: "10px 75px 10px 100px",
                gap: 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  maxHeight: 40,
                  alignItems: "flex-start",
                }}
              >
                <V3StyledTextCustomized
                  label="Grupo"
                  onChange={(e) => {
                    const text = e.target.value;
                    if (text.length <= 8) {
                      setGrupo(text);
                      setGrupoErr(false);
                    }
                  }}
                  sx={{ maxHeight: 45 }}
                  value={grupo}
                  error={grupoErr}
                  required
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  maxHeight: 40,
                  alignItems: "flex-start",
                }}
              >
                <V3StyledTextCustomized
                  label="Cota"
                  onChange={(e) => {
                    const text = e.target.value;
                    if (text.length <= 6) {
                      setCota(text);
                      setCotaErr(false);
                    }
                  }}
                  sx={{ maxHeight: 45 }}
                  value={cota}
                  error={cotaErr}
                  required
                />
              </div>

              <div style={{ position: "relative" }}>
                <ImInfo
                  onClick={() => setPorto(true)}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </CenteredBox>
          </>
        )}
        {administradora === "62" && (
          <>
            <CenteredBox style={{ marginTop: 15, padding: "0px 100px" }}>
              <V3TextH1 style={{ textAlign: "center" }}>
                Informe os dados da cota que deseja vender
              </V3TextH1>
            </CenteredBox>
            <CenteredBox
              style={{
                justifyContent: "flex-start",
                margin: "0 100px",
                marginTop: 15,
                marginBottom: 15,
              }}
            >
              <V3Text5 style={{ textAlign: "center" }}>
                Abaixo informe o número do seu contrato da sua cota, ou anexe o
                extrato detalhado da sua cota, em formato .pdf, que pode ser
                resgatado através do portal do consorciado do Santander. Estas
                informações são importantes para que possamos te apresentar a
                melhor oferta:
              </V3Text5>
            </CenteredBox>
            <CenteredBox
              style={{ justifyContent: "flex-start", margin: "10px 100px" }}
            >
              <V3TextCustom
                label="Informe o seu Contrato"
                sx={{ width: 447 }}
                onChange={(e) => {
                  const text = e.target.value.replace(/[^\d]/g, "");
                  if (text.length <= 20) {
                    setContrato(text);
                  }
                }}
                value={contrato}
                size="small"
              />
              <div style={{ position: "relative", right: -10 }}>
                <ImInfo
                  onClick={() => setSantander(true)}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </CenteredBox>
            <CenteredBox>
              <V3Text5>OU</V3Text5>
            </CenteredBox>
            <CenteredBox style={{ justifyContent: "flex-start", margin: "10px 100px" }}>
              <V3FileInput
                label="Anexe o seu Extrato"
                width={410}
                action={setFileBase}
              />
            </CenteredBox>
          </>
        )}
        {administradora === "114" && (
          <>
            <CenteredBox style={{ marginTop: 15, padding: "0px 100px" }}>
              <V3TextH1 style={{ textAlign: "center" }}>
                Informe os dados da cota que deseja vender
              </V3TextH1>
            </CenteredBox>
            <CenteredBox
              style={{
                justifyContent: "flex-start",
                margin: "0 100px",
                marginTop: 15,
                marginBottom: 15,
              }}
            >
              <V3Text5 style={{ textAlign: "center" }}>
                Abaixo informe o número do seu contrato da sua cota, ou anexe o
                extrato detalhado da sua cota, em formato .pdf, que pode ser
                resgatado através do portal do consorciado do Banrisul. Estas
                informações são importantes para que possamos te apresentar a
                melhor oferta:
              </V3Text5>
            </CenteredBox>
            <CenteredBox
              style={{ justifyContent: "flex-start", margin: "10px 100px" }}
            >
              <V3TextCustom
                label="Informe o seu Contrato"
                sx={{ width: 447 }}
                onChange={(e) => {
                  const text = e.target.value.replace(/[^\d]/g, "");
                  if (text.length <= 20) {
                    setContrato(text);
                  }
                }}
                value={contrato}
                required
                size="small"
              />
              <div style={{ position: "relative", right: -10 }}>
                <ImInfo
                  onClick={() => setBanrisul(true)}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </CenteredBox>
            <CenteredBox
              style={{ justifyContent: "center", margin: "10px 100px" }}
            >
              <V3Text5>OU</V3Text5>
            </CenteredBox>
            <CenteredBox>
              <V3FileInput
                label="Anexe o seu Extrato"
                width={410}
                action={setFileBase}
              />
              <div style={{ position: "relative", right: -10 }}>
                <ImInfo
                  onClick={() => setChooseBanrisul(true)}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </CenteredBox>
          </>
        )}
        <V3Modal
          open={canopus}
          height={306}
          width={450}
          actionClose={setCanopus}
          title={"Informação sobre seu grupo e cota:"}
          html={
            <V3Text5 style={{ textAlign: "center", color: v3Colors.black }}>
              Pedimos atenção no preenchimento das informações de Grupo e Cota
              do seu consórcio. No telegrama, SMS ou e-mail que recebeu, você
              tem o seu grupo cota e versão nesse formato: 0000/111/22 Os
              primeiros 4 dígitos são o grupo (0000) e os dígitos que estão no
              meio são a cota (111). Precisamos do preenchimento no formulário
              destes dois números iniciais para gerar a sua oferta.
            </V3Text5>
          }
        />
        <V3Modal
          open={porto}
          height={306}
          width={450}
          actionClose={setPorto}
          title={"Informação sobre seu grupo e cota:"}
          html={
            <V3Text5 style={{ textAlign: "center", color: v3Colors.black }}>
              Pedimos atenção no preenchimento das informações de Grupo e Cota
              do seu consórcio. O preenchimento no formulário destes dois campos
              são fundamentais para gerarmos sua oferta.
            </V3Text5>
          }
        />
        <V3Modal
          open={banrisul}
          height={350}
          width={450}
          actionClose={setBanrisul}
          title={"Encontre o número do seu contrato:"}
          html={
            <V3Text5 style={{ textAlign: "center", color: v3Colors.black }}>
              O número do contrato pode ser encontrado no canto superior direito
              do extrato detalhado da sua cota. Segue abaixo:
              <br />
              <br />
              <img loading="lazy" src={ContratoSantander} alt="cont" />
            </V3Text5>
          }
        />
        <V3Modal
          open={chooseBanrisul}
          height={306}
          width={450}
          actionClose={setChooseBanrisul}
          title={"Informação sobre seu contrato:"}
          html={
            <V3Text5 style={{ textAlign: "center", color: v3Colors.black }}>
              Acesse o portal do consorciado Banrisul para baixar o seu extrato.
              Não precisa imprimir. Basta salvar e nos enviar o arquivo em .pdf
              <br />
              <br />
              <a
                href="#"
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                Clique aqui
              </a>{" "}
              para acessar o portal e fazer o download do seu extrato. Após
              isto, retorne e anexe nesta tela para te enviarmos a melhor
              oferta.
            </V3Text5>
          }
        />
        <V3Modal
          open={santander}
          height={350}
          width={450}
          actionClose={setSantander}
          title={"Encontre o número do seu contrato:"}
          html={
            <V3Text5 style={{ textAlign: "center", color: v3Colors.black }}>
              O número do contrato pode ser encontrado no canto superior direito
              do extrato detalhado da sua cota. Segue abaixo:
              <br />
              <br />
              <img loading="lazy" src={ContratoSantander} alt="cont" />
            </V3Text5>
          }
        />

        <V3Modal
          open={chooseSantander}
          height={420}
          width={560}
          actionClose={setChooseSantander}
          title={"Resgate seu extrato:"}
          html={
            <V3Text5 style={{ textAlign: "center", color: v3Colors.black }}>
              Acesse o portal do consorciado Santander para baixar o seu
              extrato. Abaixo segue como acessar:
              <br />
              <br />
              1- Acesse o link:{" "}
              <a href="https://www.santander.com.br/consorciado/login">
                https://www.santander.com.br/consorciado/login
              </a>{" "}
              <br />
              2- Preencha com o seu CPF/CNPJ e senha; <br />
              para acessar o portal e fazer o download do seu extrato. Após
              isto, retorne e anexe nesta tela para te enviarmos a melhor
              oferta.
              <br />
              3- Busque a sua cota e clique em detalhes; <br />
              4-Selecione "extrato" e logo após "consultar"; <br />
              5-Selecione "Extrato completo"; <br />
              6- Clique em imprimir/baixar PDF. <br /> <br />
              Lembrando: Não é necessário imprimir
            </V3Text5>
          }
        />
        <V3Modal
          open={hasInfo}
          actionClose={setHasInfo}
          title={"Informação sobre seu consórcio"}
          html={
            <V3Text5 style={{ textAlign: "center" }}>
              Não possui as informações do consórcio? <br />
              <a href="/">Clique aqui</a> e verifique como resgatar o seu
              extrato.
            </V3Text5>
          }
        />
        <V3Modal
          open={hasError}
          actionClose={handleResetOnboarding}
          title={"Atenção!"}
          text={errorText}
          type="error"
        />
        <V3Modal
          open={hasErrorWithoutClear}
          actionClose={setHasErrorWithoutClear}
          title={"Atenção!"}
          text={errorText}
          type="error"
        />
        <V3Modal
          open={hasSuccess}
          actionClose={setHasSuccess}
          title={"Sucesso!"}
          text={successText}
          type="success"
        />
                    <CenteredBox
              style={{
                marginTop: 15,
                padding: "0px 100px",
              }}
            >
              <V3Text5 style={{ color: v3Colors.black }}>Ao clicar em "Quero a melhor oferta" aceito os               <a
                style={{ textDecoration: "none" }}
                href={require("../../../assets/termo/Politica_Privacidade.pdf")}
                target="_blank" rel="noreferrer"
              >
                <V3Text5
                  style={{
                    color: v3Colors.primary,
                    marginLeft: 5,
                    fontWeight: "500",
                  }}
                >
                  termos de uso e política de privacidade.
                </V3Text5>
              </a></V3Text5>

            </CenteredBox>
            
        <CenteredBox style={{ gap: 20, marginTop: 30 }}>
          <V3Button
            onClick={handleNext}
            style={{ marginBottom: 30 }}
            width={315}
            height={60}
            text={"QUERO A MELHOR OFERTA"}
            estilo="primary"
          />
        </CenteredBox>
        <CenteredBox style={{ gap: 20, marginTop: 15 }}>
          <V3Text5
            style={{
              cursor: "pointer",
              textDecoration: "underline",
              color: "#000",
              fontWeight: "bold",
            }}
            onClick={handlePrev}
          >
            Voltar
          </V3Text5>
        </CenteredBox>
      </BoxStepDesktop>
    </>
  );
};
export const Step3Mobile = (props) => {
  const { setCurrentStep } = props;
  const [loading, setLoading] = useState(false);
  const [aceito, setAceito] = useState(false);
  const [hrAceite, setHrAceite] = useState("");
  const [comoConheceu, setComoConheceu] = useState([]);
  const [iDComoConheceu, setIdComoConheceu] = useState(null);
  const [descComoConheceu, setDescComoConheceu] = useState("");
  const [comoConheceuErr, setComoConheceuErr] = useState(false);
  const [atendidas, setAtendidas] = useState([]);

  const [administradora, setAdministradora] = useState(
    localStorage.getItem("administradoraO")
  );
  const [vc, setVc] = useState("");
  const [tipoPessoa, setTipoPessoa] = useState("");
  const [possui, setPossui] = useState(null);
  const [idEtapa, setIdEtapa] = useState("");
  const [hasInfo, setHasInfo] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [hasErrorWithoutClear, setHasErrorWithoutClear] = useState(false);
  const [hasSuccess, setHasSuccess] = useState(false);

  const [errorText, setErrorText] = useState("");
  const [successText, setSuccessText] = useState("");

  const [cpf, setCpf] = useState("");
  const [nomeCompleto, setNomeCompleto] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [isWhatsApp, setIsWhatsApp] = useState("");

  const [grupo, setGrupo] = useState("");
  const [cota, setCota] = useState("");
  const [versao, setVersao] = useState("");
  const [contrato, setContrato] = useState("");

  const [cnpj, setCnpj] = useState("");
  const [razaoSocial, setRazaoSocial] = useState("");

  const [nomeCompletoErr, setNomeCompletoErr] = useState(false);
  const [razaoSocialErr, setRazaoSocialErr] = useState(false);

  const [cpfErr, setCpfErr] = useState(false);
  const [cnpjErr, setCnpjErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [telefoneErr, setTelefoneErr] = useState(false);
  const [razaoErr, setRazaoErr] = useState("");
  const [cotaErr, setCotaErr] = useState(false);
  const [grupoErr, setGrupoErr] = useState(false);
  const [versaoErr, setVersaoErr] = useState(false);

  const [santander, setSantander] = useState(false);
  const [banrisul, setBanrisul] = useState(false);
  const [canopus, setCanopus] = useState(false);
  const [porto, setPorto] = useState(false);

  const [chooseBanrisul, setChooseBanrisul] = useState(false);
  const [chooseCanopus, setChooseCanopus] = useState(false);
  const [chooseSantander, setChooseSantander] = useState(false);
  const [chooseVw, setChooseVw] = useState(false);

  const [gruposCanopus, setGruposCanopus] = useState([]);
  const [fileBase, setFileBase] = useState("");

  
  const [browserUser, setBrowserUser] = useState("");
  const [isMobileUser, setIsMobileUser] = useState(false);
  const [ipUser, setIpUser] = useState("");

  const isCanopus = localStorage.getItem("administradoraO") === "125";
  const canopusAceite = localStorage.getItem("canopusAceite");
  const startForm = localStorage.getItem("startForm");
  const horaAcesso = localStorage.getItem("horaAcesso");
  const timeZone = localStorage.getItem("timeZone");
  const [os, setOS] = useState("");

  const [origem, setOrigem] = useState("");
  const [midia, setMidia] = useState("");
  const [campanha, setCampanha] = useState("");
  const [segmento, setSegmento] = useState("");
  const [anuncio, setAnuncio] = useState("");

  async function getNavigationData() {
    if (window.navigator.appVersion.indexOf("Win") !== -1) {
      setOS("Windows");
    }
    if (window.navigator.appVersion.indexOf("Mac") !== -1) {
      setOS("MacOS");
    }
    if (window.navigator.appVersion.indexOf("X11") !== -1) {
      setOS("UnixOS");
    }
    if (window.navigator.appVersion.indexOf("Linux") !== -1) {
      setOS("Linux");
    }

    if (window.navigator.userAgent.indexOf("Chrome") !== -1) {
      setBrowserUser("Google Chrome");
    } else if (window.navigator.userAgent.indexOf("Firefox") !== -1) {
      setBrowserUser("Mozilla Firefox");
    } else if (window.navigator.userAgent.indexOf("MSIE") !== -1) {
      setBrowserUser("Internet Explorer");
    } else if (window.navigator.userAgent.indexOf("Edge") !== -1) {
      setBrowserUser("Edge");
    } else if (window.navigator.userAgent.indexOf("Safari") !== -1) {
      setBrowserUser("Safari");
    } else if (window.navigator.userAgent.indexOf("Opera") !== -1) {
      setBrowserUser("Opera");
    }

    const isMobileDevice = checkDevice();
    setIsMobileUser(isMobileDevice);
  }

  async function handleGetIP() {
    const dataip = await axios
      .get("https://ipv4.icanhazip.com")
      .then(({ data }) => {
        setIpUser(data.replace("\n", ""));
      })
      .catch((error) => console.log(error));
  }

  const plataforma = `{
      "acessado_em": "${horaAcesso}",
      "mobile": "${isMobileUser ? "SIM" : "NÃO"}",
      "navegador": "${browserUser}",
      "os": "${os}",
      "ip": "${ipUser}",
      "aceite_politica_em": "${hrAceite}",
      "inicio_form":" ${startForm}",
      "timezone": "${timeZone}",
      "canopus": "${isCanopus ? "SIM" : "NÃO"}",
      "hr_canopus_aceite": "${canopusAceite ? canopusAceite : "INDIFERENTE"}"
  }`;

  function handleResetOnboarding(state) {
    setHasError(state);
    localStorage.clear();
    window.location.reload();
  }


  async function handleNext() {
    setAceito(true);
    setHrAceite(moment([], true).format("DD/MM/YYYY  HH:mm:ss"));
    
    const etp = localStorage.getItem("idEtapaAtual");
    const etapa = idEtapa ? idEtapa : etp;

    if (administradora === "125") {
      if (!grupo || !cota  || Number(cota) === 0) {
        
        setHasErrorWithoutClear(true);
        return setErrorText(
          "Preencha o número do grupo e cota do seu consórcio Canopus."
        );
      }
    } 
    const currentAdm = localStorage.getItem("administradoraO");
    if (atendidas.includes(currentAdm)) {
      if (!contrato) {
      
        if (administradora === "114" || administradora == "62") {
          if (administradora === "62" && !contrato && !fileBase) {
            setContrato("0");
          }

          if (administradora === "114" && !fileBase && !contrato) {
            setHasErrorWithoutClear(true);
            setGrupoErr(true);
            return setErrorText(
              "Preencha o número do contrato ou anexe o extrato."
            );
          }
        } else {
          if (!contrato && !grupo && !cota) {
            setHasErrorWithoutClear(true);

            return setErrorText(
              "Preencha grupo, cota e versão ou número do contrato."
            );
          }
          if (!cota || cota === null) {
            setHasErrorWithoutClear(true);
            setCotaErr(true);
            return setErrorText("Preencha o número de sua cota de consórcio.");
          }
          if (administradora !== "125" && administradora !== "46" && administradora !== "123") {
            if (!versao || versao === null) {
              setHasErrorWithoutClear(true);
              setVersaoErr(true);
              return setErrorText(
                "Preencha o número da versão da sua cota de consórcio."
              );
            }
          }
        }
      }
    }

    setLoading(true);
    let obj = [];

    if(grupo) {
      obj.push({
        nome: "grupo",
        valor: `${grupo}`,
      });
    }

    if(cota) {
      obj.push({
        nome: "cota",
        valor: `${cota}`,
      });
    }

    if(versao) {
      obj.push({
        nome: "versao",
        valor: `${versao}`,
      });
    }

    if(contrato) {
      obj.push({
        nome: "contrato",
        valor: contrato,
      });
    }

    const etapas = JSON.parse(localStorage.getItem("etapasO"));
    const pass = uuidv4();

    if(administradora === "62" && !contrato && fileBase) {
    const res = await getBase64(fileBase);

        const arquivo = res.replace("data:application/pdf;base64,", "");
         await api.patch(
          `/api/concash/1.0/cliente-cadastro/onboarding/avancar/${vc}/${etapa}`,
          [...obj,
          {
            nome: "extrato",
            valor: await arquivo
          }]
        )
          while (arquivo) {
            await api
            .patch(
              `/api/concash/1.0/cliente-cadastro/onboarding/avancar/${vc}/${etapas[4].id}`,
              [
                {
                  nome: "senhaEscolhida",
                  valor: pass,
                },
                {
                  nome: "senhaConfirmacao",
                  valor: pass,
                },
                {
                  nome: "aceitaTermosPolitica",
                  valor: "SIM",
                },
                {
                  nome: "plataforma",
                  valor: plataforma,
                },
                {
                  nome: "utmSource",
                  valor: origem ? origem : "",
                },
                {
                  nome: "utmMedium",
                  valor: midia ? midia : "",
                },
                {
                  nome: "utmCampaign",
                  valor: campanha ? campanha : "",
                },
                {
                  nome: "utmTerm",
                  valor: segmento ? segmento : "",
                },
                {
                  nome: "utmContent",
                  valor: anuncio ? anuncio : "",
                },
              ]
            )
            const tagManagerArgs = {
              gtmId: 'GTM-WB757J7'
            }
            var idTipoConsorcio=localStorage.getItem("tipoConsorcioO")
            var tipoConsorcio = ''
            if (idTipoConsorcio === "1") {
              tipoConsorcio = 'Imóvel'
            } else if (idTipoConsorcio === "2") {
              tipoConsorcio = 'Serviço'
            } else if (idTipoConsorcio === "3") {
              tipoConsorcio = 'Automóvel'
            } else {
              tipoConsorcio = 'Motocicleta'
            }
            var  idStatusCota =  localStorage.getItem("statusCotaO");
            var statusCota = ''
            if (idStatusCota === "1") {
              statusCota = 'ATIVA'
            } else {
              statusCota = 'CANCELADA'
            }
            TagManager.initialize(tagManagerArgs);
            window.dataLayer.push({
              event: "solicitacao-proposta-site-4",
              administradora: localStorage.getItem("administradoraODesc"),
              tipo: tipoConsorcio,
              status: statusCota,
              email: localStorage.getItem("emailO"),
              phone: localStorage.getItem("telO")
            });
            setLoading(false);
            localStorage.setItem("concluido", "true");
            window.location.href = 'vc-sucesso'
            break;
          }
          

          const currentAdm = localStorage.getItem("administradoraO");
          if (!atendidas.includes(currentAdm)) {
            return (window.location.href = "/nao-atendida");
          } else {
  
            localStorage.setItem("concluido", "true");
            window.location.href = 'vc-sucesso'
          }

    } else {
      await api
      .patch(
        `/api/concash/1.0/cliente-cadastro/onboarding/avancar/${vc}/${etapa}`,
        [...obj]
      )
      .then(async ({ data }) => {
        await api
        .patch(
          `/api/concash/1.0/cliente-cadastro/onboarding/avancar/${vc}/${etapas[4].id}`,
          [
            {
              nome: "senhaEscolhida",
              valor: pass,
            },
            {
              nome: "senhaConfirmacao",
              valor: pass,
            },
            {
              nome: "aceitaTermosPolitica",
              valor: "SIM",
            },
            {
              nome: "plataforma",
              valor: plataforma,
            },
            {
              nome: "utmSource",
              valor: origem ? origem : "",
            },
            {
              nome: "utmMedium",
              valor: midia ? midia : "",
            },
            {
              nome: "utmCampaign",
              valor: campanha ? campanha : "",
            },
            {
              nome: "utmTerm",
              valor: segmento ? segmento : "",
            },
            {
              nome: "utmContent",
              valor: anuncio ? anuncio : "",
            },
          ]
        )
        const currentAdm = localStorage.getItem("administradoraO");
        if (!atendidas.includes(currentAdm)) {
          return (window.location.href = "/nao-atendida");
        } else {

          localStorage.setItem("concluido", "true");
          window.location.href = 'vc-sucesso'
        }
      })
      .catch((data) => {
        setHasError(true);
        setErrorText(
          "Tivemos um problema ao processar seus dados, por favor, preencha os dados novamente."
        );
      });
    }



  }

  function handlePrev() {
    setCurrentStep(2);
  }


  async function getBase64(file) {
    setLoading(true)
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          resolve(reader.result);
        }
        reader.onerror = reject
      })
 }

  async function handleGetComoConheceu() {
    try {
      const { data } = await api.get("/api/como-conheceu/1.0/listar");
      let arrComo = [];

      data.map((item) => {
        arrComo.push({ id: item.id, label: item.descricao });
      });

      setComoConheceu(arrComo);
    } catch (error) {
      setErrorText(
        "Houve um erro ao buscar a lista de como conheceu a concash."
      );
      setHasError(true);
      console.log(error);
    }
  }

  function validNome(n) {
    const partes = n?.split(" ");
    const tam1 = partes[0]?.length;
    const tam2 = partes[1]?.length;

    if (tam1 >= 3 && tam2 >= 2) {
      return true;
    } else {
      return false;
    }
  }

  async function recuperarEtapas() {
    const vcObj = JSON.parse(localStorage.getItem("vc"));

    if (!vcObj) {
      const on = localStorage.getItem("vc");
      const etp = await api.get(
        `/api/concash/1.0/cliente-cadastro/onboarding/recuperar/${on}`
      );
      localStorage.setItem("etapaAtualO", `3`);
      localStorage.setItem("vcObj", `${JSON.stringify(etp.data)}`);
      localStorage.setItem("etapasO", `${JSON.stringify(etp.data.etapas)}`);

      const cotaR = await etp.data.etapas[2].campos.find(
        (i) => i.nome === "cota"
      ).valor;
      const grupoR = await etp.data.etapas[2].campos.find(
        (i) => i.nome === "grupo"
      ).valor;
      const versaoR = await etp.data.etapas[2].campos.find(
        (i) => i.nome === "versao"
      ).valor;
      const contratoR = await etp.data.etapas[2].campos.find(
        (i) => i.nome === "contrato"
      ).valor;

      setCota(cotaR?.replace(/[^0-9]/g, ""));
      setGrupo(grupoR?.replace(/[^0-9]/g, ""));
      setVersao(versaoR?.replace(/[^0-9]/g, ""));
      setContrato(contratoR?.replace(/[^0-9]/g, ""));
    }

    const cotaR = await vcObj.etapas[2].campos.find((i) => i.nome === "cota")
      .valor;
    const grupoR = await vcObj.etapas[2].campos.find((i) => i.nome === "grupo")
      .valor;
    const versaoR = await vcObj.etapas[2].campos.find(
      (i) => i.nome === "versao"
    ).valor;
    const contratoR = await vcObj.etapas[2].campos.find(
      (i) => i.nome === "contrato"
    ).valor;

    setCota(cotaR?.replace(/[^0-9]/g, ""));
    setGrupo(grupoR?.replace(/[^0-9]/g, ""));
    setVersao(versaoR?.replace(/[^0-9]/g, ""));
    setContrato(contratoR?.replace(/[^0-9]/g, ""));
  }

  async function persistData() {
    const on = localStorage.getItem("vc");
    const etp = await api.get(
      `/api/concash/1.0/cliente-cadastro/onboarding/recuperar/${on}`
    );

    const cotaR = await etp.data.etapas[2].campos.find((i) => i.nome === "cota")
      .valor;
    const grupoR = await etp.data.etapas[2].campos.find(
      (i) => i.nome === "grupo"
    ).valor;
    const versaoR = await etp.data.etapas[2].campos.find(
      (i) => i.nome === "versao"
    ).valor;
    const contratoR = await etp.data.etapas[2].campos.find(
      (i) => i.nome === "contrato"
    ).valor;
    const possuiR = await etp.data.etapas[2].campos.find(
      (i) => i.nome === "possuiMaisDeUmaCota"
    ).valor;

    setCota(cotaR?.replace(/[^0-9]/g, ""));
    setGrupo(grupoR?.replace(/[^0-9]/g, ""));
    setVersao(versaoR?.replace(/[^0-9]/g, ""));
    setContrato(contratoR?.replace(/[^0-9]/g, ""));
    setPossui(possuiR);
  }

  useEffect(() => {
    persistData();
  }, [setCurrentStep]);

  async function handleGetAtendidas() {
    const { data } = await api
      .get("/api/administradoras/1.0/listar?parceiros=sim")
      .catch(() => {
        setHasError(true);
        setErrorText(
          "Houve um erro ao carregar a lista de parceiros atendidos."
        );
      });
    const ids = data.map((d) => {
      return `${d.id}`;
    });

    setAtendidas(ids);
  }

  async function handleGetGrupos(g) {
    const { data } = await api
      .get(
        `api/grupo/1.0/listar?idAdministradora=125&idTipoConsorcio=${g}`
      )
      .catch(() => {
        setHasError(true);
        localStorage.setItem("etapaAtualO", "1");
        setCurrentStep(1);

        return setErrorText("Houve um erro ao buscar grupos Canopus.");
      });

    let unique = new Set();
    let arrGrupo = [{ label: "Selecione Grupo", id: 0 }];

    data.map((dt) => {
      arrGrupo.push({ id: dt.codigoGrupo, label: dt.codigoGrupo });
    });

    const filtered = arrGrupo.filter(function (el) {
      const duplicated = unique.has(el.id);
      unique.add(el.id);
      return !duplicated;
    });
    setGruposCanopus(filtered);
  }

  function handleGrupoCanopus(dt) {
    const grupoSelecionado = dt?.label?.replace(/[^\d]/g, "");
    if (!grupoSelecionado || grupoSelecionado === "") {
      setGrupo(null);
      setGrupoErr(true);
    } else {
      setGrupo(dt?.id);
      setGrupoErr(false);
    }
  }

  useEffect(() => {   

    
    const origemUTM = getURLParameter('utm_source');
    const midiaUTM = getURLParameter('utm_medium');
    const campanhaUTM = getURLParameter('utm_campaign');
    const segmentoUTM = getURLParameter('utm_term');
    const anuncioUTM = getURLParameter('utm_content');
    if(!origem) {setOrigem(origemUTM)};
    if(!midia) {setMidia(midiaUTM)};
    if(!campanha) {setCampanha(campanhaUTM)};
    if(!segmento) {setSegmento(segmentoUTM)};
    if(!anuncio) {setAnuncio(anuncioUTM)};

    verifyDate();
    handleGetAtendidas();
    handleGetIP();
    getNavigationData();
    handleGetComoConheceu();
    localStorage.setItem("etapaAtualO", 3);
    setAdministradora(localStorage.getItem("administradoraO"));
    const vcObj = JSON.parse(localStorage.getItem("vcObj"));
    setIdEtapa(vcObj.etapas[2].id);
    setVc(vcObj.id);

    if (!vcObj) {
      recuperarEtapas();
    }
    const tipoC = localStorage.getItem("tipoConsorcioO");
    tipoC && handleGetGrupos(tipoC);
  }, []);

  return (
    <>
              <Backdrop open={loading} style={{ zIndex: 999 }}>
        <div>
          <Lottie animationData={LoadingInd} />
        </div>
        </Backdrop>
      <BoxStepMobile>
        <BoxPinStep>
          <PinStepActive num={1} type="checked" />
          <PinStepActive num={2} type="checked" />
          <PinStepActive num={3} type="active" />
          <PinStepActive num={4} type="deactive" />
        </BoxPinStep>
        <CenteredBoxMobile>
          <V3TextH1>Informações da sua cota</V3TextH1>
        </CenteredBoxMobile>

        {atendidas.includes(administradora) &&
          administradora !== "62" &&
          administradora !== "46" &&
          administradora !== "123" &&
          administradora !== "114" &&
          administradora !== "125" && (
            <>
              <CenteredBoxMobile style={{ marginTop: 15, padding: "0px 10px" }}>
                <V3TextH1 style={{ textAlign: "center" }}>
                  Informe os dados da cota que deseja vender
                </V3TextH1>
              </CenteredBoxMobile>
              <CenteredBoxMobile
                style={{
                  margin: "10px 15px 10px 10px",
                  gap: 10,
                }}
              >
                <V3TextCustom
                  label="Grupo"
                  onChange={(e) => {
                    const text = e.target.value.replace(/[^\d]/g, "");
                    if (text.length <= 5) {
                      setGrupo(text);
                      setGrupoErr(false);
                    }
                  }}
                  size="small"
                  value={grupo}
                  error={grupoErr}
                  required
                />
                <V3TextCustom
                  label="Cota"
                  onChange={(e) => {
                    const text = e.target.value.replace(/[^\d]/g, "");
                    if (text.length <= 4) {
                      setCota(text);
                      setCotaErr(false);
                    }
                  }}
                  size="small"
                  value={cota}
                  error={cotaErr}
                  required
                />
                <V3TextCustom
                  label="Versão"
                  onChange={(e) => {
                    const text = e.target.value.replace(/[^\d]/g, "");
                    if (text.length <= 2) {
                      setVersao(text);
                      setVersaoErr(false);
                    }
                  }}
                  value={versao}
                  size="small"
                  error={versaoErr}
                  required
                />
                <div style={{ position: "relative" }}>
                  <ImInfo
                    onClick={() => setHasInfo(true)}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </CenteredBoxMobile>
              <CenteredBoxMobile
                style={{ justifyContent: "center", margin: "10px 10px" }}
              >
                <V3Text5>OU</V3Text5>
              </CenteredBoxMobile>
              <CenteredBoxMobile
                style={{ justifyContent: "flex-start", margin: "0 10px" }}
              >
                <V3TextCustom
                  label="Informe o seu Contrato"
                  sx={{ width: 350 }}
                  onChange={(e) => {
                    const text = e.target.value.replace(/[^\d]/g, "");
                    if (text.length <= 20) {
                      setContrato(text);
                    }
                  }}
                  value={contrato}
                  size="small"
                />
              </CenteredBoxMobile>
            </>
          )}
        {administradora === "125" && (
          <>
            <CenteredBoxMobile style={{ marginTop: 15, padding: "0px 10px" }}>
              <V3TextH1 style={{ textAlign: "center" }}>
                Informe os dados da cota que deseja vender
              </V3TextH1>
            </CenteredBoxMobile>
            <CenteredBoxMobile
              style={{
                margin: "10px 15px 10px 10px",
                gap: 10,
              }}
            >
              <div style={{ display: "flex", flex: 1, height: 65 }}>
                <V3Select
                  id="grupo-canopus"
                  sx={{
                    display: "flex",
                    flex: 1,
                    borderRadius: 15,
                    height: 75,
                  }}
                  height={75}
                  error={grupoErr}
                  name="Grupo"
                  action={handleGrupoCanopus}
                  options={gruposCanopus}
                  value={grupo}
                  width={"100%"}
                  label="Grupo"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  maxHeight: 40,
                  alignItems: "flex-start",
                }}
              >
                <V3StyledTextCustomized
                  label="Cota"
                  onChange={(e) => {
                    const text = e.target.value.replace(/[^\d]/g, "");
                    if (text.length <= 4) {
                      setCota(text);
                      setCotaErr(false);
                    }
                  }}
                  sx={{ maxHeight: 45 }}
                  value={cota}
                  error={cotaErr}
                  required
                />
              </div>

              <div style={{ position: "relative" }}>
                <ImInfo
                  onClick={() => setCanopus(true)}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </CenteredBoxMobile>
          </>
        )}
        {administradora === "46" && ( 
          <>
            <CenteredBoxMobile style={{ marginTop: 15, padding: "0px 10px" }}>
              <V3TextH1 style={{ textAlign: "center" }}>
                Informe os dados da cota que deseja vender
              </V3TextH1>
            </CenteredBoxMobile>
            <CenteredBoxMobile
              style={{
                margin: "10px 15px 10px 10px",
                gap: 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  maxHeight: 40,
                  alignItems: "flex-start",
                }}
              >
                <V3StyledTextCustomized
                  label="Grupo"
                  onChange={(e) => {
                    const text = e.target.value;
                    if (text.length <= 8) {
                      setGrupo(text);
                      setGrupoErr(false);
                    }
                  }}
                  sx={{ maxHeight: 45 }}
                  value={grupo}
                  error={grupoErr}
                  required
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  maxHeight: 40,
                  alignItems: "flex-start",
                }}
              >
                <V3StyledTextCustomized
                  label="Cota"
                  onChange={(e) => {
                    const text = e.target.value;
                    if (text.length <= 6) {
                      setCota(text);
                      setCotaErr(false);
                    }
                  }}
                  sx={{ maxHeight: 45 }}
                  value={cota}
                  error={cotaErr}
                  required
                />
              </div>

              <div style={{ position: "relative" }}>
                <ImInfo
                  onClick={() => setPorto(true)}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </CenteredBoxMobile>
          </>
        )}
        {administradora === "123" && ( 
          <>
            <CenteredBoxMobile style={{ marginTop: 15, padding: "0px 10px" }}>
              <V3TextH1 style={{ textAlign: "center" }}>
                Informe os dados da cota que deseja vender
              </V3TextH1>
            </CenteredBoxMobile>
            <CenteredBoxMobile
              style={{
                margin: "10px 15px 10px 10px",
                gap: 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  maxHeight: 40,
                  alignItems: "flex-start",
                }}
              >
                <V3StyledTextCustomized
                  label="Grupo"
                  onChange={(e) => {
                    const text = e.target.value;
                    if (text.length <= 8) {
                      setGrupo(text);
                      setGrupoErr(false);
                    }
                  }}
                  sx={{ maxHeight: 45 }}
                  value={grupo}
                  error={grupoErr}
                  required
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  maxHeight: 40,
                  alignItems: "flex-start",
                }}
              >
                <V3StyledTextCustomized
                  label="Cota"
                  onChange={(e) => {
                    const text = e.target.value;
                    if (text.length <= 6) {
                      setCota(text);
                      setCotaErr(false);
                    }
                  }}
                  sx={{ maxHeight: 45 }}
                  value={cota}
                  error={cotaErr}
                  required
                />
              </div>

              <div style={{ position: "relative" }}>
                <ImInfo
                  onClick={() => setPorto(true)}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </CenteredBoxMobile>
          </>
        )}
        {administradora === "62" && (
          <>
            <CenteredBoxMobile style={{ marginTop: 15, padding: "0px 10px" }}>
              <V3TextH1 style={{ textAlign: "center" }}>
                Informe os dados da cota que deseja vender
              </V3TextH1>
            </CenteredBoxMobile>
            <CenteredBoxMobile
              style={{
                justifyContent: "flex-start",
                margin: "0 10px",
                marginTop: 15,
                marginBottom: 15,
              }}
            >
              <V3Text5 style={{ textAlign: "center" }}>
                Abaixo informe o número do seu contrato da sua cota, ou anexe o
                extrato detalhado da sua cota, em formato .pdf, que pode ser
                resgatado através do portal do consorciado do Santander. Estas
                informações são importantes para que possamos te apresentar a
                melhor oferta:
              </V3Text5>
            </CenteredBoxMobile>
            <CenteredBoxMobile>
              <V3TextCustom
                label="Informe o seu Contrato"
                sx={{ width: 320 }}
                onChange={(e) => {
                  const text = e.target.value.replace(/[^\d]/g, "");
                  if (text.length <= 20) {
                    setContrato(text);
                  }
                }}
                value={contrato}
                size="small"
              />
              <div style={{ position: "relative", right: -10 }}>
                <ImInfo
                  onClick={() => setSantander(true)}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </CenteredBoxMobile>
            <CenteredBoxMobile
                style={{ justifyContent: "center", margin: "10px 10px" }}
              >
                <V3Text5>OU</V3Text5>
              </CenteredBoxMobile>
            <CenteredBoxMobile>
              <V3FileInputMobile
                label="Anexe o seu Extrato"
                width={320}
                action={setFileBase}
              />
            </CenteredBoxMobile>
          </>
        )}
        {administradora === "114" && (
          <>
            <CenteredBoxMobile style={{ marginTop: 15, padding: "0px 10px" }}>
              <V3TextH1 style={{ textAlign: "center" }}>
                Informe os dados da cota que deseja vender
              </V3TextH1>
            </CenteredBoxMobile>
            <CenteredBoxMobile
              style={{
                justifyContent: "center",
                margin: "10px 10px",
                marginTop: 15,
                marginBottom: 15,
              }}
            >
              <V3Text5 style={{ textAlign: "center" }}>
                Abaixo informe o número do seu contrato da sua cota, ou anexe o
                extrato detalhado da sua cota, em formato .pdf, que pode ser
                resgatado através do portal do consorciado do Banrisul. Estas
                informações são importantes para que possamos te apresentar a
                melhor oferta:
              </V3Text5>
            </CenteredBoxMobile>
            <CenteredBoxMobile
              style={{
                justifyContent: "center",
                margin: "15px 10px",
              }}
            >
              <V3TextCustom
                label="Informe o seu Contrato"
                sx={{ width: 331 }}
                onChange={(e) => {
                  const text = e.target.value.replace(/[^\d]/g, "");
                  if (text.length <= 20) {
                    setContrato(text);
                  }
                }}
                value={contrato}
                required
                size="small"
              />
              <div style={{ position: "relative", right: -10 }}>
                <ImInfo
                  onClick={() => setBanrisul(true)}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </CenteredBoxMobile>
            <CenteredBoxMobile
              style={{
                justifyContent: "center",
                margin: "10px 10px 10px 20px",
              }}
            >
              <V3Text5>OU</V3Text5>
            </CenteredBoxMobile>
            <CenteredBoxMobile>
              <V3FileInputMobile
                label="Anexe o seu Extrato"
                width={310}
                action={setFileBase}
              />
              <div style={{ position: "relative", right: -10 }}>
                <ImInfo
                  onClick={() => setChooseBanrisul(true)}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </CenteredBoxMobile>
          </>
        )}
        <V3Modal
          open={canopus}
          height={306}
          width={450}
          actionClose={setCanopus}
          title={"Informação sobre seu grupo e cota:"}
          html={
            <V3Text5 style={{ textAlign: "center", color: v3Colors.black }}>
              Pedimos atenção no preenchimento das informações de Grupo e Cota
              do seu consórcio. No telegrama, SMS ou e-mail que recebeu, você
              tem o seu grupo cota e versão nesse formato: 0000/111/22 Os
              primeiros 4 dígitos são o grupo (0000) e os dígitos que estão no
              meio são a cota (111). Precisamos do preenchimento no formulário
              destes dois números iniciais para gerar a sua oferta.
            </V3Text5>
          }
        />
        <V3Modal
          open={porto}
          height={306}
          width={450}
          actionClose={setPorto}
          title={"Informação sobre seu grupo e cota:"}
          html={
            <V3Text5 style={{ textAlign: "center", color: v3Colors.black }}>
              Pedimos atenção no preenchimento das informações de Grupo e Cota
              do seu consórcio. O preenchimento no formulário destes dois campos
              são fundamentais para gerarmos sua oferta.
            </V3Text5>
          }
        />
        <V3Modal
          open={banrisul}
          height={350}
          width={"100%"}
          actionClose={setBanrisul}
          title={"Encontre o número do seu contrato:"}
          html={
            <V3Text5 style={{ textAlign: "center", color: v3Colors.black }}>
              O número do contrato pode ser encontrado no canto superior direito
              do extrato detalhado da sua cota. Segue abaixo:
              <br />
              <br />
              <img loading="lazy" src={ContratoSantander} alt="sant" />
            </V3Text5>
          }
        />
        <V3Modal
          open={chooseBanrisul}
          height={306}
          width={320}
          actionClose={setChooseBanrisul}
          title={"Informação sobre seu contrato:"}
          html={
            <V3Text5 style={{ textAlign: "center", color: v3Colors.black }}>
              Acesse o portal do consorciado Banrisul para baixar o seu extrato.
              Não precisa imprimir. Basta salvar e nos enviar o arquivo em .pdf
              <br />
              <br />
              <a
                href="#"
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                Clique aqui
              </a>{" "}
              para acessar o portal e fazer o download do seu extrato. Após
              isto, retorne e anexe nesta tela para te enviarmos a melhor
              oferta.
            </V3Text5>
          }
        />
        <V3Modal
          open={santander}
          height={350}
          width={350}
          actionClose={setSantander}
          title={"Encontre o número do seu contrato:"}
          html={
            <V3Text5 style={{ textAlign: "center", color: v3Colors.black }}>
              O número do contrato pode ser encontrado no canto superior direito
              do extrato detalhado da sua cota. Segue abaixo:
              <br />
              <br />
              <img loading="lazy" src={ContratoSantander} alt="santander" />
            </V3Text5>
          }
        />
        <V3Modal
          open={chooseSantander}
          height={450}
          width={350}
          style={{ margin: 10 }}
          actionClose={setChooseSantander}
          title={"Resgate seu extrato:"}
          html={
            <V3Text5 style={{ textAlign: "center", color: v3Colors.black }}>
              Acesse o portal do consorciado Santander para baixar o seu
              extrato. Abaixo segue como acessar:
              <br />
              <br />
              1- Acesse o link:{" "}
              <a href="https://www.santander.com.br/consorciado/login">
                https://www.santander.com.br/consorciado/login
              </a>{" "}
              <br />
              2- Preencha com o seu CPF/CNPJ e senha; <br />
              para acessar o portal e fazer o download do seu extrato. Após
              isto, retorne e anexe nesta tela para te enviarmos a melhor
              oferta.
              <br />
              3- Busque a sua cota e clique em detalhes; <br />
              4-Selecione "extrato" e logo após "consultar"; <br />
              5-Selecione "Extrato completo"; <br />
              6- Clique em imprimir/baixar PDF. <br /> <br />
              Lembrando: Não é necessário imprimir
            </V3Text5>
          }
        />
        <V3Modal
          open={hasInfo}
          actionClose={setHasInfo}
          title={"Informação sobre seu consórcio"}
          html={
            <V3Text5 style={{ textAlign: "center" }}>
              Não possui as informações do consórcio? <br />
              <a href="/">Clique aqui</a> e verifique como resgatar o seu
              extrato.
            </V3Text5>
          }
        />

        <V3Modal
          open={hasInfo}
          actionClose={setHasInfo}
          title={"Informação sobre seu consórcio"}
          html={
            <V3Text5 style={{ textAlign: "center" }}>
              Não possui as informações do consórcio? <br />
              <a href="/">Clique aqui</a> e verifique como resgatar o seu
              extrato.
            </V3Text5>
          }
        />
        <V3Modal
          open={hasError}
          actionClose={handleResetOnboarding}
          title={"Atenção!"}
          text={errorText}
          type="error"
        />
        <V3Modal
          open={hasErrorWithoutClear}
          actionClose={setHasErrorWithoutClear}
          title={"Atenção!"}
          text={errorText}
          type="error"
        />
        <V3Modal
          open={hasSuccess}
          actionClose={setHasSuccess}
          title={"Sucesso!"}
          text={successText}
          type="success"
        />
        <CenteredBoxMobile
              style={{
                display: "flex",
                flex: 1,
                flexWrap: "wrap",
                justifyContent: "flex-start",
                marginTop: 15,
                padding: "0px 10px",
              }}
            >
              <V3Text5 style={{ color: v3Colors.black }}>Ao clicar em "Quero a melhor oferta" aceito os               <a
                style={{ display: "flex", textDecoration: "none" }}
                href={require("../../../assets/termo/Politica_Privacidade.pdf")}
                target="_blank" rel="noreferrer"
              >
                <V3Text5
                  style={{
                    color: v3Colors.primary,
                    marginLeft: 5,
                    fontWeight: "500",
                  }}
                >
                  termos e políticas
                </V3Text5>
              </a></V3Text5>

            </CenteredBoxMobile>

        <CenteredBox style={{ gap: 20, marginTop: 30 }}>
          <V3Button
            onClick={handleNext}
            style={{ marginBottom: 30 }}
            width={315}
            height={60}
            text={"QUERO A MELHOR OFERTA"}
            estilo="primary"
          />
        </CenteredBox>
        <CenteredBox style={{ gap: 20, marginTop: 15, marginBottom: 20 }}>
          <V3Text5
            style={{
              cursor: "pointer",
              textDecoration: "underline",
              color: "#000",
              fontWeight: "bold",
            }}
            onClick={handlePrev}
          >
            Voltar
          </V3Text5>
        </CenteredBox>
      </BoxStepMobile>
    </>
  );
};
