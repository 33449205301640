import React, { useEffect, useState } from "react";
import { V3Button } from "../../styles/themes/components/button";
import { V3TextInput } from "../../styles/themes/components/input";
import {
  ContainerDesktopOPT,
  ContainerMobileOPT,
} from "../../styles/themes/components/shared/styles";

import "../../styles/reset.css";
import ConcashLogo from "../../assets/images/logo-negative.png";

import { Step1Desktop, Step1Mobile } from "./steps/step1";
import { V3Text5, V3TextFooter } from "../../styles/themes/components/texts";
import moment from "moment";
import { v3Colors } from "../../styles/themes/v3/colors";
import api from "../../services/api";
import { Step2Desktop, Step2Mobile } from "./steps/step2";
import { Step3Desktop, Step3Mobile } from "./steps/step3";
import { Step4Desktop, Step4Mobile } from "./steps/step4";

const OptIn: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [onboarding, setOnboarding] = useState("");
  const [etapas, setEtapas] = useState([]);
  const [recData, setRecData] = useState("");

  const mobile = window.matchMedia("(max-width:768px)").matches;

  const DesktopScreen = ({
    step,
    onboarding,
    setCurrentStep,
    recData,
  }: any) => {
    return (
      <>
        {onboarding && (
          <ContainerDesktopOPT>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: 10,
              }}
            >
              <img
                loading="lazy"
                src={ConcashLogo}
                style={{ marginBottom: 10 }}
                width={162}
                height={44}
                alt="concash"
              />
              <V3Text5
                style={{
                  alignSelf: "center",
                  textAlign: "center",
                  marginBottom: 10,
                }}
              >
                Venda seu consórcio online com segurança, rapidez e
                transparência.
                <br />
                Caso queira saber mais sobre a Concash{" "}
                <a
                  href="https://www.concash.com.br"
                  style={{ color: v3Colors.grayHard }}
                  target="_blank"
                >
                  clique aqui
                </a>
                .
              </V3Text5>
            </div>
            {step === null && (
              <Step1Desktop
                onboarding={onboarding}
                setCurrentStep={setCurrentStep}
                recData={recData}
              />
            )}
            {step === 1 && (
              <Step1Desktop
                onboarding={onboarding}
                setCurrentStep={setCurrentStep}
                recData={recData}
              />
            )}
            {step === 2 && (
              <Step2Desktop
                onboarding={onboarding}
                setCurrentStep={setCurrentStep}
                recData={recData}
              />
            )}
            {step === 3 && (
              <Step3Desktop
                onboarding={onboarding}
                setCurrentStep={setCurrentStep}
                recData={recData}
              />
            )}
            {step === 4 && (
              <Step4Desktop
                onboarding={onboarding}
                setCurrentStep={setCurrentStep}
                recData={recData}
              />
            )}
            <div>
              <V3TextFooter>
                © Copyright {moment(new Date()).format("YYYY")} Concash. Todos
                os direitos reservados{" "}
              </V3TextFooter>
            </div>
          </ContainerDesktopOPT>
        )}
      </>
    );
  };

  const MobileScreen = (props: any) => {
    const { step, onboarding, setCurrentStep, recData } = props;
    return (
      <>
        {onboarding && (
          <ContainerMobileOPT>
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: 40,
              }}
            >
              <img
                loading="lazy"
                src={ConcashLogo}
                style={{ marginBottom: 10 }}
                width={110}
                height={30}
                alt="concash"
              />
              <V3Text5
                style={{
                  alignSelf: "center",
                  textAlign: "center",
                  marginBottom: 10,
                  padding: "10px 30px ",
                  fontWeight: "400",
                  lineHeight: 1.3,
                  fontSize: 15,
                }}
              >
                Venda seu consórcio online com segurança, rapidez e
                transparência. Caso queira saber mais sobre a Concash{" "}
                <a
                  href="https://www.concash.com.br"
                  target="_blank"
                  style={{ color: v3Colors.grayHard }}
                >
                  clique aqui
                </a>
                .
              </V3Text5>

              <div>
                {step === null && (
                  <Step1Mobile
                    onboarding={onboarding}
                    setCurrentStep={setCurrentStep}
                    recData={recData}
                  />
                )}
                {step === 1 && (
                  <Step1Mobile
                    onboarding={onboarding}
                    setCurrentStep={setCurrentStep}
                    recData={recData}
                  />
                )}
                {step === 2 && (
                  <Step2Mobile
                    onboarding={onboarding}
                    setCurrentStep={setCurrentStep}
                    recData={recData}
                  />
                )}
                {step === 3 && (
                  <Step3Mobile
                    onboarding={onboarding}
                    setCurrentStep={setCurrentStep}
                    recData={recData}
                  />
                )}
                {step === 4 && (
                  <Step4Mobile
                    onboarding={onboarding}
                    setCurrentStep={setCurrentStep}
                    recData={recData}
                  />
                )}
              </div>

              <div style={{ position: "relative", bottom: -15 }}>
                <V3TextFooter style={{ textAlign: "center" }}>
                  © Copyright {moment(new Date()).format("YYYY")} Concash.
                  <br />
                  Todos os direitos reservados{" "}
                </V3TextFooter>
              </div>
            </div>
          </ContainerMobileOPT>
        )}
      </>
    );
  };

  async function generateOnboard() {
    const onb: any = await api.get(
      "/api/concash/1.0/cliente-cadastro/onboarding/iniciar"
    );
    const atual = parseInt(await onb.data.etapaAtual);
    setRecData(await onb.data);

    localStorage.setItem("etapas", `${JSON.stringify(await onb.data.etapas)}`);
    localStorage.setItem("etapaAtual", `${await onb.data.etapaAtual}`);
    localStorage.setItem("onboarding", `${await onb.data.id}`);
    localStorage.setItem("onboardingObj", `${JSON.stringify(await onb.data)}`);

    setOnboarding(await onb.data.id);
    setCurrentStep(await onb.data.etapaAtual);
    setEtapas(await onb.data.etapas);
  }

  async function recuperarDados() {
    const on = localStorage.getItem("onboarding");
    const etp: any = await api.get(
      `/api/concash/1.0/cliente-cadastro/onboarding/recuperar/${on}`
    );

    setRecData(await etp.data);

    const atual = parseInt(await etp.data.etapaAtual);
    setCurrentStep(await etp.data.etapaAtual);

    localStorage.setItem("onboardingObj", `${JSON.stringify(await etp.data)}`);
    localStorage.setItem("etapas", `${JSON.stringify(await etp.data.etapas)}`);
    localStorage.setItem(
      "etapaAtual",
      `${JSON.stringify(await etp.data.etapaAtual)}`
    );

    (await etp.data.etapaAtual) && setCurrentStep(await etp.data.etapaAtual);
  }

  useEffect(() => {
    const onboard: any = localStorage.getItem("onboarding");
    const onboardObj = localStorage.getItem("onboardingObj");
    if (!onboard) {
      generateOnboard();
    } else {
      recuperarDados();
    }
    setOnboarding(onboard);
  }, []);

  return (
    <>
      {mobile && (
        <MobileScreen
          step={currentStep}
          setCurrentStep={setCurrentStep}
          onboarding={onboarding}
          etapas={etapas}
          recData={recData}
        />
      )}
      {!mobile && (
        <DesktopScreen
          step={currentStep}
          setCurrentStep={setCurrentStep}
          onboarding={onboarding}
          etapas={etapas}
          recData={recData}
        />
      )}
    </>
  );
};

export default OptIn;
