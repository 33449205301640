import React, { useEffect, useState } from "react";
import * as Type from "./types";
import * as S from "./styles";
import Plus from "../assets/plus-icon.png";
import DAF from "../../../../assets/subads/daf-logo.webp";
import Yeah from "../../../../assets/subads/yeah.svg";
import JohnDeere from "../../../../assets/subads/john.webp";
import Racon from "../../../../assets/subads/racon-logo.webp";
import Volare from "../../../../assets/subads/consorcio-volare-logo.webp";
import Randon from "../../../../assets/subads/randon.webp";

import ConcashMobile from "../assets/concashlogo.svg";
import PortoWhite from "../assets/umparceirodenegocios_portobank_white.png";
import Tip1 from "../assets/tip1.svg";
import Tip2 from "../assets/tip2.svg";
import P1R from "../assets/p1-randon.svg";
import P2R from "../assets/p2-randon.svg";
import P3R from "../assets/p3-randon.svg";
import HorizontalLine from "../assets/horizontalLine.svg";
import HorizontalLineMobile from "../assets/horizontalLineMobile.svg";
import "./reset.css";
import { DateTime } from "luxon";
import Lottie from "lottie-react";
import moment from "moment";
import checkDevice from "../../../../utils/isMobile";
import {
  ComoFuncionaCardDesktop,
  ComoFuncionaCardMobile,
} from "../components/comofunciona/ComoFuncionaCard";
import api from "../../../../services/api";
import {
  VantagensDesktop,
  VantagensMobile,
} from "../components/Vantagens/Vantagens";
import { FooterDesktop, FooterMobile } from "../components/Footer/Footer";
import { Backdrop, Checkbox, FormControlLabel } from "@mui/material";
import { Helmet } from "react-helmet-async";
import {
  V3FileInput,
  V3TextCustom,
  V3Select,
  V3FileInputMobile,
  V3StyledTextCustomized,
} from "../../../../styles/themes/components/input";
import { validNome } from "../../../../utils/validate";
import {
  v3CellphoneFormater,
  v3FormataCNPJ,
  v3MaskCPF,
} from "../../../../styles/themes/utils/format";
import {
  getNavegador,
  getSistemaOperacional,
  getIp,
} from "../../../../styles/themes/utils/plataforma";
import {
  v3ValidaCpfCnpj,
  v3ValidateEmail,
} from "../../../../styles/themes/utils/validate";
import { V3Modal } from "../../../../styles/themes/components/modal";
import { V3Button } from "../../../../styles/themes/components/button";
import StyleM from "./LP.module.css";
import { BsWhatsapp } from "react-icons/bs";
import TagManager from "react-gtm-module";
import { ImInfo } from "react-icons/im";
import { V3Text5 } from "../../../../styles/themes/components/texts";
import { v3Colors } from "../../../../styles/themes/v3/colors";
import { getURLParameter } from "../../../../utils/sanitize";

import RandonLogo from "../assets/randon/logo.png";
import RandonCover from "../assets/randon/fundo.png";
import RandonForm from "../assets/randon/form.png";


const LandingRandon: React.FC = () => {
  const landing: any = window.location.pathname.replace("/", "");
  const [loading, setLoading] = useState(false);
  const [tituloLanding, setTituloLanding] = useState("");
  const mobile = window.matchMedia("(max-width:768px)").matches;
  const [estilo, setEstilo] = useState<Type.EstiloProps | null>(null);
  const [cookies, setCookies] = useState(true);
  const [extrato, setExtrato] = useState("");
  const [iconLoading, setIconLoading] = useState<any>();
  const [tiposConsorcio, setTiposConsorcio] = useState([]);
  const [formSubmetido, setFormSubmetido] = useState(false);

  const [nomeRazao, setNomeRazao] = useState("");
  const [nomeRazaoerr, setNomeRazaoerr] = useState(false);
  const [telefone, setTelefone] = useState("");
  const [telefoneerr, setTelefoneer] = useState(false);
  const [contatoWhatsapp, setContatoWhatsapp] = useState(false);
  const [cpfCnpj, setCpfCnpj] = useState("");
  const [cpfCnpjerr, setCpfCnpjerr] = useState(false);
  const [email, setEmail] = useState("");
  const [emailerr, setEmailerr] = useState(false);
  const [tipoConsorcio, setTipoConsorcio] = useState("");
  const [contrato, setContrato] = useState<any>("");
  const [canopus, setCanopus] = useState(false);

  const [hasError, setHasError] = useState(false);
  const [errorText, setErrorText] = useState("");

  const parametersTela: any = {
    randon: 51,
  };

  const iconP: any = {
    randon: ["", P1R, P2R, P3R],
  };

  const paramIdTela: any = 51;
  const [horaAcesso, setHoraAcesso] = useState("");
  const [browserUser, setBrowserUser] = useState("");
  const [isMobileUser, setIsMobileUser] = useState(false);
  const [ipUser, setIpUser] = useState("");
  const [startForm, setStartForm] = useState("");
  const [hrAceite, setHrAceite] = useState("");
  const [os, setOS] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [subAdm, setSubAdm] = useState("");

  const coverMobileStyle = {
    width: "100%",
    borderRadius: "180px 180px 0px 0px",
    color: "#FFF",
  };

  const coverDesktopStyle = {
    display: "flex",
    borderRadius: "190px 190px 0px 0px",
    zIndex: 0,
  };

  function handleTipoConsorcio(e: any) {
    if (e) {
      setTipoConsorcio(e.id);
    }
  }

  function handleNomeRazao(text: any) {
    const valid: any = validNome(text.target.value);
    handleGetDataAccess();
    if (!text.target.value || !valid) {
      setNomeRazao(text.target.value);
      setNomeRazaoerr(true);
    } else {
      setNomeRazao(text.target.value);
      setNomeRazaoerr(false);
    }
  }

  const handleGetStyles = async () => {
    if (estilo) {
      return;
    } else {
      try {
        const { data } = await api.get(
          `api/dado-tela/1.0/buscar/${landing === "porto" ? "0" : paramIdTela}`
        );
        const dadosRecebidos = data;
        dadosRecebidos.adminLogo = RandonLogo;
        dadosRecebidos.backgroundCover = RandonCover;
        dadosRecebidos.backgroundCoverSuccess = RandonCover;
        dadosRecebidos.backgroundForm = RandonForm;
        setEstilo(data);
        localStorage.setItem("administradoraO", `51`);
        localStorage.setItem("channel", "22");
      } catch (error) {
        setErrorText("Erro ao listar os dados da tela");
        setHasError(true);
      }
    }
  };

  function horaAcessado() {
    if (horaAcesso) {
      return;
    }
    const acessado = moment([], true).format("DD/MM/YYYY  HH:mm:ss");
    setHoraAcesso(acessado);
  }

  async function getNavigationData() {
    const ip = await getIp();
    setIpUser(ip);
    setOS(getSistemaOperacional(window));
    setBrowserUser(getNavegador(window));
    setIsMobileUser(checkDevice());
  }

  async function getTiposConsorcio() {
    setTiposConsorcio([]);
    if (landing == "porto" || landing == "canopus") {
      try {
        const { data } = await api.get("/api/tipo-consorcio/1.0/listar");
        let arrTipo: any = [];

        data.forEach((d: any) => {
          arrTipo.push({ label: d.descricao, id: d.id });
        });
        setTiposConsorcio(arrTipo);
      } catch (error) {
        setErrorText("Erro ao listar os dados da tela");
        setHasError(true);
      }
    } else if (landing == "volkswagen") {
      let arrTipo: any = [];
      arrTipo.push({ label: "Automóvel", id: "3" });
      arrTipo.push({ label: "Pesado", id: "21" });
      setTiposConsorcio(arrTipo);
    }
  }

  async function handleSetTitle() {
    var tituloPagina = "Vender Consórcio Randon Concash";

    setTituloLanding(tituloPagina);
  }

  function handleTelefone(t: any) {
    const validatePhone = t.replace(/[^0-9]/g, "").split("");
    if (t) {
      setTelefoneer(false);
      if (t.length > 15) {
        setTelefone(telefone);
      } else {
        setTelefone(v3CellphoneFormater(t));
      }
    }
    if (!t || t == "" || t == "(") {
      setTelefoneer(true);
      setTelefone(t);
    }

    if (t.length < 14 || validatePhone[0] === "0") {
      setTelefoneer(true);
    } else {
      setTelefoneer(false);
    }
  }

  function handleEmail(t: any) {
    const text = t.replaceAll(" ", "");
    if (v3ValidateEmail(text) || text === "") {
      setEmail(text);
      setEmailerr(false);
    } else {
      setEmail(text);
      setEmailerr(true);
    }
  }

  function handleCpfCnpj(text: string) {
    const validating = text.replace(/[^\d]/g, "");

    if (validating.length <= 14) {
      if (validating.length <= 11) {
        const cpfs = v3MaskCPF(validating);
        setCpfCnpj(cpfs);
        if (v3ValidaCpfCnpj(cpfs) || text === "") {
          setCpfCnpjerr(false);
        } else {
          setCpfCnpjerr(true);
        }
      } else {
        const cn = v3FormataCNPJ(validating);
        setCpfCnpj(cn);

        if (v3ValidaCpfCnpj(cn) || text === "") {
          setCpfCnpjerr(false);
        } else {
          setCpfCnpjerr(true);
        }
      }
    }
  }

  function handleAccept() {
    const acessado = moment([], true).format("DD/MM/YYYY  HH:mm:ss");
    setHrAceite(acessado);
    return acessado;
  }

  function handleGetDataAccess() {
    if (startForm) {
      return;
    }
    const acessado = moment([], true).format("DD/MM/YYYY  HH:mm:ss");
    setStartForm(acessado);
    const tz = DateTime.local().zone.name;
    setTimeZone(tz);
  }

  async function handleSendFormulario() {
    const aceito = handleAccept();
    const formData = new FormData();

    if (!nomeRazao || nomeRazaoerr) {
      setErrorText("Preencha corretamente Nome / Razão social.");
      return setHasError(true);
    }
    if (!cpfCnpj || cpfCnpjerr) {
      setErrorText("Preencha corretamente o CPF ou CNPJ.");
      return setHasError(true);
    }

    if (!telefone || telefoneerr) {
      setErrorText("Preencha corretamente o número de telefone.");
      return setHasError(true);
    }
    if (!email || emailerr) {
      setErrorText("Preencha corretamente o E-Mail.");
      return setHasError(true);
    }

    setLoading(true);
    const cpfcnpjParsed = parseInt(cpfCnpj.replace(/[^0-9]/g, ""));
    const tels: any = telefone?.split(" ");
    const ddd = tels[0]?.replace(/[^0-9]/g, "");
    const tel = tels[1]?.replace(/[^0-9]/g, "");

    const midia = getURLParameter('utm_medium');
    const origem =  getURLParameter('utm_source')
    const campanha =  getURLParameter('utm_campaign');
    const segmento =  getURLParameter('utm_term');
    const anuncio =  getURLParameter('utm_content');
    if(origem) formData.append("origem", origem);
    if(midia) formData.append("midia", midia)
    if(campanha) formData.append("campanha", campanha)
    if(segmento) formData.append("segmento", segmento)
    if(anuncio) formData.append("anuncio", anuncio)

    formData.append("cpfCnpj", `${cpfcnpjParsed}`);
    formData.append("nomeRazao", nomeRazao);
    formData.append("email", email);
    formData.append("contatoWhatsapp", contatoWhatsapp ? "1" : "0");
    formData.append("dddTelefone", ddd);
    formData.append("telefone", tel);
    formData.append("idAdministradora", `51`);
    formData.append("idSubAdministradora", subAdm);
    formData.append("idCnal", "22");
    formData.append("comoConheceuId", "0");
    formData.append("contrato", contrato);

    formData.append("idTipoConsorcio", "21");

    const plataforma = `{
      "acessado_em": "${horaAcesso}", 
      "mobile": ${isMobileUser ? "SIM" : "NÃO"},
      "navegador": "${browserUser}", 
      "os": "${os}", 
      "ip": "${ipUser}", 
      "aceite_politica_em": "${hrAceite ? hrAceite : aceito}", 
      "inicio_form":" ${startForm}", 
      "timezone": "${timeZone}"    
  }`;

    formData.append("plataforma", plataforma);
    try {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      await api
        .post("/api/site/1.0/melhor-proposta-website", formData)
        .then(({ data }: any) => {
          setLoading(false);

          const tagManagerArgs = {
            gtmId: "GTM-WB757J7",
          };
          TagManager.initialize(tagManagerArgs);
          (window as any).dataLayer.push({
            event: "solicitacao-proposta",
            email: email,
            phone: tel,
          });
          setFormSubmetido(true);
        });
    } catch (e: any) {
      setLoading(false);
      setErrorText("Houve um erro ao enviar seus dados.");
      return setHasError(true);
    }
  }

  useEffect(() => {
    getTiposConsorcio();
    handleSetTitle();
    handleGetStyles();
    horaAcessado();
    getNavigationData();
  }, []);

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>{tituloLanding}</title>
        <link rel="canonical" href={`https://concash.com.br/${landing}`} />
        {landing === "canopus" && (
          <>
            <meta
              name="description"
              content={
                "Venda sua cota de consórcio Canopus com a Concash e receba seu dinheiro à vista, com a melhor oferta e prazo mais rápido do mercado. Solicite sua proposta"
              }
            />
          </>
        )}
      </Helmet>
      <V3Modal
        open={canopus}
        height={306}
        width={450}
        actionClose={setCanopus}
        title={"Informação sobre seu grupo e cota:"}
        html={
          <V3Text5 style={{ textAlign: "center", color: v3Colors.black }}>
            Pedimos atenção no preenchimento das informações de Grupo e Cota do
            seu consórcio. No telegrama, SMS ou e-mail que recebeu, você tem o
            seu grupo cota e versão nesse formato: 0000/111/22 Os primeiros 4
            dígitos são o grupo (0000) e os dígitos que estão no meio são a cota
            (111). Precisamos do preenchimento no formulário destes dois números
            iniciais para gerar a sua oferta.
          </V3Text5>
        }
      />
      {estilo ? (
        <>
          <V3Modal
            open={hasError}
            actionClose={setHasError}
            title={"Atenção!"}
            estilo={landing}
            text={errorText}
            type="error"
          />

          {/* Mobile */}
          {mobile && (
            <>
                {!subAdm && (
                  <Backdrop style={{ zIndex: 9999 }} open={!subAdm}>
                    <div className={StyleM.containerSubAdm}>
                      <span style={{ fontSize: 20, fontWeight: "bold", textAlign: "center", alignSelf: "center", paddingLeft: 20, marginBottom: 15 }}>
                        Selecione a sua administradora:
                      </span>
                      <div className={StyleM.lineSubs}>
                      <div
                          id="randon"
                          className={StyleM.boxSubAdm}
                          onClick={() => setSubAdm("51")}
                        >
                          <img
                            src={Randon}
                            alt="randon"
                            style={{ width: "100%" }}
                          />
                        </div>

                        <div
                          id="john"
                          className={StyleM.boxSubAdm}
                          onClick={() => setSubAdm("161")}
                        >
                          <img
                            src={JohnDeere}
                            alt="john"
                            style={{ width: "100%" }}
                          />
                        </div>

                        <div
                          id="racon"
                          className={StyleM.boxSubAdm}
                          onClick={() => setSubAdm("162")}
                        >
                          <img
                            src={Racon}
                            alt="racon"
                            style={{ width: "100%" }}
                          />
                        </div>

                        <div
                          id="volare"
                          className={StyleM.boxSubAdm}
                          onClick={() => setSubAdm("163")}
                        >
                          <img
                            src={Volare}
                            alt="volare"
                            style={{ width: "100%" }}
                          />
                        </div>

                        <div
                          id="daf"
                          className={StyleM.boxSubAdm}
                          onClick={() => setSubAdm("164")}
                        >
                          <img src={DAF} alt="DAF" style={{ width: "100%" }} />
                        </div>
                        <div
                          id="Yeah"
                          className={StyleM.boxSubAdm}
                          onClick={() => setSubAdm("165")}
                        >
                          <img
                            src={Yeah}
                            alt="Yeah"
                            style={{ width: "100%" }}
                          />
                        </div>

                      </div>
                    </div>
                  </Backdrop>
                )}
              {loading && (
                <Backdrop
                  open={loading}
                  style={{
                    display: "flex",
                    maxWidth: "fit-content",
                    zIndex: 999,
                  }}
                >
                  <Lottie animationData={iconLoading} width={"20%"} />
                </Backdrop>
              )}
              {cookies && (
                <Backdrop
                  style={{ zIndex: 1000 }}
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={cookies}
                >
                  <S.CookieBoxMobile style={{ maxWidth: "fit-content" }}>
                    <S.TextFooterMobile>
                      Nós armazenamos dados temporariamente para melhorar a sua
                      experiência de navegação e recomendar conteúdo do seu
                      interesse. Ao continuar navegando, você concorda com a
                      nossa política de privacidade.
                    </S.TextFooterMobile>

                    <S.ButtonCookie
                      style={{
                        backgroundColor: estilo?.backgroundColor,
                        marginBottom: 10,
                      }}
                      onClick={() => setCookies(false)}
                    >
                      CONTINUAR
                    </S.ButtonCookie>
                  </S.CookieBoxMobile>
                </Backdrop>
              )}
              <>
                <S.MobilePageContainer>
                  <S.MobileHeader
                    style={{ backgroundColor: estilo?.backgroundColor }}
                  >
                    <S.MobileHeaderTopBox>
                      {landing === "porto" ? (
                        <>
                          <img
                            loading="lazy"
                            src={ConcashMobile}
                            alt="concash"
                          />
                          <img
                            loading="lazy"
                            src={PortoWhite}
                            width={120}
                            alt="porto-white"
                          />
                        </>
                      ) : (
                        <>
                          <img
                            loading="lazy"
                            src={ConcashMobile}
                            alt="concash"
                          />
                        </>
                      )}
                    </S.MobileHeaderTopBox>
                    <S.MobileFilterPhoto
                      style={{ backgroundColor: estilo?.backgroundColor }}
                    />
                    <S.MobilePhotoBox style={coverMobileStyle}>
                      <img
                        src={
                          formSubmetido
                            ? estilo?.backgroundCoverSuccess
                            : estilo?.backgroundCover
                        }
                        alt={
                          formSubmetido
                            ? estilo?.altBackgroundSuccess
                            : estilo?.altBackground
                        }
                        height="430px"
                        style={{
                          width: "100%",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          borderRadius: "180px 180px 0px 0px",
                          color: "#FFF",
                          objectFit: "cover",
                        }}
                      />
                      {!formSubmetido ? (
                        <>
                          <h1 className={StyleM.MobilePhotoTitle} style={{ zIndex: 5 }}>
                            Desistiu do seu consórcio? Venda aqui com a melhor oferta!
                          </h1>

                          <S.MobilePhotoButton
                            onClick={() => (window.location.href = "#form")}
                            style={{
                              backgroundColor: estilo?.buttonColor,
                              color: estilo?.buttonTextColor,
                              zIndex: 7,
                            }}
                          >
                            QUERO VENDER
                          </S.MobilePhotoButton>
                        </>
                      ) : (
                        <>
                          <S.MobilePhotoTitle style={{ zIndex: 15 }}>
                            Solicitação enviada com sucesso!
                          </S.MobilePhotoTitle>
                          <S.MobilePhotoSubTitle
                            style={{
                              zIndex: 5,
                              width: "90vw",
                              left: 10,
                              marginTop: "90px",
                            }}
                          >
                            Aguarde nosso contato. Enviaremos a melhor proposta
                            por e-mail, WhatsApp ou ligação, pelo número (11)
                            4040 5686. Obrigado por escolher a Concash.
                          </S.MobilePhotoSubTitle>
                        </>
                      )}
                    </S.MobilePhotoBox>
                  </S.MobileHeader>
                </S.MobilePageContainer>

                {!formSubmetido && (
                  <S.MobilePageContent style={{ marginTop: "30px" }}>
                          <span className={StyleM.titleAdmSelect}>Administradora Selecionada:</span>
<div style={{ display: "flex", marginTop: 30 }}>
                        <div className={StyleM.lineSubs}>
                        <div
                            id="randon"
                            className={
                              subAdm === "51"
                                ? StyleM.boxSubAdmSelected
                                : StyleM.boxSubAdmNotSelected
                            }
                            onClick={() => setSubAdm("51")}
                          >
                            <img
                              src={Randon}
                              alt="randon"
                              style={{ width: "100%" }}
                            />
                          </div>

                          <div
                            id="john"
                            className={
                              subAdm === "161"
                                ? StyleM.boxSubAdmSelected
                                : StyleM.boxSubAdmNotSelected
                            }
                            onClick={() => setSubAdm("161")}
                          >
                            <img
                              src={JohnDeere}
                              alt="john"
                              style={{ width: "100%" }}
                            />
                          </div>

                          <div
                            id="racon"
                            className={
                              subAdm === "162"
                                ? StyleM.boxSubAdmSelected
                                : StyleM.boxSubAdmNotSelected
                            }
                            onClick={() => setSubAdm("162")}
                          >
                            <img
                              src={Racon}
                              alt="racon"
                              style={{ width: "100%" }}
                            />
                          </div>

                          <div
                            id="volare"
                            className={
                              subAdm === "163"
                                ? StyleM.boxSubAdmSelected
                                : StyleM.boxSubAdmNotSelected
                            }
                            onClick={() => setSubAdm("163")}
                          >
                            <img
                              src={Volare}
                              alt="volare"
                              style={{ width: "100%" }}
                            />
                          </div>

                          <div
                            id="daf"
                            className={
                              subAdm === "164"
                                ? StyleM.boxSubAdmSelected
                                : StyleM.boxSubAdmNotSelected
                            }
                            onClick={() => setSubAdm("164")}
                          >
                            <img
                              src={DAF}
                              alt="DAF"
                              style={{ width: "100%" }}
                            />
                          </div>
                          <div
                            className={
                              subAdm === "165"
                                ? StyleM.boxSubAdmSelected
                                : StyleM.boxSubAdmNotSelected
                            }
                            onClick={() => setSubAdm("165")}
                          >
                            <img
                              src={Yeah}
                              alt="Yeah"
                              style={{ width: "100%" }}
                            />
                          </div>

                        </div>
                      </div>

                    <>
                      <div
                        id="form"
                        style={{
                          display: "flex",
                          flex: 1,
                          flexDirection: "column",
                          gap: 15,
                          margin: "15px",
                          padding: "15px",
                          alignContent: "center",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <>
                          <V3TextCustom
                            sx={{ width: "320px" }}
                            size="small"
                            onChange={handleNomeRazao}
                            label="Nome / Razão Social"
                            error={nomeRazaoerr}
                            helperText={
                              nomeRazaoerr && `Preencha o nome/razão social`
                            }
                            required
                            value={nomeRazao}
                          />

                          <V3TextCustom
                            sx={{ width: 320 }}
                            size="small"
                            onChange={(e: any) => handleCpfCnpj(e.target.value)}
                            label={"CPF / CNPJ"}
                            error={cpfCnpjerr}
                            helperText={
                              cpfCnpjerr && `Preencha corretamente CPF/CNPJ`
                            }
                            required
                            value={cpfCnpj}
                          />

                          <V3TextCustom
                            sx={{ width: 320 }}
                            size="small"
                            onChange={(e: any) =>
                              handleTelefone(e.target.value)
                            }
                            label="Telefone"
                            error={telefoneerr}
                            helperText={telefoneerr && `Preencha o telefone`}
                            required
                            value={telefone}
                          />

                          <div
                            style={{
                              display: "flex",
                              flex: 1,
                              alignItems: "flex-start",
                            }}
                          >
                            <FormControlLabel
                              value="sim"
                              style={{ display: "flex", alignSelf: "left" }}
                              control={
                                <Checkbox
                                  checked={contatoWhatsapp}
                                  style={{
                                    display: "flex",
                                    flex: 1,
                                    justifyContent: "flex-start",
                                  }}
                                  onChange={() =>
                                    setContatoWhatsapp(!contatoWhatsapp)
                                  }
                                  value={0}
                                  name="radio-buttons"
                                  inputProps={{ "aria-label": "A" }}
                                />
                              }
                              label="Este contato é WhatsApp, aceito receber contato."
                            />
                          </div>

                          <V3TextCustom
                            sx={{ width: 320 }}
                            size="small"
                            onChange={(e: any) => handleEmail(e.target.value)}
                            label="E-mail"
                            error={emailerr}
                            helperText={emailerr && `Preencha o e-mail`}
                            required
                            value={email}
                          />

                          {landing === "porto" || landing === "volkswagen" ? (
                            <V3Select
                              width="320px"
                              name="tipoConsorcio"
                              size="small"
                              action={handleTipoConsorcio}
                              options={tiposConsorcio}
                              value={tipoConsorcio}
                              label="Tipo de consórcio"
                              error={false}
                              required
                            />
                          ) : (
                            <></>
                          )}

                          {(landing === "volkswagen" ||
                            landing === "randon") && (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  gap: 10,
                                  width: "320px",
                                }}
                              >
                                <V3TextCustom
                                  sx={{ width: "100%" }}
                                  size="small"
                                  onChange={(e: any) =>
                                    setContrato(e.target.value)
                                  }
                                  label="Contrato"
                                  value={contrato}
                                />

                              </div>
                            </>
                          )}
                          <>
                            <div
                              className={StyleM.fontInter12}
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                maxWidth: "100%",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <span>
                                Ao enviar este formulário você concorda com as
                                nossas
                              </span>
                              <br />
                              <a
                                target="_blank"
                                href={require("../../../../assets/termo/Politica_Privacidade.pdf")}
                                rel="noreferrer"
                              >
                                políticas de privacidade
                              </a>
                            </div>

                            <V3Button
                              onClick={handleSendFormulario}
                              estilo={landing}
                              width={300}
                              text="SOLICITAR PROPOSTA"
                            />
                          </>
                        </>
                      </div>
                    </>

                    <VantagensMobile
                      title={"Vantagens de vender a sua cota com a Concash"}
                      estilo={estilo}
                      adm={landing}
                      title1={"Proposta em até 24h"}
                      text1={
                        "Envie os seus dados e receba uma proposta em até 24 horas."
                      }
                      alt1={"ícone de proposta rápida"}
                      title2={"Maior facilidade"}
                      text2={
                        "Não espere o grupo acabar para receber o dinheiro de volta."
                      }
                      alt2={"ícone de maior facilidade"}
                      title3={"Acompanhamento"}
                      text3={
                        "Nós conduzimos o processo de venda desde a aprovação ao pagamento final da cota."
                      }
                      alt3={"ícone de acompanhamento"}
                    />

                    <img loading="lazy" src={HorizontalLineMobile} />

                    <S.MobileCFBoxTitleCol>
                      <S.MobileCFTitle
                        style={{ color: estilo?.backgroundColor }}
                      >
                        Sobre a Concash
                      </S.MobileCFTitle>
                      <S.MobileSubTitle>
                        A Concash promove alternativas seguras aos consorciados
                        com cotas ativas, inadimplentes ou canceladas. Se por
                        algum motivo você precisou desistir do seu consórcio,
                        aqui você tem a oportunidade de recuperar o seu dinheiro
                        em um processo rápido, digital e transparente,
                        totalmente validado pela sua Administradora. A
                        satisfação dos clientes é o nosso foco, por isso temos
                        sempre as melhores ofertas e o menor prazo do mercado,
                        promovendo uma experiência única aos consorciados.
                      </S.MobileSubTitle>
                    </S.MobileCFBoxTitleCol>
                  </S.MobilePageContent>
                )}
              </>

              <FooterMobile landing={landing} estilo={estilo} />
            </>
          )}

          {/* Desktop */}
          {!mobile && (
            <>
              {loading && (
                <Backdrop open={loading} style={{ zIndex: 999 }}>
                  <Lottie animationData={iconLoading} width={"40%"} />
                </Backdrop>
              )}
              <>
                {!subAdm && (
                  <Backdrop style={{ zIndex: 9999 }} open={!subAdm}>
                    <div className={StyleM.containerSubAdm}>
                      <span style={{ fontSize: 20, fontWeight: "bold" }}>
                        Selecione a sua administradora:
                      </span>
                      <div className={StyleM.lineSubs}>
                      <div
                          id="randon"
                          className={StyleM.boxSubAdm}
                          onClick={() => setSubAdm("51")}
                        >
                          <img
                            src={Randon}
                            alt="randon"
                            style={{ width: "100%" }}
                          />
                        </div>

                        <div
                          id="john"
                          className={StyleM.boxSubAdm}
                          onClick={() => setSubAdm("161")}
                        >
                          <img
                            src={JohnDeere}
                            alt="john"
                            style={{ width: "100%" }}
                          />
                        </div>

                        <div
                          id="racon"
                          className={StyleM.boxSubAdm}
                          onClick={() => setSubAdm("162")}
                        >
                          <img
                            src={Racon}
                            alt="racon"
                            style={{ width: "100%" }}
                          />
                        </div>

                        <div
                          id="volare"
                          className={StyleM.boxSubAdm}
                          onClick={() => setSubAdm("163")}
                        >
                          <img
                            src={Volare}
                            alt="volare"
                            style={{ width: "100%" }}
                          />
                        </div>

                        <div
                          id="daf"
                          className={StyleM.boxSubAdm}
                          onClick={() => setSubAdm("164")}
                        >
                          <img src={DAF} alt="DAF" style={{ width: "100%" }} />
                        </div>

                        <div
                          id="Yeah"
                          className={StyleM.boxSubAdm}
                          onClick={() => setSubAdm("165")}
                        >
                          <img
                            src={Yeah}
                            alt="Yeah"
                            style={{ width: "100%" }}
                          />
                        </div>



                      </div>
                    </div>
                  </Backdrop>
                )}
                {cookies && (
                  <Backdrop style={{ zIndex: 999 }} open={cookies}>
                    <S.CookieBoxDesktop>
                      <S.TextFooterDesktop>
                        Nós armazenamos dados temporariamente para melhorar a
                        sua experiência de navegação e recomendar conteúdo do
                        seu interesse. Ao continuar navegando, você concorda com
                        a nossa política de privacidade.
                      </S.TextFooterDesktop>

                      <S.ButtonCookie
                        style={{
                          backgroundColor:
                            landing == "canopus"
                              ? estilo?.buttonColor
                              : estilo?.backgroundColor,
                        }}
                        onClick={() => setCookies(false)}
                      >
                        CONTINUAR
                      </S.ButtonCookie>
                    </S.CookieBoxDesktop>
                  </Backdrop>
                )}
                <S.DesktopPageContainer>
                  <S.DesktopHeader
                    style={{ backgroundColor: estilo?.backgroundColor }}
                  >
                    <S.DesktopBoxLogo>
                      {landing === "porto" ? (
                        <>
                          <img
                            loading="lazy"
                            src={ConcashMobile}
                            height={"40px"}
                            alt="concash"
                          />
                          <img
                            loading="lazy"
                            src={PortoWhite}
                            width={160}
                            alt="porto-white"
                          />
                        </>
                      ) : (
                        <>
                          <img
                            loading="lazy"
                            src={ConcashMobile}
                            height={"40px"}
                            alt="concash"
                          />
                        </>
                      )}
                    </S.DesktopBoxLogo>
                    {!formSubmetido ? (
                      <>
                        <S.DesktopBoxTitle>
                          <S.DesktopHeaderTitle>
                          Desistiu do seu consórcio? Venda aqui com a melhor oferta!
                          </S.DesktopHeaderTitle>
                        </S.DesktopBoxTitle>

                        <S.DesktopBoxButton>
                          <S.DesktopHeaderButton
                            onClick={() => (window.location.href = "#form")}
                            style={{
                              backgroundColor:
                                landing === "porto"
                                  ? "#FFF"
                                  : estilo?.buttonColor,
                              color: estilo?.buttonTextColor,
                            }}
                          >
                            QUERO VENDER
                          </S.DesktopHeaderButton>
                        </S.DesktopBoxButton>
                      </>
                    ) : (
                      <>
                        <S.DesktopBoxTitle>
                          <S.DesktopHeaderTitle>
                            Solicitação enviada com sucesso!
                          </S.DesktopHeaderTitle>
                        </S.DesktopBoxTitle>

                        <S.DesktopBoxTitle>
                          <S.DesktopHeaderSubTitle>
                            Aguarde nosso contato. Enviaremos a melhor proposta
                            por e-mail, WhatsApp ou ligação, pelo número (11)
                            4040 5686. Obrigado por escolher a Concash.
                          </S.DesktopHeaderSubTitle>
                        </S.DesktopBoxTitle>
                      </>
                    )}

                    <S.DesktopPhotoBox style={coverDesktopStyle}>
                      <img
                        src={
                          formSubmetido
                            ? estilo?.backgroundCoverSuccess
                            : estilo?.backgroundCover
                        }
                        alt={
                          formSubmetido
                            ? estilo?.altBackgroundSuccess
                            : estilo?.altBackground
                        }
                        style={{
                          display: "flex",
                          borderRadius: "190px 190px 0px 0px",
                          backgroundSize: "cover",
                          position: "absolute",
                          width: "400px",
                          height: "344px",
                          objectFit: "cover",
                        }}
                      />
                    </S.DesktopPhotoBox>

                    <img
                      loading="lazy"
                      src={Tip1}
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 130,
                        width: 116,
                        zIndex: 0,
                      }}
                      alt="tip1"
                    />

                    <img
                      loading="lazy"
                      src={Tip2}
                      style={{
                        position: "absolute",
                        top: 263,
                        right: 623,
                        width: 116,
                        zIndex: 0,
                      }}
                      alt="tip2"
                    />
                  </S.DesktopHeader>
                </S.DesktopPageContainer>
                {!formSubmetido && (
                  <>
                  <div className={StyleM.centeredAdmSelect}>
                      <span className={StyleM.titleAdmSelect}>Administradora Selecionada:</span>
                  </div>
                    <S.DesktopPageContent>
                      <div style={{ display: "flex", marginTop: 30 }}>

                        <div className={StyleM.lineSubs}>
                        <div
                            id="randon"
                            className={
                              subAdm === "51"
                                ? StyleM.boxSubAdmSelected
                                : StyleM.boxSubAdmNotSelected
                            }
                            onClick={() => setSubAdm("51")}
                          >
                            <img
                              src={Randon}
                              alt="randon"
                              style={{ width: "100%" }}
                            />
                          </div>

                          <div
                            id="john"
                            className={
                              subAdm === "161"
                                ? StyleM.boxSubAdmSelected
                                : StyleM.boxSubAdmNotSelected
                            }
                            onClick={() => setSubAdm("161")}
                          >
                            <img
                              src={JohnDeere}
                              alt="john"
                              style={{ width: "100%" }}
                            />
                          </div>

                          <div
                            id="racon"
                            className={
                              subAdm === "162"
                                ? StyleM.boxSubAdmSelected
                                : StyleM.boxSubAdmNotSelected
                            }
                            onClick={() => setSubAdm("162")}
                          >
                            <img
                              src={Racon}
                              alt="racon"
                              style={{ width: "100%" }}
                            />
                          </div>

                          <div
                            id="volare"
                            className={
                              subAdm === "163"
                                ? StyleM.boxSubAdmSelected
                                : StyleM.boxSubAdmNotSelected
                            }
                            onClick={() => setSubAdm("163")}
                          >
                            <img
                              src={Volare}
                              alt="volare"
                              style={{ width: "100%" }}
                            />
                          </div>

                          <div
                            id="daf"
                            className={
                              subAdm === "164"
                                ? StyleM.boxSubAdmSelected
                                : StyleM.boxSubAdmNotSelected
                            }
                            onClick={() => setSubAdm("164")}
                          >
                            <img
                              src={DAF}
                              alt="DAF"
                              style={{ width: "100%" }}
                            />
                          </div>
                          <div
                            className={
                              subAdm === "165"
                                ? StyleM.boxSubAdmSelected
                                : StyleM.boxSubAdmNotSelected
                            }
                            onClick={() => setSubAdm("165")}
                          >
                            <img
                              src={Yeah}
                              alt="Yeah"
                              style={{ width: "100%" }}
                            />
                          </div>

                         

                        </div>
                      </div>
                    </S.DesktopPageContent>
                    <S.DesktopPageContainer style={{ marginTop: "50px" }}>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "center",
                        }}
                      >
                        <S.ContainerForm
                          style={{
                            minHeight: estilo?.minHeightForm,
                            backgroundColor: "#F5F5F5",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ display: "flex", flex: 1 }}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "40%",
                              }}
                            >
                              <S.DesktopCFBoxTitleCol
                                style={{ marginTop: "20%" }}
                              >
                                <S.DesktopCFTitle
                                  style={{ color: estilo?.backgroundColor }}
                                >
                                  Preencha e receba uma
                                </S.DesktopCFTitle>
                                <S.DesktopCFTitle
                                  style={{ color: estilo?.backgroundColor }}
                                >
                                  proposta para a sua cota
                                </S.DesktopCFTitle>
                              </S.DesktopCFBoxTitleCol>
                              <div
                                style={{
                                  justifyContent: "left",
                                  alignSelf: "flex-end",
                                  marginTop: "auto",
                                  width: "100%",
                                  textAlign: "left",
                                }}
                              >
                                <img
                                  loading="lazy"
                                  src={estilo?.backgroundForm}
                                  alt="familia reunida no sofá"
                                />
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                width: "60%",
                              }}
                            >
                              <div
                                id="form"
                                style={{
                                  display: "flex",
                                  flex: 1,
                                  flexDirection: "column",
                                  gap: 15,
                                  alignContent: "center",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <V3TextCustom
                                  sx={{ width: 500 }}
                                  size="small"
                                  onChange={handleNomeRazao}
                                  label="Nome / Razão Social"
                                  error={nomeRazaoerr}
                                  helperText={
                                    nomeRazaoerr && `Preencha nome/razão social`
                                  }
                                  required
                                  value={nomeRazao}
                                />

                                <V3TextCustom
                                  sx={{ width: 500 }}
                                  size="small"
                                  onChange={(e: any) =>
                                    handleCpfCnpj(e.target.value)
                                  }
                                  label="CPF / CNPJ"
                                  error={cpfCnpjerr}
                                  helperText={
                                    cpfCnpjerr &&
                                    `Preencha corretamente o CPF/CNPJ`
                                  }
                                  required
                                  value={cpfCnpj}
                                />

                                <V3TextCustom
                                  sx={{ minWidth: 500 }}
                                  size="small"
                                  onChange={(e: any) =>
                                    handleTelefone(e.target.value)
                                  }
                                  label="Telefone"
                                  error={telefoneerr}
                                  helperText={
                                    telefoneerr && `Preencha o telefone`
                                  }
                                  required
                                  value={telefone}
                                />

                                <FormControlLabel
                                  value="sim"
                                  style={{
                                    display: "flex",
                                    alignSelf: "left",
                                    minWidth: 220,
                                  }}
                                  control={
                                    <Checkbox
                                      checked={contatoWhatsapp}
                                      style={{
                                        display: "flex",
                                        flex: 1,
                                        justifyContent: "flex-start",
                                      }}
                                      onChange={() =>
                                        setContatoWhatsapp(!contatoWhatsapp)
                                      }
                                      value={0}
                                      name="radio-buttons"
                                      inputProps={{ "aria-label": "A" }}
                                    />
                                  }
                                  label="Aceito receber contato por WhatsApp."
                                />

                                <V3TextCustom
                                  sx={{ width: 500 }}
                                  size="small"
                                  onChange={(e: any) =>
                                    handleEmail(e.target.value)
                                  }
                                  label="E-mail"
                                  error={emailerr}
                                  helperText={emailerr && "Preencha o email"}
                                  required
                                  value={email}
                                />



                                {landing === "volkswagen" ||
                                landing === "randon" ? (
                                  <>
                                    <div
                                      style={{
                                        display: "flex",
                                        gap: 10,
                                        width: "500px",
                                      }}
                                    >
                                      <V3TextCustom
                                        sx={{ width: "100%" }}
                                        size="small"
                                        onChange={(e: any) =>
                                          setContrato(e.target.value)
                                        }
                                        label="Contrato"
                                        value={contrato}
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}

                                <div
                                  className={StyleM.fontInter12}
                                  style={{ marginTop: 15, marginBottom: 15 }}
                                >
                                  Ao enviar este formulário você concorda com as
                                  nossas{" "}
                                  <a
                                    target="_blank"
                                    href={require("../../../../assets/termo/Politica_Privacidade.pdf")}
                                    rel="noreferrer"
                                  >
                                    políticas de privacidade
                                  </a>
                                </div>
                                <V3Button
                                  onClick={handleSendFormulario}
                                  estilo={landing}
                                  width={500}
                                  text="SOLICITAR PROPOSTA"
                                />
                              </div>
                            </div>
                          </div>
                        </S.ContainerForm>
                      </div>
                    </S.DesktopPageContainer>

                    <VantagensDesktop
                      title={"Vantagens de vender a sua cota com a Concash"}
                      estilo={estilo}
                      adm={landing}
                      title1={"Proposta em até 24h"}
                      text1={
                        "Envie os seus dados e receba uma proposta em até 24 horas."
                      }
                      alt1={"ícone de proposta rápida"}
                      title2={"Maior facilidade"}
                      text2={
                        "Não espere o grupo acabar para receber o dinheiro de volta."
                      }
                      alt2={"ícone de maior facilidade"}
                      title3={"Acompanhamento"}
                      text3={
                        "Nós conduzimos o processo de venda desde a aprovação ao pagamento final da cota."
                      }
                      alt3={"ícone de acompanhamento"}
                    />

                    <S.DesktopPageContainer style={{ marginTop: "25px" }}>
                      <img loading="lazy" src={HorizontalLine} />
                    </S.DesktopPageContainer>

                    <S.DesktopPageContent>
                      <S.DesktopCFBoxTitleCol>
                        <S.DesktopCFTitle
                          style={{ color: estilo?.backgroundColor }}
                        >
                          Sobre a Concash
                        </S.DesktopCFTitle>
                        <S.DesktopSubTitle
                          style={{ width: "60%", textAlign: "center" }}
                        >
                          A Concash promove alternativas seguras aos
                          consorciados com cotas ativas, inadimplentes ou
                          canceladas. Se por algum motivo você precisou desistir
                          do seu consórcio, aqui você tem a oportunidade de
                          recuperar o seu dinheiro em um processo rápido,
                          digital e transparente, totalmente validado pela sua
                          Administradora. A satisfação dos clientes é o nosso
                          foco, por isso temos sempre as melhores ofertas e o
                          menor prazo do mercado, promovendo uma experiência
                          única aos consorciados.
                        </S.DesktopSubTitle>
                      </S.DesktopCFBoxTitleCol>
                    </S.DesktopPageContent>
                  </>
                )}
              </>
              <FooterDesktop landing={landing} estilo={estilo} />
            </>
          )}
        </>
      ) : (
        <></>
      )}
      ;
    </>
  );
};

export default LandingRandon;