import { useState, useEffect } from "react";
import {
  BoxPinStep,
  BoxStepDesktop,
  BoxStepMobile,
  CenteredBox,
  CenteredBoxMobile,
} from "../../../styles/themes/components/box";
import {
  V3Button,
  V3RadioButton,
} from "../../../styles/themes/components/button";
import { PinStepActive } from "../../../styles/themes/components/pin";
import { V3Text5, V3TextH1 } from "../../../styles/themes/components/texts";
import { v3Colors } from "../../../styles/themes/v3/colors";
import { V3TextCustom } from "../../../styles/themes/components/input";
import { ImInfo } from "react-icons/im";
import { V3Modal } from "../../../styles/themes/components/modal";
import {
  v3CellphoneFormater,
  v3FormataCNPJ,
  v3MaskCPF,
} from "../../../styles/themes/utils/format";
import {
  v3ValidaCpfCnpj,
  v3ValidateEmail,
} from "../../../styles/themes/utils/validate";
import { Checkbox, FormControlLabel, Radio } from "@mui/material";
import {
  validNome,
  validRazaoSocial,
  validateEmail,
} from "../../../utils/validate";
import FloatButton from "../../../components/FloatButton";
import { BsWhatsapp } from "react-icons/bs";
import api from "../../../services/api";
export const Step2Desktop = (props) => {
  const { setCurrentStep } = props;
  const [onboarding, setOnboarding] = useState("");
  const [tipoPessoa, setTipoPessoa] = useState("");
  const [possui, setPossui] = useState(null);
  const [idEtapa, setIdEtapa] = useState("");
  const [hasInfo, setHasInfo] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [hasSuccess, setHasSuccess] = useState(false);

  const [errorText, setErrorText] = useState("");
  const [successText, setSuccessText] = useState("");

  const [cpf, setCpf] = useState("");
  const [nomeCompleto, setNomeCompleto] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [isWhatsApp, setIsWhatsApp] = useState("");

  const [grupo, setGrupo] = useState("");
  const [cota, setCota] = useState("");
  const [versao, setVersao] = useState("");
  const [contrato, setContrato] = useState("");

  const [cnpj, setCnpj] = useState("");
  const [razaoSocial, setRazaoSocial] = useState("");

  const [nomeCompletoErr, setNomeCompletoErr] = useState(false);
  const [razaoSocialErr, setRazaoSocialErr] = useState(false);

  const [cpfErr, setCpfErr] = useState(false);
  const [cnpjErr, setCnpjErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [telefoneErr, setTelefoneErr] = useState(false);
  const [razaoErr, setRazaoErr] = useState("");
  const [cotaErr, setCotaErr] = useState(false);
  const [grupoErr, setGrupoErr] = useState(false);
  const [versaoErr, setVersaoErr] = useState(false);

  function fillCPF(cpf) {
    const onlyDigits = cpf?.replace(/[^\d]/g, "");
    return onlyDigits?.padStart(11, "0");
  }

  function fillCNPJ(cnpj) {
    const onlyDigits = cnpj?.replace(/[^\d]/g, "");
    return onlyDigits?.padStart(14, "0");
  }

  async function handleNext() {
    if (!tipoPessoa) {
      setHasError(true);
      return setErrorText("Selecione o Tipo Pessoa");
    }

    const etp = localStorage.getItem("idEtapaAtual");
    const etapa = idEtapa ? idEtapa : etp;

    const docPF = parseInt(cpf?.replace(/[^0-9]/g, ""));
    const docPJ = parseInt(cnpj?.replace(/[^0-9]/g, ""));

    if (tipoPessoa === "PF") {
      if (!nomeCompleto) {
        setHasError(true);
        setNomeCompletoErr(true);
        return setErrorText("Preencha o Nome Completo");
      }

      if (!cpf || !v3ValidaCpfCnpj(cpf)) {
        setHasError(true);
        setCpfErr(true);
        return setErrorText("Preencha o CPF corretamente.");
      }
      if (!email || !validateEmail(email)) {
        setHasError(true);
        setEmailErr(true);
        return setErrorText("Preencha o e-mail corretamente.");
      }
      if (!telefone) {
        setHasError(true);
        setTelefoneErr(true);
        return setErrorText("Preencha o telefone corretamente.");
      }

      if (!possui || possui === null) {
        setHasError(true);
        return setErrorText(
          "Selecione se possui mais de uma cota desta administradora."
        );
      }

      if (!contrato && possui === "SIM") {
        if (!contrato && !grupo && !cota && !versao) {
          setHasError(true);

          return setErrorText(
            "Preencha grupo, cota e versão ou número do contrato."
          );
        }
        if (!contrato && (!grupo || grupo === null)) {
          setHasError(true);
          setGrupoErr(true);
          return setErrorText("Preencha o Grupo de sua cota de consórcio.");
        }

        if (!cota || cota === null) {
          setHasError(true);
          setCotaErr(true);
          return setErrorText("Preencha o número de sua cota de consórcio.");
        }

        if (!versao || versao === null) {
          setHasError(true);
          setVersaoErr(true);
          return setErrorText(
            "Preencha o número da versão da sua cota de consórcio."
          );
        }
      }

      if (!possui || possui === null) {
        setHasError(true);
        return setErrorText(
          "Selecione se possui mais de uma cota desta administradora."
        );
      }

      await api
        .patch(
          `/api/concash/1.0/cliente-cadastro/onboarding/avancar/${onboarding}/${etapa}`,
          [
            {
              nome: "tipoPessoa",
              valor: "PF",
            },
            {
              nome: "nomeRazao",
              valor: nomeCompleto,
            },
            {
              nome: "cpfCnpj",
              valor: `${docPF}`,
            },
            {
              nome: "email",
              valor: email,
            },
            {
              nome: "celular",
              valor: telefone,
            },
            {
              nome: "aceitaContatoWhatsapp",
              valor: isWhatsApp ? "SIM" : "NAO",
            },
            {
              nome: "possuiMaisDeUmaCota",
              valor: possui,
            },
            {
              nome: "grupo",
              valor: `${grupo}`,
            },
            {
              nome: "cota",
              valor: `${cota}`,
            },
            {
              nome: "versao",
              valor: `${versao}`,
            },
            {
              nome: "contrato",
              valor: contrato,
            },
          ]
        )
        .then(({ data }) => {
          localStorage.setItem("idEtapaAtual", data.idEtapaAtual);
          setCurrentStep(3);
        })
        .catch((data) => {
          setHasError(true);
          setErrorText(
            data.response.data
              ? data.response.data
              : "Não conseguimos encontrar o Grupo, Cota e Versão ou Contrato informado!"
          );
        });
    }

    if (tipoPessoa === "PJ") {
      if (!razaoSocial) {
        setHasError(true);
        setRazaoSocialErr(true);
        return setErrorText("Preencha a Razão Social corretamente.");
      }

      if (!cnpj || !v3ValidaCpfCnpj(cnpj)) {
        setHasError(true);
        setCnpjErr(true);
        return setErrorText("Preencha o CNPJ corretamente.");
      }
      if (!email || !validateEmail(email)) {
        setHasError(true);
        setEmailErr(true);
        return setErrorText("Preencha o e-mail corretamente.");
      }
      if (!telefone) {
        setHasError(true);
        setTelefoneErr(true);
        return setErrorText("Preencha o telefone corretamente.");
      }

      if (!possui || possui === null) {
        setHasError(true);
        return setErrorText(
          "Selecione se possui mais de uma cota desta administradora."
        );
      }
      if (!contrato && possui === "SIM") {
        if (!contrato && !grupo && !cota && !versao) {
          setHasError(true);

          return setErrorText(
            "Preencha grupo, cota e versão ou número do contrato."
          );
        }
        if (!contrato && (!grupo || grupo === null)) {
          setHasError(true);
          setGrupoErr(true);
          return setErrorText("Preencha o Grupo de sua cota de consórcio.");
        }

        if (!cota || cota === null) {
          setHasError(true);
          setCotaErr(true);
          return setErrorText("Preencha o número de sua cota de consórcio.");
        }

        if (!versao || versao === null) {
          setHasError(true);
          setVersaoErr(true);
          return setErrorText(
            "Preencha o número da versão da sua cota de consórcio."
          );
        }
      }

      await api
        .patch(
          `/api/concash/1.0/cliente-cadastro/onboarding/avancar/${onboarding}/${etapa}`,
          [
            {
              nome: "tipoPessoa",
              valor: "PJ",
            },
            {
              nome: "nomeRazao",
              valor: razaoSocial,
            },
            {
              nome: "cpfCnpj",
              valor: `${docPJ}`,
            },
            {
              nome: "email",
              valor: email,
            },
            {
              nome: "celular",
              valor: telefone,
            },
            {
              nome: "aceitaContatoWhatsapp",
              valor: isWhatsApp ? "SIM" : "NAO",
            },
            {
              nome: "possuiMaisDeUmaCota",
              valor: possui,
            },
            {
              nome: "grupo",
              valor: `${grupo}`,
            },
            {
              nome: "cota",
              valor: `${cota}`,
            },
            {
              nome: "versao",
              valor: `${versao}`,
            },
            {
              nome: "contrato",
              valor: contrato,
            },
          ]
        )
        .then(({ data }) => {
          localStorage.setItem("idEtapaAtual", data.idEtapaAtual);
          setCurrentStep(3);
        })
        .catch((data) => {
          setHasError(true);
          setErrorText(
            data.response.data
              ? data.response.data
              : "Não conseguimos encontrar o Grupo, Cota e Versão ou Contrato informado!"
          );
        });
    }
  }

  function handlePrev() {
    setCurrentStep(1);
  }

  function handleRazaoSocial(t) {
    const valid = validRazaoSocial(t);
    if ((razaoSocial && valid) || razaoSocial === "") {
      setRazaoSocial(t);
      setRazaoErr(false);
    } else {
      setRazaoSocial(t);
      setRazaoErr(true);
    }
  }

  function handleCpf(text) {
    const validating = text.replace(/[^\d]/g, "");
    if (validating.length <= 11) {
      const cpfs = v3MaskCPF(validating);
      setCpf(cpfs);

      if (v3ValidaCpfCnpj(cpfs) || text === "") {
        setCpfErr(false);
      } else {
        setCpfErr(true);
      }
    }
  }

  function handleCnpj(text) {
    const validating = text.replace(/[^\d]/g, "");
    if (validating.length <= 14) {
      const cn = v3FormataCNPJ(validating);
      setCnpj(cn);

      if (v3ValidaCpfCnpj(cn) || text === "") {
        setCnpjErr(false);
      } else {
        setCnpjErr(true);
      }
    }
  }

  function handleEmail(t) {
    const text = t?.replaceAll(" ", "");
    if (v3ValidateEmail(text) || text === "") {
      setEmail(text);
      setEmailErr(false);
    } else {
      setEmail(text);
      setEmailErr(true);
    }
  }

  function handleNomeCompleto(text) {
    const valid = validNome(text);

    if (!text || !valid) {
      setNomeCompleto(text);
      setNomeCompletoErr(true);
    } else {
      setNomeCompleto(text);
      setNomeCompletoErr(false);
    }
  }

  function handleTelefone(t) {
    const validatePhone = t.replace(/[^0-9]/g, "").split("");
    if (t) {
      setTelefoneErr(false);
      if (t.length > 15) {
        setTelefone(telefone);
      } else {
        setTelefone(v3CellphoneFormater(t));
      }
    }
    if (!t || t == "" || t == "(") {
      setTelefoneErr(true);
      setTelefone(t);
    }

    if (t.length < 14 || validatePhone[0] === "0") {
      setTelefoneErr(true);
    } else {
      setTelefoneErr(false);
    }
  }

  async function recuperarEtapas() {
    const onboardingObj = JSON.parse(localStorage.getItem("onboarding"));

    if (!onboardingObj) {
      const on = localStorage.getItem("onboarding");
      const etp = await api.get(
        `/api/concash/1.0/cliente-cadastro/onboarding/recuperar/${on}`
      );
      localStorage.setItem("etapaAtual", `2`);
      localStorage.setItem("onboardingObj", `${JSON.stringify(etp.data)}`);
      localStorage.setItem("etapas", `${JSON.stringify(etp.data.etapas)}`);

      const tipoR = await etp.data.etapas[1].campos.find(
        (i) => i.nome === "tipoPessoa"
      ).valor;
      setTipoPessoa(tipoR);
      const nomeRazaoR = await etp.data.etapas[1].campos.find(
        (i) => i.nome === "nomeRazao"
      ).valor;
      const cpfCnpjR = await etp.data.etapas[1].campos.find(
        (i) => i.nome === "cpfCnpj"
      ).valor;
      const emailR = await etp.data.etapas[1].campos.find(
        (i) => i.nome === "email"
      ).valor;
      const celularR = await etp.data.etapas[1].campos.find(
        (i) => i.nome === "celular"
      ).valor;
      const whatsappR = await etp.data.etapas[1].campos.find(
        (i) => i.nome === "aceitaContatoWhatsapp"
      ).valor;
      const cotaR = await etp.data.etapas[1].campos.find(
        (i) => i.nome === "cota"
      ).valor;
      const grupoR = await etp.data.etapas[1].campos.find(
        (i) => i.nome === "grupo"
      ).valor;
      const versaoR = await etp.data.etapas[1].campos.find(
        (i) => i.nome === "versao"
      ).valor;
      const contratoR = await etp.data.etapas[1].campos.find(
        (i) => i.nome === "contrato"
      ).valor;
      const possuiR = await etp.data.etapas[1].campos.find(
        (i) => i.nome === "possuiMaisDeUmaCota"
      ).valor;
      if (tipoR === "PF") {
        setNomeCompleto(nomeRazaoR);
        setCpf(cpfCnpjR && v3MaskCPF(fillCPF(await cpfCnpjR)));
        setEmail(emailR);
        setTelefone(celularR);
        setIsWhatsApp(whatsappR === "SIM" ? true : false);
      } else {
        setRazaoSocial(nomeRazaoR);
        setCnpj(cpfCnpjR && v3FormataCNPJ(fillCNPJ(await cpfCnpjR)));
        setEmail(emailR);
        setTelefone(celularR);
        setIsWhatsApp(whatsappR === "SIM" ? true : false);
      }
      setCota(cotaR?.replace(/[^0-9]/g, ""));
      setGrupo(grupoR?.replace(/[^0-9]/g, ""));
      setVersao(versaoR?.replace(/[^0-9]/g, ""));
      setContrato(contratoR?.replace(/[^0-9]/g, ""));
      setPossui(possuiR);
    }

    const tipoR = await onboardingObj.etapas[1].campos.find(
      (i) => i.nome === "tipoPessoa"
    ).valor;
    setTipoPessoa(tipoR);
    const nomeRazaoR = await onboardingObj.etapas[1].campos.find(
      (i) => i.nome === "nomeRazao"
    ).valor;
    const cpfCnpjR = await onboardingObj.etapas[1].campos.find(
      (i) => i.nome === "cpfCnpj"
    ).valor;
    const emailR = await onboardingObj.etapas[1].campos.find(
      (i) => i.nome === "email"
    ).valor;
    const celularR = await onboardingObj.etapas[1].campos.find(
      (i) => i.nome === "celular"
    ).valor;
    const whatsappR = await onboardingObj.etapas[1].campos.find(
      (i) => i.nome === "aceitaContatoWhatsapp"
    ).valor;
    const cotaR = await onboardingObj.etapas[1].campos.find(
      (i) => i.nome === "cota"
    ).valor;
    const grupoR = await onboardingObj.etapas[1].campos.find(
      (i) => i.nome === "grupo"
    ).valor;
    const versaoR = await onboardingObj.etapas[1].campos.find(
      (i) => i.nome === "versao"
    ).valor;
    const contratoR = await onboardingObj.etapas[1].campos.find(
      (i) => i.nome === "contrato"
    ).valor;
    const possuiR = await onboardingObj.etapas[1].campos.find(
      (i) => i.nome === "possuiMaisDeUmaCota"
    ).valor;

    if (tipoR === "PF") {
      setNomeCompleto(nomeRazaoR);
      setCpf(cpfCnpjR && v3MaskCPF(fillCPF(await cpfCnpjR)));
      setEmail(emailR);
      setTelefone(celularR);
      setIsWhatsApp(whatsappR === "SIM" ? true : false);
    } else {
      setRazaoSocial(nomeRazaoR);
      setCnpj(cpfCnpjR && v3FormataCNPJ(fillCNPJ(await cpfCnpjR)));
      setEmail(emailR);
      setTelefone(celularR);
      setIsWhatsApp(whatsappR === "SIM" ? true : false);
    }

    setCota(cotaR?.replace(/[^0-9]/g, ""));
    setGrupo(grupoR?.replace(/[^0-9]/g, ""));
    setVersao(versaoR?.replace(/[^0-9]/g, ""));
    setContrato(contratoR?.replace(/[^0-9]/g, ""));
    setPossui(possuiR);
  }

  async function persistData() {
    const on = localStorage.getItem("onboarding");
    const etp = await api.get(
      `/api/concash/1.0/cliente-cadastro/onboarding/recuperar/${on}`
    );

    const tipoR = await etp.data.etapas[1].campos.find(
      (i) => i.nome === "tipoPessoa"
    ).valor;
    setTipoPessoa(tipoR);
    const nomeRazaoR = await etp.data.etapas[1].campos.find(
      (i) => i.nome === "nomeRazao"
    ).valor;
    const cpfCnpjR = await etp.data.etapas[1].campos.find(
      (i) => i.nome === "cpfCnpj"
    ).valor;
    const emailR = await etp.data.etapas[1].campos.find(
      (i) => i.nome === "email"
    ).valor;
    const celularR = await etp.data.etapas[1].campos.find(
      (i) => i.nome === "celular"
    ).valor;
    const whatsappR = await etp.data.etapas[1].campos.find(
      (i) => i.nome === "aceitaContatoWhatsapp"
    ).valor;
    const cotaR = await etp.data.etapas[1].campos.find((i) => i.nome === "cota")
      .valor;
    const grupoR = await etp.data.etapas[1].campos.find(
      (i) => i.nome === "grupo"
    ).valor;
    const versaoR = await etp.data.etapas[1].campos.find(
      (i) => i.nome === "versao"
    ).valor;
    const contratoR = await etp.data.etapas[1].campos.find(
      (i) => i.nome === "contrato"
    ).valor;
    const possuiR = await etp.data.etapas[1].campos.find(
      (i) => i.nome === "possuiMaisDeUmaCota"
    ).valor;
    const dt = cpfCnpjR && v3MaskCPF(fillCPF(cpfCnpjR && (await cpfCnpjR)));
    if (tipoR === "PF") {
      setNomeCompleto(nomeRazaoR);
      setCpf(cpfCnpjR && v3MaskCPF(fillCPF(await cpfCnpjR)));
      setEmail(emailR);
      setTelefone(celularR);
      setIsWhatsApp(whatsappR === "SIM" ? true : false);
    } else {
      setRazaoSocial(nomeRazaoR);
      setCnpj(cpfCnpjR && v3FormataCNPJ(fillCNPJ(await cpfCnpjR)));
      setEmail(emailR);
      setTelefone(celularR);
      setIsWhatsApp(whatsappR === "SIM" ? true : false);
    }
    setCota(cotaR?.replace(/[^0-9]/g, ""));
    setGrupo(grupoR?.replace(/[^0-9]/g, ""));
    setVersao(versaoR?.replace(/[^0-9]/g, ""));
    setContrato(contratoR?.replace(/[^0-9]/g, ""));
    setPossui(possuiR);
  }

    function handleResetOnboarding(state) {
      setHasError(state);
      localStorage.clear();
      window.location.reload();
    }

  useEffect(() => {
    persistData();
  }, [setCurrentStep]);

  useEffect(() => {
    localStorage.setItem("etapaAtual", 2);
    const onboardingObj = JSON.parse(localStorage.getItem("onboardingObj"));
    setIdEtapa(onboardingObj.etapas[1].id);
    setOnboarding(onboardingObj.id);

    if (!onboardingObj) {
      recuperarEtapas();
    }
  }, []);

  return (
    <>
      <BoxStepDesktop style={{ minHeight: 450 }}>
        <BoxPinStep>
          <PinStepActive num={1} type="checked" />
          <PinStepActive num={2} type="active" />
          <PinStepActive num={3} type="deactive" />
          <PinStepActive num={4} type="deactive" />
        </BoxPinStep>
        <CenteredBox>
          <V3TextH1>Quem é o titular?</V3TextH1>
        </CenteredBox>

        <CenteredBox style={{ gap: 10, marginTop: 15 }}>
          <V3RadioButton
            text="Pessoa Física"
            selected={tipoPessoa === "PF"}
            onClick={() => {
              setTipoPessoa("PF");
            }}
          />
          <V3RadioButton
            text="Pessoa Jurídica"
            selected={tipoPessoa === "PJ"}
            onClick={() => {
              setTipoPessoa("PJ");
            }}
          />
        </CenteredBox>
        {tipoPessoa === "PF" && (
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              gap: 10,
              paddingLeft: 100,
              marginTop: 20,
            }}
          >
            <V3TextCustom
              sx={{ width: 447 }}
              size="small"
              onChange={(e) => {
                handleNomeCompleto(e.target.value);
              }}
              label="Nome Completo"
              error={nomeCompletoErr}
              helperText={nomeCompletoErr && "Preencha o seu nome completo."}
              required
              value={nomeCompleto}
            />
            <V3TextCustom
              id="cpf"
              sx={{ width: 447 }}
              label="CPF"
              onChange={(e) => {
                handleCpf(e.target.value);
              }}
              required
              size="small"
              error={cpfErr}
              helperText={cpfErr && "CPF inválido."}
              value={cpf}
            />

            <V3TextCustom
              sx={{ width: 447 }}
              label="E-mail"
              onChange={(e) => {
                handleEmail(e.target.value);
              }}
              error={emailErr}
              size="small"
              helperText={emailErr && "E-mail inválido."}
              value={email}
              required
            />
            <V3TextCustom
              size="small"
              sx={{ width: 447 }}
              onChange={(e) => {
                handleTelefone(e.target.value);
              }}
              error={telefoneErr}
              value={telefone}
              label="Telefone"
              helperText={
                telefoneErr &&
                "Telefone deve ser no formato(11) 1234-5678 ou (11) 98765-4321."
              }
              required
            />
            <FormControlLabel
              value="sim"
              control={
                <Checkbox
                  checked={isWhatsApp}
                  onChange={() => setIsWhatsApp(!isWhatsApp)}
                  value={0}
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                  sx={{
                    color: v3Colors.primary,
                    "&.Mui-checked": {
                      color: v3Colors.primary,
                    },
                  }}
                />
              }
              label="Este telefone é WhatsApp, aceito receber contato."
            />
          </div>
        )}
        {tipoPessoa === "PJ" && (
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              gap: 10,
              paddingLeft: 100,
              marginTop: 20,
            }}
          >
            <V3TextCustom
              size="small"
              sx={{ width: 447 }}
              onChange={(e) => {
                handleRazaoSocial(e.target.value);
              }}
              label="Razão Social"
              value={razaoSocial}
              error={razaoErr}
              helperText={razaoErr && "Razão Social inválida."}
              required
            />
            <V3TextCustom
              size="small"
              sx={{ width: 447 }}
              label="CNPJ"
              onChange={(e) => {
                handleCnpj(e.target.value);
              }}
              error={cnpjErr}
              value={cnpj}
              helperText={cnpjErr && "CNPJ inválido."}
              required
            />
            <V3TextCustom
              sx={{ width: 447 }}
              label="E-mail"
              onChange={(e) => {
                handleEmail(e.target.value);
              }}
              error={emailErr}
              size="small"
              value={email}
              helperText={emailErr && "E-mail inválido."}
              required
            />
            <V3TextCustom
              size="small"
              sx={{ width: 447 }}
              onChange={(e) => {
                handleTelefone(e.target.value);
              }}
              error={telefoneErr}
              value={telefone}
              label="Telefone"
              helperText={
                telefoneErr &&
                "Telefone deve ser no formato(11) 1234-5678 ou (11) 98765-4321."
              }
              required
            />

            <FormControlLabel
              value="sim"
              control={
                <Checkbox
                  checked={isWhatsApp}
                  onChange={() => setIsWhatsApp(!isWhatsApp)}
                  value={0}
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                  sx={{
                    color: v3Colors.primary,
                    "&.Mui-checked": {
                      color: v3Colors.primary,
                    },
                  }}
                />
              }
              label="Este telefone é WhatsApp, aceito receber contato."
            />
          </div>
        )}

        {tipoPessoa && (
          <>
            <CenteredBox style={{ marginTop: 15, padding: "0px 100px" }}>
              <V3TextH1 style={{ textAlign: "center" }}>
                Possui mais de uma cota desta administradora?
              </V3TextH1>
            </CenteredBox>

            <CenteredBox style={{ gap: 20, marginTop: 15 }}>
              <V3RadioButton
                text="Sim"
                selected={possui === "SIM"}
                onClick={() => {
                  setPossui("SIM");
                }}
              />
              <V3RadioButton
                text="Não"
                selected={possui === "NAO"}
                onClick={() => {
                  setPossui("NAO");
                }}
              />
            </CenteredBox>
          </>
        )}

        {possui === "SIM" && (
          <>
            <CenteredBox style={{ marginTop: 15, padding: "0px 100px" }}>
              <V3TextH1 style={{ textAlign: "center" }}>
                Informe os dados da cota que deseja vender
              </V3TextH1>
            </CenteredBox>
            <CenteredBox
              style={{
                margin: "10px 75px 10px 100px",
                gap: 10,
              }}
            >
              <V3TextCustom
                label="Grupo"
                onChange={(e) => {
                  const text = e.target.value.replace(/[^\d]/g, "");
                  if (text.length <= 5) {
                    setGrupo(text);
                    setGrupoErr(false);
                  }
                }}
                size="small"
                value={grupo}
                error={grupoErr}
                required
              />
              <V3TextCustom
                label="Cota"
                onChange={(e) => {
                  const text = e.target.value.replace(/[^\d]/g, "");
                  if (text.length <= 4) {
                    setCota(text);
                    setCotaErr(false);
                  }
                }}
                size="small"
                value={cota}
                error={cotaErr}
                required
              />
              <V3TextCustom
                label="Versão"
                onChange={(e) => {
                  const text = e.target.value.replace(/[^\d]/g, "");
                  if (text.length <= 2) {
                    setVersao(text);
                    setVersaoErr(false);
                  }
                }}
                value={versao}
                size="small"
                error={versaoErr}
                required
              />
              <div style={{ position: "relative" }}>
                <ImInfo
                  onClick={() => setHasInfo(true)}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </CenteredBox>
            <CenteredBox
              style={{ justifyContent: "center", margin: "10px 100px" }}
            >
              <V3Text5>OU</V3Text5>
            </CenteredBox>
            <CenteredBox
              style={{ justifyContent: "flex-start", margin: "0 100px" }}
            >
              <V3TextCustom
                label="Contrato"
                sx={{ width: 447 }}
                onChange={(e) => {
                  const text = e.target.value.replace(/[^\d]/g, "");
                  if (text.length <= 20) {
                    setContrato(text);
                  }
                }}
                value={contrato}
                required
                size="small"
              />
            </CenteredBox>
          </>
        )}
        <CenteredBox
          style={{
            marginTop: 15,
            padding: "0px 100px",
            justifyContent: "flex-start",
          }}
        >
          <V3Text5 style={{ color: v3Colors.black, fontStyle: "italic" }}>
            *Preenchimento Obrigatório
          </V3Text5>
        </CenteredBox>
        <V3Modal
          open={hasInfo}
          actionClose={setHasInfo}
          title={"Informação sobre seu consórcio"}
          html={
            <V3Text5 style={{ textAlign: "center" }}>
              Não possui as informações do consórcio? <br />
              <a href="/">Clique aqui</a> e verifique como resgatar o seu
              extrato.
            </V3Text5>
          }
        />
        <V3Modal
          open={hasError}
          actionClose={handleResetOnboarding}
          title={"Atenção!"}
          text={errorText}
          type="error"
        />
        <V3Modal
          open={hasSuccess}
          actionClose={setHasSuccess}
          title={"Sucesso!"}
          text={successText}
          type="success"
        />

        <CenteredBox style={{ gap: 20, marginTop: 30 }}>
          <V3Button
            onClick={handleNext}
            style={{ marginBottom: 30 }}
            width={315}
            height={60}
            text={"QUERO A MELHOR OFERTA"}
            estilo="primary"
          />
        </CenteredBox>
        <CenteredBox style={{ gap: 20, marginTop: 15 }}>
          <V3Text5
            style={{
              cursor: "pointer",
              textDecoration: "underline",
              color: "#000",
              fontWeight: "bold",
            }}
            onClick={handlePrev}
          >
            Voltar
          </V3Text5>
        </CenteredBox>
      </BoxStepDesktop>
    </>
  );
};
export const Step2Mobile = (props) => {
  const { setCurrentStep } = props;
  const [onboarding, setOnboarding] = useState("");
  const [tipoPessoa, setTipoPessoa] = useState("");
  const [possui, setPossui] = useState(null);
  const [idEtapa, setIdEtapa] = useState("");
  const [hasInfo, setHasInfo] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [hasSuccess, setHasSuccess] = useState(false);

  const [errorText, setErrorText] = useState("");
  const [successText, setSuccessText] = useState("");

  const [cpf, setCpf] = useState("");
  const [nomeCompleto, setNomeCompleto] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [isWhatsApp, setIsWhatsApp] = useState("");

  const [grupo, setGrupo] = useState("");
  const [cota, setCota] = useState("");
  const [versao, setVersao] = useState("");
  const [contrato, setContrato] = useState("");

  const [cnpj, setCnpj] = useState("");
  const [razaoSocial, setRazaoSocial] = useState("");

  const [nomeCompletoErr, setNomeCompletoErr] = useState(false);
  const [razaoSocialErr, setRazaoSocialErr] = useState(false);

  const [cpfErr, setCpfErr] = useState(false);
  const [cnpjErr, setCnpjErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [telefoneErr, setTelefoneErr] = useState(false);
  const [razaoErr, setRazaoErr] = useState("");
  const [cotaErr, setCotaErr] = useState(false);
  const [grupoErr, setGrupoErr] = useState(false);
  const [versaoErr, setVersaoErr] = useState(false);

  function fillCPF(cpf) {
    const onlyDigits = cpf?.replace(/[^\d]/g, "");
    return onlyDigits?.padStart(11, "0");
  }

  function fillCNPJ(cnpj) {
    const onlyDigits = cnpj?.replace(/[^\d]/g, "");
    return onlyDigits?.padStart(14, "0");
  }

  async function handleNext() {
    if (!tipoPessoa) {
      setHasError(true);
      return setErrorText("Selecione o Tipo Pessoa");
    }

    const etp = localStorage.getItem("idEtapaAtual");
    const etapa = idEtapa ? idEtapa : etp;

    const docPF = parseInt(cpf?.replace(/[^0-9]/g, ""));
    const docPJ = parseInt(cnpj?.replace(/[^0-9]/g, ""));

    if (tipoPessoa === "PF") {
      if (!nomeCompleto) {
        setHasError(true);
        setNomeCompletoErr(true);
        return setErrorText("Preencha o Nome Completo");
      }

      if (!cpf || !v3ValidaCpfCnpj(cpf)) {
        setHasError(true);
        setCpfErr(true);
        return setErrorText("Preencha o CPF corretamente.");
      }
      if (!email || !validateEmail(email)) {
        setHasError(true);
        setEmailErr(true);
        return setErrorText("Preencha o e-mail corretamente.");
      }
      if (!telefone) {
        setHasError(true);
        setTelefoneErr(true);
        return setErrorText("Preencha o telefone corretamente.");
      }

      if (!possui || possui === null) {
        setHasError(true);
        return setErrorText(
          "Selecione se possui mais de uma cota desta administradora."
        );
      }

      if (!contrato && possui === "SIM") {
        if (!contrato?.length && (!grupo || grupo === null)) {
          setHasError(true);
          setGrupoErr(true);
          return setErrorText("Preencha o Grupo de sua cota de consórcio.");
        }

        if (!cota || cota === null) {
          setHasError(true);
          setCotaErr(true);
          return setErrorText("Preencha o número de sua cota de consórcio.");
        }

        if (!versao || versao === null) {
          setHasError(true);
          setVersaoErr(true);
          return setErrorText(
            "Preencha o número da versão da sua cota de consórcio."
          );
        }
      }

      if (!possui || possui === null) {
        setHasError(true);
        return setErrorText(
          "Selecione se possui mais de uma cota desta administradora."
        );
      }

      await api
        .patch(
          `/api/concash/1.0/cliente-cadastro/onboarding/avancar/${onboarding}/${etapa}`,
          [
            {
              nome: "tipoPessoa",
              valor: "PF",
            },
            {
              nome: "nomeRazao",
              valor: nomeCompleto,
            },
            {
              nome: "cpfCnpj",
              valor: `${docPF}`,
            },
            {
              nome: "email",
              valor: email,
            },
            {
              nome: "celular",
              valor: telefone,
            },
            {
              nome: "aceitaContatoWhatsapp",
              valor: isWhatsApp ? "SIM" : "NAO",
            },
            {
              nome: "possuiMaisDeUmaCota",
              valor: possui,
            },
            {
              nome: "grupo",
              valor: `${grupo}`,
            },
            {
              nome: "cota",
              valor: `${cota}`,
            },
            {
              nome: "versao",
              valor: `${versao}`,
            },
            {
              nome: "contrato",
              valor: contrato,
            },
          ]
        )
        .then(({ data }) => {
          localStorage.setItem("idEtapaAtual", data.idEtapaAtual);
          setCurrentStep(3);
        })
        .catch((data) => {
          setHasError(true);
          setErrorText(
            "Tivemos um problema ao processar seus dados, por favor, preencha os dados novamente."
          );
        });
    }

    if (tipoPessoa === "PJ") {
      if (!razaoSocial) {
        setHasError(true);
        setRazaoSocialErr(true);
        return setErrorText("Preencha a Razão Social corretamente.");
      }

      if (!cnpj || !v3ValidaCpfCnpj(cnpj)) {
        setHasError(true);
        setCnpjErr(true);
        return setErrorText("Preencha o CNPJ corretamente.");
      }
      if (!email || !validateEmail(email)) {
        setHasError(true);
        setEmailErr(true);
        return setErrorText("Preencha o e-mail corretamente.");
      }
      if (!telefone) {
        setHasError(true);
        setTelefoneErr(true);
        return setErrorText("Preencha o telefone corretamente.");
      }
      if (!possui || possui === null) {
        setHasError(true);
        return setErrorText(
          "Selecione se possui mais de uma cota desta administradora."
        );
      }
      await api
        .patch(
          `/api/concash/1.0/cliente-cadastro/onboarding/avancar/${onboarding}/${etapa}`,
          [
            {
              nome: "tipoPessoa",
              valor: "PJ",
            },
            {
              nome: "nomeRazao",
              valor: razaoSocial,
            },
            {
              nome: "cpfCnpj",
              valor: `${docPJ}`,
            },
            {
              nome: "email",
              valor: email,
            },
            {
              nome: "celular",
              valor: telefone,
            },
            {
              nome: "aceitaContatoWhatsapp",
              valor: isWhatsApp ? "SIM" : "NAO",
            },
            {
              nome: "possuiMaisDeUmaCota",
              valor: possui,
            },
            {
              nome: "grupo",
              valor: `${grupo}`,
            },
            {
              nome: "cota",
              valor: `${cota}`,
            },
            {
              nome: "versao",
              valor: `${versao}`,
            },
            {
              nome: "contrato",
              valor: contrato,
            },
          ]
        )
        .then(({ data }) => {
          localStorage.setItem("idEtapaAtual", data.idEtapaAtual);
          setCurrentStep(3);
        })
        .catch((data) => {
          setHasError(true);
          setErrorText(
            "Tivemos um problema ao processar seus dados, por favor, preencha os dados novamente."
          );
        });
    }
  }

    function handleResetOnboarding(state) {
      setHasError(state);
      localStorage.clear();
      window.location.reload();
    }

  function handlePrev() {
    setCurrentStep(1);
  }

  function handleRazaoSocial(t) {
    const valid = validRazaoSocial(t);
    if ((razaoSocial && valid) || razaoSocial === "") {
      setRazaoSocial(t);
      setRazaoErr(false);
    } else {
      setRazaoSocial(t);
      setRazaoErr(true);
    }
  }

  function handleCpf(text) {
    const validating = text.replace(/[^\d]/g, "");
    if (validating.length <= 11) {
      const cpfs = v3MaskCPF(validating);
      setCpf(cpfs);

      if (v3ValidaCpfCnpj(cpfs) || text === "") {
        setCpfErr(false);
      } else {
        setCpfErr(true);
      }
    }
  }

  function handleCnpj(text) {
    const validating = text.replace(/[^\d]/g, "");
    if (validating.length <= 14) {
      const cn = v3FormataCNPJ(validating);
      setCnpj(cn);

      if (v3ValidaCpfCnpj(cn) || text === "") {
        setCnpjErr(false);
      } else {
        setCnpjErr(true);
      }
    }
  }

  function handleEmail(t) {
    const text = t?.replaceAll(" ", "");
    if (v3ValidateEmail(text) || text === "") {
      setEmail(text);
      setEmailErr(false);
    } else {
      setEmail(text);
      setEmailErr(true);
    }
  }

  function handleNomeCompleto(text) {
    const valid = validNome(text);

    if (!text || !valid) {
      setNomeCompleto(text);
      setNomeCompletoErr(true);
    } else {
      setNomeCompleto(text);
      setNomeCompletoErr(false);
    }
  }

  function handleTelefone(t) {
    const validatePhone = t.replace(/[^0-9]/g, "").split("");
    if (t) {
      setTelefoneErr(false);
      if (t.length > 15) {
        setTelefone(telefone);
      } else {
        setTelefone(v3CellphoneFormater(t));
      }
    }
    if (!t || t == "" || t == "(") {
      setTelefoneErr(true);
      setTelefone(t);
    }

    if (t.length < 14 || validatePhone[0] === "0") {
      setTelefoneErr(true);
    } else {
      setTelefoneErr(false);
    }
  }

  async function recuperarEtapas() {
    const onboardingObj = JSON.parse(localStorage.getItem("onboarding"));

    if (!onboardingObj) {
      const on = localStorage.getItem("onboarding");
      const etp = await api.get(
        `/api/concash/1.0/cliente-cadastro/onboarding/recuperar/${on}`
      );
      localStorage.setItem("etapaAtual", `2`);
      localStorage.setItem("onboardingObj", `${JSON.stringify(etp.data)}`);
      localStorage.setItem("etapas", `${JSON.stringify(etp.data.etapas)}`);

      const tipoR = await etp.data.etapas[1].campos.find(
        (i) => i.nome === "tipoPessoa"
      ).valor;
      setTipoPessoa(tipoR);
      const nomeRazaoR = await etp.data.etapas[1].campos.find(
        (i) => i.nome === "nomeRazao"
      ).valor;
      const cpfCnpjR = await etp.data.etapas[1].campos.find(
        (i) => i.nome === "cpfCnpj"
      ).valor;
      const emailR = await etp.data.etapas[1].campos.find(
        (i) => i.nome === "email"
      ).valor;
      const celularR = await etp.data.etapas[1].campos.find(
        (i) => i.nome === "celular"
      ).valor;
      const whatsappR = await etp.data.etapas[1].campos.find(
        (i) => i.nome === "aceitaContatoWhatsapp"
      ).valor;
      const cotaR = await etp.data.etapas[1].campos.find(
        (i) => i.nome === "cota"
      ).valor;
      const grupoR = await etp.data.etapas[1].campos.find(
        (i) => i.nome === "grupo"
      ).valor;
      const versaoR = await etp.data.etapas[1].campos.find(
        (i) => i.nome === "versao"
      ).valor;
      const contratoR = await etp.data.etapas[1].campos.find(
        (i) => i.nome === "contrato"
      ).valor;
      const possuiR = await etp.data.etapas[1].campos.find(
        (i) => i.nome === "possuiMaisDeUmaCota"
      ).valor;
      if (tipoR === "PF") {
        setNomeCompleto(nomeRazaoR);
        setCpf(cpfCnpjR && v3MaskCPF(fillCPF(await cpfCnpjR)));
        setEmail(emailR);
        setTelefone(celularR);
        setIsWhatsApp(whatsappR === "SIM" ? true : false);
      } else {
        setRazaoSocial(nomeRazaoR);
        setCnpj(cpfCnpjR && v3FormataCNPJ(fillCNPJ(await cpfCnpjR)));
        setEmail(emailR);
        setTelefone(celularR);
        setIsWhatsApp(whatsappR === "SIM" ? true : false);
      }
      setCota(cotaR?.replace(/[^0-9]/g, ""));
      setGrupo(grupoR?.replace(/[^0-9]/g, ""));
      setVersao(versaoR?.replace(/[^0-9]/g, ""));
      setContrato(contratoR?.replace(/[^0-9]/g, ""));
      setPossui(possuiR);
    }

    const tipoR = await onboardingObj.etapas[1].campos.find(
      (i) => i.nome === "tipoPessoa"
    ).valor;
    setTipoPessoa(tipoR);
    const nomeRazaoR = await onboardingObj.etapas[1].campos.find(
      (i) => i.nome === "nomeRazao"
    ).valor;
    const cpfCnpjR = await onboardingObj.etapas[1].campos.find(
      (i) => i.nome === "cpfCnpj"
    ).valor;
    const emailR = await onboardingObj.etapas[1].campos.find(
      (i) => i.nome === "email"
    ).valor;
    const celularR = await onboardingObj.etapas[1].campos.find(
      (i) => i.nome === "celular"
    ).valor;
    const whatsappR = await onboardingObj.etapas[1].campos.find(
      (i) => i.nome === "aceitaContatoWhatsapp"
    ).valor;
    const cotaR = await onboardingObj.etapas[1].campos.find(
      (i) => i.nome === "cota"
    ).valor;
    const grupoR = await onboardingObj.etapas[1].campos.find(
      (i) => i.nome === "grupo"
    ).valor;
    const versaoR = await onboardingObj.etapas[1].campos.find(
      (i) => i.nome === "versao"
    ).valor;
    const contratoR = await onboardingObj.etapas[1].campos.find(
      (i) => i.nome === "contrato"
    ).valor;
    const possuiR = await onboardingObj.etapas[1].campos.find(
      (i) => i.nome === "possuiMaisDeUmaCota"
    ).valor;

    if (tipoR === "PF") {
      setNomeCompleto(nomeRazaoR);
      setCpf(cpfCnpjR && v3MaskCPF(fillCPF(await cpfCnpjR)));
      setEmail(emailR);
      setTelefone(celularR);
      setIsWhatsApp(whatsappR === "SIM" ? true : false);
    } else {
      setRazaoSocial(nomeRazaoR);
      setCnpj(cpfCnpjR && v3FormataCNPJ(fillCNPJ(await cpfCnpjR)));
      setEmail(emailR);
      setTelefone(celularR);
      setIsWhatsApp(whatsappR === "SIM" ? true : false);
    }

    setCota(cotaR?.replace(/[^0-9]/g, ""));
    setGrupo(grupoR?.replace(/[^0-9]/g, ""));
    setVersao(versaoR?.replace(/[^0-9]/g, ""));
    setContrato(contratoR?.replace(/[^0-9]/g, ""));
    setPossui(possuiR);
  }

  async function persistData() {
    const on = localStorage.getItem("onboarding");
    const etp = await api.get(
      `/api/concash/1.0/cliente-cadastro/onboarding/recuperar/${on}`
    );

    const tipoR = await etp.data.etapas[1].campos.find(
      (i) => i.nome === "tipoPessoa"
    ).valor;
    setTipoPessoa(tipoR);
    const nomeRazaoR = await etp.data.etapas[1].campos.find(
      (i) => i.nome === "nomeRazao"
    ).valor;
    const cpfCnpjR = await etp.data.etapas[1].campos.find(
      (i) => i.nome === "cpfCnpj"
    ).valor;
    const emailR = await etp.data.etapas[1].campos.find(
      (i) => i.nome === "email"
    ).valor;
    const celularR = await etp.data.etapas[1].campos.find(
      (i) => i.nome === "celular"
    ).valor;
    const whatsappR = await etp.data.etapas[1].campos.find(
      (i) => i.nome === "aceitaContatoWhatsapp"
    ).valor;
    const cotaR = await etp.data.etapas[1].campos.find((i) => i.nome === "cota")
      .valor;
    const grupoR = await etp.data.etapas[1].campos.find(
      (i) => i.nome === "grupo"
    ).valor;
    const versaoR = await etp.data.etapas[1].campos.find(
      (i) => i.nome === "versao"
    ).valor;
    const contratoR = await etp.data.etapas[1].campos.find(
      (i) => i.nome === "contrato"
    ).valor;
    const possuiR = await etp.data.etapas[1].campos.find(
      (i) => i.nome === "possuiMaisDeUmaCota"
    ).valor;
    const dt = v3MaskCPF(fillCPF(await cpfCnpjR));
    if (tipoR === "PF") {
      setNomeCompleto(nomeRazaoR);
      setCpf(cpfCnpjR && v3MaskCPF(fillCPF(await cpfCnpjR)));
      setEmail(emailR);
      setTelefone(celularR);
      setIsWhatsApp(whatsappR === "SIM" ? true : false);
    } else {
      setRazaoSocial(nomeRazaoR);
      setCnpj(cpfCnpjR && v3FormataCNPJ(fillCNPJ(await cpfCnpjR)));
      setEmail(emailR);
      setTelefone(celularR);
      setIsWhatsApp(whatsappR === "SIM" ? true : false);
    }
    setCota(cotaR?.replace(/[^0-9]/g, ""));
    setGrupo(grupoR?.replace(/[^0-9]/g, ""));
    setVersao(versaoR?.replace(/[^0-9]/g, ""));
    setContrato(contratoR?.replace(/[^0-9]/g, ""));
    setPossui(possuiR);
  }

    function handleResetOnboarding(state) {
      setHasError(state);
      localStorage.clear();
      window.location.reload();
    }

  useEffect(() => {
    persistData();
  }, [setCurrentStep]);

  useEffect(() => {
    localStorage.setItem("etapaAtual", 2);
    const onboardingObj = JSON.parse(localStorage.getItem("onboardingObj"));
    setIdEtapa(onboardingObj.etapas[1].id);
    setOnboarding(onboardingObj.id);

    if (!onboardingObj) {
      recuperarEtapas();
    }
  }, []);
  return (
    <>
      <BoxStepMobile>
        <BoxPinStep>
          <PinStepActive num={1} type="checked" />
          <PinStepActive num={2} type="active" />
          <PinStepActive num={3} type="deactive" />
          <PinStepActive num={4} type="deactive" />
        </BoxPinStep>
        <CenteredBoxMobile>
          <V3TextH1>Quem é o titular?</V3TextH1>
        </CenteredBoxMobile>

        <CenteredBoxMobile style={{ gap: 10, marginTop: 15 }}>
          <V3RadioButton
            text="Pessoa Física"
            selected={tipoPessoa === "PF"}
            onClick={() => {
              setTipoPessoa("PF");
            }}
          />
          <V3RadioButton
            text="Pessoa Jurídica"
            selected={tipoPessoa === "PJ"}
            onClick={() => {
              setTipoPessoa("PJ");
            }}
          />
        </CenteredBoxMobile>
        {tipoPessoa === "PF" && (
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              gap: 10,
              paddingLeft: 10,
              marginTop: 20,
            }}
          >
            <V3TextCustom
              sx={{ width: 350 }}
              size="small"
              onChange={(e) => {
                handleNomeCompleto(e.target.value);
              }}
              label="Nome Completo"
              error={nomeCompletoErr}
              helperText={nomeCompletoErr && "Preencha o seu nome completo."}
              required
              value={nomeCompleto}
            />
            <V3TextCustom
              id="cpf"
              sx={{ width: 350 }}
              label="CPF"
              onChange={(e) => {
                handleCpf(e.target.value);
              }}
              required
              size="small"
              error={cpfErr}
              helperText={cpfErr && "CPF inválido."}
              value={cpf}
            />

            <V3TextCustom
              sx={{ width: 350 }}
              label="E-mail"
              onChange={(e) => {
                handleEmail(e.target.value);
              }}
              error={emailErr}
              size="small"
              helperText={emailErr && "E-mail inválido."}
              value={email}
              type="email"
              required
            />
            <V3TextCustom
              size="small"
              sx={{ width: 350 }}
              onChange={(e) => {
                handleTelefone(e.target.value);
              }}
              error={telefoneErr}
              value={telefone}
              label="Telefone"
              helperText={
                telefoneErr &&
                "Telefone deve ser no formato(11) 1234-5678 ou (11) 98765-4321."
              }
              required
            />
            <FormControlLabel
              value="sim"
              control={
                <Checkbox
                  checked={isWhatsApp}
                  onChange={() => setIsWhatsApp(!isWhatsApp)}
                  value={0}
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                  sx={{
                    color: v3Colors.primary,
                    "&.Mui-checked": {
                      color: v3Colors.primary,
                    },
                  }}
                />
              }
              label="Este telefone é WhatsApp, aceito receber contato."
            />
          </div>
        )}
        {tipoPessoa === "PJ" && (
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              gap: 10,
              paddingLeft: 10,
              marginTop: 20,
            }}
          >
            <V3TextCustom
              size="small"
              sx={{ width: 350 }}
              onChange={(e) => {
                handleRazaoSocial(e.target.value);
              }}
              label="Razão Social"
              value={razaoSocial}
              error={razaoErr}
              helperText={razaoErr && "Razão Social inválida."}
              required
            />
            <V3TextCustom
              size="small"
              sx={{ width: 350 }}
              label="CNPJ"
              onChange={(e) => {
                handleCnpj(e.target.value);
              }}
              error={cnpjErr}
              value={cnpj}
              helperText={cnpjErr && "CNPJ inválido."}
              required
            />
            <V3TextCustom
              sx={{ width: 350 }}
              label="E-mail"
              onChange={(e) => {
                handleEmail(e.target.value);
              }}
              error={emailErr}
              size="small"
              value={email}
              helperText={emailErr && "E-mail inválido."}
              required
            />
            <V3TextCustom
              size="small"
              sx={{ width: 350 }}
              onChange={(e) => {
                handleTelefone(e.target.value);
              }}
              error={telefoneErr}
              value={telefone}
              label="Telefone"
              helperText={
                telefoneErr &&
                "Telefone deve ser no formato(11) 1234-5678 ou (11) 98765-4321."
              }
              required
            />

            <FormControlLabel
              value="sim"
              control={
                <Checkbox
                  checked={isWhatsApp}
                  onChange={() => setIsWhatsApp(!isWhatsApp)}
                  value={0}
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                  sx={{
                    color: v3Colors.primary,
                    "&.Mui-checked": {
                      color: v3Colors.primary,
                    },
                  }}
                />
              }
              label="Este telefone é WhatsApp, aceito receber contato."
            />
          </div>
        )}

        {tipoPessoa && (
          <>
            <CenteredBoxMobile style={{ marginTop: 15, padding: "0px 10px" }}>
              <V3TextH1 style={{ textAlign: "center", fontSize: 20 }}>
                Possui mais de uma cota desta administradora?
              </V3TextH1>
            </CenteredBoxMobile>

            <CenteredBoxMobile style={{ gap: 10, marginTop: 15 }}>
              <V3RadioButton
                text="Sim"
                selected={possui === "SIM"}
                onClick={() => {
                  setPossui("SIM");
                }}
              />
              <V3RadioButton
                text="Não"
                selected={possui === "NAO"}
                onClick={() => {
                  setPossui("NAO");
                }}
              />
            </CenteredBoxMobile>
          </>
        )}

        {possui === "SIM" && (
          <>
            <CenteredBoxMobile style={{ marginTop: 15, padding: "0px 10px" }}>
              <V3TextH1 style={{ textAlign: "center", fontSize: 20 }}>
                Informe os dados da cota que deseja vender
              </V3TextH1>
            </CenteredBoxMobile>
            <CenteredBoxMobile
              style={{
                margin: 10,
                gap: 10,
              }}
            >
              <V3TextCustom
                label="Grupo"
                onChange={(e) => {
                  const text = e.target.value.replace(/[^\d]/g, "");
                  if (text.length <= 5) {
                    setGrupo(text);
                    setGrupoErr(false);
                  }
                }}
                size="small"
                value={grupo}
                error={grupoErr}
                required
              />
              <V3TextCustom
                label="Cota"
                onChange={(e) => {
                  const text = e.target.value.replace(/[^\d]/g, "");
                  if (text.length <= 4) {
                    setCota(text);
                    setCotaErr(false);
                  }
                }}
                size="small"
                value={cota}
                error={cotaErr}
                required
              />
              <V3TextCustom
                label="Versão"
                onChange={(e) => {
                  const text = e.target.value.replace(/[^\d]/g, "");
                  if (text.length <= 2) {
                    setVersao(text);
                    setVersaoErr(false);
                  }
                }}
                value={versao}
                size="small"
                error={versaoErr}
                required
              />
              <div style={{ width: 30, position: "relative" }}>
                <ImInfo
                  onClick={() => setHasInfo(true)}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </CenteredBoxMobile>

            <CenteredBoxMobile
              style={{ justifyContent: "center", margin: "10px 10px" }}
            >
              <V3Text5>OU</V3Text5>
            </CenteredBoxMobile>
            <CenteredBoxMobile
              style={{ justifyContent: "flex-start", margin: "10px 10px" }}
            >
              <V3TextCustom
                label="Contrato"
                sx={{ width: 350 }}
                onChange={(e) => {
                  const text = e.target.value.replace(/[^\d]/g, "");
                  if (text.length <= 20) {
                    setContrato(text);
                  }
                }}
                value={contrato}
                size="small"
              />
            </CenteredBoxMobile>
          </>
        )}

        <V3Modal
          open={hasInfo}
          actionClose={setHasInfo}
          title={"Informação sobre seu consórcio"}
          html={
            <V3Text5 style={{ textAlign: "center" }}>
              Não possui as informações do consórcio? <br />
              <a href="/">Clique aqui</a> e verifique como resgatar o seu
              extrato.
            </V3Text5>
          }
        />
        <V3Modal
          open={hasError}
          actionClose={handleResetOnboarding}
          title={"Atenção!"}
          text={errorText}
          type="error"
        />
        <V3Modal
          open={hasSuccess}
          actionClose={setHasSuccess}
          title={"Sucesso!"}
          text={successText}
          type="success"
        />
        <CenteredBoxMobile
          style={{
            marginTop: 15,
            padding: "0px 10px",
            justifyContent: "flex-start",
          }}
        >
          <V3Text5 style={{ color: v3Colors.black, fontStyle: "italic" }}>
            *Preenchimento Obrigatório
          </V3Text5>
        </CenteredBoxMobile>
        <CenteredBox style={{ gap: 20, marginTop: 30 }}>
          <V3Button
            onClick={handleNext}
            style={{ marginBottom: 30 }}
            width={315}
            height={60}
            text={"QUERO A MELHOR OFERTA"}
            estilo="primary"
          />
        </CenteredBox>
        <CenteredBox style={{ gap: 20, marginTop: 15 }}>
          <V3Text5
            style={{
              cursor: "pointer",
              textDecoration: "underline",
              color: "#000",
              fontWeight: "bold",
            }}
            onClick={handlePrev}
          >
            Voltar
          </V3Text5>
        </CenteredBox>
      </BoxStepMobile>
    </>
  );
};
