export const sanitizeParams = (text) => {
  return text?.replace(/[<>]/g, "");
}

export const getURLParameter = (key) => {
  const queryParams = new URLSearchParams(window.location.search);

  try {
    const value = queryParams.get(key);
    return value === null ? null : decodeURIComponent(value)?.replace(/[^\w\s]/gi, "");
  } catch (e) {
    console.error(e);
    return null;
  }
};