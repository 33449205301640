import styled from "styled-components";
import BGDesk from "../../v3/assets/fundo.svg";

export const ContainerDesktopOPT = styled.div`
  display: flex;
  flex: 1;
  margin: 0;
  background-image: url(${BGDesk});
  background-repeat: no-repeat;
  background-size: 110%;
  background-position: center;
  background-attachment: fixed;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 60px;
  min-height: 85vh;
`;

export const SectorDesktopOPT = styled.div`
  display: flex;
  flex: 1;
  width: 650px;
  height: 401px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 15px 40px #0000001a;
  border-radius: 20px;
  opacity: 1;
`;

export const ContainerMobileOPT = styled.div`
  display: flex;
  flex: 1;
  height: 85%;
  flex-direction: column;
`;
