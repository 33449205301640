export function v3MaskCPF(cpf) {
  if(!cpf){
    return "";
  }
  return cpf
    .replace(/\D/g, "") // Remove todos os caracteres não numéricos
    .replace(/(\d{3})(\d)/, "$1.$2") // Adiciona um ponto após os três primeiros dígitos
    .replace(/(\d{3})(\d)/, "$1.$2") // Adiciona um ponto após os seis primeiros dígitos
    .replace(/(\d{3})(\d{1,2})/, "$1-$2") // Adiciona um hífen após os nove primeiros dígitos
    .substring(0, 14); // Limita o tamanho da string para 14 caracteres
}

export function v3MaskPhone(phone) {
  return phone
    .replace(/\D/g, "") // Remove todos os caracteres não numéricos
    .replace(/(\d{2})(\d)/, "($1) $2") // Adiciona parênteses e espaço após os dois primeiros dígitos
    .replace(/(\d{4})(\d)/, "$1-$2") // Adiciona um hífen após os quatro primeiros dígitos
    .replace(/(\d{4})-(\d)(\d{4})/, "$1$2-$3") // Adiciona um hífen após o quinto dígito
    .substring(0, 15); // Limita o tamanho da string para 15 caracteres
}

function realNaN(chart) {
  const value = parseInt(chart, 10);
  return isNaN(value);
}

function createFormater(mask) {
  const computedMask = mask.split("");

  return (value) => {
    if (value.length === 0) {
      return "";
    }

    let valueIndex = 0;
    return computedMask
      .map((chart, index) => {
        const v = value[valueIndex];

        if (!v && realNaN(chart) && index === 0) {
          return chart;
        }

        if (!v) {
          return "";
        }

        if (realNaN(chart)) {
          return chart;
        }

        valueIndex += 1;
        return v;
      })
      .join("");
  };
}

export const v3FormataCNPJ = (text) => {
  const texto = text.replace(/[^\d]/g, "");
  const textoF = texto
    .replace(/\D+/g, "")
    .replace(/(\d{2})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1/$2")
    .replace(/(\d{4})(\d)/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");

  return textoF;
};

const cellphone = createFormater("(11) 91234-5678");
const phone = createFormater("(11) 1234-5678");

export const v3CellphoneFormater = (text) => {
  text = text.replace(/([^0-9])/g, "").slice(0, 11);

  if (text.length < 11) {
    return phone(text);
  }
  return cellphone(text);
};

export function v3FormatCurrency(value) {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);
}
