import { AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import React from "react";
import Accordion from "@mui/material/Accordion";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

export function AccordionFAQ({ active, changeActive, title, content }) {
  return (
    <>
      <div style={{ padding: 10 }}>
        <Accordion
          disableGutters
          elevation={0}
          expanded={active}
          style={{
            backgroundColor: "transparent",
            padding: "10px 15px",
            borderRadius: "20px",
            boxShadow: "0px 0px 40px -3px rgba(137,137,137,0.48)",
          }}
          onChange={changeActive}
        >
          <AccordionSummary
            expandIcon={
              active ? (
                <MdOutlineKeyboardArrowDown color={"#000"} size={20} />
              ) : (
                <MdOutlineKeyboardArrowDown color={"#000"} size={20} />
              )
            }
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            style={{
              backgroundColor: active ? "#FFF" : "#FFF",
              borderRadius: "20px",
              paddingTop: 10,
              border: "none",
              color: "#000",
            }}
          >
            <Typography
              className="text-acc"
              sx={{
                color: active ? "#000" : "#000",
                fontWeight: "bold",
                textAlign: "left",
                marginLeft: "15px",
              }}
            >
              {title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            className="box-acc"
            style={{
              borderRadius: "0 0 20px 20px",
              margin: "0 0px",
            }}
          >
            <Typography className="text-intern-acc">{content}</Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
}
export function AccordionFAQMobile({ active, changeActive, title, content }) {
  return (
    <>
      <div
        style={{
          display: "flex",
          flex: 1,
          margin: 10,
          alignItems: "center",
          justifyContent: "center",
          maxWidth: 300,
        }}
      >
        <Accordion
          disableGutters
          elevation={0}
          expanded={active}
          style={{
            backgroundColor: "transparent",
            padding: "10px 15px",
            borderRadius: "20px",
            boxShadow: "0px 0px 40px -3px rgba(137,137,137,0.48)",
          }}
          onChange={changeActive}
        >
          <AccordionSummary
            expandIcon={
              active ? (
                <MdOutlineKeyboardArrowDown color={"#000"} size={20} />
              ) : (
                <MdOutlineKeyboardArrowDown color={"#000"} size={20} />
              )
            }
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            style={{
              backgroundColor: active ? "#FFF" : "#FFF",
              borderRadius: "20px",
              paddingTop: 10,
              border: "none",
              color: "#000",
            }}
          >
            <Typography
              className="text-acc"
              sx={{
                color: "#000",
                fontWeight: "bold",
                textAlign: "left",

                fontSize: 15,
              }}
            >
              {title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            className="box-acc"
            style={{
              borderRadius: "0 0 20px 20px",
              margin: "0 0px",
            }}
          >
            <Typography className="text-intern-acc">{content}</Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
}
