import styled from "styled-components";
import { colors } from "../../../styles/colors";
import LogoCinza from "../../../assets/icons/logo-cinza.png";
import { GrClose } from "react-icons/gr";
import { HiOutlineDocumentText } from "react-icons/hi";
import { BsPlus } from "react-icons/bs";
import { BiUser } from "react-icons/bi";
import { MdLogout } from "react-icons/md";
import { V3SiteButtonLink } from "../../../styles/themes/components/button";
import { ambiente } from "../../../services/api";
import { useEffect, useState } from "react";
import { sanitizeParams } from "../../../utils/sanitize";

export const NewSideBar = ({ children }) => {
  return (
    <>
      <NewS>{children}</NewS>
    </>
  );
};

const NewS = styled.div`
  display: flex;
  padding: 30px 35px;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  margin-left: 83px;
  margin-top: 35px;
  min-width: 270px;
  max-width: 270px;
  height: 665px;
  background: transparent linear-gradient(0deg, #7fdec1 0%, #06878d 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 20px;
  gap: 25px;
  opacity: 1;
`;

export const NewItem = ({ icon, title, link }) => {
  const NewI = styled.a`
    display: flex;
    width: 100%;
    height: 30px;
    text-decoration: none !important;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
  `;

  const NewText = styled.span`
        font-family: Roboto, sans-serif,
            font-display: swap,
        font-size: 15px;
        font-weight: bold;
        color: #FFF;
    opacity: 1;
        &:hover {
            opacity: 0.7; 
        }
    `;

  return (
    <>
      <div style={{ textDecoration: "none !important" }}>
        <NewI href={link}>
          <div style={{ color: "FFF !important" }}>{icon}</div>
          <NewText>{title}</NewText>
        </NewI>
      </div>
    </>
  );
};

export const NewItemMobile = ({ icon, title, link }) => {
  const NewI = styled.a`
    display: flex;
    width: 100%;
    min-height: 45px;
    text-decoration: none !important;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    padding: 12px 30px;
    color: #05898f;
  `;

  const NewText = styled.span`
        font-family: Roboto, sans-serif,
    font-display: swap,
        font-size: 15px;
        font-weight: bold;
        color: #05898F;
    opacity: 1;
        &:hover {
            opacity: 0.7; 
        }
    `;

  return (
    <>
      <div style={{ textDecoration: "none !important" }}>
        <NewI href={link}>
          <div style={{ color: "FFF !important" }}>{icon}</div>
          <NewText>{title}</NewText>
        </NewI>
      </div>
    </>
  );
};

export const HeaderContainerMobile = styled.div`
  display: flex;
  flex: 1;
  z-index: 999;

  padding: 10px;

  position: absolute;

  .box-menu {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: ${colors.darkGreen};
    border-radius: 8px;
    height: 100%;
  }

  .cell {
    display: flex;
  }

  .cell-top {
    display: flex;

    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin-bottom: 10px;
    color: #fff;
  }

  .cell-content {
    display: flex;

    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
    color: #fff;
  }

  .link-arrow {
    text-decoration: none;
    color: #fff;
  }

  .buttons {
    display: flex;
    flex: 1;
    margin-top: 140px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
  }
  .link-btn {
    text-decoration: none;
  }

  .box-users {
    display: flex;
    flex: 1;
    padding: 20px;
  }
`;

export const LinkWhite = styled.a`
  font-family: "Poppins", sans-serif;
  font-display: swap;
  text-decoration: none;
  font-size: 1rem;
  color: #fff;
`;

export const TextUsers = styled.span`
  font-family: "Poppins", sans-serif;
  font-display: swap;
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
`;

export const ButtonLarge = styled.button`
  display: flex;
  color: #fff;
  width: 309px;
  height: 76px;
  border-color: ${colors.mediumGreen};
  font-family: "Poppins", sans-serif;
  font-display: swap;
  border-radius: 4px;
  font-size: 17px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  &:hover {
    background-color: ${colors.softDarkGreen};
  }
  background-color: ${colors.mediumGreen};
`;

export const NewNavBar = styled.div`
  display: flex;
  flex: 1;
  top: 0px;
  left: 0px;
  height: 65px;
  padding: 13px 82px;
  justify-content: space-between;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #00000012;
  opacity: 1;
`;

export const NewNavBarMobile = styled.div`
  display: flex;
  flex: 1;
  top: 0px;
  left: 0px;
  max-height: 60px;
  padding: 18px 20px;
  justify-content: space-between;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #00000012;
  opacity: 1;
`;

export const MenuNavMobile = (props) => {
  const { action } = props;
  const [queryParams, setQueryParams] = useState();

  useEffect(() => {
    const text = sanitizeParams(window.location.search);
    setQueryParams(text);
  }, [queryParams]);

  const Box = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    right: 0px;
    height: 100%;
    margin-left: 70px;
    width: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: -10px 0px 20px #00000040;
    opacity: 1;

    .item-mobile {
      color: var(--unnamed-color-16898e);
      text-align: left;
      text-decoration: none;
      font: normal normal bold 15px/30px Roboto;
      letter-spacing: 0px;
      color: #16898e;
      opacity: 1;
    }
    .list-links {
      display: flex;
      flex-direction: column;
      padding: 30px;
      gap: 20px;
    }
  `;

  return (
    <>
      <Box>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: 30,
          }}
        >
          <img loading="lazy" src={LogoCinza} width={110} height={30} alt="gray-concash" />
          <div onClick={action}>
            <GrClose color="#05898F" size={24} />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: 30,
            paddingBottom: 30,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
              padding: 30,
            }}
          >
            {ambiente === "hml" && (
              <V3SiteButtonLink
                estilo="white"
                text="Minha Conta"
                href={
                  ambiente === "hml"
                    ? "https://cliente.hml.concash.com.br/"
                    : "https://cliente.concash.com.br/"
                }
                target="_blank"
              />
            )}
            <V3SiteButtonLink
              estilo="primary"
              text="Vender meu Consórcio"
              href={queryParams?.length ? ("/venderconsorcio"+queryParams) : "/venderconsorcio"}
            />
          </div>
          <div className="list-links">
            <a href={queryParams?.length ? ("#como-funciona"+queryParams) : "#como-funciona"} onClick={action} className="item-mobile">
              Como funciona
            </a>
            <a href={queryParams?.length ? ("/faq"+queryParams) : "/faq"} className="item-mobile">
              Perguntas frequentes
            </a>
            <a href={queryParams?.length ? ("/contatos"+queryParams) : "/contatos"} className="item-mobile">
              Fale conosco
            </a>
            <a
              href={
                ambiente === "hml"
                  ? "https://cliente.hml.concash.com.br/"
                  : "https://cliente.concash.com.br/"
              }
              target="_blank"
              className="item-mobile" rel="noreferrer"
            >
              Blog
            </a>
            <a href={queryParams?.length ? ("/sobre"+queryParams) : "/sobre"} className="item-mobile">
              Sobre nós
            </a>
            <a href={queryParams?.length ? ("/parceiro"+queryParams) : "/parceiro"} className="item-mobile">
              Seja Parceiro
            </a>
          </div>
        </div>
      </Box>
    </>
  );
};
