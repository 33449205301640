import React from "react";
import "./comofunciona.style.css";

interface CFPropos {
  estilo: any;
  icon: any;
  title: string;
  content: string;
  adm: any;
  alt: any;
}

export const ComoFuncionaCardDesktop: React.FC<CFPropos> = ({
  estilo,
  icon,
  title,
  content,
  adm,
  alt,
}: any) => {
  return (
    <>
      <div style={{ display: "flex", flex: 1, width: "100%" }}>
        <div>
          <div
            className="circle-icon"
            style={{ backgroundColor: estilo?.buttonBgColor }}
          >
            <div style={{ color: estilo?.backgroundColor, }} >
              <img
                loading="lazy"
                src={icon}
                color={estilo?.backgroundColor}
                alt={alt}
              />
            </div>
          </div>
        </div>
        <div>
          <h3 className="cf-title">
            <p>{title}</p>
          </h3>
          <div className="cf-content">
            <p>{content}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export const ComoFuncionaCardMobile: React.FC<CFPropos> = ({
  estilo,
  icon,
  title,
  content,
  adm,
  alt
}: any) => {
  return (
    <>
      <div style={{ display: "flex", flex: 1 }}>
        <div>
          <div
            className="circle-icon"
            style={{ backgroundColor: estilo?.buttonBgColor, }}
          >
            <div style={{ color: estilo?.backgroundColor, }} >
              <img
                loading="lazy"
                src={icon}
                color={estilo?.backgroundColor}
                alt={alt}
              />
            </div>
          </div>
        </div>
        <div>
          <h3 className="cf-title-mobile">
            <p>{title}</p>
          </h3>
          <div className="cf-content-mobile">
            <p>{content}</p>
          </div>
        </div>
      </div>
    </>
  );
};
