import { Backdrop } from "@mui/material";
import React from "react";
import { GrClose } from "react-icons/gr";
import styled from "styled-components";
import { v3Colors } from "../../v3/colors";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { FaCheckCircle } from "react-icons/fa";
import { V3Text5, V3TextH1 } from "../texts";
import { V3Button } from "../button";
import { MdCancel } from "react-icons/md";

export const V3Modal = (props) => {
  const { open, type, actionClose, title, text, html, height, width, btnColor, textClose, estilo, colorIconOK } = props;
  return (
    <>
      <Backdrop open={open} style={{ zIndex: 999 }}>
        <ModalBoxStyled style={{ height: height, width: width }}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <GrClose
              onClick={() => actionClose(false)}
              style={{ cursor: "pointer" }}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 5,
              marginBottom: 10,
            }}
          >
            {type === "success" && (
              <FaCheckCircle color={colorIconOK ? colorIconOK : v3Colors.primary} size={50} />
            )}
            {type === "error" && (
              <MdCancel color={v3Colors.redAlert} size={50} />
            )}

            <V3TextH1 style={{ textAlign: "center"}}>{title}</V3TextH1>
            <V3Text5 style={{ textAlign: "center", marginTop: 10 }}>
              {text}
            </V3Text5>
            {html}
          </div>

          <V3Button
            style={{}}
            width={"100%"}
            estilo={estilo ? estilo : "primary"}
            text={textClose ? textClose : "Fechar"}
            onClick={() => actionClose(false)}
          />
        </ModalBoxStyled>
      </Backdrop>
    </>
  );
};

const ModalBoxStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 390px;
  min-height: 230px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  padding: 20px;
  justify-content: space-between;
  margin: 10px;

`;
