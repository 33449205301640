import React from "react";

import "./vantagens.css";

interface CardVantagensProps {
  icon: any;
  title: string;
  content: string;
  alt: string;
}

export const CardVantagensDesktop: React.FC<any> = ({
  icon,
  title,
  content,
  alt,
}: CardVantagensProps) => {
  return (
    <div className="container-element">
      <div className="icon-element">
        <img loading="lazy" src={icon} alt={alt}/>
      </div>
      <div className="content2">
        <div className="title-box-element">
          <h3 className="title-element">{title}</h3>
        </div>
        <div className="content-box-element">
          <p className="content-element">{content}</p>
        </div>
      </div>
    </div>
  );
};

export const CardVantagensMobile: React.FC<any> = ({
  icon,
  title,
  content,
  alt
}: CardVantagensProps) => {
  return (
    <div className="container-element">
      <div className="icon-element">
        <img loading="lazy" src={icon} alt={alt} />
      </div>
      <div className="content2">
        <div className="title-box-element">
          <h3 className="title-element">{title}</h3>
        </div>
        <div className="content-box-element-mobile">
          <p className="content-element">{content}</p>
        </div>
      </div>
    </div>
  );
};
