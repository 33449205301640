import { useState, useEffect } from "react";
import {
  BoxPinStep,
  BoxStepDesktop,
  BoxStepMobile,
  CenteredBox,
  CenteredBoxMobile,
} from "../../../styles/themes/components/box";
import {
  V3Button,
  V3RadioButton,
} from "../../../styles/themes/components/button";
import { PinStepActive } from "../../../styles/themes/components/pin";
import { V3Text5, V3TextH1 } from "../../../styles/themes/components/texts";
import { v3Colors } from "../../../styles/themes/v3/colors";
import {
  V3FileInputMobile,
  V3Select,
  V3StyledTextCustomized,
  V3TextCustom,
} from "../../../styles/themes/components/input";
import { ImInfo } from "react-icons/im";
import { V3Modal } from "../../../styles/themes/components/modal";
import {
  v3CellphoneFormater,
  v3FormataCNPJ,
  v3MaskCPF,
} from "../../../styles/themes/utils/format";
import {
  v3ValidaCpfCnpj,
  v3ValidateEmail,
} from "../../../styles/themes/utils/validate";
import { Checkbox, FormControlLabel, Radio } from "@mui/material";
import {
  validNome,
  validRazaoSocial,
  validateEmail,
} from "../../../utils/validate";
import api from "../../../services/api";
import ContratoSantander from "../../../assets/images/contrato-santander.png";
import { verifyDate } from "..";
import TagManager from 'react-gtm-module'
export const Step2Desktop = (props) => {
  const { setCurrentStep } = props;
  const [comoConheceu, setComoConheceu] = useState([]);
  const [iDComoConheceu, setIdComoConheceu] = useState(null);
  const [descComoConheceu, setDescComoConheceu] = useState("");
  const [comoConheceuErr, setComoConheceuErr] = useState(false);
  const [administradora, setAdministradora] = useState(
    localStorage.getItem("administradoraO")
  );
  const [vc, setVc] = useState("");
  const [tipoPessoa, setTipoPessoa] = useState("");
  const [possui, setPossui] = useState(null);
  const [idEtapa, setIdEtapa] = useState("");
  const [hasInfo, setHasInfo] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [hasSuccess, setHasSuccess] = useState(false);
  const [atendidas, setAtendidas] = useState([]);
  const [errorText, setErrorText] = useState("");
  const [successText, setSuccessText] = useState("");

  const [cpf, setCpf] = useState("");
  const [nomeCompleto, setNomeCompleto] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [isWhatsApp, setIsWhatsApp] = useState("");

  const [cnpj, setCnpj] = useState("");
  const [razaoSocial, setRazaoSocial] = useState("");
  const [idTipoConsorcio, setIdTipoConsorcio] = useState("");
  const [idStatusCota, setIdStatusCota] = useState("");
  const [nomeCompletoErr, setNomeCompletoErr] = useState(false);
  const [razaoSocialErr, setRazaoSocialErr] = useState(false);

  const [cpfErr, setCpfErr] = useState(false);
  const [cnpjErr, setCnpjErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [telefoneErr, setTelefoneErr] = useState(false);
  const [razaoErr, setRazaoErr] = useState("");

  const [hasErrorForm, setHasErrorForm] = useState(false);

  function fillCPF(cpf) {
    const onlyDigits = cpf?.replace(/[^\d]/g, "");
    return onlyDigits?.padStart(11, "0");
  }

  function fillCNPJ(cnpj) {
    const onlyDigits = cnpj?.replace(/[^\d]/g, "");
    return onlyDigits?.padStart(14, "0");
  }

    
    function handleResetOnboarding(state) {
      setHasError(state);
      localStorage.clear();
      window.location.reload();
    }

  async function handleNext() {
    if (!tipoPessoa) {
      setHasError(true);
      return setErrorText("Informe se o titular é pessoa física ou jurídica");
    }

    const etp = localStorage.getItem("idEtapaAtual");
    const etapa = idEtapa ? idEtapa : etp;

    const docPF = parseInt(cpf?.replace(/[^0-9]/g, ""));
    const docPJ = parseInt(cnpj?.replace(/[^0-9]/g, ""));

    if (tipoPessoa === "PF") {
      if (!nomeCompleto || nomeCompletoErr) {
        setHasError(true);
        setNomeCompletoErr(true);
        return setErrorText("Preencha o nome completo");
      }

      if (!cpf || !v3ValidaCpfCnpj(cpf) || cpfErr) {
        setHasError(true);
        setCpfErr(true);
        return setErrorText("Preencha o CPF corretamente.");
      }
      if (!email || !validateEmail(email) || emailErr) {
        setHasError(true);
        setEmailErr(true);
        return setErrorText("Preencha o e-mail corretamente.");
      }
      if (!telefone || telefoneErr) {
        setHasError(true);
        setTelefoneErr(true);
        return setErrorText("Preencha o telefone corretamente.");
      }

      await api
        .patch(
          `/api/concash/1.0/cliente-cadastro/onboarding/avancar/${vc}/${etapa}`,
          [
            {
              nome: "tipoPessoa",
              valor: "PF",
            },
            {
              nome: "nomeRazao",
              valor: nomeCompleto,
            },
            {
              nome: "cpfCnpj",
              valor: `${docPF}`,
            },
            {
              nome: "email",
              valor: email,
            },
            {
              nome: "celular",
              valor: telefone,
            },
            {
              nome: "aceitaContatoWhatsapp",
              valor: isWhatsApp ? "SIM" : "NAO",
            },
            {
              nome: "comoConheceuId",
              valor: "2",
            },
          ]
        )
        .then(({ data }) => {

          const tagManagerArgs = {
            gtmId: 'GTM-WB757J7'
          }
          
          var tipoConsorcio = ''
          if (idTipoConsorcio === "1") {
            tipoConsorcio = 'Imóvel'
          } else if (idTipoConsorcio === "2") {
            tipoConsorcio = 'Serviço'
          } else if (idTipoConsorcio === "3") {
            tipoConsorcio = 'Automóvel'
          } else {
            tipoConsorcio = 'Motocicleta'
          }
          var statusCota = ''
          if (idStatusCota === "1") {
            statusCota = 'ATIVA'
          } else {
            statusCota = 'CANCELADA'
          }
          localStorage.setItem("emailO", email);
          localStorage.setItem("telO", telefone);
          TagManager.initialize(tagManagerArgs);
          window.dataLayer.push({
            event: "solicitacao-proposta-site-2",
            administradora: localStorage.getItem("administradoraODesc"),
            tipo: tipoConsorcio,
            status: statusCota,
            email: email,
            phone: telefone
          });
          const currentAdm = localStorage.getItem("administradoraO");
          if (!atendidas.includes(currentAdm)) {
            return (window.location.href = "/nao-atendida");
          } else {
            localStorage.setItem("idEtapaAtualO", data.idEtapaAtual);
            setCurrentStep(3);
          }
        })
        .catch((data) => {
          setHasError(true);
          setErrorText(
            "Tivemos um problema ao processar seus dados, por favor, preencha os dados novamente."
          );
        });
    }

    if (tipoPessoa === "PJ") {
      if (!razaoSocial || razaoErr) {
        setHasError(true);
        setRazaoErr(true);
        return setErrorText("Preencha a Razão Social corretamente.");
      }

      if (!cnpj || !v3ValidaCpfCnpj(cnpj) || cnpjErr) {
        setHasError(true);
        setCnpjErr(true);
        return setErrorText("Preencha o CNPJ corretamente.");
      }
      if (!email || !validateEmail(email) || emailErr) {
        setHasError(true);
        setEmailErr(true);
        return setErrorText("Preencha o e-mail corretamente.");
      }
      if (!telefone || telefoneErr) {
        setHasError(true);
        setTelefoneErr(true);
        return setErrorText("Preencha o telefone corretamente.");
      }

      await api
        .patch(
          `/api/concash/1.0/cliente-cadastro/onboarding/avancar/${vc}/${etapa}`,
          [
            {
              nome: "tipoPessoa",
              valor: "PJ",
            },
            {
              nome: "nomeRazao",
              valor: razaoSocial,
            },
            {
              nome: "cpfCnpj",
              valor: `${docPJ}`,
            },
            {
              nome: "email",
              valor: email,
            },
            {
              nome: "celular",
              valor: telefone,
            },
            {
              nome: "aceitaContatoWhatsapp",
              valor: isWhatsApp ? "SIM" : "NAO",
            },
            {
              nome: "comoConheceuId",
              valor: "2",
            },
          ]
        )
        .then(({ data }) => {
          const tagManagerArgs = {
            gtmId: 'GTM-WB757J7'
          }
          var idTipoConsorcio=localStorage.getItem("tipoConsorcioO")
          var tipoConsorcio = ''
          if (idTipoConsorcio === "1") {
            tipoConsorcio = 'Imóvel'
          } else if (idTipoConsorcio === "2") {
            tipoConsorcio = 'Serviço'
          } else if (idTipoConsorcio === "3") {
            tipoConsorcio = 'Automóvel'
          } else {
            tipoConsorcio = 'Motocicleta'
          }
          var  idStatusCota =  localStorage.getItem("statusCotaO");
          var statusCota = ''
          if (idStatusCota === "1") {
            statusCota = 'ATIVA'
          } else {
            statusCota = 'CANCELADA'
          }
          TagManager.initialize(tagManagerArgs);
          window.dataLayer.push({
            event: "solicitacao-proposta-site-2",
            administradora: localStorage.getItem("administradoraODesc"),
            tipo: tipoConsorcio,
            status: statusCota,
            email: localStorage.getItem("emailO"),
            phone: localStorage.getItem("telO")
          });
          const currentAdm = localStorage.getItem("administradoraO");
          if (!atendidas.includes(currentAdm)) {
            return (window.location.href = "/nao-atendida");
          } else {
            localStorage.setItem("idEtapaAtual", data.idEtapaAtual);
            setCurrentStep(3);
          }
        })
        .catch((data) => {
          setHasError(true);
          setErrorText(
            "Tivemos um problema ao processar seus dados, por favor, preencha os dados novamente."
          );
        });
    }
  }

  function handlePrev() {
    setCurrentStep(1);
  }



  function handleRazaoSocial(t) {
    const valid = validRazaoSocial(t);
    if ((razaoSocial && valid) || razaoSocial === "") {
      setRazaoSocial(t);
      setRazaoErr(false);
    } else {
      setRazaoSocial(t);
      setRazaoErr(true);
    }
  }

  function handleCpf(text) {
    const validating = text.replace(/[^\d]/g, "");
    if (validating.length <= 11) {
      const cpfs = v3MaskCPF(validating);
      setCpf(cpfs);

      if (v3ValidaCpfCnpj(cpfs) || text === "") {
        setCpfErr(false);
      } else {
        setCpfErr(true);
      }
    }
  }

  function handleCnpj(text) {
    const validating = text.replace(/[^\d]/g, "");
    if (validating.length <= 14) {
      const cn = v3FormataCNPJ(validating);
      setCnpj(cn);

      if (v3ValidaCpfCnpj(cn) || text === "") {
        setCnpjErr(false);
      } else {
        setCnpjErr(true);
      }
    }
  }

  function handleEmail(t) {
    const text = t?.replaceAll(" ", "");
    if (v3ValidateEmail(text) || text === "") {
      setEmail(text);
      setEmailErr(false);
    } else {
      setEmail(text);
      setEmailErr(true);
    }
  }

  function handleNomeCompleto(text) {
    const valid = validNome(text);

    if (!text || !valid) {
      setNomeCompleto(text);
      setNomeCompletoErr(true);
    } else {
      setNomeCompleto(text);
      setNomeCompletoErr(false);
    }
  }

  function handleTelefone(t) {
    const validatePhone = t.replace(/[^0-9]/g, "").split("");
    if (t) {
      setTelefoneErr(false);
      if (t.length > 15) {
        setTelefone(telefone);
      } else {
        setTelefone(v3CellphoneFormater(t));
      }
    }
    if (!t || t == "" || t == "(") {
      setTelefoneErr(true);
      setTelefone(t);
    }

    if (t.length < 14 || validatePhone[0] === "0") {
      setTelefoneErr(true);
    } else {
      setTelefoneErr(false);
    }
  }

  async function recuperarEtapas() {
    const vcObj = JSON.parse(localStorage.getItem("vc"));

    if (!vcObj) {
      const on = localStorage.getItem("vc");
      const etp = await api.get(
        `/api/concash/1.0/cliente-cadastro/onboarding/recuperar/${on}`
      );
      localStorage.setItem("etapaAtualO", `2`);
      localStorage.setItem("vcObj", `${JSON.stringify(etp.data)}`);
      localStorage.setItem("etapasO", `${JSON.stringify(etp.data.etapas)}`);

      const tipoR = await etp.data.etapas[1].campos.find(
        (i) => i.nome === "tipoPessoa"
      ).valor;
      setTipoPessoa(tipoR);
      const nomeRazaoR = await etp.data.etapas[1].campos.find(
        (i) => i.nome === "nomeRazao"
      ).valor;
      const cpfCnpjR = await etp.data.etapas[1].campos.find(
        (i) => i.nome === "cpfCnpj"
      ).valor;
      const emailR = await etp.data.etapas[1].campos.find(
        (i) => i.nome === "email"
      ).valor;
      const celularR = await etp.data.etapas[1].campos.find(
        (i) => i.nome === "celular"
      ).valor;
      const whatsappR = await etp.data.etapas[1].campos.find(
        (i) => i.nome === "aceitaContatoWhatsapp"
      ).valor;

      if (tipoR === "PF") {
        setNomeCompleto(nomeRazaoR);
        setCpf(cpfCnpjR && v3MaskCPF(fillCPF(await cpfCnpjR)));
        setEmail(emailR);
        setTelefone(celularR);
        setIsWhatsApp(whatsappR === "SIM" ? true : false);
      } else {
        setRazaoSocial(nomeRazaoR);
        setCnpj(cpfCnpjR && v3FormataCNPJ(fillCNPJ(await cpfCnpjR)));
        setEmail(emailR);
        setTelefone(celularR);
        setIsWhatsApp(whatsappR === "SIM" ? true : false);
      }
    }

    const tipoR = await vcObj.etapas[1].campos.find(
      (i) => i.nome === "tipoPessoa"
    ).valor;
    setTipoPessoa(tipoR);
    const nomeRazaoR = await vcObj.etapas[1].campos.find(
      (i) => i.nome === "nomeRazao"
    ).valor;
    const cpfCnpjR = await vcObj.etapas[1].campos.find(
      (i) => i.nome === "cpfCnpj"
    ).valor;
    const emailR = await vcObj.etapas[1].campos.find((i) => i.nome === "email")
      .valor;
    const celularR = await vcObj.etapas[1].campos.find(
      (i) => i.nome === "celular"
    ).valor;
    const whatsappR = await vcObj.etapas[1].campos.find(
      (i) => i.nome === "aceitaContatoWhatsapp"
    ).valor;

    if (tipoR === "PF") {
      setNomeCompleto(nomeRazaoR);
      setCpf(cpfCnpjR && v3MaskCPF(fillCPF(await cpfCnpjR)));
      setEmail(emailR);
      setTelefone(celularR);
      setIsWhatsApp(whatsappR === "SIM" ? true : false);
    } else {
      setRazaoSocial(nomeRazaoR);
      setCnpj(cpfCnpjR && v3FormataCNPJ(fillCNPJ(await cpfCnpjR)));
      setEmail(emailR);
      setTelefone(celularR);
      setIsWhatsApp(whatsappR === "SIM" ? true : false);
    }
  }

  async function persistData() {
    const on = localStorage.getItem("vc");
    const etp = await api.get(
      `/api/concash/1.0/cliente-cadastro/onboarding/recuperar/${on}`
    );

    const tipoR = await etp.data.etapas[1].campos.find(
      (i) => i.nome === "tipoPessoa"
    ).valor;
    setTipoPessoa(tipoR);
    const nomeRazaoR = await etp.data.etapas[1].campos.find(
      (i) => i.nome === "nomeRazao"
    ).valor;
    const cpfCnpjR = await etp.data.etapas[1].campos.find(
      (i) => i.nome === "cpfCnpj"
    ).valor;
    const emailR = await etp.data.etapas[1].campos.find(
      (i) => i.nome === "email"
    ).valor;
    const celularR = await etp.data.etapas[1].campos.find(
      (i) => i.nome === "celular"
    ).valor;
    const whatsappR = await etp.data.etapas[1].campos.find(
      (i) => i.nome === "aceitaContatoWhatsapp"
    ).valor;

    const dt = cpfCnpjR && v3MaskCPF(fillCPF(cpfCnpjR && (await cpfCnpjR)));
    if (tipoR === "PF") {
      setNomeCompleto(nomeRazaoR);
      setCpf(cpfCnpjR && v3MaskCPF(fillCPF(await cpfCnpjR)));
      setEmail(emailR);
      setTelefone(celularR);
      setIsWhatsApp(whatsappR === "SIM" ? true : false);
    } else {
      setRazaoSocial(nomeRazaoR);
      setCnpj(cpfCnpjR && v3FormataCNPJ(fillCNPJ(await cpfCnpjR)));
      setEmail(emailR);
      setTelefone(celularR);
      setIsWhatsApp(whatsappR === "SIM" ? true : false);
    }
  }

  useEffect(() => {
    persistData();
  }, [setCurrentStep]);

  async function handleGetAtendidas() {
    const { data } = await api
      .get("/api/administradoras/1.0/listar?parceiros=sim")
      .catch(() => {
        setHasError(true);
        setErrorText(
          "Houve um erro ao carregar a lista de parceiros atendidos."
        );
      });
    const ids = data.map((d) => {
      return `${d.id}`;
    });

    setAtendidas(ids);
  }

  useEffect(() => {
    verifyDate();
    handleGetAtendidas();
    localStorage.setItem("etapaAtualO", 2);
    setAdministradora(localStorage.getItem("administradoraO"));
    const vcObj = JSON.parse(localStorage.getItem("vcObj"));
    setIdEtapa(vcObj.etapas[1].id);
    setVc(vcObj.id);

    if (!vcObj) {
      recuperarEtapas();
    }
    const tipoC = localStorage.getItem("tipoConsorcioO");
    setIdTipoConsorcio(tipoC)
    const statusC = localStorage.getItem("statusCotaO");
    setIdStatusCota(statusC)
  }, []);

  return (
    <>
      <BoxStepDesktop style={{ minHeight: 450 }}>
        <BoxPinStep>
          <PinStepActive num={1} type="checked" />
          <PinStepActive num={2} type="active" />
          <PinStepActive num={3} type="deactive" />
          <PinStepActive num={4} type="deactive" />
        </BoxPinStep>
        <CenteredBox>
          <V3TextH1>Quem é o titular?</V3TextH1>
        </CenteredBox>

        <CenteredBox style={{ gap: 10, marginTop: 15 }}>
          <V3RadioButton
            text="Pessoa Física"
            selected={tipoPessoa === "PF"}
            onClick={() => {
              setTipoPessoa("PF");
            }}
          />
          <V3RadioButton
            text="Pessoa Jurídica"
            selected={tipoPessoa === "PJ"}
            onClick={() => {
              setTipoPessoa("PJ");
            }}
          />
        </CenteredBox>
        {tipoPessoa === "PF" && (
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              gap: 10,
              paddingLeft: 100,
              marginTop: 20,
            }}
          >
            <V3TextCustom
              sx={{ width: 447 }}
              size="small"
              onChange={(e) => {
                handleNomeCompleto(e.target.value);
              }}
              label="Nome Completo"
              error={nomeCompletoErr}
              helperText={nomeCompletoErr && "Preencha o seu nome completo."}
              required
              value={nomeCompleto}
            />
            <V3TextCustom
              id="cpf"
              sx={{ width: 447 }}
              label="CPF"
              onChange={(e) => {
                handleCpf(e.target.value);
              }}
              required
              size="small"
              error={cpfErr}
              helperText={cpfErr && "CPF inválido."}
              value={cpf}
            />

            <V3TextCustom
              sx={{ width: 447 }}
              label="E-mail"
              onChange={(e) => {
                handleEmail(e.target.value);
              }}
              error={emailErr}
              size="small"
              helperText={emailErr && "E-mail inválido."}
              value={email}
              required
            />
            <V3TextCustom
              size="small"
              sx={{ width: 447 }}
              onChange={(e) => {
                handleTelefone(e.target.value);
              }}
              error={telefoneErr}
              value={telefone}
              label="Telefone"
              helperText={
                telefoneErr &&
                "Telefone deve ser no formato(11) 1234-5678 ou (11) 98765-4321."
              }
              required
            />

            <FormControlLabel
              value="sim"
              control={
                <Checkbox
                  checked={isWhatsApp}
                  onChange={() => setIsWhatsApp(!isWhatsApp)}
                  value={0}
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                  sx={{
                    color: v3Colors.primary,
                    "&.Mui-checked": {
                      color: v3Colors.primary,
                    },
                  }}
                />
              }
              label="Este telefone é WhatsApp, aceito receber contato."
            />
          </div>
        )}
        {tipoPessoa === "PJ" && (
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              gap: 10,
              paddingLeft: 100,
              marginTop: 20,
            }}
          >
            <V3TextCustom
              size="small"
              sx={{ width: 447 }}
              onChange={(e) => {
                handleRazaoSocial(e.target.value);
              }}
              label="Razão Social"
              value={razaoSocial}
              error={razaoErr}
              helperText={razaoErr && "Razão Social inválida."}
              required
            />
            <V3TextCustom
              size="small"
              sx={{ width: 447 }}
              label="CNPJ"
              onChange={(e) => {
                handleCnpj(e.target.value);
              }}
              error={cnpjErr}
              value={cnpj}
              helperText={cnpjErr && "CNPJ inválido."}
              required
            />
            <V3TextCustom
              sx={{ width: 447 }}
              label="E-mail"
              onChange={(e) => {
                handleEmail(e.target.value);
              }}
              error={emailErr}
              size="small"
              value={email}
              helperText={emailErr && "E-mail inválido."}
              required
            />
            <V3TextCustom
              size="small"
              sx={{ width: 447 }}
              onChange={(e) => {
                handleTelefone(e.target.value);
              }}
              error={telefoneErr}
              value={telefone}
              label="Telefone"
              helperText={
                telefoneErr &&
                "Telefone deve ser no formato(11) 1234-5678 ou (11) 98765-4321."
              }
              required
            />

            <FormControlLabel
              value="sim"
              control={
                <Checkbox
                  checked={isWhatsApp}
                  onChange={() => setIsWhatsApp(!isWhatsApp)}
                  value={0}
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                  sx={{
                    color: v3Colors.primary,
                    "&.Mui-checked": {
                      color: v3Colors.primary,
                    },
                  }}
                />
              }
              label="Este telefone é WhatsApp, aceito receber contato."
            />
          </div>
        )}

        <CenteredBox
          style={{
            marginTop: 15,
            padding: "0px 100px",
            justifyContent: "flex-start",
          }}
        >
          <V3Text5 style={{ color: v3Colors.black, fontStyle: "italic" }}>
            *Preenchimento Obrigatório
          </V3Text5>
        </CenteredBox>

        <V3Modal
          open={hasInfo}
          actionClose={setHasInfo}
          title={"Informação sobre seu consórcio"}
          html={
            <V3Text5 style={{ textAlign: "center" }}>
              Não possui as informações do consórcio? <br />
              <a href="/">Clique aqui</a> e verifique como resgatar o seu
              extrato.
            </V3Text5>
          }
        />
        <V3Modal
          open={hasError}
          actionClose={setHasError}
          title={"Atenção!"}
          text={errorText}
          type="error"
        />
        <V3Modal
          open={hasErrorForm}
          actionClose={handleResetOnboarding}
          title={"Atenção!"}
          text={errorText}
          type="error"
        />
        <V3Modal
          open={hasSuccess}
          actionClose={setHasSuccess}
          title={"Sucesso!"}
          text={successText}
          type="success"
        />
        <CenteredBox style={{ gap: 20, marginTop: 30 }}>
          <V3Button
            onClick={handleNext}
            style={{ marginBottom: 30 }}
            width={315}
            height={60}
            text={"AVANÇAR"}
            estilo="primary"
          />
        </CenteredBox>
        <CenteredBox style={{ gap: 20, marginTop: 15 }}>
          <V3Text5
            style={{
              cursor: "pointer",
              textDecoration: "underline",
              color: "#000",
              fontWeight: "bold",
            }}
            onClick={handlePrev}
          >
            Voltar
          </V3Text5>
        </CenteredBox>
      </BoxStepDesktop>
    </>
  );
};
export const Step2Mobile = (props) => {
  const { setCurrentStep } = props;

  const [comoConheceu, setComoConheceu] = useState([]);
  const [iDComoConheceu, setIdComoConheceu] = useState(null);
  const [descComoConheceu, setDescComoConheceu] = useState("");
  const [comoConheceuErr, setComoConheceuErr] = useState(false);
  const [atendidas, setAtendidas] = useState([]);

  const [administradora, setAdministradora] = useState(
    localStorage.getItem("administradoraO")
  );
  const [vc, setVc] = useState("");
  const [tipoPessoa, setTipoPessoa] = useState("");
  const [possui, setPossui] = useState(null);
  const [idEtapa, setIdEtapa] = useState("");
  const [hasInfo, setHasInfo] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [hasSuccess, setHasSuccess] = useState(false);

  const [errorText, setErrorText] = useState("");
  const [successText, setSuccessText] = useState("");

  const [cpf, setCpf] = useState("");
  const [nomeCompleto, setNomeCompleto] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [isWhatsApp, setIsWhatsApp] = useState("");

  const [cnpj, setCnpj] = useState("");
  const [razaoSocial, setRazaoSocial] = useState("");

  const [nomeCompletoErr, setNomeCompletoErr] = useState(false);
  const [razaoSocialErr, setRazaoSocialErr] = useState(false);

  const [cpfErr, setCpfErr] = useState(false);
  const [cnpjErr, setCnpjErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [telefoneErr, setTelefoneErr] = useState(false);
  const [razaoErr, setRazaoErr] = useState("");

  const [hasErrorForm, setHasErrorForm] = useState(false);
  const [idTipoConsorcio, setIdTipoConsorcio] = useState("");
  function fillCPF(cpf) {
    const onlyDigits = cpf?.replace(/[^\d]/g, "");
    return onlyDigits?.padStart(11, "0");
  }

  function fillCNPJ(cnpj) {
    const onlyDigits = cnpj?.replace(/[^\d]/g, "");
    return onlyDigits?.padStart(14, "0");
  }

  
  function handleResetOnboarding(state) {
    setHasError(state);
    localStorage.clear();
    window.location.reload();
  }

  async function handleNext() {
    if (!tipoPessoa) {
      setHasError(true);
      return setErrorText("Informe se o titular é pessoa física ou jurídica");
    }

    const etp = localStorage.getItem("idEtapaAtual");
    const etapa = idEtapa ? idEtapa : etp;

    const docPF = parseInt(cpf?.replace(/[^0-9]/g, ""));
    const docPJ = parseInt(cnpj?.replace(/[^0-9]/g, ""));

    if (tipoPessoa === "PF") {
      if (!nomeCompleto || nomeCompletoErr) {
        setHasError(true);
        setNomeCompletoErr(true);
        return setErrorText("Preencha o nome completo");
      }

      if (!cpf || !v3ValidaCpfCnpj(cpf) || cpfErr) {
        setHasError(true);
        setCpfErr(true);
        return setErrorText("Preencha o CPF corretamente.");
      }
      if (!email || !validateEmail(email) || emailErr) {
        setHasError(true);
        setEmailErr(true);
        return setErrorText("Preencha o e-mail corretamente.");
      }
      if (!telefone || telefoneErr) {
        setHasError(true);
        setTelefoneErr(true);
        return setErrorText("Preencha o telefone corretamente.");
      }

      await api
        .patch(
          `/api/concash/1.0/cliente-cadastro/onboarding/avancar/${vc}/${etapa}`,
          [
            {
              nome: "tipoPessoa",
              valor: "PF",
            },
            {
              nome: "nomeRazao",
              valor: nomeCompleto,
            },
            {
              nome: "cpfCnpj",
              valor: `${docPF}`,
            },
            {
              nome: "email",
              valor: email,
            },
            {
              nome: "celular",
              valor: telefone,
            },
            {
              nome: "aceitaContatoWhatsapp",
              valor: isWhatsApp ? "SIM" : "NAO",
            },
            {
              nome: "comoConheceuId",
              valor: "2",
            },
          ]
        )
        .then(({ data }) => {
          localStorage.setItem("emailO", email);
          localStorage.setItem("telO", telefone);
          const tagManagerArgs = {
            gtmId: 'GTM-WB757J7'
          }
          var idTipoConsorcio=localStorage.getItem("tipoConsorcioO")
          var tipoConsorcio = ''
          if (idTipoConsorcio === "1") {
            tipoConsorcio = 'Imóvel'
          } else if (idTipoConsorcio === "2") {
            tipoConsorcio = 'Serviço'
          } else if (idTipoConsorcio === "3") {
            tipoConsorcio = 'Automóvel'
          } else {
            tipoConsorcio = 'Motocicleta'
          }
          var  idStatusCota =  localStorage.getItem("statusCotaO");
          var statusCota = ''
          if (idStatusCota === "1") {
            statusCota = 'ATIVA'
          } else {
            statusCota = 'CANCELADA'
          }
          TagManager.initialize(tagManagerArgs);
          window.dataLayer.push({
            event: "solicitacao-proposta-site-2",
            administradora: localStorage.getItem("administradoraODesc"),
            tipo: tipoConsorcio,
            status: statusCota,
            email: email,
            phone: telefone
          });
          const currentAdm = localStorage.getItem("administradoraO");
          if (!atendidas.includes(currentAdm)) {
            return (window.location.href = "/nao-atendida");
          } else {
            localStorage.setItem("idEtapaAtual", data.idEtapaAtual);
            setCurrentStep(3);
          }
        })
        .catch((data) => {
          setHasError(true);
          setErrorText(
            "Tivemos um problema ao processar seus dados, por favor, preencha os dados novamente."
          );
        });
    }

    if (tipoPessoa === "PJ") {
      if (!razaoSocial || razaoErr) {
        setHasError(true);
        setRazaoErr(true);
        return setErrorText("Preencha a Razão Social corretamente.");
      }

      if (!cnpj || !v3ValidaCpfCnpj(cnpj) || cnpjErr) {
        setHasError(true);
        setCnpjErr(true);
        return setErrorText("Preencha o CNPJ corretamente.");
      }
      if (!email || !validateEmail(email) || emailErr) {
        setHasError(true);
        setEmailErr(true);
        return setErrorText("Preencha o e-mail corretamente.");
      }
      if (!telefone || telefoneErr) {
        setHasError(true);
        setTelefoneErr(true);
        return setErrorText("Preencha o telefone corretamente.");
      }
      await api
        .patch(
          `/api/concash/1.0/cliente-cadastro/onboarding/avancar/${vc}/${etapa}`,
          [
            {
              nome: "tipoPessoa",
              valor: "PJ",
            },
            {
              nome: "nomeRazao",
              valor: razaoSocial,
            },
            {
              nome: "cpfCnpj",
              valor: `${docPJ}`,
            },
            {
              nome: "email",
              valor: email,
            },
            {
              nome: "celular",
              valor: telefone,
            },
            {
              nome: "aceitaContatoWhatsapp",
              valor: isWhatsApp ? "SIM" : "NAO",
            },
            {
              nome: "comoConheceuId",
              valor: "2",
            },
          ]
        )
        .then(({ data }) => {
          localStorage.setItem("emailO", email);
          localStorage.setItem("telO", telefone);
          const currentAdm = localStorage.getItem("administradoraO");
          if (!atendidas.includes(currentAdm)) {
            return (window.location.href = "/nao-atendida");
          } else {
            localStorage.setItem("idEtapaAtualO", data.idEtapaAtual);
            setCurrentStep(3);
          }
        })
        .catch((data) => {
          setHasError(true);
          setErrorText(
            "Tivemos um problema ao processar seus dados, por favor, preencha os dados novamente."
          );
        });
    }
  }

  function handlePrev() {
    setCurrentStep(1);
  }


  function handleRazaoSocial(t) {
    const valid = validRazaoSocial(t);
    if ((razaoSocial && valid) || razaoSocial === "") {
      setRazaoSocial(t);
      setRazaoErr(false);
    } else {
      setRazaoSocial(t);
      setRazaoErr(true);
    }
  }

  function handleCpf(text) {
    const validating = text.replace(/[^\d]/g, "");
    if (validating.length <= 11) {
      const cpfs = v3MaskCPF(validating);
      setCpf(cpfs);

      if (v3ValidaCpfCnpj(cpfs) || text === "") {
        setCpfErr(false);
      } else {
        setCpfErr(true);
      }
    }
  }

  function handleCnpj(text) {
    const validating = text.replace(/[^\d]/g, "");
    if (validating.length <= 14) {
      const cn = v3FormataCNPJ(validating);
      setCnpj(cn);

      if (v3ValidaCpfCnpj(cn) || text === "") {
        setCnpjErr(false);
      } else {
        setCnpjErr(true);
      }
    }
  }

  function handleEmail(t) {
    const text = t?.replaceAll(" ", "");
    if (v3ValidateEmail(text) || text === "") {
      setEmail(text);
      setEmailErr(false);
    } else {
      setEmail(text);
      setEmailErr(true);
    }
  }

  function handleNomeCompleto(text) {
    const valid = validNome(text);

    if (!text || !valid) {
      setNomeCompleto(text);
      setNomeCompletoErr(true);
    } else {
      setNomeCompleto(text);
      setNomeCompletoErr(false);
    }
  }

  function handleTelefone(t) {
    const validatePhone = t.replace(/[^0-9]/g, "").split("");
    if (t) {
      setTelefoneErr(false);
      if (t.length > 15) {
        setTelefone(telefone);
      } else {
        setTelefone(v3CellphoneFormater(t));
      }
    }
    if (!t || t == "" || t == "(") {
      setTelefoneErr(true);
      setTelefone(t);
    }

    if (t.length < 14 || validatePhone[0] === "0") {
      setTelefoneErr(true);
    } else {
      setTelefoneErr(false);
    }
  }

  async function recuperarEtapas() {
    const vcObj = JSON.parse(localStorage.getItem("vc"));

    if (!vcObj) {
      const on = localStorage.getItem("vc");
      const etp = await api.get(
        `/api/concash/1.0/cliente-cadastro/onboarding/recuperar/${on}`
      );
      localStorage.setItem("etapaAtualO", `2`);
      localStorage.setItem("vcObj", `${JSON.stringify(etp.data)}`);
      localStorage.setItem("etapasO", `${JSON.stringify(etp.data.etapas)}`);

      const tipoR = await etp.data.etapas[1].campos.find(
        (i) => i.nome === "tipoPessoa"
      ).valor;
      setTipoPessoa(tipoR);
      const nomeRazaoR = await etp.data.etapas[1].campos.find(
        (i) => i.nome === "nomeRazao"
      ).valor;
      const cpfCnpjR = await etp.data.etapas[1].campos.find(
        (i) => i.nome === "cpfCnpj"
      ).valor;
      const emailR = await etp.data.etapas[1].campos.find(
        (i) => i.nome === "email"
      ).valor;
      const celularR = await etp.data.etapas[1].campos.find(
        (i) => i.nome === "celular"
      ).valor;
      const whatsappR = await etp.data.etapas[1].campos.find(
        (i) => i.nome === "aceitaContatoWhatsapp"
      ).valor;

      if (tipoR === "PF") {
        setNomeCompleto(nomeRazaoR);
        setCpf(cpfCnpjR && v3MaskCPF(fillCPF(await cpfCnpjR)));
        setEmail(emailR);
        setTelefone(celularR);
        setIsWhatsApp(whatsappR === "SIM" ? true : false);
      } else {
        setRazaoSocial(nomeRazaoR);
        setCnpj(cpfCnpjR && v3FormataCNPJ(fillCNPJ(await cpfCnpjR)));
        setEmail(emailR);
        setTelefone(celularR);
        setIsWhatsApp(whatsappR === "SIM" ? true : false);
      }
    }

    const tipoR = await vcObj.etapas[1].campos.find(
      (i) => i.nome === "tipoPessoa"
    ).valor;
    setTipoPessoa(tipoR);
    const nomeRazaoR = await vcObj.etapas[1].campos.find(
      (i) => i.nome === "nomeRazao"
    ).valor;
    const cpfCnpjR = await vcObj.etapas[1].campos.find(
      (i) => i.nome === "cpfCnpj"
    ).valor;
    const emailR = await vcObj.etapas[1].campos.find((i) => i.nome === "email")
      .valor;
    const celularR = await vcObj.etapas[1].campos.find(
      (i) => i.nome === "celular"
    ).valor;
    const whatsappR = await vcObj.etapas[1].campos.find(
      (i) => i.nome === "aceitaContatoWhatsapp"
    ).valor;

    if (tipoR === "PF") {
      setNomeCompleto(nomeRazaoR);
      setCpf(cpfCnpjR && v3MaskCPF(fillCPF(await cpfCnpjR)));
      setEmail(emailR);
      setTelefone(celularR);
      setIsWhatsApp(whatsappR === "SIM" ? true : false);
    } else {
      setRazaoSocial(nomeRazaoR);
      setCnpj(cpfCnpjR && v3FormataCNPJ(fillCNPJ(await cpfCnpjR)));
      setEmail(emailR);
      setTelefone(celularR);
      setIsWhatsApp(whatsappR === "SIM" ? true : false);
    }
  }

  async function persistData() {
    const on = localStorage.getItem("vc");
    const etp = await api.get(
      `/api/concash/1.0/cliente-cadastro/onboarding/recuperar/${on}`
    );

    const tipoR = await etp.data.etapas[1].campos.find(
      (i) => i.nome === "tipoPessoa"
    ).valor;
    setTipoPessoa(tipoR);
    const nomeRazaoR = await etp.data.etapas[1].campos.find(
      (i) => i.nome === "nomeRazao"
    ).valor;
    const cpfCnpjR = await etp.data.etapas[1].campos.find(
      (i) => i.nome === "cpfCnpj"
    ).valor;
    const emailR = await etp.data.etapas[1].campos.find(
      (i) => i.nome === "email"
    ).valor;
    const celularR = await etp.data.etapas[1].campos.find(
      (i) => i.nome === "celular"
    ).valor;
    const whatsappR = await etp.data.etapas[1].campos.find(
      (i) => i.nome === "aceitaContatoWhatsapp"
    ).valor;

    const dt = cpfCnpjR && v3MaskCPF(fillCPF(cpfCnpjR && (await cpfCnpjR)));
    if (tipoR === "PF") {
      setNomeCompleto(nomeRazaoR);
      setCpf(cpfCnpjR && v3MaskCPF(fillCPF(await cpfCnpjR)));
      setEmail(emailR);
      setTelefone(celularR);
      setIsWhatsApp(whatsappR === "SIM" ? true : false);
    } else {
      setRazaoSocial(nomeRazaoR);
      setCnpj(cpfCnpjR && v3FormataCNPJ(fillCNPJ(await cpfCnpjR)));
      setEmail(emailR);
      setTelefone(celularR);
      setIsWhatsApp(whatsappR === "SIM" ? true : false);
    }
  }

  useEffect(() => {
    persistData();
  }, [setCurrentStep]);

  async function handleGetAtendidas() {
    const { data } = await api
      .get("/api/administradoras/1.0/listar?parceiros=sim")
      .catch(() => {
        setHasError(true);
        setErrorText(
          "Houve um erro ao carregar a lista de parceiros atendidos."
        );
      });
    const ids = data.map((d) => {
      return `${d.id}`;
    });

    setAtendidas(ids);
  }

  useEffect(() => {
    verifyDate();
    handleGetAtendidas();
    localStorage.setItem("etapaAtualO", 2);
    setAdministradora(localStorage.getItem("administradoraO"));
    const vcObj = JSON.parse(localStorage.getItem("vcObj"));
    setIdEtapa(vcObj.etapas[1].id);
    setVc(vcObj.id);

    if (!vcObj) {
      recuperarEtapas();
    }
    const tipoC = localStorage.getItem("tipoConsorcioO");
  }, []);

  return (
    <>
      <BoxStepMobile>
        <BoxPinStep>
          <PinStepActive num={1} type="checked" />
          <PinStepActive num={2} type="active" />
          <PinStepActive num={3} type="deactive" />
          <PinStepActive num={4} type="deactive" />
        </BoxPinStep>
        <CenteredBoxMobile>
          <V3TextH1>Quem é o titular?</V3TextH1>
        </CenteredBoxMobile>

        <CenteredBoxMobile style={{ gap: 10, marginTop: 15 }}>
          <V3RadioButton
            text="Pessoa Física"
            selected={tipoPessoa === "PF"}
            onClick={() => {
              setTipoPessoa("PF");
            }}
          />
          <V3RadioButton
            text="Pessoa Jurídica"
            selected={tipoPessoa === "PJ"}
            onClick={() => {
              setTipoPessoa("PJ");
            }}
          />
        </CenteredBoxMobile>
        {tipoPessoa === "PF" && (
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              gap: 10,
              paddingLeft: 15,
              paddingRight: 15,
              marginTop: 20,
            }}
          >
            <V3TextCustom
              sx={{ width: "100%" }}
              size="small"
              onChange={(e) => {
                handleNomeCompleto(e.target.value);
              }}
              label="Nome Completo"
              error={nomeCompletoErr}
              helperText={nomeCompletoErr && "Preencha o seu nome completo."}
              required
              value={nomeCompleto}
            />
            <V3TextCustom
              id="cpf"
              sx={{ width: "100%" }}
              label="CPF"
              onChange={(e) => {
                handleCpf(e.target.value);
              }}
              required
              size="small"
              error={cpfErr}
              helperText={cpfErr && "CPF inválido."}
              value={cpf}
            />

            <V3TextCustom
              sx={{ width: "100%" }}
              label="E-mail"
              onChange={(e) => {
                handleEmail(e.target.value);
              }}
              error={emailErr}
              size="small"
              helperText={emailErr && "E-mail inválido."}
              value={email}
              type="email"
              required
            />
            <V3TextCustom
              size="small"
              sx={{ width: "100%" }}
              onChange={(e) => {
                handleTelefone(e.target.value);
              }}
              error={telefoneErr}
              value={telefone}
              label="Telefone"
              helperText={
                telefoneErr &&
                "Telefone deve ser no formato(11) 1234-5678 ou (11) 98765-4321."
              }
              required
            />

            <FormControlLabel
              value="sim"
              control={
                <Checkbox
                  checked={isWhatsApp}
                  onChange={() => setIsWhatsApp(!isWhatsApp)}
                  value={0}
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                  sx={{
                    color: v3Colors.primary,
                    "&.Mui-checked": {
                      color: v3Colors.primary,
                    },
                  }}
                />
              }
              label="Este telefone é WhatsApp, aceito receber contato."
            />
          </div>
        )}
        {tipoPessoa === "PJ" && (
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              gap: 10,
              paddingLeft: 15,
              paddingRight: 15,
              marginTop: 20,
            }}
          >
            <V3TextCustom
              size="small"
              sx={{ width: "100%" }}
              onChange={(e) => {
                handleRazaoSocial(e.target.value);
              }}
              label="Razão Social"
              value={razaoSocial}
              error={razaoErr}
              helperText={razaoErr && "Razão Social inválida."}
              required
            />
            <V3TextCustom
              size="small"
              sx={{ width: "100%" }}
              label="CNPJ"
              onChange={(e) => {
                handleCnpj(e.target.value);
              }}
              error={cnpjErr}
              value={cnpj}
              helperText={cnpjErr && "CNPJ inválido."}
              required
            />
            <V3TextCustom
              sx={{ width: "100%" }}
              label="E-mail"
              onChange={(e) => {
                handleEmail(e.target.value);
              }}
              error={emailErr}
              size="small"
              value={email}
              helperText={emailErr && "E-mail inválido."}
              required
            />
            <V3TextCustom
              size="small"
              sx={{ width: "100%" }}
              onChange={(e) => {
                handleTelefone(e.target.value);
              }}
              error={telefoneErr}
              value={telefone}
              label="Telefone"
              helperText={
                telefoneErr &&
                "Telefone deve ser no formato(11) 1234-5678 ou (11) 98765-4321."
              }
              required
            />

            <FormControlLabel
              value="sim"
              control={
                <Checkbox
                  checked={isWhatsApp}
                  onChange={() => setIsWhatsApp(!isWhatsApp)}
                  value={0}
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                  sx={{
                    color: v3Colors.primary,
                    "&.Mui-checked": {
                      color: v3Colors.primary,
                    },
                  }}
                />
              }
              label="Este telefone é WhatsApp, aceito receber contato."
            />
          </div>
        )}

        <CenteredBoxMobile
          style={{
            marginTop: 15,
            padding: "0px 10px",
            justifyContent: "flex-start",
          }}
        >
          <V3Text5 style={{ color: v3Colors.black, fontStyle: "italic" }}>
            *Preenchimento Obrigatório
          </V3Text5>
        </CenteredBoxMobile>

        <V3Modal
          open={hasInfo}
          actionClose={setHasInfo}
          title={"Informação sobre seu consórcio"}
          html={
            <V3Text5 style={{ textAlign: "center" }}>
              Não possui as informações do consórcio? <br />
              <a href="/">Clique aqui</a> e verifique como resgatar o seu
              extrato.
            </V3Text5>
          }
        />

        <V3Modal
          open={hasInfo}
          actionClose={setHasInfo}
          title={"Informação sobre seu consórcio"}
          html={
            <V3Text5 style={{ textAlign: "center" }}>
              Não possui as informações do consórcio? <br />
              <a href="/">Clique aqui</a> e verifique como resgatar o seu
              extrato.
            </V3Text5>
          }
        />
        <V3Modal
          open={hasError}
          actionClose={setHasError}
          title={"Atenção!"}
          text={errorText}
          type="error"
        />
        <V3Modal
          open={hasErrorForm}
          actionClose={handleResetOnboarding}
          title={"Atenção!"}
          text={errorText}
          type="error"
        />
        <V3Modal
          open={hasSuccess}
          actionClose={setHasSuccess}
          title={"Sucesso!"}
          text={successText}
          type="success"
        />

        <CenteredBox style={{ gap: 20, marginTop: 30 }}>
          <V3Button
            onClick={handleNext}
            style={{ marginBottom: 30 }}
            width={315}
            height={60}
            text={"AVANÇAR"}
            estilo="primary"
          />
        </CenteredBox>
        <CenteredBox style={{ gap: 20, marginTop: 15, marginBottom: 20 }}>
          <V3Text5
            style={{
              cursor: "pointer",
              textDecoration: "underline",
              color: "#000",
              fontWeight: "bold",
            }}
            onClick={handlePrev}
          >
            Voltar
          </V3Text5>
        </CenteredBox>
      </BoxStepMobile>
    </>
  );
};
