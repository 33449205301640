import styled from "styled-components";
import { colors } from "../../styles/colors";

export const AboutContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 150px 83px;

  .title-box {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0 200px;
    gap: 15px;
    margin-bottom: 50px;
  }

  .title {
    text-align: center;
    font: normal normal bold 50px/50px Roboto;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }

  .subtitle {
    text-align: center;
    font: normal normal normal 15px/23px Roboto;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }

  .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-bottom: 40px;
    align-items: center;
    justify-content: center;
  }

  .text-box {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0 303px;
  }

  .text {
    text-align: left;
    font: normal normal normal 15px/23px Roboto;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }

  .box-logos {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 60px 300px;
  }
`;

export const AboutContainerMobile = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 50px 30px 250px;

  .title-box {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 50px;
  }

  .title {
    text-align: center;
    font: normal normal bold 30px/30px Roboto;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }

  .subtitle {
    text-align: center;
    font: normal normal normal 15px/20px Roboto;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }

  .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-bottom: 40px;
    align-items: center;
    justify-content: center;
  }

  .text-box {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0;
  }

  .text {
    text-align: left;
    font: normal normal normal 15px/23px Roboto;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }

  .box-logos {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    padding: 60px 0px;
  }
`;
