import React from "react";
import { ButtonLarge } from "../../components/Buttons/button";
import Footer from "../../components/shared/Footer";
import Header from "../../components/shared/Header";
import { H1, H3 } from "../../components/shared/Typography";

const Page404: React.FC = () => {
  return (
    <>
      <Header />
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          alignContent: "center",
          height: 600,
        }}
      >
        <H1>404</H1>
        <H3>Página não localizada</H3>
        <a href="/" style={{ textDecoration: "none", marginTop: 30 }}>
          <ButtonLarge>Ir para o início</ButtonLarge>
        </a>
      </div>
      <Footer />
    </>
  );
};

export default Page404;
