import { useState, useEffect } from "react";
import {
  BoxPinStep,
  BoxStepDesktop,
  BoxStepMobile,
  CenteredBox,
  CenteredBoxMobile,
} from "../../../styles/themes/components/box";
import { V3Button } from "../../../styles/themes/components/button";
import { PinStepActive } from "../../../styles/themes/components/pin";
import { V3Text5, V3TextH1 } from "../../../styles/themes/components/texts";
import { v3Colors } from "../../../styles/themes/v3/colors";
import { V3TextCustom } from "../../../styles/themes/components/input";
import Lottie from "lottie-react";
import Loading from "../../../assets/lottie/loading.json";
import moment from "moment";

import { V3Modal } from "../../../styles/themes/components/modal";
import { formataCNPJ, formataCPF } from "../../../utils/formaters";
import { v3ValidateEmail } from "../../../styles/themes/utils/validate";
import { Backdrop, Checkbox, FormControlLabel } from "@mui/material";
import api from "../../../services/api";
import checkDevice from "../../../utils/isMobile";
import axios from "axios";

export const Step5Desktop = (props) => {
  const { setCurrentStep } = props;
  const [ready, setReady] = useState(false);
  const [vc, setVc] = useState("");
  const [onLoad, setOnLoad] = useState(false);

  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState(false);
  const [pass, setPass] = useState("");
  const [passErr, setPassErr] = useState(false);
  const [cpass, setCPass] = useState("");
  const [cpassErr, setCPassErr] = useState(false);
  const [aceito, setAceito] = useState(false);
  const [idEtapa, setIdEtapa] = useState("");
  const [hasError, setHasError] = useState(false);
  const [hasSuccess, setHasSuccess] = useState(false);
  const [cpfCnpj, setCpfCnpj] = useState("");
  const [cadastroCliente, setCadastroCliente] = useState(false);

  const [nomeUrl, setNomeUrl] = useState("");

  const [errorText, setErrorText] = useState("");
  const [successText, setSuccessText] = useState("");

  const [browserUser, setBrowserUser] = useState("");
  const [isMobileUser, setIsMobileUser] = useState(false);
  const [ipUser, setIpUser] = useState("");
  const [hrAceite, setHrAceite] = useState("");

  const isCanopus = localStorage.getItem("administradoraO") === "125";
  const canopusAceite = localStorage.getItem("canopusAceite");
  const startForm = localStorage.getItem("startForm");
  const horaAcesso = localStorage.getItem("horaAcesso");
  const timeZone = localStorage.getItem("timeZone");
  const [os, setOS] = useState("");

  async function getNavigationData() {
    if (window.navigator.appVersion.indexOf("Win") !== -1) {
      setOS("Windows");
    }
    if (window.navigator.appVersion.indexOf("Mac") !== -1) {
      setOS("MacOS");
    }
    if (window.navigator.appVersion.indexOf("X11") !== -1) {
      setOS("UnixOS");
    }
    if (window.navigator.appVersion.indexOf("Linux") !== -1) {
      setOS("Linux");
    }

    if (window.navigator.userAgent.indexOf("Chrome") !== -1) {
      setBrowserUser("Google Chrome");
    } else if (window.navigator.userAgent.indexOf("Firefox") !== -1) {
      setBrowserUser("Mozilla Firefox");
    } else if (window.navigator.userAgent.indexOf("MSIE") !== -1) {
      setBrowserUser("Internet Explorer");
    } else if (window.navigator.userAgent.indexOf("Edge") !== -1) {
      setBrowserUser("Edge");
    } else if (window.navigator.userAgent.indexOf("Safari") !== -1) {
      setBrowserUser("Safari");
    } else if (window.navigator.userAgent.indexOf("Opera") !== -1) {
      setBrowserUser("Opera");
    }

    const isMobileDevice = checkDevice();
    setIsMobileUser(isMobileDevice);
  }

  async function handleGetIP() {
    const dataip = await axios
      .get("https://ipv4.icanhazip.com")
      .then(({ data }) => {
        setIpUser(data.replace("\n", ""));
      })
      .catch((error) => console.log(error));
  }

  const plataforma = `{
      "acessado_em": "${horaAcesso}",
      "mobile": "${isMobileUser ? "SIM" : "NÃO"}",
      "navegador": "${browserUser}",
      "os": "${os}",
      "ip": "${ipUser}",
      "aceite_politica_em": "${hrAceite}",
      "inicio_form":" ${startForm}",
      "timezone": "${timeZone}",
      "canopus": "${isCanopus ? "SIM" : "NÃO"}",
      "hr_canopus_aceite": "${canopusAceite ? canopusAceite : "INDIFERENTE"}"
  }`;

  function handleEmail(t) {
    const text = t.replaceAll(" ", "");
    if (v3ValidateEmail(text) || text === "") {
      setEmail(text);
      setEmailErr(false);
    } else {
      setEmail(text);
      setEmailErr(true);
    }
  }

  function handlePass(text) {
    if (text.length >= 8 && checkPassword(text)) {
      setPassErr(false);
      setPass(text);
    } else {
      setPassErr(true);
      setPass(text);
    }
  }
  function handleCPass(text) {
    setCPass(text);
    if (text !== pass) {
      setCPassErr(true);
    } else {
      setCPassErr(false);
    }
  }

  function handlePrev() {
    if (onLoad) {
      return;
    }
    setCurrentStep(3);
  }

  function checkPassword() {
    let regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[^\w\s]).*$/;
    if (regex.test(pass)) {
      return true;
    } else {
      return false;
    }
  }

  async function handleNext() {
    if (onLoad) {
      return;
    }

    if (!cadastroCliente) {
      if (!pass || !checkPassword() || passErr) {
        setHasError(true);
        setPassErr(true);
        return setErrorText(
          "Preencha o campo senha corretamente contendo letras, números e ao menos um caractere especial e uma letra maiuscula."
        );
      }
      if (!cpass || cpass !== pass) {
        setHasError(true);
        setCPassErr(true);
        return setErrorText("Senha e confirmação de senha não coincídem.");
      }
    }

    if (!aceito) {
      setHasError(true);
      return setErrorText(
        "Por favor aceito os termos de uso e política de privacidade."
      );
    }

    setOnLoad(true);
    localStorage.setItem("concluido", "true");
    await api
      .patch(
        `/api/concash/1.0/cliente-cadastro/onboarding/avancar/${vc}/${idEtapa}`,
        [
          {
            nome: "senhaEscolhida",
            valor: pass,
          },
          {
            nome: "senhaConfirmacao",
            valor: cpass,
          },
          {
            nome: "aceitaTermosPolitica",
            valor: "SIM",
          },
          {
            nome: "plataforma",
            valor: plataforma,
          },
        ]
      )
      .then(() => {
        setHasError(false);
        window.location.href = "/vc-sucesso";
        setOnLoad(false);
      })
      .catch((error) => {
        setOnLoad(false);
        setHasError(true);
          setErrorText(
            "Tivemos um problema ao processar seus dados, por favor, preencha os dados novamente."
          );
        console.log(error.response.data);
      });
  }

  async function recuperarEtapas(onb) {
    const on = onb ? onb : localStorage.getItem("vc");
    const etp = await api.get(
      `/api/concash/1.0/cliente-cadastro/onboarding/recuperar/${on ? on : vc}`
    );
    setCadastroCliente(etp.data.cadastroCliente === 1 ? true : false);
    const name = encodeURIComponent(
      await etp?.data.etapas[1].campos.find((i) => i.nome === "nomeRazao").valor
    );

    setEmail(
      await etp.data.etapas[1].campos.find((i) => i.nome === "email").valor
    );
    setCpfCnpj(
      await etp.data.etapas[1].campos.find((i) => i.nome === "cpfCnpj").valor
    );

    setNomeUrl(name);

    setVc(etp.data.id);
    setIdEtapa(await etp.data.etapas[4].id);

    localStorage.setItem("etapasO", `${JSON.stringify(etp.data.etapas)}`);
  }

    function handleResetOnboarding(state) {
      setHasError(state);
      localStorage.clear();
      window.location.reload();
    }

  useEffect(() => {
    window.location.href = "/vc-sucesso";
    // getNavigationData();
    // handleGetIP();
    // const on = vc ? vc : localStorage.getItem("vc");

    // recuperarEtapas(on);
  }, []);

  return (
    <>
    {ready && (
    <>
      <Backdrop open={onLoad} style={{ zIndex: 999 }}>
        <div>
          <Lottie animationData={Loading} />
        </div>
      </Backdrop>
      <BoxStepDesktop style={{ minHeight: 450 }}>
        <BoxPinStep>
          <PinStepActive num={1} type="checked" />
          <PinStepActive num={2} type="checked" />
          <PinStepActive num={3} type="checked" />
          <PinStepActive num={4} type="active" />
        </BoxPinStep>
        <CenteredBox>
          <V3TextH1>Conclua seu cadastro</V3TextH1>
        </CenteredBox>
        {cadastroCliente ? (
          <>
            <CenteredBox style={{ padding: "0px 100px", marginTop: 20 }}>
              <V3Text5 style={{ textAlign: "center" }}>
                Identificamos que o CPF/CNPJ informado já possui cadastro!
                <br />
                Acesse o portal do cliente da Concash e acompanhe o processo de
                venda da sua cota de forma online.
              </V3Text5>
            </CenteredBox>
            <CenteredBox
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: 15,
                gap: 10,
                padding: "0px 100px",
              }}
            >
              <V3TextCustom
                sx={{ width: 447 }}
                label="CPF / CNPJ"
                size="small"
                value={
                  cpfCnpj.length <= 11
                    ? formataCPF(cpfCnpj)
                    : formataCNPJ(cpfCnpj)
                }
                disabled
              />
            </CenteredBox>

            <CenteredBox
              style={{
                marginTop: 15,
                padding: "0px 100px",
              }}
            >
              <FormControlLabel
                value="sim"
                control={
                  <Checkbox
                    checked={aceito}
                    onChange={() => {
                      setAceito(!aceito);
                      setHrAceite(
                        moment([], true).format("DD/MM/YYYY  HH:mm:ss")
                      );
                    }}
                    value={0}
                    name="radio-buttons"
                    inputProps={{ "aria-label": "A" }}
                    sx={{
                      color: v3Colors.primary,
                      "&.Mui-checked": {
                        color: v3Colors.primary,
                      },
                    }}
                  />
                }
              />
              <V3Text5 style={{ color: v3Colors.black }}>Aceito os </V3Text5>
              <a
                style={{ textDecoration: "none" }}
                href={require("../../../assets/termo/Politica_Privacidade.pdf")}
                target="_blank" rel="noreferrer"
              >
                <V3Text5
                  style={{
                    color: v3Colors.primary,
                    marginLeft: 5,
                    fontWeight: "500",
                  }}
                >
                  termos de uso e política de privacidade.
                </V3Text5>
              </a>
            </CenteredBox>

            <V3Modal
              open={hasError}
              actionClose={handleResetOnboarding}
              title={"Atenção!"}
              text={errorText}
              type="error"
            />
            <V3Modal
              open={hasSuccess}
              actionClose={setHasSuccess}
              title={"Sucesso!"}
              text={successText}
              type="success"
            />
            <CenteredBox style={{ gap: 20, marginTop: 30 }}>
              <V3Button
                style={{
                  marginBottom: 30,
                  cursor: onLoad ? "wait" : "pointer",
                }}
                width={206}
                text={"Voltar"}
                estilo="secondary"
                onClick={handlePrev}
                disabled={onLoad}
              />
              <V3Button
                onClick={handleNext}
                style={{
                  marginBottom: 30,
                  cursor: onLoad ? "wait" : "pointer",
                }}
                width={206}
                text={"Finalizar Cadastro"}
                estilo="primary"
                disabled={onLoad}
              />
            </CenteredBox>
          </>
        ) : (
          <>
            <CenteredBox style={{ padding: "0px 100px", marginTop: 20 }}>
              <V3Text5 style={{ textAlign: "center" }}>
                Esta senha será utilizada para acessar o portal do cliente da
                Concash e acompanhar o processo de venda da sua cota de forma
                online.​
              </V3Text5>
            </CenteredBox>
            <CenteredBox
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: 15,
                gap: 10,
                padding: "0px 100px",
              }}
            >
              <V3TextCustom
                sx={{ width: 447 }}
                label="CPF / CNPJ"
                size="small"
                value={
                  cpfCnpj.length <= 11
                    ? formataCPF(cpfCnpj)
                    : formataCNPJ(cpfCnpj)
                }
                disabled
              />
              <V3TextCustom
                sx={{ width: 447 }}
                label="Senha"
                onChange={(e) => {
                  handlePass(e.target.value);
                }}
                error={passErr}
                size="small"
                helperText={
                  passErr &&
                  "Preencha o campo senha com letras, números e ao menos um caractere especial e uma letra maiuscula."
                }
                type="password"
                required
              />
              <V3TextCustom
                sx={{ width: 447 }}
                label="Confirme a senha"
                onChange={(e) => {
                  handleCPass(e.target.value);
                }}
                error={cpassErr}
                size="small"
                helperText={cpassErr && "Confirmação não confere com a senha."}
                type="password"
                value={cpass}
                required
              />
            </CenteredBox>

            <CenteredBox
              style={{
                marginTop: 15,
                padding: "0px 100px",
              }}
            >
              <FormControlLabel
                value="sim"
                control={
                  <Checkbox
                    checked={aceito}
                    onChange={() => {
                      setAceito(!aceito);
                      setHrAceite(
                        moment([], true).format("DD/MM/YYYY  HH:mm:ss")
                      );
                    }}
                    value={0}
                    name="radio-buttons"
                    inputProps={{ "aria-label": "A" }}
                    sx={{
                      color: v3Colors.primary,
                      "&.Mui-checked": {
                        color: v3Colors.primary,
                      },
                    }}
                  />
                }
              />
              <V3Text5 style={{ color: v3Colors.black }}>Aceito os </V3Text5>
              <a
                style={{ textDecoration: "none" }}
                href={require("../../../assets/termo/Politica_Privacidade.pdf")}
                target="_blank" rel="noreferrer"
              >
                <V3Text5
                  style={{
                    color: v3Colors.primary,
                    marginLeft: 5,
                    fontWeight: "500",
                  }}
                >
                  termos de uso e política de privacidade.
                </V3Text5>
              </a>
            </CenteredBox>
            <CenteredBox
              style={{
                marginTop: 15,
                padding: "0px 100px",
                justifyContent: "flex-start",
              }}
            >
              <V3Text5 style={{ color: v3Colors.black, fontStyle: "italic" }}>
                *Preenchimento Obrigatório
              </V3Text5>
            </CenteredBox>
            <V3Modal
              open={hasError}
              actionClose={handleResetOnboarding}
              title={"Atenção!"}
              text={errorText}
              type="error"
            />
            <V3Modal
              open={hasSuccess}
              actionClose={setHasSuccess}
              title={"Sucesso!"}
              text={successText}
              type="success"
            />
            <CenteredBox style={{ gap: 20, marginTop: 30 }}>
              <V3Button
                style={{
                  marginBottom: 30,
                  cursor: onLoad ? "wait" : "pointer",
                }}
                width={206}
                text={"Voltar"}
                estilo="secondary"
                onClick={handlePrev}
                disabled={onLoad}
              />
              <V3Button
                onClick={handleNext}
                style={{
                  marginBottom: 30,
                  cursor: onLoad ? "wait" : "pointer",
                }}
                width={206}
                text={"Enviar"}
                estilo="primary"
                disabled={onLoad}
              />
            </CenteredBox>
          </>
        )}
      </BoxStepDesktop>
    </>
    )}
    </>
  );
};
export const Step5Mobile = (props) => {
  const { setCurrentStep } = props;
  const [ready, setReady] = useState(false);
  const [vc, setVc] = useState("");
  const [onLoad, setOnLoad] = useState(false);
  const [cadastroCliente, setCadastroCliente] = useState(false);

  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState(false);
  const [pass, setPass] = useState("");
  const [passErr, setPassErr] = useState(false);
  const [cpass, setCPass] = useState("");
  const [cpassErr, setCPassErr] = useState(false);
  const [aceito, setAceito] = useState(false);
  const [idEtapa, setIdEtapa] = useState("");
  const [hasError, setHasError] = useState(false);
  const [hasSuccess, setHasSuccess] = useState(false);
  const [cpfCnpj, setCpfCnpj] = useState("");
  const [errorText, setErrorText] = useState("");
  const [successText, setSuccessText] = useState("");

  const [browserUser, setBrowserUser] = useState("");
  const [isMobileUser, setIsMobileUser] = useState(false);
  const [ipUser, setIpUser] = useState("");
  const [hrAceite, setHrAceite] = useState("");

  const isCanopus = localStorage.getItem("administradoraO") === "125";
  const canopusAceite = localStorage.getItem("canopusAceite");
  const startForm = localStorage.getItem("startForm");
  const horaAcesso = localStorage.getItem("horaAcesso");
  const timeZone = localStorage.getItem("timeZone");
  const [os, setOS] = useState("");

  async function getNavigationData() {
    if (window.navigator.appVersion.indexOf("Win") !== -1) {
      setOS("Windows");
    }
    if (window.navigator.appVersion.indexOf("Mac") !== -1) {
      setOS("MacOS");
    }
    if (window.navigator.appVersion.indexOf("X11") !== -1) {
      setOS("UnixOS");
    }
    if (window.navigator.appVersion.indexOf("Linux") !== -1) {
      setOS("Linux");
    }

    if (window.navigator.userAgent.indexOf("Chrome") !== -1) {
      setBrowserUser("Google Chrome");
    } else if (window.navigator.userAgent.indexOf("Firefox") !== -1) {
      setBrowserUser("Mozilla Firefox");
    } else if (window.navigator.userAgent.indexOf("MSIE") !== -1) {
      setBrowserUser("Internet Explorer");
    } else if (window.navigator.userAgent.indexOf("Edge") !== -1) {
      setBrowserUser("Edge");
    } else if (window.navigator.userAgent.indexOf("Safari") !== -1) {
      setBrowserUser("Safari");
    } else if (window.navigator.userAgent.indexOf("Opera") !== -1) {
      setBrowserUser("Opera");
    }

    const isMobileDevice = checkDevice();
    setIsMobileUser(isMobileDevice);
  }

  async function handleGetIP() {
    const dataip = await axios
      .get("https://ipv4.icanhazip.com")
      .then(({ data }) => {
        setIpUser(data.replace("\n", ""));
      })
      .catch((error) => console.log(error));
  }

  const plataforma = `{
      "acessado_em": "${horaAcesso}",
      "mobile": "${isMobileUser ? "SIM" : "NÃO"}",
      "navegador": "${browserUser}",
      "os": "${os}",
      "ip": "${ipUser}",
      "aceite_politica_em": "${hrAceite}",
      "inicio_form":" ${startForm}",
      "timezone": "${timeZone}",
      "canopus": "${isCanopus ? "SIM" : "NÃO"}",
      "hr_canopus_aceite": "${canopusAceite ? canopusAceite : "INDIFERENTE"}"
  }`;

  function handleEmail(t) {
    const text = t.replaceAll(" ", "");
    if (v3ValidateEmail(text) || text === "") {
      setEmail(text);
      setEmailErr(false);
    } else {
      setEmail(text);
      setEmailErr(true);
    }
  }

  function handlePass(text) {
    if (text.length >= 8 && checkPassword(text)) {
      setPassErr(false);
      setPass(text);
    } else {
      setPassErr(true);
      setPass(text);
    }
  }
  function handleCPass(text) {
    setCPass(text);
    if (text !== pass) {
      setCPassErr(true);
    } else {
      setCPassErr(false);
    }
  }

  function handlePrev() {
    if (onLoad) {
      return;
    }
    setCurrentStep(3);
  }

  function checkPassword() {
    let regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[^\w\s]).*$/;
    if (regex.test(pass)) {
      return true;
    } else {
      return false;
    }
  }

  async function handleNext() {
    if (onLoad) {
      return;
    }

    if (!cadastroCliente) {
      if (!pass || !checkPassword() || passErr) {
        setHasError(true);
        setPassErr(true);
        return setErrorText(
          "Preencha o campo senha corretamente contendo letras, números e ao menos um caractere especial e uma letra maiuscula."
        );
      }
      if (!cpass || cpass !== pass) {
        setHasError(true);
        setCPassErr(true);
        return setErrorText("Senha e confirmação de senha não coincídem.");
      }
    }
    if (!aceito) {
      setHasError(true);
      return setErrorText(
        "Por favor aceito os termos de uso e política de privacidade."
      );
    }

    setOnLoad(true);
    localStorage.setItem("concluido", "true");

    await api
      .patch(
        `/api/concash/1.0/cliente-cadastro/onboarding/avancar/${vc}/${idEtapa}`,
        [
          {
            nome: "senhaEscolhida",
            valor: pass,
          },
          {
            nome: "senhaConfirmacao",
            valor: cpass,
          },
          {
            nome: "aceitaTermosPolitica",
            valor: "SIM",
          },
          {
            nome: "plataforma",
            valor: plataforma,
          },
        ]
      )
      .then(() => {
        setHasError(false);
        window.location.href = "/vc-sucesso";
        setOnLoad(false);
      })
      .catch((error) => {
        setOnLoad(false);
        setHasError(true);
          setErrorText(
            "Tivemos um problema ao processar seus dados, por favor, preencha os dados novamente."
          );
        console.log(error.response.data);
      });
  }

    function handleResetOnboarding(state) {
      setHasError(state);
      localStorage.clear();
      window.location.reload();
    }

  async function recuperarEtapas(onb) {
    const on = onb ? onb : localStorage.getItem("vc");
    const etp = await api.get(
      `/api/concash/1.0/cliente-cadastro/onboarding/recuperar/${on ? on : vc}`
    );
    setCadastroCliente(etp.data.cadastroCliente === 1 ? true : false);
    setEmail(
      await etp.data.etapas[1].campos.find((i) => i.nome === "email").valor
    );
    setCpfCnpj(
      await etp.data.etapas[1].campos.find((i) => i.nome === "cpfCnpj").valor
    );
    setVc(etp.data.id);
    setIdEtapa(await etp.data.etapas[4].id);

    localStorage.setItem("etapasO", `${JSON.stringify(etp.data.etapas)}`);
  }

  useEffect(() => {
    window.location.href = "/vc-sucesso";
    // getNavigationData();
    // handleGetIP();
    // const on = vc ? vc : localStorage.getItem("vc");
    // recuperarEtapas(on);
  }, []);

  return (
    <>
    {ready && (
    <>
      <Backdrop open={onLoad} style={{ zIndex: 999 }}>
        <div>
          <Lottie animationData={Loading} />
        </div>
      </Backdrop>
      <BoxStepMobile>
        <BoxPinStep>
          <PinStepActive num={1} type="checked" />
          <PinStepActive num={2} type="checked" />
          <PinStepActive num={3} type="checked" />
          <PinStepActive num={4} type="active" />
        </BoxPinStep>
        <CenteredBox>
          <V3TextH1>Conclua seu cadastro</V3TextH1>
        </CenteredBox>
        {cadastroCliente ? (
          <>
            <CenteredBoxMobile style={{ marginTop: 40 }}>
              <V3Text5 style={{ textAlign: "center" }}>
                Identificamos que o CPF/CNPJ informado já possui cadastro!
                <br />
                Acesse o portal do cliente da Concash e acompanhe o processo de
                venda da sua cota de forma online.
              </V3Text5>
            </CenteredBoxMobile>
            <CenteredBoxMobile
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: 15,
                gap: 10,
                padding: "0px 10px",
              }}
            >
              <V3TextCustom
                sx={{ width: 350 }}
                label="CPF / CNPJ"
                size="small"
                value={
                  cpfCnpj.length <= 11
                    ? formataCPF(cpfCnpj)
                    : formataCNPJ(cpfCnpj)
                }
                disabled
              />
            </CenteredBoxMobile>

            <CenteredBoxMobile
              style={{
                display: "flex",
                flex: 1,
                flexWrap: "wrap",
                justifyContent: "flex-start",
                marginTop: 15,
                padding: "0px 10px",
              }}
            >
              <Checkbox
                checked={aceito}
                onChange={() => {
                  setAceito(!aceito);
                  setHrAceite(moment([], true).format("DD/MM/YYYY  HH:mm:ss"));
                }}
                value={0}
                name="radio-buttons"
                inputProps={{ "aria-label": "A" }}
                sx={{
                  margin: 0,
                  color: v3Colors.primary,
                  "&.Mui-checked": {
                    color: v3Colors.primary,
                  },
                }}
              />

              <V3Text5 style={{ color: v3Colors.black }}>Aceito os </V3Text5>
              <a
                style={{ display: "flex", textDecoration: "none" }}
                href={require("../../../assets/termo/Politica_Privacidade.pdf")}
                target="_blank" rel="noreferrer"
              >
                <V3Text5
                  style={{
                    color: v3Colors.primary,
                    marginLeft: 5,
                    fontWeight: "500",
                  }}
                >
                  termos e políticas
                </V3Text5>
              </a>
            </CenteredBoxMobile>

            <V3Modal
              open={hasError}
              actionClose={handleResetOnboarding}
              title={"Atenção!"}
              text={errorText}
              type="error"
            />
            <V3Modal
              open={hasSuccess}
              actionClose={setHasSuccess}
              title={"Sucesso!"}
              text={successText}
              type="success"
            />
            <CenteredBoxMobile style={{ gap: 20, marginTop: 30 }}>
              <V3Button
                style={{
                  marginBottom: 30,
                  cursor: onLoad ? "wait" : "pointer",
                }}
                width={206}
                text={"Voltar"}
                estilo="secondary"
                onClick={handlePrev}
                disabled={onLoad}
              />
              <V3Button
                onClick={handleNext}
                style={{
                  marginBottom: 30,
                  cursor: onLoad ? "wait" : "pointer",
                }}
                width={206}
                text={"Finalizar Cadastro"}
                estilo="primary"
                disabled={onLoad}
              />
            </CenteredBoxMobile>
          </>
        ) : (
          <>
            <CenteredBoxMobile>
              <V3Text5 style={{ textAlign: "center", marginTop: 20 }}>
                Esta senha será utilizada para acessar o portal do cliente da
                Concash e acompanhar o processo de venda da sua cota de forma
                online.​
              </V3Text5>
            </CenteredBoxMobile>

            <CenteredBoxMobile
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: 15,
                gap: 10,
                padding: "0px 10px",
              }}
            >
              <V3TextCustom
                sx={{ width: 350 }}
                label="CPF / CNPJ"
                size="small"
                value={
                  cpfCnpj.length <= 11
                    ? formataCPF(cpfCnpj)
                    : formataCNPJ(cpfCnpj)
                }
                disabled
              />
              <V3TextCustom
                sx={{ width: 350 }}
                label="Senha"
                onChange={(e) => {
                  handlePass(e.target.value);
                }}
                error={passErr}
                size="small"
                helperText={
                  passErr &&
                  "Preencha o campo senha com letras, números e ao menos um caractere especial e uma letra maiuscula."
                }
                type="password"
                required
              />
              <V3TextCustom
                sx={{ width: 350 }}
                label="Confirme a senha"
                onChange={(e) => {
                  handleCPass(e.target.value);
                }}
                error={cpassErr}
                size="small"
                helperText={cpassErr && "Confirmação não confere com a senha."}
                type="password"
                value={cpass}
                required
              />
            </CenteredBoxMobile>

            <CenteredBoxMobile
              style={{
                display: "flex",
                flex: 1,
                flexWrap: "wrap",
                justifyContent: "flex-start",
                marginTop: 15,
                padding: "0px 10px",
              }}
            >
              <Checkbox
                checked={aceito}
                onChange={() => {
                  setAceito(!aceito);
                  setHrAceite(moment([], true).format("DD/MM/YYYY  HH:mm:ss"));
                }}
                value={0}
                name="radio-buttons"
                inputProps={{ "aria-label": "A" }}
                sx={{
                  margin: 0,
                  color: v3Colors.primary,
                  "&.Mui-checked": {
                    color: v3Colors.primary,
                  },
                }}
              />

              <V3Text5 style={{ color: v3Colors.black }}>Aceito os </V3Text5>
              <a
                style={{ display: "flex", textDecoration: "none" }}
                href={require("../../../assets/termo/Politica_Privacidade.pdf")}
                target="_blank" rel="noreferrer"
              >
                <V3Text5
                  style={{
                    color: v3Colors.primary,
                    marginLeft: 5,
                    fontWeight: "500",
                  }}
                >
                  termos e políticas
                </V3Text5>
              </a>
            </CenteredBoxMobile>
            <CenteredBoxMobile
              style={{
                marginTop: 15,
                padding: "0px 10px",
                justifyContent: "flex-start",
              }}
            >
              <V3Text5 style={{ color: v3Colors.black, fontStyle: "italic" }}>
                *Preenchimento Obrigatório
              </V3Text5>
            </CenteredBoxMobile>
            <V3Modal
              open={hasError}
              actionClose={handleResetOnboarding}
              title={"Atenção!"}
              text={errorText}
              type="error"
            />
            <V3Modal
              open={hasSuccess}
              actionClose={setHasSuccess}
              title={"Sucesso!"}
              text={successText}
              type="success"
            />
            <CenteredBoxMobile style={{ gap: 20, marginTop: 30 }}>
              <V3Button
                style={{
                  marginBottom: 30,
                  cursor: onLoad ? "wait" : "pointer",
                }}
                width={206}
                text={"Voltar"}
                estilo="secondary"
                onClick={handlePrev}
                disabled={onLoad}
              />
              <V3Button
                onClick={handleNext}
                style={{
                  marginBottom: 30,
                  cursor: onLoad ? "wait" : "pointer",
                }}
                width={206}
                text={"Enviar"}
                estilo="primary"
                disabled={onLoad}
              />
            </CenteredBoxMobile>
          </>
        )}
      </BoxStepMobile>
    </>
     )}
    </>
  );
};
