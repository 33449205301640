import { useState, useEffect } from "react";
import {
  BoxPinStep,
  BoxStep,
  BoxStepDesktop,
  BoxStepMobile,
  CenteredBox,
  CenteredBoxMobile,
} from "../../../styles/themes/components/box";
import {
  V3Button,
  V3RadioButton,
} from "../../../styles/themes/components/button";
import { PinStepActive } from "../../../styles/themes/components/pin";
import { V3TextH1 } from "../../../styles/themes/components/texts";
import api from "../../../services/api";
import { V3TextCustom } from "../../../styles/themes/components/input";
import { AiFillCar } from "react-icons/ai";
import {
  BsFileEarmarkCheck,
  BsFileEarmarkExcel,
  BsHouse,
} from "react-icons/bs";
import { V3Modal } from "../../../styles/themes/components/modal";
import moment from "moment";
import { DateTime } from "luxon";

export const Step1Desktop = (props) => {
  const { recData, setCurrentStep, onboarding, etapas } = props;
  const [admSelect, setAdmSelect] = useState([]);
  const [adm, setAdm] = useState({
    label: "CNP CONSORCIO S.A. ADMINISTRADORA DE CONSORCIOS",
    id: 123,
  });
  const [tipo, setTipo] = useState(null);
  const [status, setStatus] = useState(null);
  const [idEtapa, setIdEtapa] = useState("");

  const [hasError, setHasError] = useState(false);
  const [hasSuccess, setHasSuccess] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [successText, setSuccessText] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [horaAcesso, setHoraAcesso] = useState("");

  const [startForm, setStartForm] = useState("");

  function handleGetDataAccess() {
    if (startForm) {
      return;
    }
    const acessado = moment([], true).format("DD/MM/YYYY  HH:mm:ss");
    setStartForm(acessado);
    localStorage.setItem("startForm", acessado);
    const tz = DateTime.local().zone.name;
    setTimeZone(tz);
    localStorage.setItem("startForm", acessado);
    localStorage.setItem("timeZone", tz);
  }

  function horaAcessado() {
    const acessado = moment([], true).format("DD/MM/YYYY  HH:mm:ss");
    setHoraAcesso(acessado);
    localStorage.setItem("horaAcesso", acessado);
  }

  async function getAdm() {
    setAdmSelect([]);
    // const { data } = await api.get("/api/administradoras/1.0/listar");
    let arrAdm = [
      { label: "CNP CONSORCIO S.A. ADMINISTRADORA DE CONSORCIOS", id: 123 },
    ];

    setAdmSelect(arrAdm);
  }

  async function recuperarEtapas() {
    const onboardingObj = JSON.parse(localStorage.getItem("onboardingObj"));

    if (!onboardingObj) {
      const on = localStorage.getItem("onboarding");
      const etp = await api.get(
        `/api/concash/1.0/cliente-cadastro/onboarding/recuperar/${on}`
      );
      localStorage.setItem("onboardingObj", `${JSON.stringify(etp.data)}`);
      localStorage.setItem("etapas", `${JSON.stringify(etp.data.etapas)}`);
      const tipoR = await etp.data.etapas[0].campos.find(
        (i) => i.nome === "idTipoConsorcio"
      ).valor;

      const statusR = await etp.data.etapas[0].campos.find(
        (i) => i.nome === "statusCota"
      ).valor;
      setTipo(tipoR);
      setStatus(statusR);
      setIdEtapa(etp.data.etapas[0].id);
    }

    setTipo(
      onboardingObj &&
        onboardingObj.etapas[0].campos.find((i) => i.nome === "idTipoConsorcio")
          .valor
    );
    setStatus(
      onboardingObj &&
        onboardingObj.etapas[0].campos.find((i) => i.nome === "statusCota")
          .valor
    );
    setIdEtapa(onboardingObj && onboardingObj.etapas[0].id);
  }

  async function handleNext(e) {
    e.preventDefault();

    if (!tipo) {
      setHasError(true);
      return setErrorText("Preencha o tipo de consórcio corretamente.");
    }
    if (!status) {
      setHasError(true);
      return setErrorText("Preencha o status da cota corretamente.");
    }

    await api
      .patch(
        `/api/concash/1.0/cliente-cadastro/onboarding/avancar/${onboarding}/${idEtapa}`,
        [
          {
            nome: "idAdministradora",
            valor: "123",
          },
          {
            nome: "statusCota",
            valor: status,
          },
          {
            nome: "idTipoConsorcio",
            valor: `${tipo}`,
          },
          {
            nome: "idCanal",
            valor: "81",
          },
        ]
      )
      .then(async ({ data }) => {
        setCurrentStep(2);
      });
  }

  async function persistData() {
    const on = localStorage.getItem("onboarding");
    const etp = await api.get(
      `/api/concash/1.0/cliente-cadastro/onboarding/recuperar/${on}`
    );
    const tipoR = await etp.data.etapas[0].campos.find(
      (i) => i.nome === "idTipoConsorcio"
    ).valor;

    const statusR = await etp.data.etapas[0].campos.find(
      (i) => i.nome === "statusCota"
    ).valor;
    setTipo(tipoR);
    setStatus(statusR);
  }

  useEffect(() => {
    persistData();
  }, [setCurrentStep]);

  useEffect(() => {
    horaAcessado();
    handleGetDataAccess();
    getAdm();
    const onboardingObj = JSON.parse(localStorage.getItem("onboardingObj"));

    setIdEtapa(onboardingObj && onboardingObj?.etapas[0].id);

    if (!onboardingObj) {
      recuperarEtapas();
    }
  }, []);

  return (
    <>
      <V3Modal
        open={hasError}
        actionClose={setHasError}
        title={"Atenção!"}
        text={errorText}
        type="error"
      />
      <V3Modal
        open={hasSuccess}
        actionClose={setHasSuccess}
        title={"Sucesso!"}
        text={successText}
        type="success"
      />
      <BoxStepDesktop>
        <BoxPinStep>
          <PinStepActive num={1} active />
          <PinStepActive num={2} />
          <PinStepActive num={3} />
          <PinStepActive num={4} />
        </BoxPinStep>
        <CenteredBox>
          <V3TextH1>Qual a administradora do seu consórcio?</V3TextH1>
        </CenteredBox>
        <CenteredBox style={{ marginTop: 15 }}>
          {}
          <V3TextCustom
            label="Administradora do consórcio"
            size="small"
            value={adm.label}
            sx={{ width: 447 }}
            disabled
            required
          />
        </CenteredBox>
        <CenteredBox style={{ marginTop: 30 }}>
          <V3TextH1>Qual o tipo do seu consórcio?</V3TextH1>
        </CenteredBox>

        <CenteredBox style={{ gap: 20, marginTop: 15 }}>
          <V3RadioButton
            text="Veículo"
            selected={tipo === "3"}
            icon={<AiFillCar size={20} />}
            onClick={() => {
              setTipo("3");
            }}
          />
          <V3RadioButton
            text="Imóvel"
            selected={tipo === "1"}
            icon={<BsHouse size={20} />}
            onClick={() => {
              setTipo("1");
            }}
          />
        </CenteredBox>
        {tipo && (
          <>
            <CenteredBox style={{ marginTop: 30 }}>
              <V3TextH1>Qual o status da sua cota?</V3TextH1>
            </CenteredBox>

            <CenteredBox style={{ gap: 20, marginTop: 15 }}>
              <V3RadioButton
                text="Ativa"
                selected={status === "1"}
                icon={<BsFileEarmarkCheck size={20} />}
                onClick={() => setStatus("1")}
              />
              <V3RadioButton
                text="Cancelada"
                selected={status === "0"}
                icon={<BsFileEarmarkExcel size={20} />}
                onClick={() => setStatus("0")}
              />
            </CenteredBox>
          </>
        )}

        <div
          style={{
            display: "flex",
            flex: 1,
            marginTop: 30,
            marginBottom: 10,
            paddingRight: 109,
            justifyContent: "flex-end",
          }}
        >
          <V3Button
            onClick={handleNext}
            style={{ marginBottom: 30 }}
            width={206}
            text={"Avançar"}
            estilo="primary"
          />
        </div>
      </BoxStepDesktop>
    </>
  );
};

export const Step1Mobile = (props) => {
  const { recData, setCurrentStep, onboarding, etapas } = props;
  const [admSelect, setAdmSelect] = useState([]);
  const [adm, setAdm] = useState({
    label: "CNP CONSORCIO S.A. ADMINISTRADORA DE CONSORCIOS",
    id: 123,
  });
  const [tipo, setTipo] = useState(null);
  const [status, setStatus] = useState(null);
  const [idEtapa, setIdEtapa] = useState("");

  const [hasError, setHasError] = useState(false);
  const [hasSuccess, setHasSuccess] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [successText, setSuccessText] = useState("");

  const [timeZone, setTimeZone] = useState("");
  const [horaAcesso, setHoraAcesso] = useState("");

  const [startForm, setStartForm] = useState("");

  function handleGetDataAccess() {
    if (startForm) {
      return;
    }
    const acessado = moment([], true).format("DD/MM/YYYY  HH:mm:ss");
    setStartForm(acessado);
    localStorage.setItem("startForm", acessado);
    const tz = DateTime.local().zone.name;
    setTimeZone(tz);
    localStorage.setItem("startForm", acessado);
    localStorage.setItem("timeZone", tz);
  }

  function horaAcessado() {
    const acessado = moment([], true).format("DD/MM/YYYY  HH:mm:ss");
    setHoraAcesso(acessado);
    localStorage.setItem("horaAcesso", acessado);
  }

  async function getAdm() {
    setAdmSelect([]);
    // const { data } = await api.get("/api/administradoras/1.0/listar");
    let arrAdm = [
      { label: "CNP CONSORCIO S.A. ADMINISTRADORA DE CONSORCIOS", id: 123 },
    ];

    setAdmSelect(arrAdm);
  }

  async function recuperarEtapas() {
    const onboardingObj = JSON.parse(localStorage.getItem("onboardingObj"));

    if (!onboardingObj) {
      const on = localStorage.getItem("onboarding");
      const etp = await api.get(
        `/api/concash/1.0/cliente-cadastro/onboarding/recuperar/${on}`
      );
      localStorage.setItem("onboardingObj", `${JSON.stringify(etp.data)}`);
      localStorage.setItem("etapas", `${JSON.stringify(etp.data.etapas)}`);
      const tipoR = await etp.data.etapas[0].campos.find(
        (i) => i.nome === "idTipoConsorcio"
      ).valor;

      const statusR = await etp.data.etapas[0].campos.find(
        (i) => i.nome === "statusCota"
      ).valor;
      setTipo(tipoR);
      setStatus(statusR);
      setIdEtapa(etp.data.etapas[0].id);
    }

    setTipo(
      onboardingObj &&
        onboardingObj.etapas[0].campos.find((i) => i.nome === "idTipoConsorcio")
          .valor
    );
    setStatus(
      onboardingObj &&
        onboardingObj.etapas[0].campos.find((i) => i.nome === "statusCota")
          .valor
    );
    setIdEtapa(onboardingObj && onboardingObj.etapas[0].id);
  }

  async function handleNext(e) {
    e.preventDefault();

    if (!tipo) {
      setHasError(true);
      return setErrorText("Preencha o tipo de consórcio corretamente.");
    }
    if (!status) {
      setHasError(true);
      return setErrorText("Preencha o status da cota corretamente.");
    }

    await api
      .patch(
        `/api/concash/1.0/cliente-cadastro/onboarding/avancar/${onboarding}/${idEtapa}`,
        [
          {
            nome: "idAdministradora",
            valor: "123",
          },
          {
            nome: "statusCota",
            valor: status,
          },
          {
            nome: "idTipoConsorcio",
            valor: `${tipo}`,
          },
          {
            nome: "idCanal",
            valor: "81",
          },
        ]
      )
      .then(async ({ data }) => {
        setCurrentStep(2);
      });
  }

  async function persistData() {
    const on = localStorage.getItem("onboarding");
    const etp = await api.get(
      `/api/concash/1.0/cliente-cadastro/onboarding/recuperar/${on}`
    );
    const tipoR = await etp.data.etapas[0].campos.find(
      (i) => i.nome === "idTipoConsorcio"
    ).valor;

    const statusR = await etp.data.etapas[0].campos.find(
      (i) => i.nome === "statusCota"
    ).valor;
    setTipo(tipoR);
    setStatus(statusR);
  }

  useEffect(() => {
    persistData();
  }, [setCurrentStep]);

  useEffect(() => {
    horaAcessado();
    handleGetDataAccess();
    getAdm();
    const onboardingObj = JSON.parse(localStorage.getItem("onboardingObj"));

    setIdEtapa(onboardingObj && onboardingObj?.etapas[0].id);

    if (!onboardingObj) {
      recuperarEtapas();
    }
  }, []);
  return (
    <>
      <V3Modal
        open={hasError}
        actionClose={setHasError}
        title={"Atenção!"}
        text={errorText}
        type="error"
      />
      <V3Modal
        open={hasSuccess}
        actionClose={setHasSuccess}
        title={"Sucesso!"}
        text={successText}
        type="success"
      />
      <BoxStepMobile>
        <BoxPinStep>
          <PinStepActive num={1} type="active" />
          <PinStepActive num={2} type="deactive" />
          <PinStepActive num={3} type="deactive" />
          <PinStepActive num={4} type="deactive" />
        </BoxPinStep>
        <CenteredBoxMobile>
          <V3TextH1 style={{ textAlign: "center" }}>
            Qual a administradora do seu consórcio?
          </V3TextH1>
        </CenteredBoxMobile>
        <CenteredBoxMobile style={{ marginTop: 15 }}>
          {}
          <V3TextCustom
            label="Administradora do consórcio"
            size="small"
            value={adm.label}
            sx={{ width: 350 }}
            disabled
            required
          />
        </CenteredBoxMobile>
        <CenteredBoxMobile style={{ marginTop: 30 }}>
          <V3TextH1>Qual o tipo do seu consórcio?</V3TextH1>
        </CenteredBoxMobile>

        <CenteredBoxMobile style={{ gap: 20, marginTop: 15 }}>
          <V3RadioButton
            text="Veículo"
            selected={tipo === "3"}
            icon={<AiFillCar size={20} />}
            onClick={() => {
              setTipo("3");
            }}
          />
          <V3RadioButton
            text="Imóvel"
            selected={tipo === "1"}
            icon={<BsHouse size={20} />}
            onClick={() => {
              setTipo("1");
            }}
          />
        </CenteredBoxMobile>
        {tipo && (
          <>
            <CenteredBoxMobile style={{ marginTop: 30 }}>
              <V3TextH1>Qual o status da sua cota?</V3TextH1>
            </CenteredBoxMobile>

            <CenteredBoxMobile style={{ gap: 20, marginTop: 15 }}>
              <V3RadioButton
                text="Ativa"
                selected={status === "1"}
                icon={<BsFileEarmarkCheck size={20} />}
                onClick={() => setStatus("1")}
              />
              <V3RadioButton
                text="Cancelada"
                selected={status === "0"}
                icon={<BsFileEarmarkExcel size={20} />}
                onClick={() => setStatus("0")}
              />
            </CenteredBoxMobile>
          </>
        )}

        <div
          style={{
            display: "flex",
            flex: 1,
            marginTop: 30,
            paddingRight: 15,
            justifyContent: "flex-end",
          }}
        >
          <V3Button
            onClick={handleNext}
            style={{ display: "flex", height: 45 }}
            text={"Avançar"}
            size="md"
            estilo="primary"
          />
        </div>
      </BoxStepMobile>
    </>
  );
};
