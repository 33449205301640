import React, { useState, useEffect } from "react";
import logo from "../../../assets/images/logo-negative.png";
import logoW from "../../../assets/images/logo_footer.png";

import { ButtonLarge, ButtonMain } from "../../Buttons/button";
import { Container, HeaderContainerMobile } from "./styles";
import { Link } from "react-router-dom";

import { GiHamburgerMenu } from "react-icons/gi";
import { colors } from "../../../styles/colors";
import { Backdrop } from "@mui/material";
import { GrClose } from "react-icons/gr";
import { AiOutlineClose } from "react-icons/ai";
import { LinkWhite } from "../Typography";
import { MdArrowForwardIos } from "react-icons/md";
import BlocoFAQ from "../../BlocoFAQ";
import { ambiente } from "../../../services/api";
import {
  V3Button,
  V3ButtonLink,
  V3SiteButtonLink,
} from "../../../styles/themes/components/button";
import { HiOutlineMenu } from "react-icons/hi";
import { MenuNavMobile, NewNavBarMobile } from "./styles.mobile";
import { sanitizeParams } from "../../../utils/sanitize";

interface HeaderProps {
  mobile: boolean;
}

const Header: React.FC<any> = ({ mobile }: HeaderProps) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);
  const [menuNav, setMenuNav] = useState(false);
  const [queryParams, setQueryParams] = useState<any>();

  useEffect(() => {
    const text = sanitizeParams(window.location.search);
    setQueryParams(text);
  }, [queryParams]);

  useEffect(() => {
    setIsMobile(window.innerWidth <= 500);
  }, [window.innerWidth]);


  const rolarPagina = () => {
    window.scrollBy({
      top: 400,
      behavior: 'smooth',
    });
  };


  return (
    <>
      {!isMobile ? (
        <>
          <Container>
            <a href={queryParams?.length ? "/" + queryParams : "/"}>
              <img
                loading="lazy"
                src={logo}
                width={174}
                height={48}
                alt="logo"
              />
            </a>
            <div className="box-right">
              <ul className="list-items">
                <li>
                  <a
                    href="https://blog.concash.com.br"
                    target="_blank"
                    className="link-main"
                    rel="noreferrer"
                  >
                    Blog
                  </a>
                </li>
                <li>
                  <a
                    href={
                      queryParams?.length != 0
                        ? "/sobre" + queryParams
                        : "/sobre"
                    }
                    className="link-main"
                  >
                    Sobre nós
                  </a>
                </li>
                <li>
                  <a
                    href={
                      queryParams?.length != 0
                        ? "/faq" + queryParams
                        : "/faq"
                    }
                    className="link-main"
                  >
                    Perguntas Frequentes
                  </a>
                </li>
                <li>
                  {window.location.pathname === "/" ? (
                    <span onClick={rolarPagina} className="link-main">
                    Administradoras
                  </span>
                  ) : (
                    <a
                      href={
                        queryParams?.length != 0
                          ? "/" + queryParams
                          : "/"
                      }
                      className="link-main"
                    >
                      Administradoras
                    </a>

                  )}
                </li>
                <li>
                  <a
                    href={
                      queryParams?.length != 0
                        ? "/contatos" + queryParams
                        : "/contatos"
                    }
                    className="link-main"
                  >
                    Fale Conosco
                  </a>
                </li>
                <li>
                  <a
                    href={
                      queryParams?.length != 0
                        ? "/parceiro" + queryParams
                        : "/parceiro"
                    }
                    className="link-main"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Seja Parceiro
                  </a>
                </li>
              </ul>
              <div style={{ display: "flex", gap: 10 }}>
                {ambiente === "hml" && (
                  <V3SiteButtonLink
                    estilo="white"
                    text="Minha Conta"
                    href={
                      ambiente === "hml"
                        ? "https://cliente.hml.concash.com.br/"
                        : "https://cliente.concash.com.br/"
                    }
                    target="_blank"
                  />
                )}
                <V3SiteButtonLink
                  width={160}
                  estilo="primary"
                  text="Vender meu Consórcio"
                  href={
                    queryParams?.length != 0
                      ? "/venderconsorcio" + queryParams
                      : "/venderconsorcio"
                  }
                />
              </div>
            </div>
          </Container>
        </>
      ) : (
        <>
          <NewNavBarMobile>
            <a
              href={queryParams?.length ? "/" + queryParams : "/"}
              style={{
                display: "flex",
                textDecoration: "none",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                loading="lazy"
                src={logo}
                width={110}
                height={30}
                alt="logo"
              />
            </a>

            <div onClick={() => setMenuNav(!menuNav)}>
              <HiOutlineMenu color="#16898E" size={28} />
            </div>
          </NewNavBarMobile>
          <Backdrop
            open={menuNav}
            style={{ alignItems: "flex-end", padding: 0, zIndex: 999 }}
          >
            <MenuNavMobile action={() => setMenuNav(false)} />
          </Backdrop>
        </>
      )}
    </>
  );
};

export default Header;
