import styled from "styled-components";

export const DesktopPageContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;
export const DesktopPageContent = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  @media (min-width: 1024px) {
    padding: 0 10.28rem;
  }
  @media (max-width: 1024px) {
    padding: 0 3.28rem;
  }
`;

export const DesktopHeader = styled.div`
  display: flex;
  width: 100vw;
  max-height: 379px;
  min-height: 379px;
  flex-direction: column;
  align-content: center;
  padding: 0 130px;
  justify-content: center;
  gap: 20px;
`;

export const DesktopBoxLogo = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;
export const DesktopBoxTitle = styled.div`
  display: flex;
  align-items: center;
`;
export const DesktopBoxButton = styled.div``;

export const DesktopBoxButtonCNP = styled.div`
  display: flex;
  gap: 12px;
`;

export const DesktopLogoBox = styled.div`
  display: flex;
`;

export const DesktopHeaderTitle = styled.h1`
  width: 526px;
  font-family: "Roboto", sans-serif;
  font-display: swap;
  font-style: bold;
  font-weight: 700;
  font-size: 40px;
  line-height: 45px;
  z-index: 900;
  color: #ffffff;
`;

export const DesktopHeaderSubTitle = styled.h3`
  width: 526px;
  font-family: "Roboto", sans-serif;
  font-display: swap;
  font-style: normal;
  font-size: 18px;
  line-height: 20px;
  z-index: 900;
  color: #ffffff;
`;

export const ButtonVender = styled.a`
  display: flex;
  width: 450px;
  height: 45px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-family: "Roboto", sans-serif;
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
`;
export const ButtonVenderMobile = styled.a`
  display: flex;
  flex: 1;
  width: auto;
  height: 45px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-family: "Roboto", sans-serif;
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
`;

export const DesktopHeaderButton = styled.button`
  display: flex;
  min-width: 149px;
  height: 45px;
  background-color: #fff;
  color: #000;
  text-transform: uppercase;
  border: none;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  font-family: "Roboto", sans-serif;
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  cursor: pointer;
  wrap: no-wrap;
`;

export const DesktopPhotoBox = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-size: cover;
  position: absolute;
  width: 400px;
  height: 344px;
  right: 208px;
  top: 35px;
`;

export const DesktopComoFunciona = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 286px;
  max-height: 286px;
`;

export const DesktopCFBoxTitle = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 120px;
  align-items: center;
  justify-content: center;
  align-content: center;
`;

export const DesktopCFBoxCards = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
`;

export const DesktopCFTitle = styled.h2`
  font-family: "Roboto", sans-serif;
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
`;

export const DesktopSubTitle = styled.h4`
font-family: "Roboto", sans-serif;

`;

export const DesktopCFBoxTitleCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 15px;
  gap: 15px;
`;

export const MobilePageContainer = styled.div`
  display: flex;
  width: 100vw;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-items: center;
  margin 0 0;
  padding: 0;
`;

export const MobileHeader = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 499px;
  width: 100%;
  left: 0px;
  top: 0px;
`;

export const MobileHeaderTopBox = styled.div`
  display: flex;

  border-radius: 0px;
  align-items: center;
  justify-content: center;
  gap: 13px;
  padding: 20px;
`;

export const MobilePhotoBox = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100vw;
  height; 400px;
  background-size: cover;
`;

export const MobileFilterPhoto = styled.div`
  display: flex;
  flex: 1 1 0%;
  width: 100vw;
  height: 428px;
  opacity: 0.3;
  position: absolute;
  z-index: 1;
  top: 71px;
  border-radius: 180px 180px 0px 0px;
`;

export const MobilePhotoBoxSucesso = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100vw;
  height: 400px;
  background-size: cover;
  justify-content: center;
`;

export const MobilePhotoTitle = styled.h1`
  font-family: "Roboto", sans-serif;

  position: absolute;
  width: 300px;
  height: 105px;
  left: 46px;
  top: 299px;

  font-family: "Roboto", sans-serif;
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 35px;
  text-align: center;

  color: #ffffff !important;
`;

export const MobilePhotoSubTitle = styled.h1`
  font-family: "Roboto", sans-serif;

  position: absolute;
  width: 276px;
  height: 105px;
  left: 46px;
  top: 299px;

  font-family: "Roboto", sans-serif;
  font-display: swap;
  font-style: normal;
  font-size: 16px;
  text-align: center;

  color: #ffffff !important;
`;

export const MobilePhotoTitleCNP = styled.h1`
  font-family: "Roboto", sans-serif;

  position: absolute;
  width: 276px;
  height: 105px;
  left: 46px;
  top: 240px;

  font-family: "Roboto", sans-serif;
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 35px;
  text-align: center;

  color: #ffffff !important;
`;
export const MobilePhotoTitleSucesso = styled.h1`
  font-family: "Roboto", sans-serif;

  position: absolute;
  width: 90%;
  height: 105px;
  left: 18px;
  top: 220px;

  font-family: "Roboto", sans-serif;
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 35px;
  text-align: center;

  color: #ffffff;
`;

export const MobilePhotoButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: center;
  position: absolute;
  width: 149px;
  height: 45px;
  top: 434px;

  border-radius: 10px;

  min-width: 109px;
  min-height: 20px;
  border: none;

  font-family: "Roboto", sans-serif;
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;

  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const MobilePageContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
`;

export const MobileComoFunciona = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100vw;
`;

export const MobileCFBoxTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
`;

export const MobileCFBoxTitleCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  padding: 20px;
  gap: 15px;
`;

export const MobileCFTitle = styled.h2`
  font-family: "Roboto", sans-serif;
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
`;

export const MobileSubTitle = styled.h4`
  font-family: "Roboto", sans-serif;
  text-align: center;

`;

export const MobileCFBoxCards = styled.div`
  display: flex;
  max-width: 100vw;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 15px;
  gap: 40px;
`;

export const CookieBoxDesktop = styled.div`
  display: flex;
  flex: 1;
  background-color: #fff;
  padding: 20px 90px;
  max-height: 100px;
  min-height: 100px;
  height: 100px;

  position: absolute;
  left: 0px;
  bottom: 0px;

  position: absolute;
  background: #ffffff;
  box-shadow: 0px -10px 30px rgba(0, 0, 0, 0.1);
  gap: 34px;
`;

export const CookieBoxMobile = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #fff;
  padding: 30px;

  max-height: 223px;
  align-items: flex-end;

  position: fixed;
  left: 0px;
  bottom: 0px;
  gap: 10px;
  position: absolute;
  background: #ffffff;
  box-shadow: 0px -10px 30px rgba(0, 0, 0, 0.1);
`;

export const TextFooterDesktop = styled.p`
  display: flex;
  font-family: "Roboto", sans-serif;
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  flex-wrap: auto;

  color: #000000;
`;

export const TextFooterMobile = styled.p`
  display: flex;
  font-family: "Roboto", sans-serif;
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  flex-wrap: auto;

  color: #000000;
`;

export const ButtonCookie = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  gap: 10px;

  width: 125px;
  height: 45px;
  border-radius: 10px;
  border: none;

  font-family: "Roboto", sans-serif;
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;

  color: #ffffff;
`;

export const GradientBox = styled.div`
  position: absolute;
  z-index: 5;
  width: 100vw;
  height: 300px;
  top: 200px;
  background: rgb(43, 43, 43);
  background: linear-gradient(
    0deg,
    rgba(43, 43, 43, 1) 0%,
    rgba(93, 93, 93, 1) 34%,
    rgba(255, 255, 255, 0) 100%
  );
`;
export const GradientBoxSantander = styled.div`
  position: absolute;
  z-index: 5;
  width: 100vw;
  height: 300px;
  top: 200px;
  background: rgb(255, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(255, 0, 0, 1) 0%,
    rgba(255, 0, 0, 1) 34%,
    rgba(255, 255, 255, 0) 100%
  );
`;

export const GradientBoxGM = styled.div`
  position: absolute;
  z-index: 5;
  width: 100vw;
  height: 300px;
  top: 200px;
  background: rgb(43, 43, 43);
  background: linear-gradient(
    0deg,
    rgba(43, 43, 43, 1) 0%,
    rgba(93, 93, 93, 1) 34%,
    rgba(255, 255, 255, 0) 100%
  );
`;

export const ContainerForm = styled.div`
    display: flex;
    flex-direction: column;
    width: 75%;
    position: relative;
    z-index: 900;
    top: -10px;
    min-height: 580px;
    background: #e4f8f8 0% 0% no-repeat padding-box;
    border-radius: 50px;
    opacity: 1;
    margin-left: 50px;
    margin: 0 83px;

    .box-content {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
    }
    .content {
      display: flex;
      flex: 4;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;
    }
`;
