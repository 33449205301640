import React from "react";
import styled from "styled-components";

export const FloatButton = ({
  link,
  direction,
  color,
  icon,
  width,
  height,
  radius,
}) => {
  const FloatButtonStyled = styled.a`
    display: flex;
    width: ${width ? width : "60px"};
    height: ${height ? height : "60px"};
    border-radius: ${radius ? radius : "50%"};
    background-color: ${color};
    position: fixed;
    left: ${direction === "left" ? "20px" : "auto"};
    right: ${direction === "right" ? "20px" : "auto"};
    bottom: 20px;
    text-decoration: none;
    align-items: center;
    justify-content: center;
  `;

  return (
    <>
      <FloatButtonStyled href={link} target="_blank">
        {icon}
      </FloatButtonStyled>
    </>
  );
};
export default FloatButton;
