import React, { useEffect, useState } from "react";
import { V3Button } from "../../styles/themes/components/button";
import { V3TextInput } from "../../styles/themes/components/input";
import {
  ContainerDesktopOPT,
  ContainerMobileOPT,
} from "../../styles/themes/components/shared/styles";

import "../../styles/reset.css";
import ConcashLogo from "../../assets/images/logo-negative.png";

import { V3Text5, V3TextFooter } from "../../styles/themes/components/texts";
import moment from "moment";
import { v3Colors } from "../../styles/themes/v3/colors";
import api from "../../services/api";
import { Step1Desktop, Step1Mobile } from "./steps/step1";
import { Step2Desktop, Step2Mobile } from "./steps/step2";
import { Step3Desktop, Step3Mobile } from "./steps/step3";
import { Step4Desktop, Step4Mobile } from "./steps/step4";
import { Step5Desktop, Step5Mobile } from "./steps/step5";
import Helmet from "react-helmet";

export const verifyDate = () => {
  const dataAtual = moment();
  const dataInformada = moment();
  const diferencaEmDias = dataAtual.diff(dataInformada, 'days');

  const maisDe7Dias = diferencaEmDias >= 7;

  const startObj = localStorage.getItem("startOnboard");
  
  if (maisDe7Dias  && startObj) {
    localStorage.clear();
    window.location.reload();
  } else {
    return true;
  }
};

const VenderConsorcio: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [vc, setVc] = useState("");
  const [etapas, setEtapas] = useState([]);
  const [recData, setRecData] = useState("");

  const mobile = window.matchMedia("(max-width:768px)").matches;

  async function generateOnboard() {
    const onb: any = await api.get(
      "/api/concash/1.0/cliente-cadastro/onboarding/iniciar"
    );
    const atual = parseInt(await onb.data.etapaAtual);
    setRecData(await onb.data);

    const start: any = moment();
    localStorage.setItem("startOnboard", start);
    localStorage.setItem("etapasO", `${JSON.stringify(await onb.data.etapas)}`);
    localStorage.setItem("etapaAtualO", `${await onb.data.etapaAtual}`);
    localStorage.setItem("vc", `${await onb.data.id}`);
    localStorage.setItem("vcObj", `${JSON.stringify(await onb.data)}`);
    localStorage.setItem("idEtapaO", await onb.data.etapas[0].id);
    setVc(await onb.data.id);
    setCurrentStep(await onb.data.etapaAtual);
    setEtapas(await onb.data.etapas);
  }



  async function recuperarDados() {
    const on = localStorage.getItem("vc");

    if (on && !on !== null && on !== "null") {
      const etp: any = await api.get(
        `/api/concash/1.0/cliente-cadastro/onboarding/recuperar/${on}`
      );

      setRecData(await etp.data);

      const atual = parseInt(await etp.data.etapaAtual);
      setCurrentStep(await etp.data.etapaAtual);

      localStorage.setItem("vcObj", `${JSON.stringify(await etp.data)}`);
      localStorage.setItem(
        "etapasO",
        `${JSON.stringify(await etp.data.etapas)}`
      );
      localStorage.setItem(
        "etapaAtualO",
        `${JSON.stringify(await etp.data.etapaAtual)}`
      );

      (await etp.data.etapaAtual) && setCurrentStep(await etp.data.etapaAtual);
    }
  }

  useEffect(() => {
    const vc: any = localStorage.getItem("vc");
    const vcObj = localStorage.getItem("vcObj");

    if (!vc) {
      generateOnboard();
    } else {
      recuperarDados();
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Concash - Solicite Uma Proposta | Concash</title>
        <meta
          name="description"
          content="Na Concash você vende seu consórcio online com segurança, rapidez,  transparência e com a melhor oferta do mercado"
        />
      </Helmet>
      {mobile && (
        <MobileScreen
          step={currentStep}
          setCurrentStep={setCurrentStep}
          vc={vc}
          etapas={etapas}
          recData={recData}
        />
      )}
      {!mobile && (
        <DesktopScreen
          step={currentStep}
          setCurrentStep={setCurrentStep}
          vc={vc}
          etapas={etapas}
          recData={recData}
        />
      )}
    </>
  );
};

const DesktopScreen = ({ step, vc, setCurrentStep, recData }: any) => {
  return (
    <>
      <ContainerDesktopOPT>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 10,
          }}
        >
          <img
            loading="lazy"
            src={ConcashLogo}
            style={{ marginBottom: 10 }}
            width={162}
            height={44}
            alt="concash"
          />
        </div>
        {step === null && (
          <Step1Desktop
            vc={vc}
            setCurrentStep={setCurrentStep}
            recData={recData}
          />
        )}
        {step === 1 && (
          <Step1Desktop
            vc={vc}
            setCurrentStep={setCurrentStep}
            recData={recData}
          />
        )}
        {step === 2 && (
          <Step2Desktop
            vc={vc}
            setCurrentStep={setCurrentStep}
            recData={recData}
          />
        )}
        {step === 3 && (
          <Step3Desktop
            vc={vc}
            setCurrentStep={setCurrentStep}
            recData={recData}
          />
        )}
        {step === 4 && (
          <Step4Desktop
            vc={vc}
            setCurrentStep={setCurrentStep}
            recData={recData}
          />
        )}
        {step === 5 && (
          <Step5Desktop
            vc={vc}
            setCurrentStep={setCurrentStep}
            recData={recData}
          />
        )}
        <div>
          <V3TextFooter>
            © Copyright {moment(new Date()).format("YYYY")} Concash. Todos os
            direitos reservados{" "}
          </V3TextFooter>
        </div>
      </ContainerDesktopOPT>
      {/* )} */}
    </>
  );
};

const MobileScreen = ({ step, vc, setCurrentStep, recData }: any) => {
  return (
    <>
      <ContainerMobileOPT>
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 40,
          }}
        >
          <img
            loading="lazy"
            src={ConcashLogo}
            style={{ marginBottom: 10 }}
            width={160}
            height={44}
            alt="concash"
          />

          <div>
            {step === null && (
              <Step1Mobile
                vc={vc}
                setCurrentStep={setCurrentStep}
                recData={recData}
              />
            )}
            {step === 1 && (
              <Step1Mobile
                vc={vc}
                setCurrentStep={setCurrentStep}
                recData={recData}
              />
            )}
            {step === 2 && (
              <Step2Mobile
                vc={vc}
                setCurrentStep={setCurrentStep}
                recData={recData}
              />
            )}
            {step === 3 && (
              <Step3Mobile
                vc={vc}
                setCurrentStep={setCurrentStep}
                recData={recData}
              />
            )}
            {step === 4 && (
              <Step4Mobile
                vc={vc}
                setCurrentStep={setCurrentStep}
                recData={recData}
              />
            )}
            {step === 5 && (
              <Step5Mobile
                vc={vc}
                setCurrentStep={setCurrentStep}
                recData={recData}
              />
            )}
          </div>

          <div style={{ position: "relative", bottom: -15 }}>
            <V3TextFooter style={{ textAlign: "center" }}>
              © Copyright {moment(new Date()).format("YYYY")} Concash.
              <br />
              Todos os direitos reservados{" "}
            </V3TextFooter>
          </div>
        </div>
      </ContainerMobileOPT>
    </>
  );
};

export default VenderConsorcio;
