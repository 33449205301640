import React from "react";
import "./iconeTexto.style.css";

interface ITPropos {
    align: any;
    icon: any;
    content: string;
    contentColor?: string;
    subContent?: string;
    subContentColor?: string;
}

export const IconeTextoDesktop: React.FC<ITPropos> = ({
  align,
  icon,
  content,
  contentColor,
  subContent,
  subContentColor
}: any) => {
  return (
    <div style={{ display: "flex", flex: 1, width: "100%", justifyContent: align }}>
        <div style={{ marginLeft: "10%" }}>
            <div>
                <img loading="lazy" src={icon} alt="icon" />
            </div>
        </div>
        <div>
            <div className="cf-content" style={{ marginTop: subContent ? "-10px" : "0px" }}>
                <p  style={{ color: contentColor ? contentColor : "#000" }}>{content}</p>
            </div>
            {subContent && (
                <div className="cf-subcontent" style={{ marginTop: "-10px" }}>
                    <p  style={{ color: subContentColor ? subContentColor : "#9A9A9A" }}>{subContent}</p>
                </div>
            )}
        </div>
    </div>
  );
};

export const IconeTextoMobile: React.FC<ITPropos> = ({
  align,
  icon,
  content,
  contentColor,
  subContent,
  subContentColor
}: any) => {
  return (
    <>
        <div style={{ display: "flex", flex: 1, width: "100%", justifyContent: align }}>
            <div>
                <div>
                    <img loading="lazy" src={icon} alt="icon" />
                </div>
            </div>
            <div>
                <div className="cf-content">
                    <p>{content}</p>
                </div>
            </div>
        </div>
    </>
  );
};
