import React from "react";
import { CardVantagensDesktop, CardVantagensMobile } from "./card";
import * as S from "../../Home/styles";

import V1P from "../../assets/v1-parceiro.svg";
import V2P from "../../assets/v2-parceiro.svg";
import V3P from "../../assets/v3-parceiro.svg";
import "./vantagens.css";
import { ButtonVender, ButtonVenderMobile } from "../../Home/styles";

interface VantagensProps {
  title: string;
  estilo: any;
}

export const VantagensDesktop: React.FC<any> = ({
  title,
  estilo,
}: VantagensProps) => {
  const iconV: any = { parceiro: ["", V1P, V2P, V3P] };

  return (
    <div className="container-vantagens">
        <S.DesktopCFBoxTitleCol>
            <S.DesktopCFTitle style={{ color: estilo?.backgroundColor }} >
                Vantagens da parceria com a Concash
            </S.DesktopCFTitle>
            <S.DesktopSubTitle>
                A Concash e o BTG Pactual uniram-se para oferecer uma plataforma segura e eficiente para a compra de cotas de consórcio. 
                Seja parceiro e obtenha:
            </S.DesktopSubTitle>
        </S.DesktopCFBoxTitleCol>
        <div className="box-content-vantagens">
            <CardVantagensDesktop
                icon={iconV["parceiro"][1]}
                title="Consultoria personalizada"
                content="Entre em contato conosco para receber orientações sobre a venda da sua cota de consórcio."
                alt="ícone de consultoria personalizada"
            />
            <CardVantagensDesktop
                icon={iconV["parceiro"][2]}
                title="Avaliação da cota"
                content="Você terá acesso a uma calculadora e receberá a proposta na hora."
                alt="ícone de avaliação da cota"
            />
            <CardVantagensDesktop
                icon={iconV["parceiro"][3]}
                title="Conclusão da transação"
                content="Após a aceitação da oferta, a transação será concluída e o pagamento será efetuado de forma rápida e garantida."
                alt="ícone de conclusão da transação"
            />
        </div>

        <div className="box-button" style={{ alignContent: "center", justifyContent: "center" }} >
            <ButtonVender
            href={ "#form" }
            style={{
                background: estilo?.backgroundColor,
                color: "#FFF",
                marginTop: 40,
                maxWidth: 450,
                cursor: "pointer",
            }}
            >
                Quero me tornar um parceiro
            </ButtonVender>
        </div>
    </div>
  );
};
export const VantagensMobile: React.FC<any> = ({
  title,
  estilo,
}: VantagensProps) => {
    const iconV: any = { parceiro: ["", V1P, V2P, V3P] };
  return (
    <div className="container-vantagens-mobile">
        <S.MobileCFBoxTitleCol>
            <S.MobileCFTitle style={{ color: estilo?.backgroundColor }} >
                Vantagens da parceria com a Concash
            </S.MobileCFTitle>
            <S.MobileSubTitle>
                A Concash e o BTG Pactual uniram-se para oferecer uma plataforma segura e eficiente para a compra de cotas de consórcio. 
                Seja parceiro e obtenha:
            </S.MobileSubTitle>
        </S.MobileCFBoxTitleCol>
        <div className="box-content-vantagens-mobile">
            <CardVantagensMobile
                icon={iconV["parceiro"][1]}
                title="Consultoria personalizada"
                content="Entre em contato conosco para receber orientações sobre a venda da sua cota de consórcio."
                alt="ícone de consultoria personalizada"
            />
            <CardVantagensMobile
                icon={iconV["parceiro"][2]}
                title="Avaliação da cota"
                content="Você terá acesso a uma calculadora e receberá a proposta na hora."
                alt="ícone de avaliação da cota"
            />
            <CardVantagensMobile
                icon={iconV["parceiro"][3]}
                title="Conclusão da transação"
                content="Após a aceitação da oferta, a transação será concluída e o pagamento será efetuado de forma rápida e garantida."
                alt="ícone de conclusão da transação"
            />
        </div>

        <S.MobileCFBoxTitleCol style={{ marginTop: "15px" }}>
            <S.MobileSubTitle style={{ fontWeight: "bold" }}>
                Seja parceiro na venda de cotas de consórcio com a concash e o BTG Pactual.
            </S.MobileSubTitle>
        </S.MobileCFBoxTitleCol>
        

        <ButtonVenderMobile
            href={  "#form" }
            style={{
                flex: 1,
                background: estilo?.backgroundColor,
                color: "#FFF",
                minHeight: 45,
                width: "100%",
                textDecoration: "none",
                cursor: "pointer",
                margin: "20px 0",
            }}
        >
           Quero me tornar parceiro
        </ButtonVenderMobile>
    </div>
  );
};
