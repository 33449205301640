import styled from "styled-components";
import { colors } from "../../styles/colors";
import WP from "../../assets/icons/whatsapp-xxl.png";

export const ButtonMain = styled.button`
  display: flex;
  color: #fff;
  width: 237px;
  height: 58px;
  border-color: ${colors.mediumGreen};
  font-family: "Poppins", sans-serif;
  font-display: swap;
  border-radius: 4px;
  font-size: 17px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;

  &:hover {
    background-color: ${colors.softDarkGreen};
  }
  background-color: ${colors.mediumGreen};
`;

export const ButtonLarge = styled.button`
  display: flex;
  color: #fff;
  width: 309px;
  height: 76px;
  border-color: ${colors.mediumGreen};
  font-family: "Poppins", sans-serif;
  font-display: swap;
  border-radius: 4px;
  font-size: 17px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  &:hover {
    background-color: ${colors.softDarkGreen};
  }
  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
  background-color: ${colors.mediumGreen};
`;

export const WhatsAppLargeButton = ({ href }) => {
  const Container = styled.a`
    display: flex;
    flex: 1;
    text-decoration: none;
    color: #fff;
    align-items: center;
    justify-content: center;
    width: 298px;
    gap: 21px;
    min-height: 65px;
    max-height: 65px;
    background: #53c723 0% 0% no-repeat padding-box;
    border-radius: 50px;
    opacity: 1;

    .wp {
      width: 34px;
    }
  `;

  const Text = styled.span`
    font: normal normal bold 20px/23px Roboto;
    color: #ffffff;
    opacity: 1;
  `;
  return (
    <>
      <Container href={href}>
        <Text>Chama no WhatsApp</Text>
        <div style={{}}>
          <img loading="lazy" src={WP} width={35} alt="whatsapp" />
          {}
        </div>
      </Container>
    </>
  );
};
