import axios from "axios";

export function getNavegador(window) {
    if (window.navigator.userAgent.indexOf("Chrome") !== -1) {
        return "Google Chrome";
    } else if (window.navigator.userAgent.indexOf("Firefox") !== -1) {
        return "Mozilla Firefox";
    } else if (window.navigator.userAgent.indexOf("MSIE") !== -1) {
        return "Internet Explorer";
    } else if (window.navigator.userAgent.indexOf("Edge") !== -1) {
        return "Edge";
    } else if (window.navigator.userAgent.indexOf("Safari") !== -1) {
        return "Safari";
    } else if (window.navigator.userAgent.indexOf("Opera") !== -1) {
        return "Opera";
    }
    
    return "Navegador não identificado";
}

export function getSistemaOperacional(window){
    if (window.navigator.appVersion.indexOf("Win") !== -1) {
     return "Windows";
    }else if (window.navigator.appVersion.indexOf("Mac") !== -1) {
        return "MacOS";
    }else if (window.navigator.appVersion.indexOf("X11") !== -1) {
        return "UnixOS";
    }else if (window.navigator.appVersion.indexOf("Linux") !== -1) {
        return "Linux";
    }

    return "Sistema operacional não identificado";
}

export async function getIp(){
    try{
        var data = await axios.get("https://api.ipify.org?format=json");
        return data.data.ip;
    }catch(error){
        return "0.0.0.0";
    }
}