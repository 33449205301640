import React from "react";
import styled from "styled-components";

import { BsCheckLg } from "react-icons/bs";
import { v3Colors } from "../../v3/colors";

export const V3Button = (props) => {
  const { text, width, size, height, onClick, estilo, disabled } = props;
  const backgroundColor = {
    primary: v3Colors.primary,
    secondary: v3Colors.secondary,
    white: v3Colors.secondary,
    cnp: "#D70064",
    santander: "#E90B18",
    bradesco: "#CC092F",
    chevrolet: "#B88030",
    parceiro: "#16888D",
    porto: "#16888D",
    canopus: "#FF8A00",
    volkswagen: "#0F7390",
    randon: "#4587C2",
    bb: "#0067ab",
  };
  const textColor = {
    primary: v3Colors.white,
    cnp: v3Colors.white,
    secondary: v3Colors.primary,
    white: v3Colors.primary,
    santander: v3Colors.white,
    bradesco: v3Colors.white,
    chevrolet: v3Colors.white,
    parceiro: v3Colors.white,
    porto: v3Colors.white,
    canopus: v3Colors.white,
    volkswagen: v3Colors.white,
    randon: v3Colors.white,
    bb: v3Colors.white,
  };
  const fontSize = {
    sm: "0.9375rem",
    md: "1.25rem",
  };

  const padding = {
    sm: "12px 20px",
    md: "18px 40px",
  };

  const StyledLink = styled.button`
    display: flex;
    padding: ${size ? padding[size] : "12px 20px"},
    color: ${textColor[estilo]};
    align-items: center;
    justify-content: center;
    cursor: pointer;
    max-height: ${height ? height : "45px"};

    &:visited {
      color: ${textColor[estilo]};
    }
  `;

  return (
    <>
      <StyledLink
        onClick={onClick}
        style={{
          backgroundColor: backgroundColor[estilo],
          textDecoration: "none",
          border: `2px solid ${backgroundColor[estilo]}`,
          height: height ? height : 45,
          width: width ? width : 160,
          borderRadius: 10,
          textAlign: "center",
          fontFamily: "Roboto",
          fontWeight: "bold",
          fontSize: size ? fontSize[size] : 15,
          disabled: disabled,
          letterSpacing: 0,
          // textColor: textColor[estilo],
        }}
      >
        <span style={{ color: textColor[estilo], padding: 0, margin: 0 }}>
          {text}
        </span>
      </StyledLink>
    </>
  );
};
export const V3ButtonLink = (props) => {
  const {
    white,
    text,
    width,
    size,
    height,
    estilo,
    disabled,
    href,
    target,
    fontSz,
  } = props;
  const backgroundColor = {
    primary: v3Colors.primary,
    secondary: v3Colors.secondary,
    white: v3Colors.secondary,
  };
  const textColor = {
    primary: v3Colors.white,
    secondary: v3Colors.primary,
    white: v3Colors.primary,
  };
  const fontSize = {
    sm: "0.9375rem",
    md: "1.25rem",
  };

  const padding = {
    sm: "12px 20px",
    md: "18px 40px",
  };

  const StyledLink = styled.a`
    display: flex;
  padding: ${size ? padding[size] : "12px 20px"},
    color: ${textColor[estilo]};
    align-items: center;
    text-decoration: none;
    justify-content: center;
    cursor: pointer;
    max-height: ${height ? height : "45px"};

    &:visited {
      color: ${textColor[estilo]};
    }
  `;

  return (
    <>
      <StyledLink
        href={href}
        target={target}
        style={{
          backgroundColor: backgroundColor[estilo],
          textDecoration: "none",
          border: `2px solid ${v3Colors.primary}`,
          height: height ? height : 45,
          width: width ? width : 160,
          borderRadius: 10,
          textAlign: "center",
          fontFamily: "Roboto",
          fontWeight: "bold",
          fontSize: fontSz ? fontSz : size ? fontSize[size] : 15,
          disabled: disabled,
          letterSpacing: 0,
          // textColor: textColor[estilo],
        }}
      >
        <span style={{ color: textColor[estilo], padding: 0, margin: 0 }}>
          {text}
        </span>
      </StyledLink>
    </>
  );
};
export const V3SiteButtonLink = (props) => {
  const { white, text, width, size, height, estilo, disabled, href, target } =
    props;
  const backgroundColor = {
    primary: v3Colors.primary,
    secondary: v3Colors.secondary,
    white: v3Colors.white,
  };
  const textColor = {
    primary: v3Colors.white,
    secondary: v3Colors.primary,
    white: v3Colors.primary,
  };
  const fontSize = {
    sm: "0.9375rem",
    md: "1.25rem",
  };

  const padding = {
    sm: "12px 20px",
    md: "18px 40px",
  };

  const StyledLink = styled.a`
    display: flex;
    padding: ${size ? padding[size] : "0 20px"};
    color: ${textColor[estilo]};
    align-items: center;
    text-decoration: none;
    justify-content: center;
    cursor: pointer;
    background-color: ${backgroundColor[estilo]};

    max-height: ${height ? height : "45px"};

    &:visited {
      color: ${textColor[estilo]};
    }
    &:hover {
      opacity: ${estilo === "white" ? 1 : 0.75};
      color: ${estilo === "white" ? textColor["primary"] : textColor[estilo]};
      background-color: ${estilo === "white"
        ? backgroundColor["primary"]
        : backgroundColor[estilo]};
    }

    span {
      padding: 0;
      margin: 0;
    }
  `;

  return (
    <>
      <StyledLink
        href={href}
        target={target}
        style={{
          textDecoration: "none",
          border: `2px solid ${v3Colors.primary}`,
          height: height ? height : 45,
          width: width ? width : "auto",
          borderRadius: 10,
          textAlign: "center",
          fontFamily: "Roboto",
          fontWeight: "bold",
          fontSize: size ? fontSize[size] : 15,
          disabled: disabled,
          letterSpacing: 0,
          // textColor: textColor[estilo],
        }}
      >
        <span>{text}</span>
      </StyledLink>
    </>
  );
};

export const V3RadioButton = (props) => {
  const { text, width, selected, icon, onClick } = props;

  const RadioContainer = styled.button`
    display: flex;
    max-height: 45px;
    border: ${selected ? `1px solid ${v3Colors.primary}` : "1px solid #ababab"};
    border-radius: 50px;
    padding: 10px;
    background-color: ${selected ? v3Colors.secondary : "#fff"};
    cursor: pointer;
  `;

  const RadioCircle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    border: ${selected ? `1px solid ${v3Colors.primary}` : "1px solid #ababab"};
    background-color: ${selected ? v3Colors.primary : "#fff"};
    border-radius: 50%;
    margin-right: 10px;
  `;

  const RadioTextBox = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
  `;

  const RadioText = styled.span`
    text-align: left;
    font: normal normal normal 15px/20px Roboto;
    letter-spacing: 0px;
    color: ${selected ? v3Colors.primary : "#ababab"};
    font-weight: bold;
    opacity: 1;
  `;

  return (
    <>
      <RadioContainer
        style={{ width: width ? width : 206, alignItems: "center" }}
        onClick={onClick}
      >
        <RadioCircle>
          <BsCheckLg color={selected ? "#FFF" : "transparent"} />
        </RadioCircle>
        <RadioTextBox>
          <RadioText>{text}</RadioText>
          <div
            style={{
              color: selected ? v3Colors.primary : v3Colors.inputBorder,
            }}
          >
            {icon ? icon : ""}
          </div>
        </RadioTextBox>
      </RadioContainer>
    </>
  );
};
