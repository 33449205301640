import styled from "styled-components";
import { colors } from "../../../styles/colors";
import { ScreenDisplay } from "../../../styles/display";

export const Container = styled.div`
  display: flex;
  margin: 0;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 998;
  background: white;
  align-items: center;
  justify-content: space-between;

  .list-items {
    display: flex;
    flex: 1;
    align-items: center;

    @media (max-width: 1265px) {
      padding-left: 90px;
    }
    @media (min-width: 1266px) {
    }
  }

  @media (${ScreenDisplay.desktop}) {
    padding: 0 83px;
    height: 85px;
  }

  @media (${ScreenDisplay.mobile}) {
    padding-top: 20px;
    padding-right: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
    max-height: 80px;
  }
  img {
    width: 174px;
    height: 48px;
  }

  .box-right {
    display: flex;
    align-items: center;
    gap: 17px;
  }

  ul {
    display: flex;
    flex: 1;
    justify-content: space-around;
    align-items: center;
    gap: 17px;
  }

  a.link-main {
    display: flex;
    text-align: left;
    font: normal normal bold 15px/30px Roboto;
    letter-spacing: 0px;
    color: ${colors.darkGreen};
    opacity: 1;
    padding: 20px 6px;
    line-height: 1;
    text-decoration: none;
  }
  span.link-main {
    display: flex;
    text-align: left;
    font: normal normal bold 15px/30px Roboto;
    letter-spacing: 0px;
    color: ${colors.darkGreen};
    opacity: 1;
    padding: 20px 6px;
    line-height: 1;
    text-decoration: none;
    cursor: pointer;
  }
`;

export const HeaderContainerMobile = styled.div`
  display: flex;
  flex: 1;
  z-index: 999;

  position: absolute;

  .box-menu {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: ${colors.darkGreen};
    border-radius: 8px;
    height: 100%;
  }

  .cell {
    display: flex;
  }

  .cell-top {
    display: flex;

    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin-bottom: 40px;
    color: #fff;
  }

  .cell-content {
    display: flex;

    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
    color: #fff;
  }

  .link-arrow {
    text-decoration: none;
    color: #fff;
  }

  .buttons {
    display: flex;
    flex: 1;
    margin-top: 30px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
  }
  .link-btn {
    text-decoration: none;
  }
`;
