import styled from "styled-components";
import { v3Colors } from "../../v3/colors";
import { BsCheck, BsFileEarmarkCheck } from "react-icons/bs";

const PinStepStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  opacity: 1;
`;

const TextActiveStyled = styled.span`
  text-align: left;
  font: normal normal bold 15px/23px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
`;

export const PinStepActive = (props) => {
  const { num, type } = props;
  const background = {
    active: v3Colors.primary,
    deactive: v3Colors.grayLight,
    checked: v3Colors.secondary,
  };

  return (
    <>
      <PinStepStyled
        style={{
          backgroundColor: background[type],
        }}
      >
        {type === "active" && <TextActiveStyled>{num}</TextActiveStyled>}
        {type === "checked" && <BsCheck size={18} color={v3Colors.primary} />}
        {type === "deactive" && <TextActiveStyled>{num}</TextActiveStyled>}
      </PinStepStyled>
    </>
  );
};
