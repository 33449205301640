import React, { useEffect } from "react";
import RoutesWeb from "./routes";
import "./input.css";
import WebFont from "webfontloader";
import { Helmet, HelmetProvider } from 'react-helmet-async';

function App() {

  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Roboto:100,300,400,500,700,900", "Open Sans"],
      },
    });
  }, []);

  return (
    <>
      <HelmetProvider>
        <RoutesWeb />
      </HelmetProvider>
    </>
  );
}

export default App;
