import * as S from "../../Home/styles";
import Mail from "../../assets/mail.svg";
import Phone from "../../assets/phone.svg";
import WhatsApp from "../../assets/WhatsApp.svg";
import Facebook from "../../assets/facebook.svg";
import Instagram from "../../assets/instagram.svg";
import Linkedin from "../../assets/linkedin.svg";
import ReclameAqui from "../../assets/reclameAqui.svg"; 
import Concash from "../../assets/concashlogo.svg"
import HorizontalLineMobile from "../../assets/horizontalLineMobile.svg";
import { IconeTextoDesktop } from "../../components/iconeTexto/IconeTexto";

import "./footer.css";

export const FooterDesktop: any = ({ landing, estilo }: any) => {

  return (
    <div className="footer-box" style={{ backgroundColor: "#343434", height: landing == "parceiro" ? "180px" : "430px" }} >
        <div style={{ display: "flex", flex: 1, width: "100%", justifyContent: "space-between" }}>
            <S.DesktopCFBoxTitleCol style={{ alignItems: "flex-start"}}>
                <div className="cf-content">
                    <a
                        href={require("../../../../../assets/termo/Politica_Privacidade.pdf")}
                        className="termo-link"
                        target="_blank" rel="noreferrer"
                    >
                        <p  style={{ color: "#FFF" }}>Termos de Uso</p>
                    </a>
                </div>
                <div className="cf-content" style={{ marginTop: landing == "parceiro" ? "0px" : "-150px" }}>
                    <a
                        href={require("../../../../../assets/termo/Politica_Privacidade.pdf")}
                        className="termo-link"
                        target="_blank" rel="noreferrer"
                    >
                        Políticas de privacidade
                    </a>
                </div>
            </S.DesktopCFBoxTitleCol>

            <S.DesktopCFBoxTitleCol style={{ alignItems: "flex-start"}}>
                <div style={{ width: "100%"}}>
                    <a
                        href={landing == "parceiro" ? "mailto:parceiros@concash.com.br" : "mailto:voce@concash.com.br"}
                        target="_blank"
                        style={{ textDecoration: "none", color: "#000" }}
                        className="text-contact" rel="noreferrer"
                    >
                        <IconeTextoDesktop 
                            align={ "left" } 
                            content={ landing == "parceiro" ? "parceiros@concash.com.br" : "voce@concash.com.br" }
                            contentColor={ "#FFFFFF" }
                            icon={Mail}
                        />
                    </a>
                </div>

                {landing != "parceiro" && (
                    <>
                        <IconeTextoDesktop 
                            align={ "left" } 
                            content={ "(11) 4040-5686" }
                            contentColor={ "#FFFFFF" }
                            subContent={ "Capitais e Regiões Metropolitanas" }
                            icon={Phone}
                        />

                        <div style={{ width: "100%"}}>
                            <a
                                href="https://api.whatsapp.com/send/?phone=1140405686&text=Ol%C3%A1%2C+quero+vender+minha+cota+de+consorcio+para+a+Concash%21&type=phone_number&app_absent=0"
                                className="text-contact"
                                target="_blank"
                                style={{ textDecoration: "none", color: "#000" }} rel="noreferrer"
                            >
                                <IconeTextoDesktop 
                                    align={ "left" } 
                                    content={ "(11) 4040-5686" }
                                    contentColor={ "#FFFFFF" }
                                    subContent={ "WhatsApp" }
                                    icon={WhatsApp}
                                />
                            </a>
                        </div>

                        <div style={{ display: "flex", flex: 1, width: "100%", flexDirection: "column" }}>
                            <div>
                                <div className="cf-content">
                                    <p  style={{ color: "#FFF", fontWeight: "bold" }}><b>Horário de Atendimento</b></p>
                                </div>
                            </div>
                            <div>
                                <div className="cf-content" style={{ marginTop: "-10px"}}>
                                    <p  style={{ color: "#FFF" }}>Segunda à sexta, das 8h às 18h</p>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </S.DesktopCFBoxTitleCol>

            <S.DesktopCFBoxTitleCol style={{ width: "25%", alignItems: "flex-start"}}>
                <div style={{ display: "flex", flex: 1, width: "100%", flexDirection: "column", alignItems: "center" }}>
                    <div>
                        <div className="cf-content">
                            <p  style={{ color: "#FFF" }}>Siga a gente:</p> <br/>
                        </div>
                    </div>
                    <div style={{ display: "flex", flex: 1, width: "100%", justifyContent: "space-evenly",  flexDirection: "row" }}>
                        <div>
                            <a href="https://www.facebook.com/voceconcash/" className="circle" target="_blank" rel="noreferrer" >
                                <img loading="lazy" src={Facebook} alt="icon" />
                            </a>
                        </div>
                        <div>
                            <a href="https://www.instagram.com/voceconcash" className="circle" target="_blank" rel="noreferrer" >
                                <img loading="lazy" src={Instagram} alt="icon" />
                            </a>
                        </div>
                        <div>
                            <a href="https://www.linkedin.com/company/voceconcash" className="circle" target="_blank" rel="noreferrer" >
                                <img loading="lazy" src={Linkedin} alt="icon" />
                            </a>
                        </div>
                    </div>
                </div>
            </S.DesktopCFBoxTitleCol>

            <S.DesktopCFBoxTitleCol style={{ width: "25%", alignItems: "flex-start"}}>
                <div style={{ display: "flex", flex: 1, width: "100%", flexDirection: "column", alignItems: "center", marginTop: "10px" }}>
                    <div>
                        <a href="https://www.reclameaqui.com.br/empresa/concash-intermediacao-de-negocios-e-participacoes/" target="_blank" rel="noreferrer" >
                            <img loading="lazy" src={ReclameAqui} alt="icon" />
                        </a>
                    </div>
                </div>
            </S.DesktopCFBoxTitleCol>
        </div>

        {landing == "cnp" && (
            <div style={{ display: "flex", flex: 1, width: "100%", justifyContent: "center", alignItems: "center" }}>
                <div className="aviso-rodape-cnp">
                    <p  style={{ color: "#FFF" }}>
                        A CNP Seguradora reúne empresas de Seguros (CNPJ 92.751.213/0001-73), Consórcios 
                        (CNPJ 05.349.595/0001-09), Odonto (CNPJ 40.223.893/0001-59) e Capitalização (CNPJ 01.599.296/0001-71).
                    </p>
                </div>
            </div>
        )}
        <div style={{ display: "flex", flex: 1, width: "100%", justifyContent: "center", alignItems: "center" }}>
            <div>
                <a href="https://www.concash.com.br/" target="_blank" rel="noreferrer" >
                    <img loading="lazy" src={Concash} alt="icon" />
                </a>
            </div>
            <div>
                <div className="cf-content">
                    <p  style={{ color: "#9A9A9A" }}> © Copyright {new Date().getFullYear()} Concash. Todos os direitos reservados</p>
                </div>
            </div>
        </div>
    </div>
  );
};

export const FooterMobile: any = ({ landing, estilo }: any) => {
  return (
    <div className="footer-box-mobile" style={{ backgroundColor: "#343434", height: landing == "parceiro" ?  "530px" :  landing == "cnp" ? "940px" : "793px" }} >
      <div className="box-mobile">
        <a
          href={require("../../../../../assets/termo/Politica_Privacidade.pdf")}
          className="termo-link"
          target="_blank" rel="noreferrer"
        >
          Termos de uso
        </a>
        <a
          href={require("../../../../../assets/termo/Politica_Privacidade.pdf")}
          className="termo-link"
          target="_blank" rel="noreferrer"
        >
          Políticas de privacidade
        </a>

        <img loading="lazy" src={HorizontalLineMobile} style={{ marginTop: "10px" }}  />

        <div style={{ marginTop: "10px" }}>
            <a
                href={landing == "parceiro" ? "mailto:parceiros@concash.com.br" : "mailto:voce@concash.com.br"}
                target="_blank"
                style={{ textDecoration: "none", color: "#000" }}
                className="text-contact" rel="noreferrer"
            >
                <IconeTextoDesktop 
                    align={ "left" } 
                    content={landing == "parceiro" ? "parceiros@concash.com.br" : "voce@concash.com.br"}
                    contentColor={ "#FFFFFF" }
                    icon={Mail}
                />
            </a>

            {landing != "parceiro" && (
                <>
                    <br/><br/>
                    <a
                        href="tel:+551140405686"
                        className="text-contact"
                        target="_blank"
                        style={{ textDecoration: "none", color: "#000" }} rel="noreferrer"
                    >
                        <IconeTextoDesktop 
                            align={ "left" } 
                            content={ "(11) 4040-5686" }
                            contentColor={ "#FFFFFF" }
                            subContent={ "Capitais e Regiões Metropolitanas" }
                            icon={Phone}
                        />
                    </a>
                
                    <br/><br/>

                    <a
                        href="https://api.whatsapp.com/send/?phone=551140405686&text=Ol%C3%A1%2C+quero+vender+minha+cota+de+consorcio+para+a+Concash%21&type=phone_number&app_absent=0"
                        className="text-contact"
                        target="_blank"
                        style={{ textDecoration: "none", color: "#000" }} rel="noreferrer"
                    >
                        <IconeTextoDesktop 
                            align={ "left" } 
                            content={ "(11) 4040-5686" }
                            contentColor={ "#FFFFFF" }
                            subContent={ "WhatsApp" }
                            icon={WhatsApp}
                        />
                    </a>

                    <br/><br/>

                    <div>
                        <div className="cf-content">
                            <p  style={{ color: "#FFF", fontWeight: "bold" }}><b>Horário de Atendimento</b></p>
                        </div>
                    </div>
                    <div>
                        <div className="cf-content" style={{ marginTop: "-10px"}}>
                            <p  style={{ color: "#FFF" }}>Segunda à sexta, das 8h às 18h</p>
                        </div>
                    </div>
                </>
            )}
        </div>

        <div style={{ display: "flex", flex: 1, width: "100%", flexDirection: "column", alignItems: "center", marginTop: "15px" }}>
            <div>
                <div className="cf-content">
                    <p  style={{ color: "#FFF" }}>Siga a gente:</p> <br/>
                </div>
            </div>
            <div style={{ display: "flex", flex: 1, width: "100%", justifyContent: "space-evenly",  flexDirection: "row" }}>
                <div>
                    <a href="https://www.facebook.com/voceconcash/" className="circle" target="_blank" rel="noreferrer" >
                        <img loading="lazy" src={Facebook} alt="icon" />
                    </a>
                </div>
                <div>
                    <a href="https://www.instagram.com/voceconcash" className="circle" target="_blank" rel="noreferrer" >
                        <img loading="lazy" src={Instagram} alt="icon" />
                    </a>
                </div>
                <div>
                    <a href="https://www.linkedin.com/company/voceconcash" className="circle" target="_blank" rel="noreferrer" >
                        <img loading="lazy" src={Linkedin} alt="icon" />
                    </a>
                </div>
            </div>
        </div>

        <div style={{ marginTop: "20px" }}>
            <a href="https://www.reclameaqui.com.br/empresa/concash-intermediacao-de-negocios-e-participacoes/" target="_blank" rel="noreferrer" >
                <img loading="lazy" src={ReclameAqui} alt="icon" />
            </a>
        </div>
 
        {landing == "cnp" && (
            <div>
                <div className="cf-content" style={{ textAlign: "justify" }}>
                    <p  style={{ color: "#FFF" }}>
                        A CNP Seguradora reúne empresas de Seguros (CNPJ 92.751.213/0001-73), Consórcios (CNPJ 05.349.595/0001-09), 
                        Odonto (CNPJ 40.223.893/0001-59) e Capitalização (CNPJ 01.599.296/0001-71).
                    </p>
                </div>
            </div>
        )}

        <div style={{ marginTop: "35px" }}>
            <a href="https://www.concash.com.br/" target="_blank" rel="noreferrer" >
                <img loading="lazy" src={Concash} alt="icon" />
            </a>
            <br/>
        </div>
        <div style={{ marginTop: "-15px" }}>
            <div className="cf-content">
                <p  style={{ color: "#9A9A9A" }}> © Copyright {new Date().getFullYear()} Concash. Todos os direitos reservados</p>
            </div>
        </div>
      </div>
    </div>
  );
};
