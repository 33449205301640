import { useState, useEffect } from "react";
import {
  BoxPinStep,
  BoxStep,
  BoxStepDesktop,
  BoxStepMobile,
  CenteredBox,
  CenteredBoxMobile,
} from "../../../styles/themes/components/box";
import {
  V3Button,
  V3RadioButton,
} from "../../../styles/themes/components/button";
import { PinStepActive } from "../../../styles/themes/components/pin";
import {
  V3PropostaText,
  V3Text5,
  V3TextFooter,
  V3TextH1,
  V3TextInputInfo,
} from "../../../styles/themes/components/texts";
import { v3Colors } from "../../../styles/themes/v3/colors";
import {
  V3CustomTextField,
  V3TextCustom,
  V3TextInput,
} from "../../../styles/themes/components/input";

import { ImInfo } from "react-icons/im";
import { V3Modal } from "../../../styles/themes/components/modal";
import { formataCPF } from "../../../utils/formaters";
import { v3ValidateEmail } from "../../../styles/themes/utils/validate";
import { Checkbox, FormControlLabel } from "@mui/material";
import {
  ContainerDesktopOPT,
  ContainerMobileOPT,
} from "../../../styles/themes/components/shared/styles";
import ConcashLogo from "../../../assets/images/logo-negative.png";
import { BsWhatsapp } from "react-icons/bs";
import { RiPhoneLine } from "react-icons/ri";
import moment from "moment";

export const RejeitouOferta = (props) => {
  const { navigtion } = props;
  const mobile = window.matchMedia("(max-width:768px)").matches;

  const whatsapp = localStorage.getItem("whatsapp");

  useEffect(() => {
    localStorage.removeItem("onboarding");
    localStorage.removeItem("onboardingObj");
    localStorage.removeItem("etapas");
    localStorage.removeItem("idEtapaAtual");
    localStorage.removeItem("etapaAtual");
  }, []);

  return (
    <>
      {mobile && (
        <>
          <ContainerMobileOPT>
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: 20,
              }}
            >
              <img
                loading="lazy"
                onClick={() => (window.location.href = "/")}
                src={ConcashLogo}
                style={{ marginBottom: 10 }}
                width={110}
                height={30}
                alt="concash-logo"
              />
              <V3Text5
                style={{
                  alignSelf: "center",
                  textAlign: "center",
                  marginBottom: 10,
                  padding: "10px 30px ",
                  fontWeight: "400",
                  lineHeight: 1.3,
                  fontSize: 15,
                }}
              >
                Venda seu consórcio online com segurança, rapidez e
                transparência. <br />
                Caso queira saber mais sobre a Concash{" "}
                <a href="/">clique aqui</a>.
              </V3Text5>
              <BoxStepMobile style={{ minHeight: 450 }}>
                <BoxPinStep>
                  <PinStepActive num={1} type="checked" />
                  <PinStepActive num={2} type="checked" />
                  <PinStepActive num={3} type="checked" />
                  <PinStepActive num={4} type="checked" />
                </BoxPinStep>
                <CenteredBoxMobile>
                  <V3TextH1 style={{ textAlign: "center" }}>
                    Obrigado por escolher a Concash!
                  </V3TextH1>
                </CenteredBoxMobile>
                <CenteredBoxMobile
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: 15,
                    gap: 10,
                    padding: "0px 10px",
                  }}
                >
                  <V3Text5 style={{ textAlign: "center" }}>
                    Agradecemos por considerar nossa proposta. Respeitamos sua
                    decisão de não aceitá-la. Caso tenha alguma dúvida ou
                    precise de informações adicionais, estamos à disposição para
                    ajudar.
                  </V3Text5>
                </CenteredBoxMobile>
                <CenteredBoxMobile
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: 15,
                    gap: 10,
                    padding: "0px 10px",
                  }}
                >
                  <V3Text5 style={{ textAlign: "center" }}>
                    Esperamos poder colaborar com você em futuras oportunidades.
                  </V3Text5>
                </CenteredBoxMobile>

                <CenteredBoxMobile style={{ gap: 20, marginTop: 30 }}>
                  <V3Button
                    estilo="secondary"
                    text="Home"
                    onClick={() => (window.location.href = "/")}
                  />
                </CenteredBoxMobile>
              </BoxStepMobile>
            </div>
            <div>
              <V3TextFooter>
                © Copyright {moment(new Date()).format("YYYY")} Concash. Todos
                os direitos reservados{" "}
              </V3TextFooter>
            </div>
          </ContainerMobileOPT>
        </>
      )}
      {!mobile && (
        <ContainerDesktopOPT>
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: 20,
            }}
          >
            <img
              loading="lazy"
              onClick={() => (window.location.href = "/")}
              src={ConcashLogo}
              style={{ marginBottom: 10 }}
              width={110}
              height={30}
              alt="concash"
            />
            <V3Text5
              style={{
                alignSelf: "center",
                textAlign: "center",
                marginBottom: 10,
                padding: "10px 30px ",
                fontWeight: "400",
                lineHeight: 1.3,
                fontSize: 15,
              }}
            >
              Venda seu consórcio online com segurança, rapidez e transparência.{" "}
              <br />
              Caso queira saber mais sobre a Concash{" "}
              <a href="/" style={{ color: v3Colors.grayHard }}>
                clique aqui
              </a>
              .
            </V3Text5>
            <BoxStepDesktop style={{ minHeight: 450 }}>
              <BoxPinStep>
                <PinStepActive num={1} type="checked" />
                <PinStepActive num={2} type="checked" />
                <PinStepActive num={3} type="checked" />
                <PinStepActive num={4} type="checked" />
              </BoxPinStep>
              <CenteredBox>
                <V3TextH1 style={{ textAlign: "center" }}>
                  Obrigado por escolher a Concash!
                </V3TextH1>
              </CenteredBox>
              <CenteredBox
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 15,
                  gap: 10,
                  padding: "0px 100px",
                }}
              >
                <V3Text5 style={{ textAlign: "center" }}>
                  Agradecemos por considerar nossa proposta. Respeitamos sua
                  decisão de não aceitá-la. Caso tenha alguma dúvida ou precise
                  de informações adicionais, estamos à disposição para ajudar.
                </V3Text5>
              </CenteredBox>
              <CenteredBox
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 15,
                  gap: 10,
                  padding: "0px 100px",
                }}
              >
                <V3Text5 style={{ textAlign: "center" }}>
                  Esperamos poder colaborar com você em futuras oportunidades.
                </V3Text5>
              </CenteredBox>

              <CenteredBox
                style={{
                  marginTop: 15,
                  padding: "0px 100px",
                }}
              ></CenteredBox>

              <CenteredBoxMobile style={{ gap: 20, marginTop: 30 }}>
                <V3Button
                  estilo="primary"
                  text="Home"
                  onClick={() => (window.location.href = "/")}
                />
              </CenteredBoxMobile>
            </BoxStepDesktop>
          </div>

          <div style={{ position: "relative", bottom: -15 }}>
            <V3TextFooter style={{ textAlign: "center" }}>
              © Copyright {moment(new Date()).format("YYYY")} Concash.
              <br />
              Todos os direitos reservados{" "}
            </V3TextFooter>
          </div>
        </ContainerDesktopOPT>
      )}
    </>
  );
};
