import { useState, useEffect } from "react";
import {
  BoxPinStep,
  BoxStep,
  BoxStepDesktop,
  BoxStepMobile,
  CenteredBox,
  CenteredBoxMobile,
} from "../../../styles/themes/components/box";
import {
  V3Button,
  V3RadioButton,
} from "../../../styles/themes/components/button";
import { PinStepActive } from "../../../styles/themes/components/pin";
import {
  V3PropostaText,
  V3Text5,
  V3TextH1,
  V3TextInputInfo,
  V3Text5Center,
} from "../../../styles/themes/components/texts";
import { v3Colors } from "../../../styles/themes/v3/colors";
import {
  V3CustomTextField,
  V3TextCustom,
  V3TextInput,
} from "../../../styles/themes/components/input";
import moment from "moment";
import FloatButton from "../../../components/FloatButton";
import { BsWhatsapp } from "react-icons/bs";
import { ImInfo } from "react-icons/im";
import { V3Modal } from "../../../styles/themes/components/modal";
import { formataCPF } from "../../../utils/formaters";
import { v3FormatCurrency } from "../../../styles/themes/utils/format";
import api from "../../../services/api";

export const Step4Desktop = (props) => {
  const { setCurrentStep } = props;
  const [ready, setReady] = useState(false);
  const [onboarding, setOnboarding] = useState("");
  const [etapas, setEtapas] = useState(null);
  const [idEtapa, setIdEtapa] = useState("");
  const [valorOferta, setValorOferta] = useState("");
  const [validadeOferta, setValidadeOferta] = useState("");
  const [aceitaOferta, setAceitaOferta] = useState("");
  const [idOferta, setIdOferta] = useState("");

  function handlePrev() {
    setCurrentStep(3);
  }

  async function handleAccept() {
    await api.patch(
      `/api/concash/1.0/cliente-cadastro/onboarding/avancar/${onboarding}/${idEtapa}`,
      [
        {
          nome: "valorOferta",
          valor: valorOferta,
        },
        {
          nome: "aceitaOferta",
          valor: "SIM",
        },
        {
          nome: "idProposta",
          valor: idOferta,
        },
      ]
    );
    setCurrentStep(4);
  }

  async function handleReject() {
    await api
      .patch(
        `/api/concash/1.0/cliente-cadastro/onboarding/avancar/${onboarding}/${idEtapa}`,
        [
          {
            nome: "valorOferta",
            valor: valorOferta,
          },
          {
            nome: "aceitaOferta",
            valor: "NAO",
          },
          {
            nome: "idProposta",
            valor: idOferta,
          },
        ]
      )
      .then(() => (window.location.href = "/rejeitou-oferta"));
  }

  async function recuperarEtapas() {
    const on = localStorage.getItem("vc");
    const etp = await api.get(
      `/api/concash/1.0/cliente-cadastro/onboarding/recuperar/${on}`
    );

    setIdEtapa(await etp.data.etapas[3].id);
    const idO = await etp.data.etapas[3].campos.find(
      (i) => i.nome === "idProposta"
    ).valor;
    const off = await etp.data.etapas[3].campos.find(
      (i) => i.nome === "valorOferta"
    ).valor;
    const val = await etp.data.etapas[3].campos.find(
      (i) => i.nome === "validadeOferta"
    ).valor;
    localStorage.setItem("vcObj", `${JSON.stringify(await etp.data)}`);
    localStorage.setItem("etapaAtualO", 4);
    localStorage.setItem("idEtapaAtualO", await etp.data.etapas[3].id);
    setIdOferta(idO);
    setValorOferta(off);
    setValidadeOferta(val);

    localStorage.setItem("etapasO", `${JSON.stringify(etp.data.etapas)}`);
  }

  useEffect(() => {
    handlePrev();
    //window.location.href = "/vc-sucesso";
    // const on = localStorage.getItem("vc");
    // setOnboarding(on);
    // recuperarEtapas();
  }, []);

  return (
    <>
    {ready && (
    <>
      <BoxStepDesktop style={{ minHeight: 450 }}>
        <BoxPinStep>
          <PinStepActive num={1} type="checked" />
          <PinStepActive num={2} type="checked" />
          <PinStepActive num={3} type="active" />
          <PinStepActive num={4} type="deactive" />
        </BoxPinStep>
        <CenteredBox>
          <V3TextH1>A melhor oferta é:</V3TextH1>
        </CenteredBox>
        <CenteredBox style={{ marginTop: 50 }}>
          <V3PropostaText>{v3FormatCurrency(valorOferta)}</V3PropostaText>
        </CenteredBox>
        <CenteredBox>
          <V3Text5>
            Oferta válida até {moment(validadeOferta).format("DD/MM/YYYY")} *
          </V3Text5>
        </CenteredBox>
        <br />
        <CenteredBox>
          <V3Text5Center>
            Este valor será pago em até 5 dias úteis após a assinatura do termo
            e do contrato diretamente na sua conta. Tudo de forma rápida, segura
            e 100% digital.​
          </V3Text5Center>
        </CenteredBox>
        <CenteredBox
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            alignItems: "center",
            gap: 15,
          }}
        >
          <div style={{ display: "flex", gap: 20 }}>
            <V3Button
              width={206}
              text={"Voltar"}
              estilo="secondary"
              onClick={handlePrev}
            />
            <V3Button
              text="QUERO VENDER"
              width={206}
              estilo="primary"
              onClick={handleAccept}
            />
          </div>
          <V3Text5 style={{ cursor: "pointer" }} onClick={handleReject}>
            Não tenho interesse
          </V3Text5>
        </CenteredBox>
      </BoxStepDesktop>
    </>
   )}
    </>
  );
};
export const Step4Mobile = (props) => {
  const [ready, setReady] = useState(false);
  const { setCurrentStep } = props;
  const [onboarding, setOnboarding] = useState("");
  const [etapas, setEtapas] = useState(null);
  const [idEtapa, setIdEtapa] = useState("");
  const [valorOferta, setValorOferta] = useState("");
  const [validadeOferta, setValidadeOferta] = useState("");
  const [aceitaOferta, setAceitaOferta] = useState("");
  const [idOferta, setIdOferta] = useState("");

  function handlePrev() {
    setCurrentStep(3);
  }

  async function handleAccept() {
    await api.patch(
      `/api/concash/1.0/cliente-cadastro/onboarding/avancar/${onboarding}/${idEtapa}`,
      [
        {
          nome: "valorOferta",
          valor: valorOferta,
        },
        {
          nome: "aceitaOferta",
          valor: "SIM",
        },
        {
          nome: "idProposta",
          valor: idOferta,
        },
      ]
    );
    setCurrentStep(4);
  }

  async function handleReject() {
    await api
      .patch(
        `/api/concash/1.0/cliente-cadastro/onboarding/avancar/${onboarding}/${idEtapa}`,
        [
          {
            nome: "valorOferta",
            valor: valorOferta,
          },
          {
            nome: "aceitaOferta",
            valor: "NAO",
          },
          {
            nome: "idProposta",
            valor: idOferta,
          },
        ]
      )
      .then(() => (window.location.href = "/sucesso-oferta"));
  }

  async function recuperarEtapas() {
    const on = localStorage.getItem("vc");
    const etp = await api.get(
      `/api/concash/1.0/cliente-cadastro/onboarding/recuperar/${on}`
    );

    setIdEtapa(await etp.data.etapas[3].id);
    const idO = await etp.data.etapas[3].campos.find(
      (i) => i.nome === "idProposta"
    ).valor;
    const off = await etp.data.etapas[3].campos.find(
      (i) => i.nome === "valorOferta"
    ).valor;
    const val = await etp.data.etapas[3].campos.find(
      (i) => i.nome === "validadeOferta"
    ).valor;
    localStorage.setItem("vcObj", `${JSON.stringify(await etp.data)}`);
    localStorage.setItem("etapaAtualO", 4);
    localStorage.setItem("idEtapaAtualO", await etp.data.etapas[3].id);
    setIdOferta(idO);
    setValorOferta(off);
    setValidadeOferta(val);

    localStorage.setItem("etapasO", `${JSON.stringify(etp.data.etapas)}`);
  }

  useEffect(() => {
    handlePrev();
    //window.location.href = "/vc-sucesso";
    // const on = localStorage.getItem("vc");
    // setOnboarding(on);
    // recuperarEtapas();
  }, []);

  return (
    <>
    {ready && (
    <>
      <BoxStepMobile>
        <BoxPinStep>
          <PinStepActive num={1} type="checked" />
          <PinStepActive num={2} type="checked" />
          <PinStepActive num={3} type="active" />
          <PinStepActive num={4} type="deactive" />
        </BoxPinStep>
        <CenteredBoxMobile>
          <V3TextH1>A melhor oferta é:</V3TextH1>
        </CenteredBoxMobile>
        <CenteredBoxMobile style={{ marginTop: 20 }}>
          <V3PropostaText>{v3FormatCurrency(valorOferta)}</V3PropostaText>
        </CenteredBoxMobile>
        <CenteredBoxMobile>
          <V3Text5>
            Oferta válida até {moment(validadeOferta).format("DD/MM/YYYY")} *
          </V3Text5>
        </CenteredBoxMobile>
        <br />
        <CenteredBoxMobile>
          <V3Text5Center>
            Este valor será pago em até 5 dias úteis após a assinatura do termo
            e do contrato diretamente na sua conta.
            <br /> Tudo de forma rápida, segura e 100% digital.​
          </V3Text5Center>
        </CenteredBoxMobile>
        <CenteredBoxMobile
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            marginTop: 30,
            marginBottom: 30,
            gap: 10,
          }}
        >
          <V3Button
            style={{ marginBottom: 30 }}
            width={206}
            text={"Voltar"}
            estilo="secondary"
            onClick={handlePrev}
          />
          <V3Button
            text="QUERO VENDER"
            width={206}
            estilo="primary"
            onClick={handleAccept}
          />
        </CenteredBoxMobile>
        <CenteredBoxMobile
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <V3Text5
            style={{ fontWeight: "bold", fontSize: 20 }}
            onClick={handleReject}
          >
            Não tenho interesse
          </V3Text5>
        </CenteredBoxMobile>
      </BoxStepMobile>
    </>
    )}
    </>
  );
};
