import { useState, useEffect } from "react";
import {
  BoxPinStep,
  BoxStepDesktop,
  BoxStepMobile,
  CenteredBox,
  CenteredBoxMobile,
} from "../../../styles/themes/components/box";
import {
  V3Button,
  V3RadioButton,
} from "../../../styles/themes/components/button";
import { PinStepActive } from "../../../styles/themes/components/pin";
import {
  V3TextH1,
} from "../../../styles/themes/components/texts";
import api from "../../../services/api";

import { AiFillCar } from "react-icons/ai";
import {
  BsFileEarmarkCheck,
  BsFileEarmarkExcel,
  BsHouse,
  BsTools,
} from "react-icons/bs";
import { V3Modal } from "../../../styles/themes/components/modal";
import { FaMotorcycle } from "react-icons/fa";
import {  V3Searcheable } from "../../../components/Selects";
import moment from "moment";
import { DateTime } from "luxon";
import { verifyDate } from "..";
import TagManager from 'react-gtm-module'
import { administradorasList } from "./data.content";
export const Step1Desktop = (props) => {
  const { recData, setCurrentStep, onboarding, etapas } = props;
  const [admSelect, setAdmSelect] = useState(administradorasList);
  const [adm, setAdm] = useState("");
  const [admDesc, setAdmDesc] = useState("");
  const [tipo, setTipo] = useState(null);
  const [status, setStatus] = useState(null);
  const [idEtapa, setIdEtapa] = useState("");
  const [onb, setOnb] = useState(localStorage.getItem("vc"));
  const [recInitial, setRecInitial] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [hasSuccess, setHasSuccess] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [successText, setSuccessText] = useState("");
  const [channel, setChannel] = useState();
  const [timeZone, setTimeZone] = useState("");
  const [horaAcesso, setHoraAcesso] = useState("");

  const [startForm, setStartForm] = useState("");

  function handleGetDataAccess() {
    if (startForm) {
      return;
    }
    const acessado = moment([], true).format("DD/MM/YYYY  HH:mm:ss");
    setStartForm(acessado);
    localStorage.setItem("startForm", acessado);
    const tz = DateTime.local().zone.name;
    setTimeZone(tz);
    localStorage.setItem("startForm", acessado);
    localStorage.setItem("timeZone", tz);
  }

  function horaAcessado() {
    const acessado = moment([], true).format("DD/MM/YYYY  HH:mm:ss");
    setHoraAcesso(acessado);
    localStorage.setItem("horaAcesso", acessado);
  }

  async function recuperarEtapas() {
    const vcObj = JSON.parse(localStorage.getItem("vcObj"));

    if (!vcObj) {
      const on = localStorage.getItem("vc");
      const etp = await api.get(
        `/api/concash/1.0/cliente-cadastro/onboarding/recuperar/${on}`
      );
      localStorage.setItem("vcObj", `${JSON.stringify(etp.data)}`);
      localStorage.setItem("etapasO", `${JSON.stringify(etp.data.etapas)}`);
      const tipoR = await etp.data.etapas[0].campos.find(
        (i) => i.nome === "idTipoConsorcio"
      ).valor;

      const statusR = await etp.data.etapas[0].campos.find(
        (i) => i.nome === "statusCota"
      ).valor;
      setTipo(tipoR);
      setStatus(statusR);
      setIdEtapa(etp.data.etapas[0].id);
    }

    setOnb(vcObj.id);

    setTipo(
      vcObj &&
        vcObj.etapas[0].campos.find((i) => i.nome === "idTipoConsorcio").valor
    );
    setStatus(
      vcObj && vcObj.etapas[0].campos.find((i) => i.nome === "statusCota").valor
    );
    setIdEtapa(vcObj && vcObj.etapas[0].id);
  }

  async function handleNext(e) {
    e.preventDefault();

    if (!adm) {
      setHasError(true);
      return setErrorText("Por favor selecione Administradora.");
    }

    if (!tipo) {
      setHasError(true);
      return setErrorText("Preencha o tipo de consórcio corretamente.");
    }
    if (!status) {
      setHasError(true);
      return setErrorText("Preencha o status da cota corretamente.");
    }

    const on = localStorage.getItem("vc");
    const idEtapaInicial = localStorage.getItem("idEtapaO");
    localStorage.setItem("tipoConsorcioO", tipo);
    localStorage.setItem("statusCotaO", status);
    await api
      .patch(
        `/api/concash/1.0/cliente-cadastro/onboarding/avancar/${on}/${idEtapaInicial}`,
        [
          {
            nome: "idAdministradora",
            valor: adm,
          },
          {
            nome: "statusCota",
            valor: status,
          },
          {
            nome: "idTipoConsorcio",
            valor: `${tipo}`,
          },
          {
            nome: "idCanal",
            valor: `${channel}`,
          },
        ]
      )
      .then(async ({ data }) => {
        const tagManagerArgs = {
          gtmId: 'GTM-WB757J7'
        }
        var tipoConsorcio = ''
        if (tipo === "1") {
          tipoConsorcio = 'Imóvel'
        } else if (tipo === "2") {
          tipoConsorcio = 'Serviço'
        } else if (tipo === "3") {
          tipoConsorcio = 'Automóvel'
        } else {
          tipoConsorcio = 'Motocicleta'
        }
        var statusCota = ''
        if (status === "1") {
          statusCota = 'ATIVA'
        } else {
          statusCota = 'CANCELADA'
        }
        TagManager.initialize(tagManagerArgs);
        window.dataLayer.push({
          event: "solicitacao-proposta-site-1",
          administradora: admDesc,
          tipo: tipoConsorcio,
          status: statusCota
        });
        setCurrentStep(2);
      }).catch(() => {
        setErrorText("Houve um erro na requisição");
        setHasError(true);
      })
  }

  async function persistData() {
    const on = localStorage.getItem("vc");
    setOnb(on);
    if (on && (on !== null) & (on !== "null")) {
      const etp = await api.get(
        `/api/concash/1.0/cliente-cadastro/onboarding/recuperar/${on}`
      );
      const tipoR = await etp.data.etapas[0].campos.find(
        (i) => i.nome === "idTipoConsorcio"
      ).valor;

      const statusR = await etp.data.etapas[0].campos.find(
        (i) => i.nome === "statusCota"
      ).valor;
      const admR = await etp.data.etapas[0].campos.find(
        (i) => i.nome === "idAdministradora"
      ).valor;
      const admDescr = await admSelect.find((i) => {
        if (i.id == admR) {
          return i.label;
        }
      });

      setAdm(admR);
      setAdmDesc(admDescr);
      setTipo(tipoR);
      setStatus(statusR);

      setIdEtapa(etp.data.etapas[0].id);
    }
  }

  function handleChangeSelectAdm(e) {
    if (!e) {
      setAdm(null);
      setAdmDesc("");
    } else {
      const { id, label } = e;
      localStorage.setItem("administradoraO", `${id}`);
      localStorage.setItem("administradoraODesc", label);
      if (id == "125")
        localStorage.setItem(
          "canopusAceite",
          moment([], true).format("DD/MM/YYYY  HH:mm:ss")
        );
      setAdm(`${id}`);
      setRecInitial(null);

      setAdmDesc(label);
    }
  }

  function getChannel() {
    const ch = localStorage.getItem("channel");
    if (!ch || ch == null) {
      setChannel("21");
    } else {
      setChannel(ch);
    }
  }

  useEffect(() => {
    persistData();
  }, [setCurrentStep]);

  useEffect(() => {
    verifyDate();
    horaAcessado();
    handleGetDataAccess();
    getChannel();
    const vcObj = JSON.parse(localStorage.getItem("vcObj"));
    const vc = localStorage.getItem("vc");
    setOnb(vc);
    setIdEtapa(vcObj && vcObj?.etapas[0].id);
    if (!vcObj && vc) {
      recuperarEtapas();
    } else {
      setOnb(vc);

      setIdEtapa(vcObj && vcObj.etapas[0].id);
    }
  }, []);

  return (
    <>
      <V3Modal
        open={hasError}
        actionClose={setHasError}
        title={"Atenção!"}
        text={errorText}
        type="error"
      />
      <V3Modal
        open={hasSuccess}
        actionClose={setHasSuccess}
        title={"Sucesso!"}
        text={successText}
        type="success"
      />

      <BoxStepDesktop>
        <BoxPinStep>
          <PinStepActive num={1} type="active" />
          <PinStepActive num={2} type="deactive" />
          <PinStepActive num={3} type="deactive" />
          <PinStepActive num={4} type="deactive" />
        </BoxPinStep>
        <CenteredBox>
          <V3TextH1>Qual a administradora do seu consórcio?</V3TextH1>
        </CenteredBox>
        <CenteredBox style={{ marginTop: 15, padding: "0 100px" }}>
          <V3Searcheable
            id="administradoras"
            sx={{ borderRadius: 15 }}
            name="administradoras"
            action={handleChangeSelectAdm}
            options={admSelect}
            value={admDesc}
            label="Administradora do Consórcio"
            initial={recInitial}
          />
        </CenteredBox>
        <CenteredBox style={{ marginTop: 30 }}>
          <V3TextH1>Qual o tipo do seu consórcio?</V3TextH1>
        </CenteredBox>

        <CenteredBox style={{ gap: 20, marginTop: 15 }}>
          <V3RadioButton
            text="Automóvel"
            selected={tipo === "3"}
            icon={<AiFillCar size={20} />}
            onClick={() => {
              setTipo("3");
            }}
          />
          <V3RadioButton
            text="Imóvel"
            selected={tipo === "1"}
            icon={<BsHouse size={20} />}
            onClick={() => {
              setTipo("1");
            }}
          />
        </CenteredBox>
        <CenteredBox style={{ gap: 20, marginTop: 15 }}>
          <V3RadioButton
            text="Serviço"
            selected={tipo === "2"}
            icon={<BsTools size={20} />}
            onClick={() => {
              setTipo("2");
            }}
          />
          <V3RadioButton
            text="Motocicleta"
            selected={tipo === "4"}
            icon={<FaMotorcycle size={20} />}
            onClick={() => {
              setTipo("4");
            }}
          />
        </CenteredBox>
        {tipo && (
          <>
            <CenteredBox style={{ marginTop: 30 }}>
              <V3TextH1>Qual o status da sua cota?</V3TextH1>
            </CenteredBox>

            <CenteredBox style={{ gap: 20, marginTop: 15 }}>
              <V3RadioButton
                text="Ativa"
                selected={status === "1"}
                icon={<BsFileEarmarkCheck size={20} />}
                onClick={() => setStatus("1")}
              />
              <V3RadioButton
                text="Cancelada"
                selected={status === "0"}
                icon={<BsFileEarmarkExcel size={20} />}
                onClick={() => setStatus("0")}
              />
            </CenteredBox>
          </>
        )}

        <div
          style={{
            display: "flex",
            flex: 1,
            marginTop: 30,
            marginBottom: 10,
            paddingRight: 109,
            justifyContent: "flex-end",
          }}
        >
          <V3Button
            onClick={handleNext}
            style={{ marginBottom: 30 }}
            width={206}
            text={"Avançar"}
            estilo="primary"
          />
        </div>
      </BoxStepDesktop>
    </>
  );
};

export const Step1Mobile = (props) => {
  const { recData, setCurrentStep, onboarding, etapas } = props;
  const [admSelect, setAdmSelect] = useState(administradorasList);
  const [adm, setAdm] = useState("");
  const [admDesc, setAdmDesc] = useState("");
  const [tipo, setTipo] = useState(null);
  const [status, setStatus] = useState(null);
  const [idEtapa, setIdEtapa] = useState("");
  const [onb, setOnb] = useState(localStorage.getItem("vc"));
  const [recInitial, setRecInitial] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [hasSuccess, setHasSuccess] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [successText, setSuccessText] = useState("");
  const [channel, setChannel] = useState("");

  const [timeZone, setTimeZone] = useState("");
  const [horaAcesso, setHoraAcesso] = useState("");

  const [startForm, setStartForm] = useState("");

  function handleGetDataAccess() {
    if (startForm) {
      return;
    }
    const acessado = moment([], true).format("DD/MM/YYYY  HH:mm:ss");
    setStartForm(acessado);
    localStorage.setItem("startForm", acessado);
    const tz = DateTime.local().zone.name;
    setTimeZone(tz);
    localStorage.setItem("startForm", acessado);
    localStorage.setItem("timeZone", tz);
  }

  function horaAcessado() {
    const acessado = moment([], true).format("DD/MM/YYYY  HH:mm:ss");
    setHoraAcesso(acessado);
    localStorage.setItem("horaAcesso", acessado);
  }

  async function recuperarEtapas() {
    const vcObj = JSON.parse(localStorage.getItem("vcObj"));

    if (!vcObj) {
      const on = localStorage.getItem("vc");
      const etp = await api.get(
        `/api/concash/1.0/cliente-cadastro/onboarding/recuperar/${on}`
      );
      localStorage.setItem("vcObj", `${JSON.stringify(etp.data)}`);
      localStorage.setItem("etapasO", `${JSON.stringify(etp.data.etapas)}`);
      const tipoR = await etp.data.etapas[0].campos.find(
        (i) => i.nome === "idTipoConsorcio"
      ).valor;

      const statusR = await etp.data.etapas[0].campos.find(
        (i) => i.nome === "statusCota"
      ).valor;
      setTipo(tipoR);
      setStatus(statusR);
      setIdEtapa(etp.data.etapas[0].id);
    }

    setOnb(vcObj.id);

    setTipo(
      vcObj &&
        vcObj.etapas[0].campos.find((i) => i.nome === "idTipoConsorcio").valor
    );
    setStatus(
      vcObj && vcObj.etapas[0].campos.find((i) => i.nome === "statusCota").valor
    );
    setIdEtapa(vcObj && vcObj.etapas[0].id);
  }

  async function handleNext(e) {
    e.preventDefault();

    if (!adm) {
      setHasError(true);
      return setErrorText("Por favor selecione Administradora.");
    }

    if (!tipo) {
      setHasError(true);
      return setErrorText("Preencha o tipo de consórcio corretamente.");
    }
    if (!status) {
      setHasError(true);
      return setErrorText("Preencha o status da cota corretamente.");
    }

    const on = localStorage.getItem("vc");
    const idEtapaInicial = localStorage.getItem("idEtapaO");
    localStorage.setItem("tipoConsorcioO", tipo);
    localStorage.setItem("statusCotaO", status);
    await api
      .patch(
        `/api/concash/1.0/cliente-cadastro/onboarding/avancar/${on}/${idEtapaInicial}`,
        [
          {
            nome: "idAdministradora",
            valor: adm,
          },
          {
            nome: "statusCota",
            valor: status,
          },
          {
            nome: "idTipoConsorcio",
            valor: `${tipo}`,
          },
          {
            nome: "idCanal",
            valor: `${channel}`,
          },
        ]
      )
      .then(async ({ data }) => {

        const tagManagerArgs = {
          gtmId: 'GTM-WB757J7'
        }
      var tipoConsorcio = ''
      if(tipo==="1"){
        tipoConsorcio = 'Imóvel'
      }else if(tipo==="2"){
        tipoConsorcio = 'Serviço'
      }else if(tipo==="3"){
        tipoConsorcio = 'Automóvel'
      }else{
        tipoConsorcio='Motocicleta'
      }
      var statusCota = ''
      if(status==="1"){
        statusCota = 'ATIVA'
      }else{
        statusCota='CANCELADA'
      }
      TagManager.initialize(tagManagerArgs);
      window.dataLayer.push({
        event: "solicitacao-proposta-site-1",
        administradora: admDesc ,
        tipo: tipoConsorcio,
        status: statusCota
      });    

        setCurrentStep(2);
      }).catch(() => {
        setErrorText("Houve um erro na requisição");
        setHasError(true);
      })
  }

  async function persistData() {
    const on = localStorage.getItem("vc");
    setOnb(on);
    if (on && (on !== null) & (on !== "null")) {
      const etp = await api.get(
        `/api/concash/1.0/cliente-cadastro/onboarding/recuperar/${on}`
      );
      const tipoR = await etp.data.etapas[0].campos.find(
        (i) => i.nome === "idTipoConsorcio"
      ).valor;

      const statusR = await etp.data.etapas[0].campos.find(
        (i) => i.nome === "statusCota"
      ).valor;
      const admR = await etp.data.etapas[0].campos.find(
        (i) => i.nome === "idAdministradora"
      ).valor;
      const admDescr = await admSelect.find((i) => {
        if (i.id == admR) {
          return i.label;
        }
      });

      setAdm(admR);
      setAdmDesc(admDescr);
      setTipo(tipoR);
      setStatus(statusR);

      setIdEtapa(etp.data.etapas[0].id);
    }
  }

  function handleChangeSelectAdm(e) {
    if (!e) {
      setAdm(null);
      setAdmDesc("");
    } else {
      const { id, label } = e;
      localStorage.setItem("administradoraO", `${id}`);
      localStorage.setItem("administradoraODesc", label);
      if (id == "125")
        localStorage.setItem(
          "canopusAceite",
          moment([], true).format("DD/MM/YYYY  HH:mm:ss")
        );
      setAdm(`${id}`);
      setRecInitial(null);
      setAdmDesc(label);
    }
  }

  function getChannel() {
    const ch = localStorage.getItem("channel");
    if (!ch || ch == null) {
      setChannel("21");
    } else {
      setChannel(ch);
    }
  }

  useEffect(() => {
    persistData();
  }, [setCurrentStep]);

  useEffect(() => {
    
    verifyDate();
    horaAcessado();
    handleGetDataAccess();
    getChannel();
    const vcObj = JSON.parse(localStorage.getItem("vcObj"));
    const vc = localStorage.getItem("vc");
    setOnb(vc);
    setIdEtapa(vcObj && vcObj?.etapas[0].id);
    if (!vcObj && vc) {
      recuperarEtapas();
    } else {
      setOnb(vc);

      setIdEtapa(vcObj && vcObj.etapas[0].id);
    }
  }, []);

  return (
    <>
      <V3Modal
        open={hasError}
        actionClose={setHasError}
        title={"Atenção!"}
        text={errorText}
        type="error"
      />
      <V3Modal
        open={hasSuccess}
        actionClose={setHasSuccess}
        title={"Sucesso!"}
        text={successText}
        type="success"
      />
      <BoxStepMobile>
        <BoxPinStep>
          <PinStepActive num={1} type="active" />
          <PinStepActive num={2} type="deactive" />
          <PinStepActive num={3} type="deactive" />
          <PinStepActive num={4} type="deactive" />
        </BoxPinStep>
        <CenteredBoxMobile>
          <V3TextH1 style={{ textAlign: "center" }}>
            Qual a administradora do seu consórcio?
          </V3TextH1>
        </CenteredBoxMobile>
        <CenteredBoxMobile style={{ marginTop: 15 }}>
          <V3Searcheable
            id="administradoras"
            sx={{ borderRadius: 15 }}
            name="administradoras"
            action={handleChangeSelectAdm}
            options={admSelect}
            value={admDesc}
            label="Administradora do Consórcio"
            initial={recInitial}
          />
        </CenteredBoxMobile>
        <CenteredBoxMobile style={{ marginTop: 30 }}>
          <V3TextH1>Qual o tipo do seu consórcio?</V3TextH1>
        </CenteredBoxMobile>

        <CenteredBoxMobile style={{ gap: 20, marginTop: 15 }}>
          <V3RadioButton
            text="Veículo"
            selected={tipo === "3"}
            icon={<AiFillCar size={20} />}
            onClick={() => {
              setTipo("3");
            }}
          />
          <V3RadioButton
            text="Imóvel"
            selected={tipo === "1"}
            icon={<BsHouse size={20} />}
            onClick={() => {
              setTipo("1");
            }}
          />
        </CenteredBoxMobile>
        <CenteredBoxMobile style={{ gap: 20, marginTop: 15 }}>
          <V3RadioButton
            text="Serviço"
            selected={tipo === "2"}
            icon={<BsTools size={20} />}
            onClick={() => {
              setTipo("2");
            }}
          />
          <V3RadioButton
            text="Motocicleta"
            selected={tipo === "4"}
            icon={<FaMotorcycle size={20} />}
            onClick={() => {
              setTipo("4");
            }}
          />
        </CenteredBoxMobile>
        {tipo && (
          <>
            <CenteredBoxMobile style={{ marginTop: 30 }}>
              <V3TextH1>Qual o status da sua cota?</V3TextH1>
            </CenteredBoxMobile>

            <CenteredBoxMobile style={{ gap: 20, marginTop: 15 }}>
              <V3RadioButton
                text="Ativa"
                selected={status === "1"}
                icon={<BsFileEarmarkCheck size={20} />}
                onClick={() => setStatus("1")}
              />
              <V3RadioButton
                text="Cancelada"
                selected={status === "0"}
                icon={<BsFileEarmarkExcel size={20} />}
                onClick={() => setStatus("0")}
              />
            </CenteredBoxMobile>
          </>
        )}

        <div
          style={{
            display: "flex",
            flex: 1,
            marginTop: 30,
            paddingRight: 15,
            justifyContent: "flex-end",
          }}
        >
          <V3Button
            onClick={handleNext}
            style={{ display: "flex", height: 45 }}
            text={"Avançar"}
            size="md"
            estilo="primary"
          />
        </div>
      </BoxStepMobile>
    </>
  );
};
