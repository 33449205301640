export const v3Colors = {
  white: "#FFF",
  black: "#000000",
  primary: "#16898E",
  primaryTop: "#16878C",
  secondary: "#E4F8F8",
  redAlert: "#E90B18",
  grayHard: "#898989",
  grayMedium: "#979797",
  grayLight: "#EDEDED",
  inputBorder: "#ABABAB",
  inputDisabled: "#F5F5F5",
  inputTitle: "#979797",
  inputSuccess: "#05898F",
  infoBoxBorder: "#E2D489",
  infoBoxBg: "#FDFFCE",
  infoBoxTip: "#E5C615",
  greenLight: "#00C43A",
  whatsapp: "#53C723",
};
